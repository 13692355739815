import { EuiImage } from "@elastic/eui";

export type MMIconType =
  | "referral"
  | "care-need"
  | "referrer"
  | "indication"
  | "characteristic"
  | "usage"
  | "signal-0"
  | "signal-1"
  | "signal-2"
  | "signal-3"
  | "signal-4"
  | "signal-5";
export interface MMIconProps {
  type: MMIconType;
  title?: string;
}

function MMIcon(props: MMIconProps) {
  return (
    <EuiImage
      title={props.title}
      alt={props.type}
      src={`/icons/${props.type}.svg`}
      style={{ display: "inline", position: "relative", top: "-2px" }}
    />
  );
}

export default MMIcon;
