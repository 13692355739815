import { EuiBasicTable, EuiFlexItem, EuiPanel, EuiText } from "@elastic/eui";
import ConnectAPIHelper from "api/connect-api-helper";
import AuthenticationHelper from "helpers/authentication-helper";
import txt from "helpers/text-helper";
import { useEffect, useState } from "react";
import { Order } from "store/data/order/order";

import { ApiResponse, ApiResponseStatus } from "api/api-helper";
import DateHelper from "helpers/date-helper";
import { CostingCheckLog, toCostingLogDescription } from "store/data/costing/costing";
import { fullName } from "store/data/personal-details/personal-details";

export interface MMCostingLogsProps {
  order: Order;
}

const DEFAULT_PAGE_SIZE = 5;
export const PAGE_SIZE_OPTIONS = [5, 10];

function MMCostingLogs(props: MMCostingLogsProps) {
  const api = new ConnectAPIHelper();

  const [logs, setLogs] = useState<CostingCheckLog[]>([]);
  const [order] = useState(props.order);
  const [limit, setLimit] = useState<number>(DEFAULT_PAGE_SIZE);
  const [offset, setOffset] = useState<number>(0);
  const [total, setTotal] = useState(0);

  const loadLogs = async () => {
    const canSeeLogs: boolean = await AuthenticationHelper.hasPermission([
      "costings#read_all",
      "costings#edit_all",
      "invoices#read_all",
      "invoices#edit_all",
    ]);
    if (canSeeLogs) {
      const result: ApiResponse = await api.getCostingLogs({ order_id: order.id }, limit, offset, "created_at", "DESC");
      if (result && result.status === ApiResponseStatus.OK) {
        setLogs(result.result);
        setTotal(result.meta_data.result_set.total);
      } else {
        setLogs([]);
        setTotal(0);
      }
    } else {
      setLogs([]);
      setTotal(0);
    }
  };

  useEffect(() => {
    loadLogs();
  }, [limit, offset]);

  const onLogsChange = ({ page = {} as any, sort = {} as any }) => {
    if (page.size) {
      const newLimit = page.size;
      const newOffset = Math.max(0, page.index * page.size);
      if (limit !== newLimit) setLimit(newLimit);
      if (offset !== newOffset) setOffset(newOffset);
    }
  };

  const limitOffsetToPage = (limit: number, offset: number) => {
    //pages in EUI are zero based
    const page = limit > 0 ? Math.max(0, offset / limit) : 0;
    return page;
  };

  const pagination: any = {
    pageIndex: limitOffsetToPage(limit, offset),
    pageSize: limit,
    totalItemCount: total,
    pageSizeOptions: PAGE_SIZE_OPTIONS,
    showPerPageOptions: false,
  };

  const columns = () => [
    {
      name: txt.get("orders.order.costing.log"),
      truncateText: false,
      width: "250px",
      render: (log: CostingCheckLog) => (
        <EuiText size="xs">
          {DateHelper.format(log.date, "dd-MM-yy HH:ii")}{" "}
          <span style={{ color: "#777" }}>
            {log.order_line_costing?.order_line?.code ? log.order_line_costing.order_line.code : ""} (
            {log.order_line_costing?.order_line?.id})
          </span>
          {log.comment ? <br /> : ""}
          {log.comment ? log.comment : ""}
          <br />
          <span style={{ fontWeight: 700 }}>{toCostingLogDescription(log)}</span>
          &nbsp;
          {log.personal_details ? txt.lo("generic.by_x", fullName(log.personal_details)) : "by unknown"}
        </EuiText>
      ),
    },
  ];

  return logs && logs.length > 0 ? (
    <EuiFlexItem style={{ maxWidth: "250px" }}>
      <EuiBasicTable
        itemId="id"
        items={logs}
        columns={columns()}
        noItemsMessage={txt.uf("generic.found_no_x", txt.lo("orders.order.log"))}
        onChange={onLogsChange}
        pagination={pagination}
      />
    </EuiFlexItem>
  ) : (
    <EuiPanel>
      <EuiText size="s" color="gray">
        {txt.uf("generic.found_no_x", txt.lo("orders.order.log"))}
      </EuiText>
    </EuiPanel>
  );
}

export default MMCostingLogs;
