import {
  EuiButtonIcon,
  EuiFieldText,
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiPanel,
  EuiSelect,
  EuiSpacer,
} from "@elastic/eui";
import MMTitle from "components/layouts/title/title";
import txt from "helpers/text-helper";
import { onStringValue, updateField } from "hoc/helper-hooks";
import { useEffect, useState } from "react";
import { ACTIVE_COUNTRY_CODES } from "store/data/adress/address";
import { Client } from "store/data/client/client";

export interface MMAddressInfoProps {
  client: Client | null;
  isEditable?: boolean;
  isHeaderShown?: boolean;
}

function MMAddressInfo(props: MMAddressInfoProps) {
  const [client, setClient] = useState<Client>();
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [isHeaderShown] = useState<boolean>(false);
  const [selectedCountry, setSelectedCountry] = useState<any>();

  const countryOptions: any[] = ACTIVE_COUNTRY_CODES.map((code) => ({
    value: code.toLowerCase(),
    text: code.toUpperCase(),
  }));

  useEffect(() => {
    setIsEditable(!!props.isEditable);
    if (props.client) {
      setSelectedCountry(client?.country || "");
      setClient(props.client);
    } else {
      setClient(undefined);
    }
  }, [props.client, props.isEditable, props.isHeaderShown]);

  const onClient = (client: Client) => {
    setClient(client);
  };

  return client ? (
    <EuiFlexGrid gutterSize="s" className="subtle-editing">
      <EuiFlexItem grow={false}>
        <EuiFlexGroup justifyContent="spaceBetween">
          <MMTitle text={txt.get("clients.address_info.name")} />
          <EuiButtonIcon aria-label="add" iconType={"plus"} />
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiPanel className="subtle-panel">
        <EuiFlexItem grow={false}>
          <EuiFlexGroup gutterSize="xs" style={{ maxWidth: "400px" }}>
            <EuiFlexItem grow={5}>
              <EuiFormRow
                display="rowCompressed"
                label={isHeaderShown ? txt.get("address.street_name") : undefined}
                title={txt.get("address.street_name")}
              >
                <EuiFieldText
                  placeholder={txt.get("address.street_name")}
                  compressed={true}
                  disabled={!isEditable}
                  name="street_name"
                  value={client.street_name || ""}
                  onChange={(e) => onClient(updateField(client, "street_name", onStringValue(e)))}
                />
              </EuiFormRow>
            </EuiFlexItem>{" "}
            <EuiFlexItem grow={2}>
              <EuiFormRow
                display="rowCompressed"
                label={isHeaderShown ? txt.get("address.street_number") : undefined}
                title={txt.get("address.street_number")}
              >
                <EuiFieldText
                  placeholder={txt.get("address.street_number_short")}
                  compressed={true}
                  disabled={!isEditable}
                  name="street_number"
                  value={client.street_number || ""}
                  onChange={(e) => onClient(updateField(client, "street_number", onStringValue(e)))}
                />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem grow={2}>
              <EuiFormRow
                display="rowCompressed"
                label={isHeaderShown ? txt.get("address.street_number_suffix") : undefined}
                title={txt.get("address.street_number_suffix")}
              >
                <EuiFieldText
                  placeholder={txt.get("address.street_number_suffix_short")}
                  compressed={true}
                  disabled={!isEditable}
                  name="street_number_suffix"
                  value={client.street_number_suffix || ""}
                  onChange={(e) => onClient(updateField(client, "street_number_suffix", onStringValue(e)))}
                />
              </EuiFormRow>
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiSpacer size="s" />
          <EuiFlexGroup gutterSize="xs" style={{ maxWidth: "400px" }}>
            <EuiFlexItem grow={2}>
              <EuiFormRow
                display="rowCompressed"
                label={isHeaderShown ? txt.get("address.postal_area") : undefined}
                title={txt.get("address.postal_area")}
              >
                <EuiFieldText
                  placeholder={txt.get("address.postal_area")}
                  compressed={true}
                  disabled={!isEditable}
                  name="postal_area"
                  value={client.postal_code || ""}
                  onChange={(e) => onClient(updateField(client, "postal_area", onStringValue(e)))}
                />
              </EuiFormRow>
            </EuiFlexItem>{" "}
            <EuiFlexItem grow={3}>
              <EuiFormRow
                display="rowCompressed"
                label={isHeaderShown ? txt.get("address.city") : undefined}
                title={txt.get("address.city")}
              >
                <EuiFieldText
                  placeholder={txt.get("address.city")}
                  compressed={true}
                  disabled={!isEditable}
                  name="city"
                  value={client.city || ""}
                  onChange={(e) => onClient(updateField(client, "city", onStringValue(e)))}
                />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem grow={1}>
              <EuiFormRow
                display="rowCompressed"
                label={isHeaderShown ? txt.get("address.country") : undefined}
                title={txt.get("address.country")}
              >
                <EuiSelect
                  disabled={!isEditable}
                  aria-placeholder={txt.get("address.country")}
                  compressed={true}
                  options={countryOptions}
                  value={selectedCountry}
                  onChange={(e) => setSelectedCountry(e.target.value)}
                  aria-label={txt.get("address.country")}
                />
              </EuiFormRow>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiPanel>
    </EuiFlexGrid>
  ) : (
    <></>
  );
}

export default MMAddressInfo;
