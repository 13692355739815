import { EuiFlexGroup, EuiFlexItem, EuiMarkdownFormat, EuiSpacer, EuiText, EuiTitle } from "@elastic/eui";
import txt from "helpers/text-helper";

function MMDeliveryInstructions() {
  return (
    <EuiFlexGroup gutterSize="xs">
      <EuiFlexItem>
        <EuiTitle size="xxs">
          <EuiText color="grey">{txt.get("orders.delivery.instructions")}</EuiText>
        </EuiTitle>
        <EuiSpacer size="s" />
        <EuiText size="s" color="grey">
          <EuiMarkdownFormat textSize="s">{txt.get("orders.delivery.instruction_details_md")}</EuiMarkdownFormat>
        </EuiText>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
}

export default MMDeliveryInstructions;
