import { EuiFlexItem, EuiInMemoryTable, EuiSearchBarProps, EuiText, EuiToolTip } from "@elastic/eui";
// import { useNavigate } from "react-router-dom";
import ConnectAPIHelper from "api/connect-api-helper";
import MMCell from "components/layouts/table/cell";
import MMColumnFormatted from "components/layouts/table/column-formatted";
import StringHelper from "helpers/string-helper";
import txt from "helpers/text-helper";
import { columnString } from "hoc/helper-hooks";
import { useEffect, useState } from "react";
import { Contract, toAGPHDescription } from "store/data/contract/contract";
import { Costing } from "store/data/costing/costing";

export const PAGE_SIZE_OPTIONS = [25]; //,0]; //0 would mean without limit
export const DEFAULT_PAGE_SIZE = 25;
export const SEARCH_DEBOUNCE_DELAY = 700;
export const DEFAULT_SORT_BY = "id";
export const DEFAULT_SORT_ORDER = "asc";

export interface ContractSelectHandler {
  (contract: Contract): void;
}
export interface MMCostingContractListProps {
  selectable?: boolean;
  onSelect?: ContractSelectHandler;
  search?: string;
  contracts: Contract[];
  costing?: Costing | null;
  isEditable: boolean;
  isYearVisible?: boolean;
}

function MMCostingContractsList(props: MMCostingContractListProps) {
  const api = new ConnectAPIHelper();

  const [search] = useState<string>(props.search || "");
  const [isYearVisible, setIsYearVisible] = useState<boolean>(props.isYearVisible || false);
  const [contracts, setContracts] = useState<any[]>([]);
  const [productFields, setProductFields] = useState<any>({});

  useEffect(() => {
    if (props.contracts !== undefined && props.contracts !== null) {
      setContracts(props.contracts);
    } else {
      setContracts([]);
    }
  }, [props.contracts]);

  useEffect(() => {
    setIsYearVisible(!!props.isYearVisible);
  }, [props.isYearVisible]);

  useEffect(() => {
    const load = async () => {
      const productFields = await api.getProductFields();
      let lookup: any = {};
      for (let i = 0; i < productFields.length; i++) {
        const fields = productFields[i];
        lookup[fields.base_article_code] = { ...fields };
      }
      console.log(lookup);
      setProductFields(lookup);
    };
    load();
  }, []);

  const onContractSelect = (contract: Contract) => {
    console.log("select other contract");
    if (props.onSelect) {
      props.onSelect(contract);
    }
  };

  const contractFieldToSortKey = (field: string) => {
    switch (field) {
      default:
        return field;
    }
  };

  const columns = () => {
    const columns = [
      {
        name: txt.get("admin.contracts.base_article_code"),
        field: "base_article_code",
        sortable: true,
        render: (base_article_code: string) => (
          <MMCell
            text={
              productFields[base_article_code] && productFields[base_article_code].product_name
                ? productFields[base_article_code].product_name
                : ""
            }
            subText={base_article_code}
            subSoft={true}
          />
        ),
      },

      {
        name: txt.get("admin.contracts.product_declaration_description"),
        field: "product_declaration_description",
        sortable: true,
        render: (product_declaration_description: string, contract: Contract) => (
          <MMCell text={product_declaration_description} subText={contract.product_declaration_code} subSoft={true} />
        ),
      },
      {
        name: txt.get("admin.contracts.product_declaration_additional_codes"),
        field: "product_declaration_additional_code1",
        sortable: api.contractIsSortableBy(contractFieldToSortKey("product_declaration_code")),
        render: (product_declaration_additional_code1: string, contract: Contract) => (
          <MMCell
            text={
              contract.product_declaration_additional_code1
                ? contract.product_declaration_additional_code1.split(",").map((codePart: string, i: number) => (
                    <EuiToolTip key={`code-${i}`} position="top" content={toAGPHDescription(codePart)}>
                      <EuiText size="s">{codePart} </EuiText>
                    </EuiToolTip>
                  ))
                : ""
            }
            subText={
              contract.product_declaration_additional_code2
                ? contract.product_declaration_additional_code2.split(",").map((codePart: string, i: number) => (
                    <EuiToolTip key={`code-${i}`} position="top" content={toAGPHDescription(codePart)}>
                      <EuiText size="s">{codePart} </EuiText>
                    </EuiToolTip>
                  ))
                : ""
            }
          />
        ),
      },
      {
        name: txt.get("admin.contracts.invoice_line_description"),
        field: "invoice_line_description",
        sortable: true,
        render: (invoice_line_description: string) => (
          <MMColumnFormatted value={columnString(invoice_line_description)} noWrap={false} />
        ),
      },
      {
        name: txt.get("admin.contracts.product_declaration_value"),
        field: "product_declaration_value",
        sortable: true,
        render: (product_declaration_value: string, contract: any) => (
          <MMCell
            text={columnString(product_declaration_value ? StringHelper.currency(product_declaration_value) : "-")}
            wrap={false}
            subSoft={true}
            subText={isYearVisible ? contract.year : undefined}
          />
        ),
      },
    ];
    return columns;
  };

  const getRowProps = (contract: any) => {
    const { id } = contract;
    return {
      "data-selected": !!props.costing?.contract_id && contract.id === props.costing?.contract_id ? "true" : "false",
      "data-id": `row-${id}`,
      "data-article": (contract.base_article_code || "").toLowerCase(),
      "data-testid": `row-order-contract`,
      onClick: props.isEditable
        ? (e: any) => {
            e.stopPropagation();
            if (e.target.tagName !== "BUTTON" && e.target.tagName !== "INPUT" && e.target.tagName !== "A") {
              onContractSelect(contract);
            }
          }
        : () => {},
    };
  };
  const searchBar: EuiSearchBarProps = {
    query: search,
    box: {
      incremental: false,
      schema: true,
    },
  };

  return (
    <EuiFlexItem className="contract-search">
      <EuiInMemoryTable
        data-testid="table-order-contracts"
        search={searchBar}
        itemId="id"
        items={contracts}
        columns={columns()}
        sorting={true}
        rowProps={getRowProps}
      />
    </EuiFlexItem>
  );
}

export default MMCostingContractsList;
