import { EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiTextArea, useIsWithinBreakpoints } from "@elastic/eui";
import MMAITextToSentence from "components/layouts/aitools/text-to-sentence";
import { feat, Feature } from "feats";
import AuthenticationHelper from "helpers/authentication-helper";
import { ValidationResult } from "helpers/input-helper";
import txt from "helpers/text-helper";
import { CSSProperties, useEffect, useState } from "react";
import { MMOrderLineFieldProps, MMOrderLineProductInput } from "../order-line-entry";

function MMOrderArgumentation(props: MMOrderLineFieldProps) {
  const isMobile: boolean = useIsWithinBreakpoints(["xs", "s"]);
  const [argumentation, setArgumentation] = useState<string>(props.selection.argumentation);
  const [error, setError] = useState<string>();

  const [isTextToSentenceShown, setIsTextToSentenceShown] = useState<boolean>(false);
  const [isLarge, setIsLarge] = useState<boolean>(
    true || !!(props.selection && props.selection.ui && props.selection.ui?.argumentationIsLarge)
  );

  useEffect(() => {
    const setInterfaceForPermissions = async () => {
      setIsTextToSentenceShown(await AuthenticationHelper.hasPermission(["contracts#read_all", "contracts#edit_all"]));
    };
    setInterfaceForPermissions();
  }, []);

  useEffect(() => {
    setArgumentation(props.selection.argumentation);
    setIsLarge(true || !!(props.selection && props.selection.ui && props.selection.ui?.argumentationIsLarge));
  }, [props.selection]);

  useEffect(() => {
    if (props.hasValidation && props.orderLineInputs) {
      if (
        props.orderLineInputs.argumentation &&
        props.orderLineInputs.argumentation.mandatory &&
        props.orderLineInputs.argumentation.validator
      ) {
        let validationResult: ValidationResult = props.orderLineInputs.argumentation.validator(argumentation);
        setError(!validationResult.result ? validationResult.message : "");
      } else {
        setError("");
      }
    } else {
      setError("");
    }
  }, [props.hasValidation, props.orderLineInputs, argumentation]);

  const onArgumentationChange = (argumentation: string) => {
    if (props.onChange) {
      props.onChange({
        ...(props.selection || {}),
        argumentation,
      } as MMOrderLineProductInput);
    } else {
      setArgumentation(argumentation);
    }
  };

  const css = (): CSSProperties => {
    let result: any = {};

    if (!isMobile) {
      result = {
        ...result,
        width: "100%",
        minWidth: !feat(Feature.OrdersLegacyIntakeScreens) ? "260px" : "382px",
        maxWidth: props.maxWidth ? props.maxWidth : "260px",
        // height: "72px",
      };
    }
    if (!isLarge) {
      result = { ...result, height: "32px", overflowY: "hidden" };
    }

    return result;
  };

  return (
    <EuiFlexGroup gutterSize="xs" style={css()}>
      <EuiFlexItem style={{ position: "relative" }}>
        <EuiFormRow
          className="product-input"
          display="rowCompressed"
          label={props.showTitle ? txt.get("orders.order.motivation") : undefined}
          style={css()}
          isInvalid={!!error}
          error={error}
        >
          <EuiTextArea
            onFocus={props.onFocus || undefined}
            value={argumentation}
            rows={isLarge ? (!feat(Feature.OrdersLegacyIntakeScreens) ? 11 : 3) : 1}
            compressed={true}
            style={css()}
            placeholder={`${txt.get("orders.order.motivation")}...`}
            onChange={(e) => setArgumentation(e.target.value)}
            onBlur={(e) => onArgumentationChange(e.target.value)}
            data-testid={props.testId || undefined}
            disabled={!props.isEditable}
          />
        </EuiFormRow>
        {isTextToSentenceShown ? (
          <MMAITextToSentence
            style={{ position: "absolute", top: "5px", right: "15px" }}
            text={argumentation}
            intent="motivation"
            onResult={(sentence: string) => {
              onArgumentationChange(sentence);
            }}
          />
        ) : (
          <></>
        )}
      </EuiFlexItem>
    </EuiFlexGroup>
  );
}

export default MMOrderArgumentation;
