import { feat, Feature } from "feats";
import { OrderLine } from "../order/order";
import { getNeed, hasNeed, Need } from "./need";

export const NEED_LEGACY_MAPPING_TEXTS: any = {
  "usage.explanation": ["care_needs.activities", "usage.other"],
  "characteristics.explanation": ["anatomy.other", "characteristics.other"],
  "care_needs.explanation": ["care_needs.needs"],
};
export const NEED_LEGACY_MAPPING_CHECKS: any = {
  "care_needs.pain": "anatomy.pain",
  "care_needs.strength": "anatomy.strength",
  "care_needs.mobility": "anatomy.mobility",
  "care_needs.sensibility": "anatomy.sensibility",
  "": "usage.hygiene",
};

export const handleLegacyOrderLine = (orderLine: OrderLine, needs: Need[]): OrderLine => {
  let updatedOrderLine = { ...orderLine };
  // old_product.used
  // old_product.not_used_description
  // old_product.description

  let deprecatedOldProductNeed: string = getNeed(needs, "old_product.used");

  let deprecatedOldProductDescription: string =
    deprecatedOldProductNeed === "true" ? getNeed(needs, "old_product.description") : "";
  let deprecatedOldProductNotUsedNeedDescription: string = getNeed(needs, "old_product.not_used_description");

  if (deprecatedOldProductNeed === "" && deprecatedOldProductNotUsedNeedDescription !== "") {
    deprecatedOldProductNeed = "false";
  }

  let mergedDeprecatedDescription: string =
    deprecatedOldProductNeed === "true"
      ? deprecatedOldProductDescription
      : deprecatedOldProductNeed === "false"
        ? deprecatedOldProductNotUsedNeedDescription
        : "";
  let newHasPreviousAid: boolean | null | undefined = orderLine.has_previous_aid;

  let newPreviousAid: string | undefined = orderLine.previous_aid || undefined;

  // Calculated hasPreviousAid value according to product data of a deprecated-intake-screen.
  let has =
    (newHasPreviousAid === undefined || newHasPreviousAid === null) && deprecatedOldProductNeed !== ""
      ? deprecatedOldProductNeed === "true"
      : newHasPreviousAid === undefined
        ? null
        : newHasPreviousAid;

  // Calculated previousAid value according to product data of a deprecated-intake-screen.
  let txt =
    (newPreviousAid === undefined || mergedDeprecatedDescription === null) && mergedDeprecatedDescription !== ""
      ? mergedDeprecatedDescription
      : newPreviousAid;

  updatedOrderLine.has_previous_aid = has;
  updatedOrderLine.previous_aid = txt || "";
  // console.log({
  //   func: "handleLegacyOrderLine",
  //   needs,
  //   orderLine,
  //   updatedOrderLine,
  //   mergedDeprecatedDescription,
  //   newHasPreviousAid,
  //   txt,
  // });
  return updatedOrderLine;
};

export const handleLegacyOrderLines = (orderLines: OrderLine[], needs: Need[]): OrderLine[] => {
  if (feat(Feature.OrdersLegacyIntakeScreens)) {
    return orderLines;
  }

  let updatedOrderLines: OrderLine[] = [];
  updatedOrderLines = orderLines.map((orderLine: OrderLine) => handleLegacyOrderLine(orderLine, needs));

  return updatedOrderLines;
};

export const handleLegacyNeeds = (needs: Need[]): Need[] => {
  if (feat(Feature.OrdersLegacyIntakeScreens)) {
    return needs;
  }

  let updatedNeeds: Need[] = [];

  let legacyNeedQuestions: string[] = Object.values(NEED_LEGACY_MAPPING_CHECKS);
  Object.values(NEED_LEGACY_MAPPING_TEXTS).forEach((keys: any) => {
    legacyNeedQuestions = legacyNeedQuestions.concat(keys);
  });

  updatedNeeds = needs.map((need: Need) => ({
    ...need,
    answer: fallbackToLegacyNeed(need.question, need.answer, needs),
  }));

  for (const newQuestion in NEED_LEGACY_MAPPING_CHECKS) {
    if (Object.prototype.hasOwnProperty.call(NEED_LEGACY_MAPPING_CHECKS, newQuestion)) {
      const oldQuestions: string | string[] = NEED_LEGACY_MAPPING_CHECKS[newQuestion];
      if (updatedNeeds.findIndex((need: Need) => need.question === newQuestion) < 0) {
        let toCheck: string[] = typeof oldQuestions === "string" ? [oldQuestions] : oldQuestions;
        let oldAnswer: string = toCheck
          .map((question: string) => getNeed(needs, question))
          .filter((answer: string) => answer !== "")
          .join("; ");
        updatedNeeds.push({ question: newQuestion, answer: oldAnswer } as Need);
      }
    }
  }
  updatedNeeds = updatedNeeds.filter((need: Need) => legacyNeedQuestions.indexOf(need.question) < 0);
  console.log("handleLegacyNeeds", legacyNeedQuestions, needs, updatedNeeds);

  return updatedNeeds;
};

export const fallbackToLegacyNeed = (question: string, answer: string, needs: Need[]): string => {
  let result: string = answer;

  //fallback for older orders before -feature-intake-improvements branch merging 2024-08-07

  if (Object.keys(NEED_LEGACY_MAPPING_TEXTS).includes(question) && !hasNeed(needs, question)) {
    let oldText: string = (
      typeof NEED_LEGACY_MAPPING_TEXTS[question] === "string"
        ? [NEED_LEGACY_MAPPING_TEXTS[question]]
        : NEED_LEGACY_MAPPING_TEXTS[question]
    )
      .map((mapped: string) => getNeed(needs, mapped))
      .filter((answer: string) => answer !== "")
      .join("; ");

    if (oldText !== "") {
      result = oldText;
    }
  } else if (Object.keys(NEED_LEGACY_MAPPING_CHECKS)) {
    let oldChecked: string = getNeed(needs, NEED_LEGACY_MAPPING_CHECKS[question]);
    let newChecked: string = result;
    if (newChecked === "" && (oldChecked === "true" || oldChecked === "false")) {
      result = oldChecked;
    }
  }

  return result;
};
