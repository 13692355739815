import { EuiFlexItem, EuiSpacer } from "@elastic/eui";
import MMPage from "components/layouts/page/page";
import txt from "helpers/text-helper";
import MMScansList from "./scans-list";

function MMScans() {
  return (
    <MMPage hideTitle={true} title={txt.get("scanning.scans.page_title")}>
      <EuiFlexItem>
        <EuiSpacer />
        <MMScansList />
      </EuiFlexItem>
    </MMPage>
  );
}

export default MMScans;
