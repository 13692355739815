import { EuiFlexItem, EuiLoadingSpinner, EuiModal } from "@elastic/eui";
import { ApiResponse, ApiResponseStatus } from "api/api-helper";
import ConnectAPIHelper from "api/connect-api-helper";
import { trackEvent } from "helpers/analytics-helper";
import AuthenticationHelper from "helpers/authentication-helper";
import { formatApiError } from "helpers/error-helper";
import txt from "helpers/text-helper";
import UrlHelper from "helpers/url-helper";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { AlertConfirmActionState, confirmAsk, confirmGet } from "store/components/alert/confirm";
import { toastAdd } from "store/components/toast/toast";
import { Order, orderToApiOrderInfo, ORDER_EMPTY } from "store/data/order/order";
import { v4 as uuid } from "uuid";
import MMOrderEntry from "./order-entry/order-entry";

export type OrderNavigate = (orderId?: string | number) => void;

function MMOrderPopover() {
  let searchParams = useSearchParams();
  const [orderChanged, setOrderChanged] = useState<boolean>(false);
  const [orderId, setOrderId] = useState<number | string>();
  const [orderTab, setOrderTab] = useState<string>();
  const [order, setOrder] = useState<Order | null>();
  const [changedOrder, setChangedOrder] = useState<Order | null>();
  const [closeRef] = useState("close_order_" + uuid());

  const [userId] = useState(AuthenticationHelper.getPersonalDetailsId());
  const [products, setProducts] = useState<any[]>([]);
  const [isLoadingProducts, setIsLoadingProducts] = useState<boolean>(false);

  const dispatch = useDispatch();
  const orderNavigate: OrderNavigate = (orderId?: string | number) => {
    let params = UrlHelper.queryParams();

    if (!!!orderId) {
      delete params.order;
      closeOrder();
    } else if (orderId && ((typeof orderId === "string" && orderId === "new") || !isNaN(orderId as number))) {
      params.order = orderId;
    }
    console.log("orderNavigate", params);
    searchParams[1](params);
  };

  const api: ConnectAPIHelper = new ConnectAPIHelper();

  const loadOrder = async (orderToLoad?: any) => {
    console.log("loadOrder iuds:", orderId, orderToLoad);
    if (!orderToLoad) {
      orderToLoad = orderId;
      orderToLoad = Number(orderToLoad);
    }

    if (isNaN(orderToLoad)) {
      // dispatch(toastAdd(`No order id ${orderToLoad}`, null, "danger"));
    } else {
      const result = await api.getOrder(orderToLoad);
      console.log("loadOrder result", orderToLoad, result);
      if (!result || result.message) {
        setOrder(null);
        setChangedOrder(null);
        navigateToClose();
        dispatch(toastAdd(result.message, null, "danger"));
      } else {
        setOrder(result);
        setChangedOrder(result);
      }
      setOrderChanged((old: boolean) => false);
    }
  };

  useEffect(() => {
    const handleUnloadWarning = (e: any) => {
      // Recommended
      e.preventDefault();

      // Included for legacy support, e.g. Chrome/Edge < 119
      e.returnValue = true;
      console.log("handleUnloadWarning");
    };

    if (orderChanged) {
      window.addEventListener("beforeunload", handleUnloadWarning);
    } else {
      window.removeEventListener("beforeunload", handleUnloadWarning);
    }

    // onbeforeunload = (event) => {};

    return () => {
      window.removeEventListener("beforeunload", handleUnloadWarning);
    };
  }, [orderChanged]);

  useEffect(() => {
    const loadProducts = async () => {
      setIsLoadingProducts(true);
      let products: any[] = await api.getProducts();
      setProducts(products);
      setIsLoadingProducts(false);
    };

    if (orderId && userId && products.length === 0) {
      loadProducts();
    }
  }, [orderId, userId]);

  useEffect(() => {
    console.log("MMOrderPopover", orderId);
    if (orderId) {
      setOrderChanged(false);
      if (orderId === "new") {
        setOrder(ORDER_EMPTY);
      } else if (!isNaN(Number(orderId))) {
        loadOrder(orderId);
      } else {
        console.log("ORDER ID CHANGED TO NON NUMER?", orderId);
        console.log("ORDER ID CHANGED TO NON NUMBR?", orderId);
      }
    } else {
      closeOrder();
    }
  }, [orderId]);

  useEffect(() => {
    const orderId: string | number = UrlHelper.queryParam("order");
    if (orderId) {
      setOrderId(orderId);
    }
    setOrderTab(UrlHelper.queryParam("order_tab"));
  }, [searchParams[0]]);

  const handleClose = () => {
    if (orderChanged) {
      dispatch(
        confirmAsk(
          `${txt.uf("generic.save_x", txt.get("orders.order.name"))}?`,
          txt.get("orders.order.close_confirm"),
          closeRef,
          undefined,
          undefined,
          txt.uf("generic.save"),
          txt.uf("generic.back_to", txt.get("orders.order.name")),
          "success",
          txt.get("generic.close_without_saving")
        )
      );
    } else {
      navigateToClose();
    }
  };

  const alertConfirm = useSelector(confirmGet);
  useEffect(() => {
    if (alertConfirm.actionState === AlertConfirmActionState.Alternative && alertConfirm.actionKey === closeRef) {
      navigateToClose();
    } else if (alertConfirm.actionState === AlertConfirmActionState.Perform && alertConfirm.actionKey === closeRef) {
      saveAndNavigateToClose();
    }
  }, [alertConfirm]);

  const saveAndNavigateToClose = async () => {
    await saveChangedOrder(false);
    navigateToClose();
  };
  const navigateToClose = () => {
    let params = UrlHelper.queryParams();
    if (params.order) {
      delete params.order;
    }
    closeOrder();
    searchParams[1](params);
  };

  const closeOrder = () => {
    setOrderId(undefined);
    setOrder(null);
    setChangedOrder(null);
    setOrderChanged(false);
  };

  const saveChangedOrder = async (silent: boolean = true) => {
    if (!changedOrder) {
      return false;
    }

    const orderInfo: any = orderToApiOrderInfo(changedOrder);
    const response: ApiResponse = await api.saveOrder(orderInfo);

    if (response.status === ApiResponseStatus.OK) {
      if (!silent) {
        trackEvent("order", "order_saved", `order ${response.result.order_id}`);
      }

      if (!silent) {
        dispatch(toastAdd(txt.get("generic.is_saved", txt.get("orders.order.name")), null, "success"));
      }
    } else {
      const { title, message, importance, code } = formatApiError(response);
      dispatch(toastAdd(title, message, importance, code));
    }
  };

  return !!orderId ? (
    <EuiModal className={`order-modal`} onClose={() => handleClose()}>
      {order ? (
        <MMOrderEntry
          orderNavigate={orderNavigate}
          orderTab={orderTab}
          onOrderId={(orderId: number) => setOrderId(orderId)}
          onOrderChange={(order: Order) => {
            setChangedOrder(order);
            setOrderChanged(true);
          }}
          order={order}
          products={products}
          isLoadingProducts={isLoadingProducts}
          isOrderChanged={orderChanged}
          onOrderVersion={(version: any) => {
            loadOrder();
          }}
        />
      ) : (
        <EuiFlexItem
          style={{
            width: "100vw",
            maxWidth: "1400px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <EuiLoadingSpinner />
        </EuiFlexItem>
      )}
    </EuiModal>
  ) : (
    <></>
  );
}

export default MMOrderPopover;
