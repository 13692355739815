import { EuiButton, EuiFlexItem, EuiSpacer } from "@elastic/eui";
import ManoXAPIHelper from "api/manox-api-helper";
import MMPage, { PageLink } from "components/layouts/page/page";
import txt from "helpers/text-helper";
import { useEffect, useState } from "react";
import { adminNav } from "../admin";
import MMScannersList from "./scanners-list";

function MMAdminScanners() {
  const api = new ManoXAPIHelper();

  const [buttonsToShow, setButtonsToShow] = useState<any[]>([]);
  const [subPages, setSubPages] = useState<PageLink[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const getSubPages = async () => {
      setSubPages(await adminNav("/admin/scanners"));
    };

    getSubPages();
  }, []);

  const handleDownloadSshConfigFile = async () => {
    setIsLoading(true);

    try {
      // Make the API call
      const response = await api.getScannersConfigFile();
      if (!response) {
        throw new Error("Null response");
      }
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Get the filename from Content-Disposition header or use default
      const contentDisposition = response.headers.get("Content-Disposition");
      let filename = "scanner-ssh-config.txt";
      if (contentDisposition) {
        const filenameMatch = contentDisposition.match(/filename=(.+)/);
        if (filenameMatch) filename = filenameMatch[1];
      }

      // Get the text content
      const text = await response.text();

      // Create a blob and download it
      const blob = new Blob([text], { type: "text/plain" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();

      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error("handleDownloadSshConfigFile", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const setInterfaceForPermissions = async () => {
      let buttons: any[] = [];
      buttons.push(
        <EuiButton
          key="download-config"
          color="primary"
          iconType="download"
          onClick={handleDownloadSshConfigFile}
          size="s"
          isLoading={isLoading}
          isDisabled={isLoading}
        >
          {txt.get("admin.scanners.download_ssh_config_file")}
        </EuiButton>
      );
      setButtonsToShow(buttons);
    };
    setInterfaceForPermissions();
  }, []);

  const buttons = () => {
    return buttonsToShow;
  };

  return (
    <MMPage
      title={`${txt.get("admin.page_title")} ${txt.lo("admin.scanners.page_title")}`}
      subPages={subPages}
      backTo="/admin"
      backToText={txt.uf("generic.back_to", txt.get("admin.page_title"))}
      sideActions={buttons()}
    >
      <EuiFlexItem>
        <EuiSpacer />
        <MMScannersList />
      </EuiFlexItem>
    </MMPage>
  );
}
export default MMAdminScanners;
