import txt from "helpers/text-helper";
import { File } from "store/data/file/file";
import { Indication, INDICATION_EMPTY } from "../indication/indication";
import { Order } from "../order/order";
import { Referrer } from "../referrer/referrer";

export enum ReferralStatus {
  Pending = "PENDING",
  Submitted = "SUBMITTED",
  Accepted = "ACCEPTED",
  Rejected = "REJECTED",
  NotApplicable = "NOT_APPLICABLE",
}

export const REFERRAL_EMPTY: Referral = {
  id: null,
  client_code: null,
  referrer_id: null,
  referred_at: null,
  intake_at: null,
  within_previous_usage_term: false,
  referrer: null,
  no_referral_reasons: null,
  no_referral_follow_up: null,
  rejection_reasons: null,
  status: ReferralStatus.Pending,
  files: [],
  indications: [INDICATION_EMPTY],
  created_at: null,
  updated_at: null,
};

export interface ReferralStatusLog {
  id: number;
  referral_id: number;
  old_status: ReferralStatus | null;
  new_status: ReferralStatus;
  user: string;
  comment: string;
}

export interface Referral {
  id: number | null;
  client_code: string | null;
  referred_at: Date | null;
  intake_at: Date | null;
  referrer_id: number | null;
  referrer: Referrer | null;
  within_previous_usage_term: boolean | null;
  no_referral_reasons: string | null;
  no_referral_follow_up: string | null;
  rejection_reasons: string | null;
  status: ReferralStatus | null;
  indications: Indication[];
  files: File[];
  updated_at: Date | null;
  created_at: Date | null;
  orders?: Order[];
  first_order_id?: number;
}

export const referralNeedsFollowUp = (referral?: Referral) =>
  referral &&
  referral.no_referral_reasons &&
  referral.no_referral_reasons.indexOf("n/a") < 0 &&
  referral.no_referral_reasons.indexOf("n.v.t.") < 0;

export const isValidReferralStatus = (statusValue: string) => {
  return (Object as any).values(ReferralStatus).includes(statusValue);
};

export const referralToHealth = (status: string | ReferralStatus) => {
  switch (status as ReferralStatus) {
    case ReferralStatus.Pending:
      return "default";
    case ReferralStatus.Submitted:
      return "warning";
    case ReferralStatus.Accepted:
      return "success";
    case ReferralStatus.Rejected:
      return "danger";
    default:
      return "hollow";
  }
};

export const referralStatusDescription = (status: ReferralStatus) => {
  return status ? txt.uc(`referrals.status.${status.toString().toLowerCase()}`) : "-";
};

export const referralMissingText = (referral: Referral) => {
  if (!referral.status || referral.status === ReferralStatus.Pending) {
    return txt.str_to_html(`${referral.no_referral_reasons || ""}<br>${referral.no_referral_follow_up || ""}`);
  } else {
    return "-";
  }
};

export const referralRejectionText = (referral: Referral) => {
  if (!referral.status || referral.status === ReferralStatus.Rejected) {
    return txt.str_to_html(`${referral.rejection_reasons || ""}<br>`);
  } else {
    return "-";
  }
};
