import MM404 from "components/404/404";
import MMAdmin from "components/admin/admin";
import MMAdminContracts from "components/admin/contracts/admin-contracts";
import MMAdminData from "components/admin/data/admin-data";
import MMAdminAgencies from "components/admin/insurers/admin-agencies";
import MMAdminAgency from "components/admin/insurers/admin-agency";
import MMAdminInsurer from "components/admin/insurers/admin-insurer";
import MMAdminInsurers from "components/admin/insurers/admin-insurers";
import MMAdminInvoiceMatching from "components/admin/invoices/admin-invoice-matching";
import MMAdminInvoices from "components/admin/invoices/admin-invoices";
import MMAdminLocation from "components/admin/locations/admin-location";
import MMAdminLocations from "components/admin/locations/admin-locations";
import MMAdminDataOrderMatches from "components/admin/order-matches/admin-order-matches";
import MMAdminOrganisation from "components/admin/organisations/admin-organisation";
import MMAdminOrganisations from "components/admin/organisations/admin-organisations";
import MMAdminPermissions from "components/admin/permissions/admin-permissions";
import MMAdminReferrer from "components/admin/referrers/admin-referrer";
import MMAdminReferrers from "components/admin/referrers/admin-referrers";
import MMAdminScanner from "components/admin/scanners/admin-scanner";
import MMAdminScanners from "components/admin/scanners/admin-scanners";
import MMAdminUser from "components/admin/users/admin-user";
import MMAdminUsers from "components/admin/users/admin-users";
import MMAdminWorkflow from "components/admin/workflows/admin-workflow";
import MMAdminWorkflows from "components/admin/workflows/admin-workflows";
import MMClientDossier from "components/clients/client-dossier";
import MMClients from "components/clients/clients";
import MMAuthorizations from "components/finance/authorizations";
import MMDeclarations from "components/finance/declarations";
import MMFinance from "components/finance/finance";
import MMOrderValues from "components/finance/order-values";
import MMReferrals from "components/finance/referrals";
import MMHome from "components/home/home";
import MMLogout from "components/logout/logout";
import MMMyAccount from "components/my-account/my-account";
import MMOKRs from "components/okrs/okrs";
import MMOrderNew from "components/orders/order-new";
import MMOrders from "components/orders/orders";
import MMOrdersSearch from "components/orders/orders-search";
import MMPrivacyPolicy from "components/privacy-policy/privacy-policy";
import MMProduction from "components/production/production";
import MMProductionAgenda from "components/production/production-agenda";
import MMProductionAgendaImport from "components/production/production-agenda-import";
import MMProductions from "components/production/production-productions";
import MMProducts from "components/products/products";
import MMQuickLinks from "components/quick-links/quick-links";
import MMSales from "components/sales/sales";
import MMScan from "components/scanners/scan";
import MMScanning from "components/scanners/scanning";
import MMScans from "components/scanners/scans";
import MMTOC from "components/toc/toc";
import { trackView } from "helpers/analytics-helper";
import AuthenticationHelper from "helpers/authentication-helper";
import { FC, useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

const REDIRECT_EXTERNAL = "/my-dashboard";

interface MMNavigationProps {}

function MMNavigation(props: MMNavigationProps) {
  const location = useLocation();
  const navigate = useNavigate();

  const RequireAuth: FC<{
    permission?: string | string[];
    realmRoles?: string | string[];
    children: React.ReactElement;
  }> = (element: any) => {
    const [isAuthorized, setIsAuthorized] = useState<boolean | null>(null);

    useEffect(() => {
      const hasRequiredAuth = async (permission?: string | string[], realmRoles?: string | string[]) => {
        let result: boolean = false;
        if (!permission) permission = [];
        if (!realmRoles) realmRoles = [];
        if (permission.length === 0 && realmRoles.length === 0) {
          result = !!AuthenticationHelper.isAuthenticated();
        } else {
          result =
            (permission.length === 0 || (await AuthenticationHelper.hasPermission(permission))) &&
            (realmRoles.length === 0 || AuthenticationHelper.hasRealmRole(realmRoles));
        }
        setIsAuthorized(result);
      };
      hasRequiredAuth(element.permission, element.realmRoles);
    }, []);

    useEffect(() => {
      if (isAuthorized === false) {
        if (AuthenticationHelper.hasRole("external-hand-specialist")) {
          //how to manage returning to external...
          navigate(REDIRECT_EXTERNAL);
        } else {
          navigate("/404");
        }
      }
    }, [isAuthorized]);

    if (isAuthorized === null) {
      return <></>;
    } else if (isAuthorized) {
      return element.children;
    }
  };

  useEffect(() => {
    trackView(location.pathname);
  }, [location]);

  return (
    <Routes>
      <Route key="route-home" path="/" element={<MMHome />} />
      <Route
        key="route-sales"
        path="/sales"
        element={
          <RequireAuth permission={"sales#read_all"}>
            <MMSales />
          </RequireAuth>
        }
      />

      <Route
        key="route-orders"
        path="/my-dashboard"
        element={
          <RequireAuth permission={["orders#read", "orders#read_org", "orders#read_all"]}>
            <MMOrders />
          </RequireAuth>
        }
      />
      <Route
        key="route-orders-search"
        path="/orders"
        element={
          <RequireAuth permission={["orders#read", "orders#read_org", "orders#read_all"]}>
            <MMOrdersSearch />
          </RequireAuth>
        }
      />
      <Route
        key="route-order-new"
        path="/orders/new"
        element={
          <RequireAuth permission={["orders#submit", "orders#submit_org", "orders#submit_all"]}>
            <MMOrderNew />
          </RequireAuth>
        }
      />
      <Route
        key="route-clients"
        path="/clients"
        element={
          <RequireAuth permission={["client_details#read_all", "client_details#edit_all"]}>
            <MMClients />
          </RequireAuth>
        }
      />
      <Route
        key="route-client"
        path="/clients/:clientId"
        element={
          <RequireAuth permission={["client_details#read", "client_details#read_org", "client_details#read_all"]}>
            <MMClientDossier />
          </RequireAuth>
        }
      />
      <Route
        key="route-production"
        path="/production"
        element={
          <RequireAuth permission={["appointments#create", "appointments#delete", "productions#read_all"]}>
            <MMProduction />
          </RequireAuth>
        }
      />
      <Route
        key="route-production-agenda"
        path="/production/agenda"
        element={
          <RequireAuth permission={["appointments#create", "appointments#delete"]}>
            <MMProductionAgenda />
          </RequireAuth>
        }
      />
      <Route
        key="route-production-agenda-import"
        path="/production/agenda/import"
        element={
          <RequireAuth permission={["appointments#create", "appointments#delete"]}>
            <MMProductionAgendaImport />
          </RequireAuth>
        }
      />
      <Route
        key="route-production-productions"
        path="/production/productions"
        element={
          <RequireAuth permission={["productions#read_all"]}>
            <MMProductions />
          </RequireAuth>
        }
      />

      <Route
        key="route-finance"
        path="/finance"
        element={
          <RequireAuth permission={"finance#read_all"}>
            <MMFinance />
          </RequireAuth>
        }
      />

      <Route
        key="route-finance-declarations"
        path="/finance/declarations"
        element={
          <RequireAuth permission={"finance#read_all"}>
            <MMDeclarations />
          </RequireAuth>
        }
      />

      <Route
        key="route-finance-order-values"
        path="/finance/order-values"
        element={
          <RequireAuth permission={"invoices#edit_all"}>
            <MMOrderValues />
          </RequireAuth>
        }
      />

      <Route
        key="route-finance-authorizations"
        path="/finance/authorizations"
        element={
          <RequireAuth permission={"invoices#edit_all"}>
            <MMAuthorizations />
          </RequireAuth>
        }
      />
      <Route
        key="route-referrals"
        path="/finance/referrals"
        element={
          <RequireAuth permission={"referrals#edit_all"}>
            <MMReferrals />
          </RequireAuth>
        }
      />

      <Route
        key="route-products"
        path="/products"
        element={
          <RequireAuth permission={"products#read_all"}>
            <MMProducts />
          </RequireAuth>
        }
      />

      <Route
        key="route-scanning"
        path="/scanning"
        element={
          <RequireAuth permission={["scans#read_all", "scans#read_org", "scanners#read_org", "scanners#read_all"]}>
            <MMScanning />
          </RequireAuth>
        }
      />
      <Route
        key="route-scans"
        path="/scanning/scans"
        element={
          <RequireAuth permission={["scans#read_all", "scans#read_org"]}>
            <MMScans />
          </RequireAuth>
        }
      />
      <Route
        key="route-scan"
        path="/scanning/scans/:scanId"
        element={
          <RequireAuth permission={["scans#read_all", "scans#read_org"]}>
            <MMScan />
          </RequireAuth>
        }
      />

      <Route
        key="route-quick-links"
        path="/quick-links"
        element={
          <RequireAuth permission={["finance#read_all", "sales#read_all", "products#read_all", "finance#read_all"]}>
            <MMQuickLinks />
          </RequireAuth>
        }
      />

      <Route
        key="route-okrs"
        path="/okrs"
        element={
          <RequireAuth permission={"okrs#read_all"}>
            <MMOKRs />
          </RequireAuth>
        }
      />

      <Route
        key="route-admin"
        path="/admin"
        element={
          <RequireAuth
            permission={[
              "personal_details#read_all",
              "personal_details#edit_all",
              "organisations#read_all",
              "organisations#edit_all",
              "locations#read_all",
              "locations#edit_all",
              "insurers#read_all",
              "insurers#edit_all",
              "data_reload#execute",
              "validate_products#execute",
              "orders#edit_all",
              "referrers#edit_all",
              "scanners#edit_all",
            ]}
          >
            <MMAdmin />
          </RequireAuth>
        }
      />

      <Route
        key="route-admin-organisations"
        path="/admin/organisations"
        element={
          <RequireAuth permission={["organisations#read_all", "organisations#edit_all"]}>
            <MMAdminOrganisations />
          </RequireAuth>
        }
      />

      <Route
        key="route-admin-organisation"
        path="/admin/organisations/:organisationId"
        element={
          <RequireAuth permission={["organisations#read_all", "organisations#edit_all"]}>
            <MMAdminOrganisation />
          </RequireAuth>
        }
      />

      <Route
        key="route-admin-locations"
        path="/admin/locations"
        element={
          <RequireAuth permission={["locations#read_all", "locations#edit_all"]}>
            <MMAdminLocations />
          </RequireAuth>
        }
      />

      <Route
        key="route-admin-location"
        path="/admin/locations/:locationId"
        element={
          <RequireAuth permission={["locations#read_all", "locations#edit_all"]}>
            <MMAdminLocation />
          </RequireAuth>
        }
      />

      <Route
        key="route-admin-users"
        path="/admin/users"
        element={
          <RequireAuth permission={["personal_details#read_all", "personal_details#edit_all"]}>
            <MMAdminUsers />
          </RequireAuth>
        }
      />

      <Route
        key="route-admin-user"
        path="/admin/users/:userId"
        element={
          <RequireAuth permission={["personal_details#read_all", "personal_details#edit_all"]}>
            <MMAdminUser />
          </RequireAuth>
        }
      />

      <Route
        key="route-admin-insurers"
        path="/admin/insurers"
        element={
          <RequireAuth permission={["insurers#read_all", "insurers#edit_all"]}>
            <MMAdminInsurers />
          </RequireAuth>
        }
      />
      <Route
        key="route-admin-agencies"
        path="/admin/insurers/agencies"
        element={
          <RequireAuth permission={["insurers#read_all", "insurers#edit_all"]}>
            <MMAdminAgencies />
          </RequireAuth>
        }
      />

      <Route
        key="route-admin-agency"
        path="/admin/insurers/agencies/:agencyId"
        element={
          <RequireAuth permission={["insurers#read_all", "insurers#edit_all"]}>
            <MMAdminAgency />
          </RequireAuth>
        }
      />

      <Route
        key="route-admin-insurers"
        path="/admin/insurers/:insurerId"
        element={
          <RequireAuth permission={["insurers#read_all", "insurers#edit_all"]}>
            <MMAdminInsurer />
          </RequireAuth>
        }
      />

      <Route
        key="route-admin-contracts"
        path="/admin/insurers/contracts"
        element={
          <RequireAuth permission={["contracts#read_all", "contracts#edit_all"]}>
            <MMAdminContracts />
          </RequireAuth>
        }
      />

      <Route
        key="route-admin-invoices"
        path="/admin/invoices"
        element={
          <RequireAuth permission={["invoices#read_all", "invoices#edit_all"]}>
            <MMAdminInvoices />
          </RequireAuth>
        }
      />

      <Route
        key="route-admin-invoice-matching"
        path="/admin/invoices/matching"
        element={
          <RequireAuth permission={["invoices#edit_all"]}>
            <MMAdminInvoiceMatching />
          </RequireAuth>
        }
      />

      <Route
        key="route-admin-referrers"
        path="/admin/referrers"
        element={
          <RequireAuth permission={["referrers#read_all", "referrers#edit_all"]}>
            <MMAdminReferrers />
          </RequireAuth>
        }
      />

      <Route
        key="route-admin-referrer"
        path="/admin/referrers/:referrerId"
        element={
          <RequireAuth permission={["referrers#read_all", "referrers#edit_all"]}>
            <MMAdminReferrer />
          </RequireAuth>
        }
      />

      <Route
        key="route-admin-scanners"
        path="/admin/scanners"
        element={
          <RequireAuth permission={["scanners#read_all", "scanners#edit_all"]}>
            <MMAdminScanners />
          </RequireAuth>
        }
      />

      <Route
        key="route-admin-scanner"
        path="/admin/scanners/:scannerId"
        element={
          <RequireAuth permission={["scanners#read_all", "scanners#edit_all"]}>
            <MMAdminScanner />
          </RequireAuth>
        }
      />

      <Route
        key="route-admin-permissions"
        path="/admin/permissions"
        element={
          <RequireAuth realmRoles={["manage-clients"]}>
            <MMAdminPermissions />
          </RequireAuth>
        }
      />

      <Route
        key="route-admin-data"
        path="/admin/data"
        element={
          <RequireAuth permission={["data_reload#execute", "validate_products#execute"]}>
            <MMAdminData />
          </RequireAuth>
        }
      />

      <Route
        key="route-admin-workflows"
        path="/admin/workflows"
        element={
          <RequireAuth permission={["workflows#read_all", "workflows#edit_all"]}>
            <MMAdminWorkflows />
          </RequireAuth>
        }
      />

      <Route
        key="route-admin-workflow"
        path="/admin/workflows/:workflowId"
        element={
          <RequireAuth permission={["workflows#read_all", "workflows#edit_all"]}>
            <MMAdminWorkflow />
          </RequireAuth>
        }
      />

      <Route
        key="route-admin-data-order-matches"
        path="/admin/order-matches"
        element={
          <RequireAuth permission={["orders#edit_all"]}>
            <MMAdminDataOrderMatches />
          </RequireAuth>
        }
      />

      <Route
        key="route-my-account"
        path="/my-account"
        element={
          <RequireAuth>
            <MMMyAccount />
          </RequireAuth>
        }
      />
      <Route key="route-logout" path="/logout" element={<MMLogout />} />

      <Route key="route-login" path="/login" element={<MMHome />} />

      <Route key="route-privacy-policy" path="/privacy-policy" element={<MMPrivacyPolicy />} />
      <Route key="route-toc" path="/toc" element={<MMTOC />} />

      <Route key="route-404" path="*" element={<MM404 />} />
    </Routes>
  );
}

export default MMNavigation;
