import AuthenticationHelper from "helpers/authentication-helper";
import env from "helpers/env-helper";
import * as mqtt from "precompiled-mqtt";
import { IClientSubscribeOptions, MqttClient } from "precompiled-mqtt";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { eventAdd, eventsGet } from "store/data/event/event";

function MMMqttProvider(props: any) {
  const messages = useSelector(eventsGet);
  const [client, setClient] = useState<MqttClient | undefined>(undefined);

  const dispatch = useDispatch();
  const getTokenForConnection = async () => {
    let token = AuthenticationHelper.getToken();

    return token;
  };

  const setupClient = async () => {
    console.log("MMMqttProvider initializing");
    const url = env("REACT_APP_API_EVENTS") || "ws://localhost";
    const token: string = await getTokenForConnection();
    if (!token) {
      console.log("MMMqttProvider ERROR GETTING TOKEN");
      return false;
    } else {
      console.log("MMMqttProvider connecting to", url);
    }
    setClient(
      mqtt.connect(url, {
        username: "",
        password: token,
        reconnectPeriod: 0,
        keepalive: 30,
      })
    );
  };

  useEffect(() => {
    if (!client) {
      setupClient();
    }
  }, []);

  useEffect(() => {
    if (client) {
      client.on("connect", (packet) => {
        const organisationId = AuthenticationHelper.getOrganisationId();
        console.log("MMMqttProvider connected", packet);
        client.subscribe(`${organisationId}/#`, {} as IClientSubscribeOptions, (err, granted) => {
          console.log("MMMqttProvider subscription result", err, granted);
        });
      });

      client.on("disconnect", (packet) => {
        console.log("MMMqttProvider disconnected", packet);
      });

      client.on("message", (topic, encodedPayload) => {
        console.log("MMMqttProvider message received", topic);
        const payload = JSON.parse(new TextDecoder("utf-8").decode(encodedPayload));

        dispatch(eventAdd(topic, payload));
      });
    }
  }, [client]);

  useEffect(() => {
    console.log("MMMqttProvider message count", messages.length);
    console.log(
      "MMMqttProvider last message",
      messages.length > 0 ? messages[messages.length - 1] : "-===NO LAST MESSAGE===-"
    );
  }, [messages]);
  return null;
}

export default MMMqttProvider;
