import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiModal, EuiSpacer, EuiText } from "@elastic/eui";
import { pdf } from "@react-pdf/renderer";
import { ApiResponse, ApiResponseStatus } from "api/api-helper";
import ClientsAPIHelper from "api/clients-api.helper";
import ConnectAPIHelper from "api/connect-api-helper";
import MMPage from "components/layouts/page/page";
import MMPdfClientDossier from "components/pdf/pdf-client-dossier";
import AuthenticationHelper from "helpers/authentication-helper";
import DateHelper from "helpers/date-helper";
import FileUploadHelper from "helpers/file-upload-helper";
import txt from "helpers/text-helper";
import PDFMerger from "pdf-merger-js/browser";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Appointment } from "store/data/appointment/appointment";
import { Client, clientName } from "store/data/client/client";
import { ClientContactMoment } from "store/data/client/contact-moment";
import { ClientInsurance } from "store/data/client/insurance";
import { File } from "store/data/file/file";
import { Referral } from "store/data/referral/referral";
import MMAddressInfo from "./dossier/address-info";
import MMCareInfo from "./dossier/care-info";
import MMCommunicationInfo from "./dossier/communication-info";
import MMContactInfo from "./dossier/contact-info";
import MMIdInfo from "./dossier/id-info";
import MMInsuranceInfo from "./dossier/insurance-info";
import MMPersonalInfo from "./dossier/personal-info";

function MMClientDossier() {
  const [buttonsToShow, setButtonsToShow] = useState([]);
  const [sideButtonsToShow, setSideButtonsToShow] = useState([]);

  const api: ConnectAPIHelper = new ConnectAPIHelper();
  const clientApi: ClientsAPIHelper = new ClientsAPIHelper();
  const navigate = useNavigate();
  const { clientId } = useParams();
  const [client, setClient] = useState<Client | null>(null);
  const [insurance, setInsurance] = useState<ClientInsurance | null>(null);
  const [appointments, setAppointments] = useState<Appointment[] | null>(null);
  const [contactMoments, setContactMoments] = useState<ClientContactMoment[] | null>(null);
  const [referrals, setReferrals] = useState<Referral[]>([]);
  const [products, setProducts] = useState<any[] | null>(null);

  const [referralsWithOrders, setReferralsWithOrders] = useState<Referral[] | null>(null);

  const [isPdfOpen, setIsPdfOpen] = useState<boolean>(false);

  const [isPdfLoading, setIsPdfLoading] = useState<boolean>(false);

  const [isClientEditAllowed, setIsClientEditAllowed] = useState<boolean>(false);

  const loadReferrals = async (clientCode: string, full: boolean = false) => {
    //todo: figure out paging  / load more interface for referrals.
    const referralsResult: ApiResponse = await api.getReferrals(
      {
        client_code: clientCode,
        include_orders: full,
      },
      10,
      0,
      "intake_at",
      "asc"
    );
    if (referralsResult && referralsResult.status === ApiResponseStatus.OK && referralsResult.result.length > 0) {
      if (full) {
        setReferrals(referralsResult.result);
        setReferralsWithOrders(referralsResult.result);
      } else {
        setReferrals(referralsResult.result);
      }
    } else {
      setReferrals([]);
      setReferralsWithOrders([]);
    }
  };

  const loadProducts = async () => {
    let products: any[] = await api.getProducts();
    if (products && products.sort) {
      products.sort((a: any, b: any) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
      setProducts(products);
    } else {
      setProducts(null);
    }
  };

  useEffect(() => {
    const loadClient = async (clientId: number) => {
      const result: ApiResponse = await clientApi.getClient(clientId);
      if (result && result.status === ApiResponseStatus.OK) {
        delete result.result.vlot_data;
        setClient(result.result);
      } else {
        setClient(null);
        return;
      }

      if (result && result.result) {
        const insuranceResult: ApiResponse = await clientApi.getInsuranceDetails(result.result.id);
        if (insuranceResult && insuranceResult.status === ApiResponseStatus.OK) {
          setInsurance(insuranceResult.result);
        } else {
          console.log("Something went wrong, no client?", insuranceResult);
        }

        loadReferrals(result.result.client_code, true);
        if (await AuthenticationHelper.hasPermission(["appointments#read_all"])) {
          let filters: any = {};
          filters.vlot_client_id = result.result.vlot_id;
          const appointmentsResult: ApiResponse = await clientApi.getAppointments(filters);
          if (appointmentsResult && appointmentsResult.status === ApiResponseStatus.OK) {
            setAppointments(appointmentsResult.result);
          } else {
            console.log("appointmentsResult error", appointmentsResult.message);
            setAppointments([]);
          }
        } else {
          setAppointments([]);
        }

        if (await AuthenticationHelper.hasPermission(["appointments#read_all"])) {
          let filters: any = {};
          if (result.result.vlot_id) {
            filters.vlot_client_id = result.result.vlot_id;
            const contactMomentResult: ApiResponse = await clientApi.getClientContactMoments(filters);
            if (contactMomentResult && contactMomentResult.status === ApiResponseStatus.OK) {
              setContactMoments(contactMomentResult.result);
            } else {
              console.log("contactMomentResult error", contactMomentResult.message);
              setContactMoments([]);
            }
          } else {
            setContactMoments([]);
          }
        } else {
          setContactMoments([]);
        }
      }
    };

    if (clientId) {
      loadClient(parseInt(clientId as string));
      loadProducts();
    } else {
      console.log("no client id?", clientId);
    }
  }, [clientId]);

  useEffect(() => {
    const setInterfaceForPermissions = async () => {
      let buttons: any = [];

      buttons.push(
        <EuiButton
          aria-label={txt.get("clients.page_title")}
          size="s"
          fill={true}
          style={{ width: "40px", minInlineSize: "40px" }}
          iconType="faceHappy"
          onClick={() => navigate("/clients")}
        />
      );

      if (client) {
        buttons.push(
          <EuiButton
            aria-label={txt.get("clients.page_title")}
            size="s"
            onClick={() => navigate(`/clients/${client.id}`)}
          >
            {clientName(client)} #{client.client_code}
          </EuiButton>
        );
      }

      setButtonsToShow(buttons);

      let sideButtons: any = [];

      if (client) {
        sideButtons.push(
          <EuiFlexItem style={{ margin: "auto", padding: "10px" }}>
            <EuiText size="xs" color="#999999">
              {txt.get("orders.order.client_last_refresh", DateHelper.toDateHM(client.updated_at))}
            </EuiText>
          </EuiFlexItem>
        );
      }

      const isDisabled: boolean = !(client && insurance && referralsWithOrders && appointments && contactMoments);

      sideButtons.push(
        <EuiButton
          aria-label={`${txt.get("generic.preview_pdf")}`}
          size="s"
          onClick={() => previewPDF()}
          isLoading={isPdfLoading}
          disabled={isDisabled}
        >
          {txt.get("generic.preview_pdf")}...
        </EuiButton>
      );
      sideButtons.push(
        <EuiButton
          aria-label={`${txt.get("generic.download_pdf")}`}
          size="s"
          onClick={() => downloadPDF()}
          isLoading={isPdfLoading}
          disabled={isDisabled}
        >
          {txt.get("generic.download_pdf")}...
        </EuiButton>
      );

      setSideButtonsToShow(sideButtons);
      setIsClientEditAllowed(
        false &&
          (await AuthenticationHelper.hasPermission([
            "client_details#edit_all",
            "client_details#edit_org",
            "client_details#edit",
          ]))
      );
    };

    setInterfaceForPermissions();
  }, [client, insurance, isPdfLoading, referralsWithOrders, contactMoments, appointments]);

  const previewPDF = async () => {
    if (client?.client_code) {
      setIsPdfOpen(true);
    }
  };

  const toFileName = (client: Client) => `client-dossier-${client.client_code || "."}-${DateHelper.toSortable()}.pdf`;

  const fileIdsFromReferrals = (referrals: Referral[]) =>
    referrals.reduce(
      (fileIds: number[], referral: Referral) => (referral.files || []).map((file: File) => file.id).concat(fileIds),
      []
    );

  const downloadPDF = async () => {
    setIsPdfLoading(true);
    if (client?.client_code && referralsWithOrders && products) {
      const pdfMerger = new PDFMerger();

      const dossierBlob = await pdf(
        <MMPdfClientDossier
          type="download"
          client={client}
          insurance={insurance}
          contactMoments={contactMoments || []}
          appointments={appointments || []}
          referralsWithOrders={referralsWithOrders}
          products={products}
        />
      ).toBlob();

      await pdfMerger.add(dossierBlob);

      const fileIds: number[] = fileIdsFromReferrals(referralsWithOrders);
      if (fileIds.length > 0) {
        const referralsBlob: Blob | null = await api.getMergedFiles(fileIds);
        console.log("downloadPdf", { fileIds, referralsBlob });
        if (referralsBlob) {
          await pdfMerger.add(referralsBlob);
        } else {
          console.log("no referral files to merge");
        }
      }
      const blob = await pdfMerger.saveAsBlob();
      FileUploadHelper.triggerDownloadFile(blob, toFileName(client));
    } else {
      console.warn("NO CLIENT");
    }
    setIsPdfLoading(false);
  };

  const renderPdf = () =>
    isPdfOpen && client && referralsWithOrders && products ? (
      <EuiModal
        style={{
          backgroundColor: "#fffffff",
          maxWidth: "90vw",
          maxHeight: "80vh",
          width: "90vw",
          height: "90vh",
        }}
        onClose={(e) => setIsPdfOpen(false)}
        initialFocus="[name=popswitch]"
        className="pdf-preview"
      >
        <MMPdfClientDossier
          type="view"
          client={client}
          insurance={insurance}
          contactMoments={contactMoments || []}
          appointments={appointments || []}
          referralsWithOrders={referralsWithOrders}
          products={products}
        />
      </EuiModal>
    ) : (
      <></>
    );

  return (
    <MMPage
      title={txt.get("clients.client.page_title")}
      topActions={buttonsToShow}
      sideActions={sideButtonsToShow}
      hideTitle={true}
      className="subtle-editing"
    >
      <EuiSpacer />

      <EuiFlexGroup gutterSize="xl">
        <EuiFlexItem grow={3}>
          <MMPersonalInfo client={client} isEditable={isClientEditAllowed} />
          <EuiSpacer />
          <MMContactInfo client={client} isEditable={isClientEditAllowed} />
          <EuiSpacer />
          <MMAddressInfo client={client} isEditable={isClientEditAllowed} />
          <EuiSpacer />
          <MMInsuranceInfo insurance={insurance} isEditable={isClientEditAllowed} />
          <EuiSpacer />
          <MMIdInfo client={client} isEditable={isClientEditAllowed} />
        </EuiFlexItem>

        <EuiFlexItem grow={3}>
          {clientId ? (
            <MMCommunicationInfo client={client} appointments={appointments} contactMoments={contactMoments} />
          ) : (
            <></>
          )}
        </EuiFlexItem>
        <EuiFlexItem grow={3}>
          {clientId ? (
            <MMCareInfo referrals={referrals} clientId={parseInt(clientId as string)} client={client} />
          ) : (
            <></>
          )}
        </EuiFlexItem>
      </EuiFlexGroup>
      {renderPdf()}
    </MMPage>
  );
}

export default MMClientDossier;
