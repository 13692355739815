import {
  EuiButton,
  EuiComboBox,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiSpacer,
  EuiSwitch,
} from "@elastic/eui";
import ConnectAPIHelper from "api/connect-api-helper";
import AuthenticationHelper from "helpers/authentication-helper";
import txt from "helpers/text-helper";
import { Fragment, useEffect, useState } from "react";
import { PersonalDetails } from "store/data/personal-details/personal-details";

const USER_IS_PRACTITIONER_DEFAULT: boolean = true;

export interface MMUserProps {
  fields: {
    user: PersonalDetails | null;
  };
  handleSubmit?: Function;
}

function MMUser(props: MMUserProps) {
  const api = new ConnectAPIHelper();
  const [user, setUser] = useState(props.fields.user);
  const [organisations, setOrganisations] = useState([]);
  const [selectedOrganisations, setSelectedOrganisations] = useState<any[]>([]);
  const [editAllowed, setEditAllowed] = useState(false);
  useEffect(() => {
    const loadOrganisations = async () => {
      const organisations = await api.getOrganisations();
      setOrganisations(organisations);
    };

    loadOrganisations();
    const setPermissions = async () => {
      setEditAllowed(await AuthenticationHelper.hasPermission("personal_details#edit_all"));
    };
    setPermissions();
  }, []);

  useEffect(() => {
    if (organisations.length > 0 && user) {
      setSelectedOrganisations(
        toSelectOptions(organisations.filter((organisation: any) => organisation.id === user.organisation_id))
      );
    }
  }, [organisations, user]);

  const updateField = (object: any, key: string, value: any) => {
    return { ...object, ...{ [key]: value } };
  };

  const onStringValue = (e: any) => e.target.value;

  const toSelectOptions = (list: any[]) => {
    return list.map((item) => ({
      label: item.name,
      "data-id": item.id,
    }));
  };

  const onOrganisationChange = (selectedOptions: any[]) => {
    setUser(updateField(user, "organisation_id", selectedOptions.length > 0 ? selectedOptions[0]["data-id"] : null));
  };

  const onPractitionerChange = (e: any) => {
    setUser(updateField(user, "is_practitioner", e.target.checked));
  };

  // const onVlotPractitionerIdChange = (value: string) => {
  //   console.log(value);
  //   setUser(
  //     updateField(
  //       user,
  //       "legacy_info",
  //       updateField(user?.legacy_info || {}, "vlot_practitioner_id", value)
  //     )
  //   );
  // };

  const onSubmit = (e: any) => {
    if (props.handleSubmit) {
      props.handleSubmit(user);
    }
  };

  return (
    <EuiForm>
      <EuiFormRow label={txt.get("account.first_name")}>
        <EuiFieldText
          readOnly={!editAllowed}
          name="first_name"
          value={user?.first_name}
          onChange={(e) => setUser(updateField(user, "first_name", onStringValue(e)))}
        />
      </EuiFormRow>
      <EuiFormRow label={txt.get("account.last_name")}>
        <EuiFieldText
          readOnly={!editAllowed}
          name="last_name"
          value={user?.last_name}
          onChange={(e) => setUser(updateField(user, "last_name", onStringValue(e)))}
        />
      </EuiFormRow>
      <EuiFormRow label={txt.get("account.email")}>
        <EuiFieldText
          readOnly={!editAllowed}
          name="email"
          value={user?.email}
          onChange={(e) => setUser(updateField(user, "email", onStringValue(e)))}
        />
      </EuiFormRow>
      <EuiFormRow label={<span id="user-is-practitioner">{txt.get("admin.users.is_practitioner")}</span>}>
        <EuiSwitch
          disabled={!editAllowed}
          onChange={onPractitionerChange}
          label={user?.is_practitioner ? txt.get("generic.yes") : txt.get("generic.no")}
          checked={user && user.is_practitioner !== undefined ? user.is_practitioner : USER_IS_PRACTITIONER_DEFAULT}
          aria-describedby="location-is-demo"
        />
      </EuiFormRow>
      {/* <EuiFormRow label={txt.get("admin.users.vlot_practitioner_id")}>
        <EuiFieldText
          disabled={!editAllowed}
          name="vlot_practitioner_id"
          value={user?.legacy_info?.vlot_practitioner_id}
          onChange={(e) => onVlotPractitionerIdChange(onStringValue(e))}
        />
      </EuiFormRow> */}
      <EuiFormRow label={txt.get("admin.organisations.name")} helpText={txt.get("admin.users.organisation_help")}>
        <EuiComboBox
          isDisabled={!editAllowed}
          aria-autocomplete="none"
          aria-label="Select organisation"
          placeholder={txt.uf("generic.select_x", txt.get("admin.organisations.name"))}
          singleSelection={{ asPlainText: true }}
          options={toSelectOptions(organisations)}
          selectedOptions={selectedOrganisations}
          onChange={onOrganisationChange}
        />
      </EuiFormRow>
      {editAllowed ? (
        <Fragment>
          <EuiSpacer size="xxl" />
          <EuiFlexGroup>
            <EuiFlexItem grow={false}>
              <EuiButton size="s" onClick={onSubmit} fill={true}>
                {txt.get("generic.save")}
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </Fragment>
      ) : (
        <></>
      )}
    </EuiForm>
  );
}
export default MMUser;
