import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store/store";

export enum AlertConfirmActionState {
  Inactive,
  Ask,
  Confirm,
  Cancel,
  Perform,
  Alternative,
}

export enum AlertInputValueType {
  Text,
  Check,
  Password,
}
export interface AlertInput {
  isVisible: boolean;
  isMandatory: boolean;
  description?: string;
  valueType?: AlertInputValueType;
  value?: string;
}

export interface AlertConfirm {
  actionKey: string | null;
  actionData: any;
  actionState: AlertConfirmActionState;
  title: string;
  content: string;
  contentType?: "html" | "markdown" | "string";
  confirmText?: string;
  cancelText?: string;
  altText?: string;
  input?: AlertInput;
  type?: "danger" | "success";
}

export interface ConfirmState {
  alertConfirm: AlertConfirm;
}

const INACTIVE_STATE = {
  actionKey: null,
  actionState: AlertConfirmActionState.Inactive,
  title: "",
  content: "",
} as AlertConfirm;

const initialState: ConfirmState = {
  alertConfirm: INACTIVE_STATE,
};

//one at a time for now

export const alertConfirmSlice = createSlice({
  name: "alertConfirm",
  initialState,
  reducers: {
    confirmAsk: {
      reducer(state, action: PayloadAction<AlertConfirm>) {
        state.alertConfirm = action.payload;
      },
      prepare(
        title,
        content,
        actionKey?,
        actionData?,
        input?,
        confirmText?,
        cancelText?,
        type?: "danger" | "success",
        altText?,
        contentType?
      ) {
        return {
          payload: {
            actionKey: actionKey || null,
            actionData: actionData || {},
            actionState: AlertConfirmActionState.Ask,
            title,
            content,
            contentType,
            input: input || {},
            confirmText: confirmText,
            cancelText: cancelText,
            altText: altText,
            type: type || "success",
          },
        };
      },
    },
    confirmPerform: {
      reducer(state, action: PayloadAction<AlertConfirm>) {
        state.alertConfirm = action.payload;
      },
      prepare(alertConfirm) {
        return {
          payload: {
            ...alertConfirm,
            ...{ actionState: AlertConfirmActionState.Perform },
          },
        };
      },
    },
    confirmClose: (state) => {
      state.alertConfirm = INACTIVE_STATE;
    },
    confirmAlternative: {
      reducer(state, action: PayloadAction<AlertConfirm>) {
        state.alertConfirm = action.payload;
      },
      prepare(alertConfirm) {
        return {
          payload: {
            ...alertConfirm,
            ...{ actionState: AlertConfirmActionState.Alternative },
          },
        };
      },
    },
  },
});

export const confirmGet = (state: RootState) => state.alertConfirm.alertConfirm;

export const { confirmAsk, confirmClose, confirmPerform, confirmAlternative } = alertConfirmSlice.actions;

export default alertConfirmSlice.reducer;
