import {
  EuiButtonIcon,
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiSpacer,
  EuiText,
  EuiTitle,
  EuiToolTip,
} from "@elastic/eui";
import txt from "helpers/text-helper";
import { Fragment, useEffect, useState } from "react";
import { Fitting, FittingScore, FITTING_EMPTY } from "store/data/fitting/fitting";
import { MMOption, MMOrderLineDeliveryFieldProps } from "../order-line-delivery";

const DOT_COUNT: number = 5;

function MMDeliveryScores(props: MMOrderLineDeliveryFieldProps) {
  const [fitting, setFitting] = useState<Fitting | undefined | null>();

  useEffect(() => {
    setFitting(props.fitting);
  }, [props.fitting]);

  const handleValueChange = (id: string, value: number) => {
    let updatedScores: FittingScore[] = fitting ? [...fitting.fitting_scores] : [];
    let found: boolean = false;
    for (let i = 0; i < updatedScores.length; i++) {
      const score = updatedScores[i];
      if (score.attribute === id) {
        score.score = value;
        updatedScores[i] = { ...score };
        found = true;
        break;
      }
    }
    if (!found) {
      updatedScores.push({ attribute: id, score: value });
    }
    if (props.onChange) {
      props.onChange(
        {
          ...FITTING_EMPTY,
          ...props.fitting,
          order_line_id: props.orderLineId,
          fitting_scores: updatedScores,
        },
        props.orderLineId
      );
    }
  };

  const renderDots = (id: string, value?: number) => {
    let result: any[] = [];
    for (let i = 0; i < DOT_COUNT; i++) {
      result.push(
        <EuiFlexItem key={`dot-${i}`}>
          <EuiIcon
            size="l"
            type="dot"
            color={value && value / 2 >= i + 1 ? "#7DA4A3" : "#E3E3E3"}
            style={{ cursor: "pointer" }}
            onClick={(e: any) => {
              handleValueChange(id, (i + 1) * 2);
            }}
            data-testid={`dot-${id}-${i}`}
          />
        </EuiFlexItem>
      );
    }
    return result;
  };
  return (
    <EuiFlexGrid gutterSize="m">
      {(props.scoringAttributes || []).map((attr: MMOption, i: number) => (
        <EuiFlexItem key={`scoring-${i}`}>
          <EuiFlexGroup gutterSize="m" alignItems="center">
            <EuiFlexItem>
              <EuiTitle size="xxs">
                <EuiText color="grey">
                  {attr.label}
                  <EuiToolTip
                    display="block"
                    content={
                      <Fragment>
                        <EuiTitle size="xxs">
                          <EuiText size="s" color="white">
                            {txt.html(`orders.delivery.${attr.id}`)}
                          </EuiText>
                        </EuiTitle>
                        <EuiSpacer size="xs" />
                        <EuiText size="s" color="white">
                          {txt.html(`orders.delivery.instructions_${attr.id}`)}
                        </EuiText>
                      </Fragment>
                    }
                  >
                    <EuiButtonIcon aria-label="score-tick" size="xs" iconType="iInCircle" />
                  </EuiToolTip>
                </EuiText>
              </EuiTitle>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiFlexGroup gutterSize="m">
                {renderDots(
                  attr.id,
                  props.fitting &&
                    props.fitting.fitting_scores.find((score: FittingScore) => score.attribute === attr.id)?.score
                )}
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      ))}
    </EuiFlexGrid>
  );
}

export default MMDeliveryScores;
