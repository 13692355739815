import {
  EuiButton,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiRadioGroup,
  EuiSpacer,
} from "@elastic/eui";
import AuthenticationHelper from "helpers/authentication-helper";
import txt from "helpers/text-helper";
import { updateField, useStateWithCallback } from "hoc/helper-hooks";
import { Fragment, useEffect, useState } from "react";
import { Organisation, OrganisationOrdering } from "store/data/organisation/organisation";

const ACRONYM_MAX_LENGTH = 5;
export interface MMOrganisationProps {
  fields: {
    organisation: Organisation | null;
  };
  handleSubmit?: Function;
}

function MMOrganisation(props: MMOrganisationProps) {
  const CLIENT_REFERENCE_OPTIONS = [
    {
      id: "orders.external_client_reference_name.patient_number",
      label: `"${txt.get("orders.external_client_reference_name.patient_number")}"`,
    },
    {
      id: "orders.external_client_reference_name.reference",
      label: `"${txt.get("orders.external_client_reference_name.reference")}"`,
    },
    {
      id: "orders.external_client_reference_name.external",
      label: `"${txt.get("orders.external_client_reference_name.external")}"`,
    },
  ];

  const CLIENT_NAMING_OPTIONS = [
    {
      id: "orders.external_client_reference_name.patient",
      label: `"${txt.get("orders.external_client_reference_name.patient.name")}"`,
    },
    {
      id: "orders.external_client_reference_name.client",
      label: `"${txt.get("orders.external_client_reference_name.client.name")}"`,
    },
  ];

  const CLIENT_ORDERING_OPTIONS = [
    {
      id: OrganisationOrdering.Internal,
      label: txt.get("admin.organisations.ordering_option.internal"),
    },
    {
      id: OrganisationOrdering.External,
      label: txt.get("admin.organisations.ordering_option.external"),
    },
    {
      id: OrganisationOrdering.B2B,
      label: txt.get("admin.organisations.ordering_option.b2b"),
    },
  ];
  const [editAllowed, setEditAllowed] = useState(false);

  const [organisation, setOrganisation] = useStateWithCallback(props.fields.organisation);

  useEffect(() => {
    const setPermissions = async () => {
      setEditAllowed(await AuthenticationHelper.hasPermission("organisations#edit_all"));
    };
    setPermissions();
  }, []);

  const onName = (name: string) => {
    setOrganisation({
      ...organisation,
      name,
    } as Organisation);
  };

  const onAcronymChange = (e: any) => {
    const input = e.target;
    const start = input.selectionStart;
    const end = input.selectionEnd;
    const acronym = e.target.value.toUpperCase();

    setOrganisation(
      {
        ...organisation,
        acronym,
      } as Organisation,
      () => input.setSelectionRange(start, end)
    );
  };

  const onClientReference = (client_reference: string) => {
    setOrganisation({
      ...organisation,
      client_reference,
    } as Organisation);
  };

  const onClientNaming = (client_naming: string) => {
    setOrganisation({
      ...organisation,
      client_naming,
    } as Organisation);
  };

  const onOrdering = (ordering: string) => {
    setOrganisation({
      ...organisation,
      ordering,
    } as Organisation);
  };

  const onPortfolioReference = (reference: string) => {
    setOrganisation(
      updateField(
        organisation,
        "legacy_portfolio",
        updateField(updateField(organisation?.legacy_portfolio, "scanning", reference), "reference", reference)
      )
    );
  };

  // const onPortfolioScanning = (scanning: string) => {
  //   setOrganisation(
  //     updateField(
  //       organisation,
  //       "legacy_portfolio",
  //       updateField(organisation?.legacy_portfolio, "scanning", scanning)
  //     )
  //   );
  // };

  const onStringValue = (e: any) => e.target.value;

  const onSubmit = (e: any) => {
    if (props.handleSubmit) {
      props.handleSubmit(organisation);
    }
  };

  return (
    <EuiForm>
      <EuiFormRow
        label={txt.get("generic.name")}
        // helpText={"The name of the organisation."}
      >
        <EuiFieldText
          disabled={!editAllowed}
          name="name"
          value={organisation?.name}
          onChange={(e) => onName(onStringValue(e))}
        />
      </EuiFormRow>
      <EuiFormRow
        label={txt.get("admin.organisations.acronym")}
        // helpText={"Short version of the name shown in internal lists."}
      >
        <EuiFieldText
          disabled={!editAllowed}
          name="acronym"
          maxLength={ACRONYM_MAX_LENGTH}
          value={organisation?.acronym}
          onChange={onAcronymChange}
        />
      </EuiFormRow>
      <EuiFormRow
        label={txt.get("admin.organisations.client_reference")}
        // helpText={
        //   "What the organisations' users see as the wording for an external patient number."
        // }
      >
        <EuiRadioGroup
          disabled={!editAllowed}
          onChange={onClientReference}
          options={CLIENT_REFERENCE_OPTIONS}
          idSelected={organisation?.client_reference}
        />
      </EuiFormRow>
      <EuiFormRow
        label={txt.get("admin.organisations.client_naming")}
        // helpText={
        //   "What the organisations' users see as the wording for a patient."
        // }
      >
        <EuiRadioGroup
          disabled={!editAllowed}
          onChange={onClientNaming}
          options={CLIENT_NAMING_OPTIONS}
          idSelected={organisation?.client_naming}
        />
      </EuiFormRow>
      <EuiSpacer />
      <EuiFlexGroup>
        <EuiFlexItem grow={false}>
          <EuiFormRow
            label={txt.get("admin.organisations.portfolio_reference")}
            // helpText={txt.get("admin.organisations.portfolio_reference_help")}
          >
            <EuiFieldText
              disabled={!editAllowed}
              name="portfolio_reference"
              value={organisation?.legacy_portfolio?.reference}
              onChange={(e) => onPortfolioReference(onStringValue(e))}
            />
          </EuiFormRow>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer />

      <EuiFormRow
        label={txt.get("admin.organisations.ordering")}
        helpText={txt.get("admin.organisations.ordering_help")}
      >
        <EuiRadioGroup
          disabled={!editAllowed}
          onChange={onOrdering}
          options={CLIENT_ORDERING_OPTIONS}
          idSelected={organisation?.ordering}
        />
      </EuiFormRow>
      {editAllowed ? (
        <Fragment>
          <EuiSpacer size="xxl" />
          <EuiFlexGroup>
            <EuiFlexItem grow={false}>
              <EuiButton size="s" onClick={onSubmit} fill={true}>
                {txt.get("generic.save")}
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </Fragment>
      ) : (
        <></>
      )}
    </EuiForm>
  );
}
export default MMOrganisation;
