import { CriteriaWithPagination, EuiInMemoryTable, EuiText, htmlIdGenerator } from "@elastic/eui";
import txt from "helpers/text-helper";
import { useCallback, useEffect, useState } from "react";

export interface MMMemoryTableProps {
  items: any[];
  columns: any[];
  groupKey?: string;
}

function MMMemoryTable(props: MMMemoryTableProps) {
  const tableId: string = htmlIdGenerator("mm-memory-table")();
  const [pagination, setPagination] = useState<any>({
    pageIndex: Math.floor(props.items.length / 5),
    showPerPageOptions: false,
  });
  const [items, setItems] = useState<any[]>(props.items);
  useEffect(() => {
    setItems(props.items);
  }, [props.items]);
  const tableRef = useCallback(
    (tableNode: any) => {
      if (tableNode !== null && tableId && props.groupKey) {
        let rows = document.querySelectorAll(`#${tableId} tr[data-group-key]`);
        let currentKey: any = undefined;
        for (let i = 0; i < rows.length; i++) {
          const row: any = rows[i];
          let key: any = row.getAttribute("data-group-key");
          if (currentKey !== key) {
            let keyRows = document.querySelectorAll(`tr[data-group-key="${key}"]`);
            for (let j = 0; j < keyRows.length; j++) {
              const keyRow = keyRows[j];
              const keyCell = keyRow.querySelector(`td[data-group-key="${key}"]`);
              if (keyCell) {
                if (j === 0) {
                  keyCell.setAttribute("rowspan", `${keyRows.length}`);
                } else {
                  keyCell.setAttribute("style", "display:none;");
                }

                if (j === 0) {
                  keyRow.classList.add("grouped");
                  keyRow.classList.add("grouped-first");
                } else if (j === keyRows.length - 2) {
                  keyRow.setAttribute("class", "grouped");
                } else {
                  keyRow.setAttribute("class", "grouped grouped-last");
                }
              }
            }
          }
          currentKey = key;
        }
      }
    },
    [items, tableId, props.groupKey]
  );

  const onTableChange = ({ page: { index } }: CriteriaWithPagination<any>) => {
    setPagination({ pageIndex: index });
  };

  const getRowProps = (line: any) => {
    return props.groupKey && line[props.groupKey] ? { "data-group-key": line[props.groupKey] } : {};
  };

  const getCellProps = (line: any, column: any) => {
    return props.groupKey && line[props.groupKey] && column.field === props.groupKey
      ? { "data-group-key": line[props.groupKey] }
      : {};
  };

  return (
    <EuiInMemoryTable
      id={tableId}
      ref={props.groupKey ? tableRef : undefined}
      className={`subtle-table${props.groupKey ? " grouped-keys-table" : ""}${items?.length === 0 ? " empty" : ""}`}
      rowHeader={undefined}
      items={items}
      columns={props.columns}
      pagination={{
        ...pagination,
        pageSizeOptions: [5],
      }}
      message={
        <EuiText color="subdued" size="xs">
          {txt.get("generic.no_results")}
        </EuiText>
      }
      onTableChange={onTableChange}
      rowProps={props.groupKey ? getRowProps : undefined}
      cellProps={props.groupKey ? getCellProps : undefined}
    />
  );
}

export default MMMemoryTable;
