import { EuiBasicTable, EuiFlexGroup } from "@elastic/eui";
import ClientsAPIHelper from "api/clients-api.helper";
import MMCell from "components/layouts/table/cell";
import AuthenticationHelper from "helpers/authentication-helper";
import DateHelper from "helpers/date-helper";
import txt from "helpers/text-helper";
import { Fragment, useEffect, useState } from "react";
import { Appointment } from "store/data/appointment/appointment";
import { useLocalStorage } from "store/local-storage";

export const PAGE_SIZE_OPTIONS = [10, 25, 50, 100]; //,0]; //0 would mean without limit
export const DEFAULT_PAGE_SIZE = 10;
export const SEARCH_DEBOUNCE_DELAY = 700;
export const DEFAULT_SORT_BY = "name";
export const DEFAULT_SORT_ORDER = "asc";

function MMAppointmentsShortList() {
  const api = new ClientsAPIHelper();

  const [appointments, setAppointments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [limit, setLimit] = useLocalStorage("appointment_limit", DEFAULT_PAGE_SIZE);
  const [offset, setOffset] = useLocalStorage("appointment_offset", 0);
  const [total, setTotal] = useState(0);
  const [sortBy, setSortBy] = useLocalStorage("appointment_sort_by", DEFAULT_SORT_BY);
  const [sortOrder, setSortOrder] = useLocalStorage("appointment_sort_order", DEFAULT_SORT_ORDER);
  const [error, setError] = useState("");

  useEffect(() => {
    const loadAppointments = async () => {
      setIsLoading(true);

      let filters: any = {};
      filters.practitioner_id = AuthenticationHelper.getPersonalDetailsId();

      const result = await api.getAppointments(filters, limit, offset, appointmentFieldToSortKey(sortBy), sortOrder);
      if (result.status === "OK") {
        setAppointments(result.result);
        setTotal(result.meta_data.result_set.total);
        setError("");
      } else {
        setAppointments([]);
        setTotal(0);
        setError(`${result.status} (${result.code}): ${result.message}`);
      }
      setIsLoading(false);
    };

    loadAppointments();
  }, [sortOrder, sortBy, limit, offset]);

  const limitOffsetToPage = (limit: number, offset: number) => {
    //pages in EUI are zero based
    const page = limit > 0 ? Math.max(0, offset / limit) : 0;
    return page;
  };

  const pagination: any = {
    pageIndex: limitOffsetToPage(limit, offset),
    pageSize: limit,
    totalItemCount: total,
    pageSizeOptions: PAGE_SIZE_OPTIONS,
    showPerPageOptions: true,
  };

  const sorting: any = {
    sort: {
      field: sortBy,
      direction: sortOrder,
    },
    enableAllColumns: false,
  };

  const appointmentFieldToSortKey = (field: string) => {
    switch (field) {
      default:
        return field;
    }
  };

  const onAppointmentsChange = ({ page = {} as any, sort = {} as any }) => {
    if (page.size) {
      const newLimit = page.size;
      const newOffset = Math.max(0, page.index * page.size);
      if (limit !== newLimit) setLimit(newLimit);
      if (offset !== newOffset) setOffset(newOffset);
    }
    if (sort.field) {
      setSortBy(sort.field);
      setSortOrder(sort.direction ?? "asc");
    }
  };

  const columns = [
    {
      name: txt.get("generic.date"),
      field: "start",
      sortable: api.appointmentIsSortableBy(appointmentFieldToSortKey("start")),
      style: { minWidth: "60px" },
      render: (start: Date, appointment: Appointment) => (
        <MMCell
          text={DateHelper.toDateHM(start)}
          bold={true}
          subText={`${DateHelper.duration(start, appointment.end)} ${txt.get("appointments.minutes_short")}`}
        />
      ),
    },

    {
      name: txt.get("appointments.name"),
      field: "appointment",
      sortable: api.appointmentIsSortableBy(appointmentFieldToSortKey("appointment")),
      render: (appt: string, appointment: Appointment) => (
        <MMCell subText={appt} bold={true} text={appointment.client_description} />
      ),
    },
    {
      name: txt.get("appointments.details"),
      field: "details",
      sortable: api.appointmentIsSortableBy(appointmentFieldToSortKey("details")),
    },
  ];

  const getRowProps = (appointment: any) => {
    const { id } = appointment;
    return {
      "data-id": `row-${id}`,
    };
  };

  return (
    <Fragment>
      <EuiFlexGroup alignItems="flexEnd"></EuiFlexGroup>
      <EuiBasicTable
        loading={isLoading}
        tableLayout="auto"
        itemId="id"
        items={appointments}
        columns={columns}
        pagination={pagination}
        sorting={sorting}
        rowProps={getRowProps}
        noItemsMessage={error ? error : txt.uf("generic.found_no_x", txt.get("appointments.page_title"))}
        onChange={onAppointmentsChange}
      />
    </Fragment>
  );
}

export default MMAppointmentsShortList;
