import {
  EuiButton,
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiNotificationBadge,
  EuiPanel,
  EuiSpacer,
  EuiTab,
  EuiTabs,
  EuiText,
  EuiTitle,
} from "@elastic/eui";
import ConnectAPIHelper from "api/connect-api-helper";
import MMPage from "components/layouts/page/page";
import MMOrdersShortlist from "components/orders/order-shortlist";
import AuthenticationHelper from "helpers/authentication-helper";
import env from "helpers/env-helper";
import txt from "helpers/text-helper";
import UrlHelper from "helpers/url-helper";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { totalOrderCount } from "store/data/order/order-count";
import { PersonalDetails } from "store/data/personal-details/personal-details";
import { ReferralStatus } from "store/data/referral/referral";

const MAIN_PRACTITIONER_IDS = env("REACT_APP_MAIN_PRACTITIONER_IDS").split(",");

function MMReferrals() {
  const navigate = useNavigate();
  const api = new ConnectAPIHelper();
  const [buttonsToShow, setButtonsToShow] = useState<any[]>([]);
  const [selectedPendingTab, setSelectedPendingTab] = useState(UrlHelper.queryParam("referrals_pending_tab"));
  const [selectedPractitionerTab, setSelectedPractitionerTab] = useState(
    UrlHelper.queryParam("referrals_practitioner_tab")
  );

  const [orderCounts, setOrderCounts] = useState<any[]>([]);
  const [mainPractitioners, setMainPractitioners] = useState<PersonalDetails[]>([]);

  const loadOrderCounts = async () => {
    const result = await api.getOrderCounts(["practitioner"]);
    setOrderCounts(result);
  };

  useEffect(() => {
    let params: any = {
      referrals_practitioner_tab: selectedPractitionerTab.toString().toLowerCase(),
      referrals_pending_tab: selectedPendingTab.toString().toLowerCase(),
    };
    const orderParam = UrlHelper.queryParam("order");
    if (orderParam) {
      params.order = orderParam;
    }

    window.history.replaceState(
      null,
      txt.get("referrals.page_title"),
      `/finance/referrals?${UrlHelper.toQueryString(params)}`
    );
    loadOrderCounts();
  }, [selectedPendingTab, selectedPractitionerTab]);

  useEffect(() => {
    const load = async () => {
      const result = await api.getPractitioners(
        [AuthenticationHelper.getInternalOrganisationId()],
        MAIN_PRACTITIONER_IDS
      );
      setMainPractitioners(result);
      loadOrderCounts();
    };

    const setInterfaceForPermissions = async () => {
      let buttons: any[] = [];
      if (await AuthenticationHelper.hasPermission(["finance#read_all"])) {
        buttons.push(
          <EuiButton
            aria-label={txt.get("finance.page_title")}
            size="s"
            style={{ width: "40px", minInlineSize: "40px" }}
            iconType="stats"
            onClick={() => navigate("/finance")}
          />
        );
      }

      if (await AuthenticationHelper.hasPermission(["referrals#edit_all"])) {
        buttons.push(
          <EuiButton size="s" fill={true} color="accent" onClick={() => navigate("/finance/referrals")}>
            {txt.get("referrals.page_title")}
          </EuiButton>
        );
      }
      if (await AuthenticationHelper.hasPermission(["invoices#edit_all"])) {
        buttons.push(
          <EuiButton size="s" onClick={() => navigate("/finance/order-values")}>
            {txt.get("finance.order_values.page_title")}{" "}
          </EuiButton>
        );
      }
      if (await AuthenticationHelper.hasPermission(["invoices#edit_all"])) {
        buttons.push(
          <EuiButton size="s" onClick={() => navigate("/finance/authorizations")}>
            {txt.get("finance.authorizations.page_title")}
          </EuiButton>
        );
      }
      if (await AuthenticationHelper.hasPermission(["invoices#read_all"])) {
        buttons.push(
          <EuiButton size="s" onClick={() => navigate("/finance/declarations")}>
            {txt.get("finance.declarations.page_title")}
          </EuiButton>
        );
      }
      setButtonsToShow(buttons);
    };
    setInterfaceForPermissions();
    load();
  }, []);

  useEffect(() => {
    const currentPractitionerTab = UrlHelper.queryParam("referrals_practitioner_tab");

    if (currentPractitionerTab !== "") {
      setSelectedPractitionerTab((current: any) => currentPractitionerTab);
    } else {
      setSelectedPractitionerTab((current: any) =>
        mainPractitioners.length > 0 ? mainPractitioners[0].id?.toString().toLowerCase() : ""
      );
    }

    const currentPendingTab = UrlHelper.queryParam("referrals_pending_tab");

    if (currentPendingTab !== "") {
      setSelectedPendingTab((current: any) => currentPendingTab);
    } else {
      setSelectedPendingTab((current: any) => "check");
    }
  }, [mainPractitioners]);

  const renderPendingTabs = () => {
    const tabs = ["check", "wait", "rejected"];

    return (
      <EuiTabs>
        {tabs.map((tabName: string) => (
          <EuiTab
            key={`tab-${tabName}`}
            onClick={() => setSelectedPendingTab(tabName)}
            isSelected={selectedPendingTab === tabName}
            append={
              <EuiNotificationBadge color={tabName === "check" ? "accent" : "subdued"}>
                {totalOrderCount(orderCounts, [
                  {
                    referral_status:
                      tabName === "wait"
                        ? [ReferralStatus.Pending]
                        : tabName === "check"
                          ? [ReferralStatus.Submitted]
                          : [ReferralStatus.Rejected],
                  },
                ])}
              </EuiNotificationBadge>
            }
          >
            <span>{txt.get(`referrals.${tabName}`)}</span>
          </EuiTab>
        ))}
      </EuiTabs>
    );
  };

  const renderReferralTab = () =>
    selectedPendingTab ? (
      <EuiPanel key={`referral-${selectedPendingTab}`} grow={false}>
        <MMOrdersShortlist
          showPerPageOptions={true}
          filters={{
            referralStatus:
              selectedPendingTab === "wait"
                ? [ReferralStatus.Pending]
                : selectedPendingTab === "check"
                  ? [ReferralStatus.Submitted]
                  : [ReferralStatus.Rejected],
            sortBy: "referral.updated_at",
            sortOrder: "asc",
          }}
          columns={["order_client", "practitioner", "referral_last_update", "referral", "referrer"]}
          clickable={true}
          name={`referral_${selectedPendingTab}`}
          localStoragePrefixForLimit="all_finance_shortlist"
          orderTab="referral"
        />
      </EuiPanel>
    ) : (
      <></>
    );

  return (
    <MMPage title={txt.get("referrals.page_title")} hideTitle={true} topActions={buttonsToShow}>
      <EuiFlexItem>
        <EuiSpacer size="xl" />
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <EuiFlexGrid>
              <EuiFlexItem>
                <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                  <EuiIcon type="documentation" size="l" />
                  <EuiTitle>
                    <EuiText>{txt.get("referrals.incoming")}</EuiText>
                  </EuiTitle>
                </div>
                <EuiSpacer />

                {renderPendingTabs()}

                <EuiSpacer />
                {renderReferralTab()}
              </EuiFlexItem>
            </EuiFlexGrid>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </MMPage>
  );
}

export default MMReferrals;
