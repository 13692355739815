import {
  EuiBasicTable,
  EuiButton,
  EuiFilterButton,
  EuiFilterGroup,
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiIcon,
  EuiImage,
  EuiNotificationBadge,
  EuiPopover,
  EuiText,
  EuiTitle,
} from "@elastic/eui";
import MMSecureImage from "components/layouts/image/secure-image";
import MMCell from "components/layouts/table/cell";
import { NO_IMAGE } from "components/products/product-card";
import { feat, Feature } from "feats";
import AuthenticationHelper from "helpers/authentication-helper";
import { productOption } from "helpers/product-helper";
import { productLabelFromOrderLineProduct } from "helpers/product-name-helper";
import txt from "helpers/text-helper";
import { columnDateOnlyShort, columnTimeOnlyShort } from "hoc/helper-hooks";
import { useEffect, useState } from "react";
import { Fitting, FITTING_EMPTY } from "store/data/fitting/fitting";
import { Order, OrderLine, OrderLineStatus, ORDER_EMPTY } from "store/data/order/order";
import { handDescription } from "store/data/scan/scan";
import MMOrderProductSelectDetails from "../order-entry/inputs/order-product-select-details";
import { MMOrderEntryInputProps } from "../order-entry/order-entry";
import { MMOrderLineProductInput, orderLinesToProducts } from "../order-entry/order-line-entry";
import {
  errorCountFitting,
  toFitting,
  validateFitting,
  validateFittingInstructed,
  validateFittingNotes,
  validateFittingReasonUnfit,
  validateFittingScores,
} from "./fitting-inputs";
import MMDeliveryAttachments from "./inputs/delivery-attachments";
import MMDeliveryInstructions from "./inputs/delivery-instructions";
import MMDeliveryNotes from "./inputs/delivery-notes";
import MMDeliveryReasonRejected from "./inputs/delivery-reason-rejected";
import MMDeliveryScores from "./inputs/delivery-scores";

export interface MMOption {
  id: string;
  label: string;
}

export interface FittingChangeHandler {
  (fitting: Fitting, orderLineId: number): void;
}
export interface MMOrderLineDeliveryFieldProps {
  order: Order | null;
  orderLineId: number;
  fitting?: Fitting;
  misfitReasons?: MMOption[];
  scoringAttributes?: MMOption[];
  onChange: FittingChangeHandler;
  maxHeight?: string;
  isEditable: boolean;
}

const toOrderLineCards = (order?: Order | null, products?: any[]) => {
  return order && order.order_lines && order.order_lines.length > 0
    ? orderLinesToProducts(order.order_lines, products || []).filter(
        (orderLineProduct: MMOrderLineProductInput) => !!orderLineProduct.code
      )
    : [];
};

const orderRef = document.getElementById("order-entry") || undefined;

function MMOrderLineDelivery(props: MMOrderEntryInputProps) {
  const misfitKeys: string[] = ["product_insufficient", "client_not_accept", "product_wrong", "other"];

  const scoringKeys: string[] = ["fit", "function", "comfort", "usage"];

  const scoringKeysInternal: string[] = feat(Feature.OrdersFittingQuality) ? ["quality"] : [];
  const misfitReasons: MMOption[] = misfitKeys.map((key: string) => ({
    id: `misfitting.${key}`,
    label: txt.get(`orders.delivery.misfitting.${key}`),
  }));

  let scoringAttributes: MMOption[] = scoringKeys.map((key: string) => ({
    id: `fitting.${key}`,
    label: txt.get(`orders.delivery.fitting.${key}`),
  }));

  if (AuthenticationHelper.isInternalUser()) {
    scoringAttributes = scoringAttributes.concat(
      scoringKeysInternal.map((key: string) => ({
        id: `fitting.${key}`,
        label: txt.get(`orders.delivery.fitting.${key}`),
      }))
    );
  }
  const lang: string = txt.lang();
  const [order, setOrder] = useState<Order | null | undefined>(props.order);
  const [currentProductOption, setCurrentProductOption] = useState<any | null>(null);
  const [handledOrderLineProducts, setHandledOrderLineProducts] = useState<number[]>([]);

  const [orderLineProducts, setOrderLineProducts] = useState<MMOrderLineProductInput[]>(
    toOrderLineCards(props.order, props.products || [])
  );

  const [selectedOrderLineProducts, setSelectedOrderLineProducts] = useState<MMOrderLineProductInput[]>([]);

  const [currentHandledOrderLineProduct, setCurrentHandledOrderLineProduct] = useState<number | null>(null);
  const [isMultiSelect] = useState<boolean>(false);

  useEffect(() => {
    const currentOrderLines: any[] = toOrderLineCards(props.order, props.products || []);
    setOrderLineProducts(currentOrderLines);
    setOrder(props.order);
  }, [props.order, props.products, props.visibleIndices]);

  const renderImage = (product: any) =>
    product && product.product_photos && product.product_photos.length > 0 ? (
      <div style={{ borderRadius: "10px", overflow: "hidden" }}>
        <MMSecureImage
          allowFullScreen={false}
          thumbnailUrl={`${product.product_photos[0]}/thumb.png`}
          url={`${product.product_photos[0]}/thumb.png`}
          api={props.api}
        />
      </div>
    ) : (
      <div style={{ borderRadius: "10px", overflow: "hidden" }}>
        <EuiImage alt="No Image" className="image" src={NO_IMAGE} />
      </div>
    );

  const isRejected = (orderLineProduct: MMOrderLineProductInput) => {
    return (
      orderLineProduct.fittings &&
      orderLineProduct.fittings.length > 0 &&
      orderLineProduct.fittings[0].is_fitting === false
    );
  };

  const isDelivered = (orderLineProduct: MMOrderLineProductInput) => {
    return (
      orderLineProduct.fittings &&
      orderLineProduct.fittings.length > 0 &&
      orderLineProduct.fittings[0].is_fitting === true
    );
  };

  const isHandled = (orderLineProduct: MMOrderLineProductInput) =>
    !!orderLineProduct.id && handledOrderLineProducts.includes(orderLineProduct.id);

  const setHandled = (orderLineProduct: MMOrderLineProductInput) => {
    setHandledOrderLineProducts((old) =>
      orderLineProduct.id && !isHandled(orderLineProduct) ? old.concat(orderLineProduct.id) : old
    );
  };

  const toggleAllHandled = (
    orderLineProductsToHandle: MMOrderLineProductInput[],
    orderLineStatus: OrderLineStatus.Delivered | OrderLineStatus.Rejected
  ) => {
    // console.log("toggleAllHandled", orderLineProductsToHandle);
    //todo
  };

  const onChange: FittingChangeHandler = (fitting: Fitting, orderLineId: number) => {
    const updatedOrder: Order = { ...(order || ORDER_EMPTY) };

    updatedOrder.order_lines = updatedOrder.order_lines.map((line: OrderLine) =>
      line.id === orderLineId
        ? {
            ...line,
            status:
              fitting.is_fitting === true
                ? OrderLineStatus.Delivered
                : fitting.is_fitting === false
                  ? OrderLineStatus.Rejected
                  : line.status,
            fittings: [fitting],
          }
        : { ...line }
    );

    console.log("delivery input changed", fitting, fitting.is_fitting, orderLineId, updatedOrder.order_lines);

    if (props.onChange) {
      props.onChange(updatedOrder);
    }
  };

  const toggleHandled = (is_fitting: boolean, orderLineId?: number) => {
    if (!orderLineId) {
      return;
    }
    onChange({ ...(toFitting(order, orderLineId) || FITTING_EMPTY), is_fitting }, orderLineId);
    setCurrentHandledOrderLineProduct(orderLineId);
  };

  const renderButtons = (orderLineProduct: MMOrderLineProductInput) => (
    <EuiPopover
      container={orderRef}
      zIndex={6000}
      anchorPosition="rightCenter"
      offset={40}
      panelPaddingSize="l"
      closePopover={() => {
        setCurrentHandledOrderLineProduct(null);
      }}
      isOpen={!!currentHandledOrderLineProduct && currentHandledOrderLineProduct === orderLineProduct.id}
      button={
        <EuiFilterGroup contentEditable={false} compressed={true}>
          <EuiFilterButton
            className="danger"
            // color="danger"
            hasActiveFilters={isRejected(orderLineProduct)}
            onClick={(e: any) => {
              toggleHandled(false, orderLineProduct.id);
            }}
            data-testid="button-disapprove"
          >
            {txt.get("orders.order.disapprove")}
            {isHandled(orderLineProduct) &&
            isRejected(orderLineProduct) &&
            !validateFitting(toFitting(order, orderLineProduct.id)) ? (
              <EuiNotificationBadge color="accent" className="notification-warning" style={{ marginLeft: "10px" }}>
                {errorCountFitting(toFitting(order, orderLineProduct.id))}
              </EuiNotificationBadge>
            ) : (
              <></>
            )}
          </EuiFilterButton>
          <EuiFilterButton
            className="success"
            // color="success"
            hasActiveFilters={isDelivered(orderLineProduct)}
            onClick={(e: any) => {
              toggleHandled(true, orderLineProduct.id);
            }}
            data-testid="button-approve"
          >
            {txt.get("orders.order.approve")}
            {isHandled(orderLineProduct) &&
            isDelivered(orderLineProduct) &&
            !validateFitting(toFitting(order, orderLineProduct.id)) ? (
              <EuiNotificationBadge color="accent" className="notification-warning" style={{ marginLeft: "10px" }}>
                {errorCountFitting(toFitting(order, orderLineProduct.id))}
              </EuiNotificationBadge>
            ) : (
              <></>
            )}
          </EuiFilterButton>
        </EuiFilterGroup>
      }
    >
      {order && orderLineProduct.id ? (
        <EuiFlexGrid className="delivery-details">
          {isRejected(orderLineProduct) ? (
            <EuiFlexItem>
              <EuiFormRow
                isInvalid={
                  isHandled(orderLineProduct) && !validateFittingReasonUnfit(toFitting(order, orderLineProduct.id))
                }
                error={
                  isHandled(orderLineProduct) && !validateFittingReasonUnfit(toFitting(order, orderLineProduct.id))
                    ? txt.get("validations.not_empty")
                    : ""
                }
              >
                <MMDeliveryReasonRejected
                  order={order}
                  fitting={toFitting(order, orderLineProduct.id) || undefined}
                  orderLineId={orderLineProduct.id}
                  misfitReasons={misfitReasons}
                  onChange={onChange}
                  isEditable={true}
                />
              </EuiFormRow>
            </EuiFlexItem>
          ) : (
            <></>
          )}
          <EuiFlexItem>
            <EuiFormRow
              isInvalid={isHandled(orderLineProduct) && !validateFittingScores(toFitting(order, orderLineProduct.id))}
              error={
                isHandled(orderLineProduct) && !validateFittingScores(toFitting(order, orderLineProduct.id))
                  ? txt.get("validations.all_not_empty_choose")
                  : ""
              }
            >
              <MMDeliveryScores
                order={order}
                fitting={toFitting(order, orderLineProduct.id) || undefined}
                orderLineId={orderLineProduct.id}
                scoringAttributes={scoringAttributes}
                onChange={onChange}
                isEditable={true}
              />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiFormRow
              isInvalid={isHandled(orderLineProduct) && !validateFittingNotes(toFitting(order, orderLineProduct.id))}
              error={
                isHandled(orderLineProduct) && !validateFittingNotes(toFitting(order, orderLineProduct.id))
                  ? txt.get("validations.not_empty")
                  : ""
              }
            >
              <MMDeliveryNotes
                order={order}
                fitting={toFitting(order, orderLineProduct.id) || undefined}
                orderLineId={orderLineProduct.id}
                onChange={onChange}
                isEditable={true}
              />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem>
            <MMDeliveryAttachments
              order={order}
              fitting={toFitting(order, orderLineProduct.id) || undefined}
              orderLineId={orderLineProduct.id}
              onChange={onChange}
              isEditable={true}
            />
          </EuiFlexItem>
          {isDelivered(orderLineProduct) ? (
            <EuiFlexItem>
              <EuiFormRow
                isInvalid={
                  isHandled(orderLineProduct) && !validateFittingInstructed(toFitting(order, orderLineProduct.id))
                }
                error={
                  isHandled(orderLineProduct) && !validateFittingInstructed(toFitting(order, orderLineProduct.id))
                    ? txt.get("validations.not_empty_choose")
                    : ""
                }
              >
                <MMDeliveryInstructions />
              </EuiFormRow>
            </EuiFlexItem>
          ) : (
            <></>
          )}

          <EuiFlexItem>
            <EuiFlexGroup justifyContent="flexEnd">
              <EuiFlexItem grow={false}>
                <EuiButton
                  fill={validateFitting(toFitting(order, orderLineProduct.id))}
                  color="accent"
                  size="s"
                  onClick={(e: any) => {
                    setHandled(orderLineProduct);
                    setCurrentHandledOrderLineProduct(null);
                  }}
                >
                  {txt.get("generic.ok")}
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGrid>
      ) : (
        <></>
      )}
    </EuiPopover>
  );

  const renderAllButtons = () => (
    <EuiFilterGroup contentEditable={false} compressed={true}>
      <EuiFilterButton
        grow={true}
        isDisabled={selectedOrderLineProducts.length <= 0}
        // className="danger"
        // color="danger"
        // hasActiveFilters={true}
        onClick={(e: any) => {
          toggleAllHandled(selectedOrderLineProducts, OrderLineStatus.Rejected);
        }}
      >
        {txt.get("orders.order.disapprove_selection")}
      </EuiFilterButton>
      <EuiFilterButton
        isDisabled={selectedOrderLineProducts.length <= 0}
        // className="success"
        // color="success"
        // hasActiveFilters={false}
        onClick={(e: any) => {
          toggleAllHandled(selectedOrderLineProducts, OrderLineStatus.Delivered);
        }}
      >
        {txt.get("orders.order.approve_selection")}
      </EuiFilterButton>
    </EuiFilterGroup>
  );

  const columns = () => [
    {
      name: "",
      field: "id",
      render: (id: string, orderLineProduct: MMOrderLineProductInput) => (
        <EuiFlexItem style={{ width: "44px" }}>{renderImage(orderLineProduct.variation)}</EuiFlexItem>
      ),
    },
    {
      name: txt.get("orders.order.product"),
      field: "id",
      render: (id: string, orderLineProduct: MMOrderLineProductInput) => {
        const productLabel = productLabelFromOrderLineProduct(orderLineProduct) || orderLineProduct.name;

        return (
          <MMCell
            wrap={false}
            text={productLabel}
            subText={orderLineProduct.hand ? handDescription(orderLineProduct.hand) : ""}
            subSoft={true}
          />
        );
      },
    },
    {
      name: txt.get("orders.order.measured_at"),
      field: "id",
      render: (id: string, orderLineProduct: MMOrderLineProductInput) => (
        <MMCell
          wrap={false}
          text={columnDateOnlyShort(order?.ordered_at, lang)}
          subText={columnTimeOnlyShort(order?.ordered_at)}
          subSoft={true}
        />
      ),
    },
    {
      name: "",
      field: "id",
      render: (id: string, orderLineProduct: MMOrderLineProductInput) => renderButtons(orderLineProduct),
    },
    {
      name: "",
      isExpander: false,
      actions: [
        {
          render: (orderLineProduct: MMOrderLineProductInput) => (
            <div
              style={{ display: "flex", alignItems: "center" }}
              onClick={(e: any) => {
                e?.stopPropagation();
                selectProductOption(orderLineProduct);
              }}
            >
              <EuiIcon aria-label="details" cursor="pointer" type="eye" />
            </div>
          ),
        },
      ],
    },
  ];

  const selectProductOption = (orderLineProduct: MMOrderLineProductInput) => {
    setCurrentProductOption(productOption(orderLineProduct.variation, lang, orderLineProduct));
  };

  const renderProductSelectDetail = (currentProductOption: any) => {
    return currentProductOption && props.api && order ? (
      <MMOrderProductSelectDetails
        api={props.api}
        order={order}
        products={props.products || []}
        // selection={{ ...EMPTY_PRODUCT_INPUT, name: currentProductOption.value }}
        productOption={currentProductOption}
        onCancel={() => setCurrentProductOption(null)}
        onSelect={undefined}
        onDelete={undefined}
        isEditable={false}
        orderTypes={props.orderTypes}
        remakeReasons={props.remakeReasons}
        clientProductions={props.clientProductions}
        showProductionDetails={props.showProductionDetails}
      />
    ) : (
      <></>
    );
  };

  // const getRowProps = (orderLineProduct: any) => {
  //   const { id } = orderLineProduct;
  //   return {
  //     "data-id": `row-${id || 1}`,
  //     onClick: (e: any) => {
  //       e.stopPropagation();
  //       if (e.shiftKey) {
  //         // copyToClipboard(e.target.innerText);
  //         // dispatch(
  //         //   toastAdd(txt.get("generic.copied_x", `'${e.target.innerText}'`))
  //         // );
  //       } else if (
  //         e.target.tagName !== "BUTTON" &&
  //         e.target.tagName !== "INPUT" &&
  //         e.target.tagName !== "A" &&
  //         e.target.title !== txt.get("orders.order.approve") &&
  //         e.target.title !== txt.get("orders.order.disapprove")
  //       ) {
  //         console.log("open product", e.target);
  //         selectProductOption(orderLineProduct);
  //       }
  //     },
  //   };
  // };

  return (
    <EuiFlexGroup direction="column" gutterSize="none">
      <EuiFlexItem grow={false}>
        <EuiTitle size="xs">
          <EuiText color="grey">{txt.get("orders.order.products_ready")}</EuiText>
        </EuiTitle>
      </EuiFlexItem>
      <EuiFlexItem
        grow={false}
        style={{
          width: "100%",
          height: "calc(100vh - 440px)",
          overflow: "auto",
        }}
      >
        <EuiBasicTable
          style={{ width: "560px" }}
          className="rows-free"
          tableLayout="auto"
          itemId="id"
          selection={
            isMultiSelect
              ? {
                  onSelectionChange: (selectedOrderLineProducts: any) => {
                    setSelectedOrderLineProducts(selectedOrderLineProducts);
                  },
                }
              : undefined
          }
          items={orderLineProducts}
          columns={columns()}
          // rowProps={getRowProps}
          noItemsMessage={txt.uf("generic.found_no_x", txt.get("orders.order.order_line.name"))}
        />
      </EuiFlexItem>
      <EuiFlexItem grow={false}>{isMultiSelect ? renderAllButtons() : <></>}</EuiFlexItem>
      {renderProductSelectDetail(currentProductOption)}
    </EuiFlexGroup>
  );
}

export default MMOrderLineDelivery;
