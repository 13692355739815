import { EuiFlexGrid, EuiFlexGroup, EuiFlexItem, EuiHorizontalRule, EuiSpacer, EuiText, EuiTitle } from "@elastic/eui";
import MMIcon, { MMIconType } from "../icon/icon";
import MMTip from "../tip/tip";

export interface MMTitleProps {
  text: string;
  icon?: MMIconType;
  tip?: any;
  underline?: boolean;
  spaceAbove?: boolean;
  sideAction?: any;
}

function MMSectionTitle(props: MMTitleProps) {
  const renderIcon = (icon: any) => (
    <span style={{ paddingRight: "8px" }}>
      <MMIcon type={icon} />
    </span>
  );

  const renderText = () => (
    <div>
      {props.icon ? renderIcon(props.icon) : <></>}
      {props.text}
    </div>
  );

  return (
    <EuiFlexGrid gutterSize="s" style={{ width: "100%" }}>
      {props.spaceAbove ? <EuiSpacer size="xxl" /> : <></>}
      <EuiFlexGroup>
        <EuiFlexItem grow={true}>
          <EuiTitle size="xs">
            <EuiText color="#7E7E7E">
              {props.tip ? <MMTip text={props.tip}>{renderText()}</MMTip> : renderText()}
            </EuiText>
          </EuiTitle>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>{props.sideAction ? props.sideAction : <></>}</EuiFlexItem>
      </EuiFlexGroup>
      {props.underline === false ? (
        <EuiSpacer size="xs" />
      ) : (
        <EuiHorizontalRule className="rule-strong" margin="none" />
      )}
      <EuiSpacer size="xs" />
    </EuiFlexGrid>
  );
}

export default MMSectionTitle;
