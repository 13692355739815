import {
  EuiBasicTable,
  EuiButton,
  EuiFieldText,
  EuiFilterButton,
  EuiFilterGroup,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiHorizontalRule,
  EuiIcon,
  EuiProgress,
  EuiSpacer,
  EuiText,
} from "@elastic/eui";
import { Fragment, useEffect, useState } from "react";

import { ApiResponseStatus } from "api/api-helper";
import ConnectAPIHelper from "api/connect-api-helper";
import MMCell, { MMCellHealth } from "components/layouts/table/cell";
import DateHelper from "helpers/date-helper";
import txt from "helpers/text-helper";
import { columnCurrency } from "hoc/helper-hooks";
import { VlotInvoiceDetails } from "store/data/invoices/vlot-invoice-details";
import { totalOrderCount } from "store/data/order/order-count";
import { handDescription } from "store/data/scan/scan";
import { useLocalStorage } from "store/local-storage";
import { useDebounce } from "use-debounce";

export const PAGE_SIZE_OPTIONS = [10, 25, 50, 100]; //,0]; //0 would mean without limit
export const DEFAULT_PAGE_SIZE = 10;
export const SEARCH_DEBOUNCE_DELAY = 700;
export const DEFAULT_SORT_BY = "order_id";
export const DEFAULT_SORT_ORDER = "desc";

function MMInvoicesList() {
  const api = new ConnectAPIHelper();

  const [invoiceCounts, setInvoiceCounts] = useState<any[]>([]);

  const [hoveringId, setHoveringId] = useState<number | null>(null);
  const [invoiceDetails, setInvoiceDetails] = useState<VlotInvoiceDetails[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  const [limit, setLimit] = useLocalStorage("invoice_details_limit", DEFAULT_PAGE_SIZE);
  const [offset, setOffset] = useLocalStorage("invoice_details_offset", 0);
  const [total, setTotal] = useState(0);
  const [sortBy, setSortBy] = useLocalStorage("invoice_details_sort_by", DEFAULT_SORT_BY);
  const [sortOrder, setSortOrder] = useLocalStorage("invoice_details_sort_order", DEFAULT_SORT_ORDER);
  const [error, setError] = useState("");

  const [matchOption, setMatchOption] = useLocalStorage("invoice_match_option", "matched");
  const [matchOptions] = useState<string[]>(["matched", "unmatched", "any"]);

  const [clientCode, setClientCode] = useLocalStorage("invoice_matching_client_code", "");

  const [clientCodeSearch] = useDebounce(clientCode, SEARCH_DEBOUNCE_DELAY);

  const loadInvoiceCounts = async () => {
    const invoiceCounts = await api.getInvoiceCounts();
    setInvoiceCounts(invoiceCounts);
  };

  useEffect(() => {
    loadInvoiceCounts();
  }, []);

  const loadInvoiceDetails = async () => {
    setIsLoading(true);
    let filters: any = {};
    if (clientCodeSearch) {
      filters.client_code = clientCodeSearch;
    }
    if (matchOption && matchOption !== "any") {
      filters.match_option = matchOption;
    }

    const result = await api.getAdminInvoiceDetails(
      filters,
      limit,
      offset,
      invoiceDetailsFieldToSortKey(sortBy),
      sortOrder
    );
    if (result.status === ApiResponseStatus.OK) {
      setInvoiceDetails(result.result);
      console.log(result.result);
      setTotal(result.meta_data.result_set.total);
      setError("");
    } else {
      setInvoiceDetails([]);
      setTotal(0);
      setError(`${result.status} (${result.code}): ${result.message}`);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    loadInvoiceDetails();
  }, [sortOrder, sortBy, limit, offset, matchOption, clientCodeSearch]);

  const resultCountInfo = () =>
    total === 0
      ? txt.uf("generic.found_no_x", txt.get("admin.invoices.vlot_invoices"))
      : txt.uf(
          "generic.showing_x_of_y_found_z",
          limit === 0 ? txt.get("generic.all") : `${offset + 1}-${Math.min(total, offset + limit)}`,
          total,
          txt.get("admin.invoices.vlot_invoices")
        ) + ".";

  const limitOffsetToPage = (limit: number, offset: number) => {
    //pages in EUI are zero based
    const page = limit > 0 ? Math.max(0, offset / limit) : 0;
    return page;
  };

  const pagination: any = {
    pageIndex: limitOffsetToPage(limit, offset),
    pageSize: limit,
    totalItemCount: total,
    pageSizeOptions: PAGE_SIZE_OPTIONS,
    showPerPageOptions: true,
  };

  const sorting: any = {
    sort: {
      field: sortBy,
      direction: sortOrder,
    },
    // enableAllColumns: false,
    // readOnly: false,
  };

  const invoiceDetailsFieldToSortKey = (field: string) => {
    switch (field) {
      default:
        return field;
    }
  };

  const onInvoiceDetailsChange = ({ page = {} as any, sort = {} as any }) => {
    if (page.size) {
      const newLimit = page.size;
      const newOffset = Math.max(0, page.index * page.size);
      if (limit !== newLimit) setLimit(newLimit);
      if (offset !== newOffset) setOffset(newOffset);
    }
    if (sort.field) {
      setSortBy(sort.field);
      setSortOrder(sort.direction ?? "asc");
    }
  };

  const unmatchInvoice = async (invoiceDetails: VlotInvoiceDetails) => {
    console.log("unmatchInvoice");

    if (!invoiceDetails.id) {
      console.error("no invoice details id");
      return;
    }

    const result = await api.invoiceUnmatch(invoiceDetails.id);
    if (result && result.status === ApiResponseStatus.OK) {
      invoiceDetails = result.result;
      console.log("updated", invoiceDetails);
      updateInvoiceDetails(invoiceDetails);
    }
    await loadInvoiceCounts();
  };

  const updateInvoiceDetails = (toUpdate: VlotInvoiceDetails) => {
    let updated: VlotInvoiceDetails[] = [...invoiceDetails];
    for (let i = 0; i < updated.length; i++) {
      if (updated[i].id === toUpdate.id) {
        updated[i] = { ...toUpdate };
      }
    }
    setInvoiceDetails((old: VlotInvoiceDetails[]) => updated);
  };

  const columns = [
    // {
    //   name: txt.get("admin.invoices.id"),
    //   field: "id",
    //   sortable: api.invoiceDetailsIsSortableBy(
    //     invoiceDetailsFieldToSortKey("id")
    //   ),
    //   render: (value: string) => <MMCell text={value} wrap={false} />,
    // },
    {
      name: txt.get("admin.invoices.vlot_client_code"),
      field: "vlot_client_code",
      sortable: api.invoiceDetailsIsSortableBy(invoiceDetailsFieldToSortKey("vlot_client_code")),
      render: (value: string) => <MMCell text={value} wrap={false} />,
    },
    {
      name: txt.get("admin.invoices.order_id"),
      field: "order_id",
      sortable: api.invoiceDetailsIsSortableBy(invoiceDetailsFieldToSortKey("order_id")),
      render: (value: string, details: VlotInvoiceDetails) => (
        <MMCell text={value} subText={details.order_line_id} subSoft={true} wrap={false} />
      ),
    },
    {
      name: txt.get("orders.order.product"),
      field: "id",
      sortable: false,
      render: (id: number, details: VlotInvoiceDetails) => (
        <MMCell
          text={
            details && details.order_line && details.order_line.product_selection
              ? details.order_line.product_selection.type
              : ""
          }
          wrap={true}
        />
      ),
    },
    {
      name: "",
      field: "order_line_id",

      render: (value: number, details: VlotInvoiceDetails) =>
        !!value ? (
          <EuiIcon
            style={{ cursor: "pointer" }}
            onMouseOver={() => {
              setHoveringId(value);
            }}
            onMouseOut={() => {
              setHoveringId(null);
            }}
            onClick={() => {
              setHoveringId(null);
              unmatchInvoice(details);
            }}
            size="s"
            type={hoveringId === value ? "unlink" : "link"}
          />
        ) : (
          <></>
        ),
    },
    {
      name: txt.get("admin.invoices.vlot_order_id"),
      field: "vlot_order_id",
      sortable: api.invoiceDetailsIsSortableBy(invoiceDetailsFieldToSortKey("vlot_order_id")),
      render: (value: string, details: VlotInvoiceDetails) => (
        <MMCell text={value} subText={details.vlot_order_line_id} subSoft={true} wrap={false} />
      ),
    },

    {
      name: txt.get("admin.invoices.vlot_invoice_id"),
      field: "vlot_invoice_id",
      sortable: api.invoiceDetailsIsSortableBy(invoiceDetailsFieldToSortKey("vlot_invoice_id")),
      render: (value: string) => <MMCell text={value} wrap={false} />,
    },
    {
      name: txt.get("admin.invoices.vlot_product"),
      sortable: api.invoiceDetailsIsSortableBy(invoiceDetailsFieldToSortKey("vlot_product")),
      field: "vlot_product",
      render: (product: string, vlotInvoiceDetails: VlotInvoiceDetails) => (
        <MMCell
          text={`${product} ${vlotInvoiceDetails.vlot_hand ? handDescription(vlotInvoiceDetails.vlot_hand) : ""}`}
          subText={vlotInvoiceDetails.vlot_product_description}
        />
      ),
    },
    {
      name: txt.get("admin.invoices.vlot_order_date"),
      sortable: api.invoiceDetailsIsSortableBy(invoiceDetailsFieldToSortKey("vlot_order_date")),
      type: "date",
      field: "vlot_order_date",
      render: (date: Date) => (
        <MMCell text={DateHelper.toDateWorded(date)} wrap={false} subText={DateHelper.toTime(date)} subSoft={true} />
      ),
    },

    {
      name: txt.get("admin.invoices.vlot_order_status"),
      sortable: api.invoiceDetailsIsSortableBy(invoiceDetailsFieldToSortKey("vlot_order_status")),
      type: "string",
      field: "vlot_order_status",
      render: (orderStatus: string) => <MMCell text={orderStatus} wrap={false} />,
    },

    {
      name: txt.get("admin.invoices.vlot_invoice_date"),
      sortable: api.invoiceDetailsIsSortableBy(invoiceDetailsFieldToSortKey("vlot_invoice_date")),
      type: "date",
      field: "vlot_invoice_date",
      render: (date: Date) => (
        <MMCell text={DateHelper.toDateWorded(date)} wrap={false} subText={DateHelper.toTime(date)} subSoft={true} />
      ),
    },
    {
      name: txt.get("admin.invoices.vlot_order_amount"),
      field: "vlot_order_amount",
      sortable: api.invoiceDetailsIsSortableBy(invoiceDetailsFieldToSortKey("vlot_order_amount")),
      render: (value: string, invoiceDetails: VlotInvoiceDetails) => (
        <MMCell
          text={columnCurrency(
            value + (invoiceDetails.vlot_order_amount_vat ? invoiceDetails.vlot_order_amount_vat : 0)
          )}
          wrap={false}
        />
      ),
    },

    {
      name: txt.get("admin.invoices.vlot_invoice_amount"),
      field: "vlot_invoice_amount",
      sortable: api.invoiceDetailsIsSortableBy(invoiceDetailsFieldToSortKey("vlot_invoice_amount")),
      render: (value: string, invoiceDetails: VlotInvoiceDetails) => (
        <MMCell
          text={columnCurrency(
            value + (invoiceDetails.vlot_invoice_amount_vat ? invoiceDetails.vlot_invoice_amount_vat : 0)
          )}
          wrap={false}
        />
      ),
    },

    {
      name: txt.get("admin.invoices.vlot_paid_amount"),
      field: "vlot_paid_amount",
      sortable: api.invoiceDetailsIsSortableBy(invoiceDetailsFieldToSortKey("vlot_paid_amount")),
      render: (value: string) => <MMCell text={columnCurrency(value)} wrap={false} />,
    },
    {
      name: txt.get("admin.invoices.vlot_is_paid_full"),
      field: "vlot_is_paid_full",
      sortable: api.invoiceDetailsIsSortableBy(invoiceDetailsFieldToSortKey("vlot_is_paid_full")),
      render: (value: boolean, vlotInvoiceDetails: VlotInvoiceDetails) => (
        <MMCell
          health={value ? MMCellHealth.Success : MMCellHealth.Warning}
          text={value ? txt.get("generic.yes") : txt.get("generic.no")}
          subText={
            vlotInvoiceDetails.vlot_paid_last_date ? DateHelper.toDateHM(vlotInvoiceDetails.vlot_paid_last_date) : "-"
          }
          wrap={false}
        />
      ),
    },
    //   vlot_authorization_date: Date | null;
    //   vlot_quotation_date: Date | null;
    //   vlot_is_warranty: boolean;
    //   vlot_is_repeat: boolean;
    //   vlot_delivery_date: Date | null;
    //   vlot_invoice_response: string | null;
    //   vlot_paid_amount: number | null;
    //   vlot_paid_last_date: Date | null;
    //   vlot_is_paid_full: boolean;
  ];

  const getRowProps = (organisation: any) => {
    const { id } = organisation;
    return {
      "data-id": `row-${id}`,
      //   onClick: (e: any) => {
      //     if (
      //       e.target.tagName !== "BUTTON" &&
      //       e.target.tagName !== "INPUT" &&
      //       e.target.tagName !== "A"
      //     ) {
      //       navigate(`/admin//${id}`);
      //     }
      //   },
    };
  };

  const progressPercentage = (invoiceCounts: any) => {
    let result = 0;
    const matched = totalOrderCount(invoiceCounts, [{ is_matched: [true], invoiced_year: ["2023", "2024"] }]);
    const unmatched = totalOrderCount(invoiceCounts, [{ is_matched: [false], invoiced_year: ["2023", "2024"] }]);
    if (matched && unmatched) {
      result = (100 * matched) / (matched + unmatched);
    }
    return Math.round(result * 100) / 100;
  };

  return (
    <Fragment>
      <EuiFlexItem grow={false} style={{ width: "150px", position: "absolute", right: "50px" }}>
        <EuiProgress
          valueText={true}
          max={100}
          color="success"
          label={txt.get("admin.invoices.matching.progress")}
          value={progressPercentage(invoiceCounts)}
        />
      </EuiFlexItem>
      <EuiFlexGroup>
        <EuiFlexItem grow={false}>
          <EuiFormRow label={txt.get("orders.order.client_code")}>
            <EuiFieldText
              compressed={true}
              value={clientCode}
              placeholder={txt.get("orders.order.client_code")}
              fullWidth={true}
              onChange={(e: any) => setClientCode((e.target.value + "").trim())}
            />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiFormRow display="rowCompressed" label={txt.get("admin.invoices.matching.match_options")}>
            <EuiFilterGroup contentEditable={false} compressed={true} prefix="s">
              {matchOptions.map((option: string, i: number) => (
                <EuiFilterButton
                  key={`button-${i}`}
                  hasActiveFilters={option === matchOption}
                  onClick={(e: any) => {
                    setMatchOption(option);
                  }}
                >
                  {option}
                </EuiFilterButton>
              ))}
            </EuiFilterGroup>
          </EuiFormRow>
        </EuiFlexItem>

        <EuiFlexItem grow={false}>
          <EuiSpacer size="xs" />
          <EuiSpacer size="m" />
          <EuiButton
            size="s"
            onClick={async () => {
              await loadInvoiceDetails();
              await loadInvoiceCounts();
            }}
          >
            {txt.get("generic.reload")}
          </EuiButton>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiFlexGroup alignItems="flexEnd">
        <EuiFlexItem>
          <EuiText textAlign="right" size="xs">
            {resultCountInfo()}
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size="s" />
      <EuiHorizontalRule margin="none" style={{ height: 1 }} />
      <EuiBasicTable
        loading={isLoading}
        tableLayout="auto"
        itemId="id"
        items={invoiceDetails}
        columns={columns}
        pagination={pagination}
        sorting={sorting}
        rowProps={getRowProps}
        noItemsMessage={error ? error : txt.uf("generic.found_no_x", txt.get("admin.invoices.vlot_invoices"))}
        onChange={onInvoiceDetailsChange}
      />
    </Fragment>
  );
}

export default MMInvoicesList;
