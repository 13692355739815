import { EuiCheckbox, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiText } from "@elastic/eui";
import MMTip from "components/layouts/tip/tip";
import AuthenticationHelper from "helpers/authentication-helper";
import txt from "helpers/text-helper";
import { useEffect, useState } from "react";
import { MMOrderLineFieldProps } from "../order-line-entry";
import { getEligibleProductVariations } from "./order-product";

function MMOrderIsModification(props: MMOrderLineFieldProps) {
  const modificationIsAllowed: boolean = AuthenticationHelper.getGroups().includes("/manometric");
  const isModifiable: boolean =
    props.selection?.variation?.is_standardized || props.selection?.variation?.is_special_variant;

  const isConfection: boolean = props.selection?.variation?.is_confection;

  const [productVariations] = useState<any[]>(props.products || []);
  const [isModification, setIsModification] = useState<boolean>(props.selection?.isModification || false);

  useEffect(() => {
    setIsModification(props.selection?.isModification || false);
  }, [props.selection]);
  const isModificationChanged = (isModification: boolean) => {
    if (props.onChange) {
      const eligibleProductVariations = getEligibleProductVariations(
        productVariations,
        props.selection?.variation?.name,
        props.selection?.variation?.family,
        props.selection?.variation?.size,
        props.selection?.variation?.color,
        isModification
      );

      props.onChange({
        ...props.selection,
        isModification: isModification,
        code: eligibleProductVariations.length === 1 ? eligibleProductVariations[0].code : null,
        variation: eligibleProductVariations.length === 1 ? eligibleProductVariations[0] : null,
      });
    } else {
      setIsModification(isModification);
    }
  };

  return props.selection.variation && modificationIsAllowed ? (
    <EuiFlexGroup gutterSize="xs" style={{ width: "100%", minWidth: "275px", maxWidth: "375px" }}>
      <EuiFlexItem>
        {props.selection && !isConfection && !isModifiable ? (
          <EuiText size="xs" color="subdued">
            {txt.get("orders.order.non_standard_warning")}
          </EuiText>
        ) : props.selection && !isConfection && isModifiable ? (
          <EuiFormRow
            style={{
              marginTop: "5px",
              width: "100%",
              minWidth: "275px",
              maxWidth: "375px",
            }}
            className="product-input"
            fullWidth={true}
            display="rowCompressed"
            label={props.showTitle ? txt.get("orders.order.non_standard_selection") : undefined}
          >
            <EuiCheckbox
              disabled={!props.isEditable}
              id={"is-modified"}
              checked={isModification}
              compressed={true}
              label={
                <span>
                  <MMTip text={txt.html("orders.order.i.production_modified")}>
                    {txt.get("orders.order.non_standard_selection")}
                  </MMTip>
                </span>
              }
              onChange={(e) => isModificationChanged(e.target.checked)}
            />
          </EuiFormRow>
        ) : (
          <></>
        )}
      </EuiFlexItem>
    </EuiFlexGroup>
  ) : (
    <></>
  );
}

export default MMOrderIsModification;
