//can start defining how the count actually is structured here

export const START_ORDER_VALUE_CONNECT: string = "2024-02-01 00:00:00";

export const totalOrderCount = (orderCounts: any[], filters: any[]) => {
  if (!orderCounts || !orderCounts.reduce) {
    return 0;
  }

  const result = orderCounts.reduce((total: number, orderCount: any) => {
    const toAdd: any = filters.find((filter: any) => {
      const attributes = Object.keys(filter);
      let result: boolean = true;
      attributes.forEach((attribute) => {
        result = result && filter[attribute].includes(orderCount[attribute]);
      });
      return result;
    });

    if (toAdd) {
      return total + parseInt(orderCount.count);
    } else {
      return total;
    }
  }, 0);
  return result;
};
