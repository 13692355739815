import txt from "helpers/text-helper";
import { Location } from "../location/location";
import { PersonalDetails } from "../personal-details/personal-details";

export enum ScannerStatus {
  Planned = "PLANNED",
  Ordered = "ORDERED",
  Assembly = "ASSEMBLY",
  InUse = "IN_USE",
  Stored = "IN_STORAGE",
  Decommissioned = "DECOMMISSIONED",
}

export enum ScannerMode {
  Order = "order",
  Patient = "patient",
}

export enum ScannerReleaseChannel {
  Release = "release",
  Test = "test",
  Dev = "dev",
  None = "none",
}

export const SCANNER_EMPTY: Scanner = {
  id: null,
  name: "",
  serial: "",
  revision: "",
  location: "",
  description: "",
  status: ScannerStatus.InUse,
};
export interface Scanner {
  id: number | null;
  name: string; //old?
  serial?: string;
  revision: string;
  location: string;
  description: string;
  status: ScannerStatus;
  created_at?: Date;
  updated_at?: Date;
  commissioned_at?: Date;
  organization_id?: number | null;
  configuration?: ScannerConfiguration;
}

export interface ScannerConfiguration {
  aws_access_key_id: string;
  aws_bucket: string;
  aws_endpoint: string;
  aws_region: string;
  //aws_secret_access_key:string;
  deployment_notes?: string;
  ngrok_authtoken: string;
  ngrok_ssh_host: string;
  ngrok_ssh_port: number;
  ngrok_ssh_user: string;
  pincode?: string;
  scan_mode?: "order" | "patient";
  sim_icc?: string;
  sim_mobile_number?: string;
  sim_pin?: string;
  sim_puk?: string;
  software_channel?: "stable" | "test";
}

export interface ScannerSession {
  scanner_id: number;
  start: Date;
  end: Date;
  network_interfaces?: ScannerNetworkInterface[];
  software_version?: string;
  software_built_at?: Date;
}

export interface ScannerNetworkInterface {
  name: string;
  enabled?: boolean;
  network?: string;
  interface?: string;
  mac_address?: string;
  ip_address?: string;
  signal?: 0 | 1 | 2 | 3 | 4 | 5;
  state?: string;
}

export interface AssignedDevice {
  id: number | null;
  assigned_type: "location" | "personal_details";
  assigned_id: number;
  device_id: number;
  device_serial?: string;
  device_description?: string;
}

export interface AssignedDeviceDetails {
  device: AssignedDevice;
  location?: Location;
  user?: PersonalDetails;
}

export const scannerReleaseChannels = () => {
  const result = (Object.keys(ScannerReleaseChannel) as Array<keyof typeof ScannerReleaseChannel>).map((key) => ({
    label: scannerReleaseChannelDescription(ScannerReleaseChannel[key]),
    value: ScannerReleaseChannel[key],
  }));
  return result;
};

export const scannerModeOptions = () => {
  const result = (Object.keys(ScannerMode) as Array<keyof typeof ScannerMode>).map((key) =>
    key.toString().toLowerCase()
  );
  return result;
};

export const scannerStatusOptions = () => {
  const result = (Object.keys(ScannerStatus) as Array<keyof typeof ScannerStatus>).map((key) => ({
    label: scannerStatusDescription(ScannerStatus[key]),
    value: ScannerStatus[key],
  }));
  return result;
};

export const scannerReleaseChannelDescription = (channel?: ScannerReleaseChannel | string) => {
  return channel ? txt.get(`admin.scanners.release_channel.${channel.toString().toLowerCase()}`) : "";
};

export const scannerModeDescription = (mode?: ScannerMode | string) => {
  return mode ? txt.uc(`admin.scanners.scan_mode.${mode.toString().toLowerCase()}`) : "";
};

export const scannerStatusDescription = (status?: ScannerStatus | string) => {
  return status ? txt.uc(`admin.scanners.status.${status.toString().toLowerCase()}`) : "";
};

export const scannerSshCommandDescription = (configuration?: ScannerConfiguration) => {
  if (!configuration) return "";
  return `ssh ${configuration.ngrok_ssh_user}@${configuration.ngrok_ssh_host} -p ${configuration.ngrok_ssh_port}`;
};

export const scannerDebugLinkDescription = (scanner?: Scanner | null) => {
  if (!scanner) return "";
  if (!scanner.serial) return "";
  return `https://${scanner.serial.toLowerCase()}.eu.ngrok.io/debug/`;
};

export const networkStateToHealth = (state: string) => {
  switch (state) {
    case "UP":
      return "success";
    case "DOWN":
      return "text";
  }
  return "warning";
};
