import {
  EuiBasicTable,
  EuiButton,
  EuiComboBox,
  EuiFieldText,
  EuiFilterButton,
  EuiFilterGroup,
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiHorizontalRule,
  EuiProgress,
  EuiSpacer,
  EuiSwitch,
  EuiText,
} from "@elastic/eui";
import { Fragment, useEffect, useState } from "react";

import { ApiResponseStatus } from "api/api-helper";
import ConnectAPIHelper from "api/connect-api-helper";
import MMCell from "components/layouts/table/cell";
import DateHelper from "helpers/date-helper";
import StringHelper from "helpers/string-helper";
import txt from "helpers/text-helper";
import { InvoiceMatch, VlotInvoiceDetails } from "store/data/invoices/vlot-invoice-details";
import { totalOrderCount } from "store/data/order/order-count";
import { handDescription } from "store/data/scan/scan";
import { useLocalStorage } from "store/local-storage";
import { useDebounce } from "use-debounce";

export const PAGE_SIZE_OPTIONS = [10, 25, 50, 100]; //,0]; //0 would mean without limit
export const DEFAULT_PAGE_SIZE = 10;
export const SEARCH_DEBOUNCE_DELAY = 700;
export const DEFAULT_SORT_BY = "order_id";
export const DEFAULT_SORT_ORDER = "desc";

const DEFAULT_LINE_COUNT = "1";
const DEFAULT_DAY_RANGE = "7";
// match order date range (days before / after)
// client code
// with / without order match

// what todo with multiple invoices?
// then status updates of the order....
//invoice id
//add group invoice id (declaratie id?)
//unmatch

const years = [
  { label: "2020", "data-id": "2020" },
  { label: "2021", "data-id": "2021" },
  { label: "2022", "data-id": "2022" },
  { label: "2023", "data-id": "2023" },
  { label: "2024", "data-id": "2024" },
];
function MMInvoiceMatching() {
  const api = new ConnectAPIHelper();

  const [invoiceMatches, setInvoiceMatches] = useState<InvoiceMatch[]>([]);
  const [invoiceCounts, setInvoiceCounts] = useState<any[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  const [limit, setLimit] = useLocalStorage("invoice_matching_limit", DEFAULT_PAGE_SIZE);
  const [offset, setOffset] = useLocalStorage("invoice_matching_offset", 0);
  const [total, setTotal] = useState(0);
  const [sortBy, setSortBy] = useLocalStorage("invoice_matching_sort_by", DEFAULT_SORT_BY);
  const [sortOrder, setSortOrder] = useLocalStorage("invoice_matching_sort_order", DEFAULT_SORT_ORDER);

  const [lineCount, setLineCount] = useLocalStorage("invoice_matching_line_count", DEFAULT_LINE_COUNT);

  const [lineCounts] = useState<string[]>(["1", "2", "3", "any"]);
  const [dayRange, setDayRange] = useLocalStorage("invoice_matching_day_range", DEFAULT_DAY_RANGE);

  const [dayRanges] = useState<string[]>(["1", "7", "14", "30", "any"]);
  const [clientCode, setClientCode] = useLocalStorage("invoice_matching_client_code", "");

  const [clientCodeSearch] = useDebounce(clientCode, SEARCH_DEBOUNCE_DELAY);
  const [productCode, setProductCode] = useLocalStorage("invoice_matching_product_code", "");
  const [productCodeSearch] = useDebounce(productCode, SEARCH_DEBOUNCE_DELAY);

  const [selectedYears, setSelectedYears] = useLocalStorage("invoice_matching_years", []);

  const [isRepairsHidden, setIsRepairsHidden] = useLocalStorage("invoice_matching_repairs", false);
  const [isWarrantiesHidden, setIsWarrantiesHidden] = useLocalStorage("invoice_matching_warranties", false);

  const [isFirstFittingsOnly, setIsFirstFittingsOnly] = useLocalStorage("invoice_matching_fittings", false);

  const [isMatchingOnHand, setIsMatchingOnHand] = useLocalStorage("invoice_matching_warranties", true);
  const [error, setError] = useState("");

  const loadInvoiceCounts = async () => {
    const invoiceCounts = await api.getInvoiceCounts();
    setInvoiceCounts(invoiceCounts);
  };

  useEffect(() => {
    loadInvoiceCounts();
  }, []);

  const loadInvoiceMatches = async () => {
    setIsLoading(true);
    let filters: any = {};

    if (lineCount) {
      filters.line_count = lineCount;
    }

    if (isRepairsHidden) {
      filters.hide_repairs = true;
    }
    if (isWarrantiesHidden) {
      filters.hide_warranties = true;
    }
    if (isFirstFittingsOnly) {
      filters.first_fittings_only = true;
    }
    if (dayRange) {
      filters.day_range = dayRange;
    }

    if (clientCodeSearch) {
      filters.client_code = clientCodeSearch;
    }

    if (productCodeSearch) {
      filters.product_code = productCodeSearch;
    }

    if (isMatchingOnHand) {
      filters.match_hand = isMatchingOnHand;
    }

    if (selectedYears && selectedYears.length > 0) {
      filters.years = selectedYears.map((year: any) => year["data-id"]);
    }

    const result = await api.getAdminInvoiceMatches(
      filters,
      limit,
      offset,
      invoiceMatchesFieldToSortKey(sortBy),
      sortOrder
    );
    if (result.status === ApiResponseStatus.OK) {
      setInvoiceMatches(result.result);
      setTotal(result.meta_data.result_set.total);
      setError("");
    } else {
      setInvoiceMatches([]);
      setTotal(0);
      setError(`${result.status} (${result.code}): ${result.message}`);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadInvoiceMatches();
  }, [
    sortOrder,
    sortBy,
    limit,
    offset,
    isWarrantiesHidden,
    isRepairsHidden,
    lineCount,
    clientCodeSearch,
    productCodeSearch,
    dayRange,
    isMatchingOnHand,
    isFirstFittingsOnly,
    selectedYears,
  ]);

  const resultCountInfo = () =>
    total === 0
      ? txt.uf("generic.found_no_x", txt.get("admin.invoices.page_title"))
      : txt.uf(
          "generic.showing_x_of_y_found_z",
          limit === 0 ? txt.get("generic.all") : `${offset + 1}-${Math.min(total, offset + limit)}`,
          total,
          txt.get("admin.invoices.page_title")
        ) + ".";

  const limitOffsetToPage = (limit: number, offset: number) => {
    //pages in EUI are zero based
    const page = limit > 0 ? Math.max(0, offset / limit) : 0;
    return page;
  };

  const pagination: any = {
    pageIndex: limitOffsetToPage(limit, offset),
    pageSize: limit,
    totalItemCount: total,
    pageSizeOptions: PAGE_SIZE_OPTIONS,
    showPerPageOptions: true,
  };

  const sorting: any = {
    sort: {
      field: sortBy,
      direction: sortOrder,
    },
    // enableAllColumns: false,
    // readOnly: false,
  };

  const invoiceMatchesFieldToSortKey = (field: string) => {
    switch (field) {
      default:
        return field;
    }
  };

  const matchInvoice = async (invoiceDetails: VlotInvoiceDetails, orderId: number, orderLineId: number) => {
    console.log("matchInvoice");

    if (!invoiceDetails.id) {
      console.error("no invoice details id");
      return;
    }

    const result = await api.invoiceMatch(invoiceDetails.id, orderId, orderLineId);
    if (result && result.status === ApiResponseStatus.OK) {
      invoiceDetails = result.result;
      console.log("updated", invoiceDetails);
      updateInvoiceDetails(invoiceDetails, orderId, orderLineId);
    }
    await loadInvoiceCounts();
  };

  const unmatchInvoice = async (invoiceDetails: VlotInvoiceDetails, orderId: number, orderLineId: number) => {
    console.log("unmatchInvoice");

    if (!invoiceDetails.id) {
      console.error("no invoice details id");
      return;
    }

    const result = await api.invoiceUnmatch(invoiceDetails.id);
    if (result && result.status === ApiResponseStatus.OK) {
      invoiceDetails = result.result;
      console.log("updated", invoiceDetails);
      updateInvoiceDetails(invoiceDetails, orderId, orderLineId);
    }
    await loadInvoiceCounts();
  };

  const updateInvoiceDetails = (invoiceDetails: VlotInvoiceDetails, orderId: number, orderLineId: number) => {
    let matches: InvoiceMatch[] = [...invoiceMatches];
    for (let i = 0; i < matches.length; i++) {
      const match = matches[i];
      if (match.order_id === orderId && match.order_line_id === orderLineId) {
        let updatedMatches: VlotInvoiceDetails[] = [];
        for (let j = 0; j < match.matches.length; j++) {
          const currentDetails: VlotInvoiceDetails = match.matches[j];

          if (currentDetails.id === invoiceDetails.id) {
            console.log("REPLACING", invoiceDetails);
            updatedMatches.push({ ...invoiceDetails });
          } else {
            updatedMatches.push({ ...currentDetails });
          }
        }
        match.matches = updatedMatches;
        break;
      }
    }
    setInvoiceMatches((old: InvoiceMatch[]) => matches);
  };

  const onInvoiceMatchesChange = ({ page = {} as any, sort = {} as any }) => {
    if (page.size) {
      const newLimit = page.size;
      const newOffset = Math.max(0, page.index * page.size);
      if (limit !== newLimit) setLimit(newLimit);
      if (offset !== newOffset) setOffset(newOffset);
    }
    if (sort.field) {
      setSortBy(sort.field);
      setSortOrder(sort.direction ?? "asc");
    }
  };

  const columns = [
    {
      name: txt.get("admin.invoices.vlot_client_code"),
      field: "client_code",
      sortable: api.invoiceMatchesIsSortableBy(invoiceMatchesFieldToSortKey("client_code")),
      render: (value: string) => <MMCell text={value} wrap={false} />,
    },
    {
      name: txt.get("admin.invoices.order_id"),
      field: "order_id",
      sortable: api.invoiceMatchesIsSortableBy(invoiceMatchesFieldToSortKey("order_id")),
      render: (value: string) => <MMCell text={value} wrap={false} />,
    },
    {
      name: txt.get("admin.invoices.order_line_id"),
      field: "order_line_id",
      sortable: api.invoiceMatchesIsSortableBy(invoiceMatchesFieldToSortKey("order_line_id")),
      render: (value: string) => <MMCell text={value} wrap={false} />,
    },
    {
      name: txt.get("admin.invoices.order_date"),
      field: "order_date",
      sortable: api.invoiceMatchesIsSortableBy(invoiceMatchesFieldToSortKey("order_date")),
      render: (value: string, match: InvoiceMatch) => (
        <EuiFlexGrid gutterSize="none">
          <MMCell text={DateHelper.toDate(value)} wrap={false} />
          {/* {match.order_status ? (
            <EuiHealth color={orderToHealth(match.order_status)}>
              {orderStatusDescription(match.order_status)}
            </EuiHealth>
          ) : (
            <></>
          )} */}
        </EuiFlexGrid>
      ),
    },
    {
      name: txt.get("orders.order.product"),
      field: "product_code",
      sortable: api.invoiceMatchesIsSortableBy(invoiceMatchesFieldToSortKey("product_code")),
      render: (value: string, match: InvoiceMatch) => (
        <EuiFlexGrid gutterSize="none">
          <MMCell
            text={value}
            subText={`${match.product_selection.type ? " " + match.product_selection.type : ""}${
              match.product_selection.color ? " " + match.product_selection.color : ""
            }`}
            wrap={false}
          />
          <MMCell
            text={`${match.hand ? handDescription(match.hand) : ""}${
              match.digits && match.digits.length > 0 ? ` ${match.digits.join(",")}` : ""
            }`}
            wrap={false}
          />
          <MMCell text={match.order_type} wrap={false} />
        </EuiFlexGrid>
      ),
    },
    {
      name: txt.get("admin.invoices.matching.matches"),
      field: "matches",
      render: (matches: VlotInvoiceDetails[], match: InvoiceMatch) => (
        <EuiFlexGrid>
          {matches
            .filter((invoiceDetails: VlotInvoiceDetails) => !!invoiceDetails.vlot_order_id)
            .map((invoiceDetails: VlotInvoiceDetails, i: number) => (
              <EuiFlexItem key={`match-${i}`}>
                <EuiFlexGroup className="invoice-match" alignItems="center">
                  <EuiFlexItem grow={false}>
                    <div
                      style={{
                        width: "200px",
                        display: "block",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <div style={{ width: "80px", display: "inline-block" }}>vlot order:</div>
                      {invoiceDetails.vlot_order_id} | {invoiceDetails.vlot_order_line_id}
                    </div>
                    <div
                      style={{
                        width: "200px",
                        display: "block",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <div style={{ width: "80px", display: "inline-block" }}>ordered:</div>
                      {DateHelper.toDate(invoiceDetails.vlot_order_date)}
                    </div>
                    <div
                      style={{
                        width: "200px",
                        display: "block",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <div style={{ width: "80px", display: "inline-block" }}>requested:</div>
                      {invoiceDetails.vlot_authorization_date
                        ? DateHelper.toDate(invoiceDetails.vlot_authorization_date)
                        : "-"}
                    </div>
                    <div
                      style={{
                        width: "200px",
                        display: "block",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <div style={{ width: "80px", display: "inline-block" }}>invoiced:</div>
                      {invoiceDetails.vlot_invoice_date ? DateHelper.toDate(invoiceDetails.vlot_invoice_date) : "-"}
                    </div>
                    <div
                      style={{
                        width: "100px",
                        display: "block",
                        whiteSpace: "nowrap",
                      }}
                    ></div>
                  </EuiFlexItem>
                  <EuiFlexItem grow={false} style={{ width: "200px" }}>
                    {invoiceDetails.vlot_product}
                    <br />
                    {invoiceDetails.vlot_product_description}
                    <br />
                    {invoiceDetails.vlot_hand ? handDescription(invoiceDetails.vlot_hand) : ""}
                    <br />
                    {invoiceDetails.vlot_order_amount
                      ? "" +
                        StringHelper.currency(invoiceDetails.vlot_order_amount + invoiceDetails.vlot_order_amount_vat)
                      : ""}
                  </EuiFlexItem>
                  <EuiFlexItem grow={true}>
                    <EuiSwitch
                      label={""}
                      checked={!!invoiceDetails.order_line_id && !!invoiceDetails.order_id}
                      onChange={(e) =>
                        !!invoiceDetails.order_line_id && !!invoiceDetails.order_id
                          ? unmatchInvoice(invoiceDetails, match.order_id, match.order_line_id)
                          : matchInvoice(invoiceDetails, match.order_id, match.order_line_id)
                      }
                    />
                    <EuiText size="xs">{txt.get("admin.invoices.matching.match")}</EuiText>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlexItem>
            ))}
        </EuiFlexGrid>
      ),
    },
  ];

  const onYearsChange = (selectedOptions: any[]) => {
    setSelectedYears(selectedOptions);
  };
  const getRowProps = (match: any) => {
    const { id } = match;
    return {
      "data-id": `row-${id}`,
      //   onClick: (e: any) => {
      //     if (
      //       e.target.tagName !== "BUTTON" &&
      //       e.target.tagName !== "INPUT" &&
      //       e.target.tagName !== "A"
      //     ) {
      //       navigate(`/admin//${id}`);
      //     }
      //   },
    };
  };

  const progressPercentage = (invoiceCounts: any) => {
    let result = 0;
    const matched = totalOrderCount(invoiceCounts, [{ is_matched: [true], invoiced_year: ["2023", "2024"] }]);
    const unmatched = totalOrderCount(invoiceCounts, [{ is_matched: [false], invoiced_year: ["2023", "2024"] }]);
    if (matched && unmatched) {
      result = (100 * matched) / (matched + unmatched);
    }
    return Math.round(result * 100) / 100;
  };
  return (
    <Fragment>
      <EuiFlexItem grow={false} style={{ width: "170px", position: "absolute", right: "50px" }}>
        <EuiProgress
          valueText={true}
          max={100}
          color="success"
          label={txt.get("admin.invoices.matching.progress")}
          value={progressPercentage(invoiceCounts)}
        />
      </EuiFlexItem>
      <EuiFlexGroup>
        <EuiFlexItem grow={false}>
          <EuiFormRow label={txt.get("orders.order.client_code")}>
            <EuiFieldText
              style={{ width: "190px" }}
              compressed={true}
              value={clientCode}
              placeholder={txt.get("orders.order.client_code")}
              fullWidth={true}
              onChange={(e: any) => setClientCode(e.target.value)}
            />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiFormRow label={txt.get("orders.order.product")}>
            <EuiFieldText
              style={{ width: "190px" }}
              compressed={true}
              value={productCode}
              placeholder={txt.get("orders.order.product")}
              fullWidth={true}
              onChange={(e: any) => setProductCode(e.target.value)}
            />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiFormRow label={txt.get("admin.invoices.matching.year")}>
            <EuiComboBox
              compressed={true}
              style={{ width: "260px" }}
              aria-label={txt.uf("generic.select_x", txt.get("admin.invoices.matching.year"))}
              placeholder={txt.uf("generic.select_x", txt.get("admin.invoices.matching.year"))}
              // singleSelection={{ asPlainText: true }}
              options={years}
              selectedOptions={selectedYears}
              onChange={onYearsChange}
            />
          </EuiFormRow>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size="m" />
      <EuiFlexGroup>
        <EuiFlexItem grow={false}>
          <EuiFormRow display="rowCompressed" label={txt.get("admin.invoices.matching.line_count")}>
            <EuiFilterGroup style={{ width: "190px" }} contentEditable={false} compressed={true} prefix="s">
              {lineCounts.map((count: string, i: number) => (
                <EuiFilterButton
                  key={`button-${i}`}
                  hasActiveFilters={count === lineCount}
                  onClick={(e: any) => {
                    setLineCount(count);
                  }}
                >
                  {count}
                </EuiFilterButton>
              ))}
            </EuiFilterGroup>
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiFormRow
            display="rowCompressed"
            label={txt.get("admin.invoices.matching.order_types")}
            style={{ width: "190px" }}
          >
            <EuiFlexItem>
              <EuiSwitch
                compressed={true}
                label={<span style={{ whiteSpace: "nowrap" }}>{txt.get("admin.invoices.matching.hide_repairs")}</span>}
                checked={isRepairsHidden}
                onChange={(e) => setIsRepairsHidden(!isRepairsHidden)}
              />
              <EuiSwitch
                compressed={true}
                label={
                  <span style={{ whiteSpace: "nowrap" }}>{txt.get("admin.invoices.matching.hide_warranties")}</span>
                }
                checked={isWarrantiesHidden}
                onChange={(e) => setIsWarrantiesHidden(!isWarrantiesHidden)}
              />
              <EuiSwitch
                compressed={true}
                label={
                  <span style={{ whiteSpace: "nowrap" }}>{txt.get("admin.invoices.matching.first_fittings_only")}</span>
                }
                checked={isFirstFittingsOnly}
                onChange={(e) => setIsFirstFittingsOnly(!isFirstFittingsOnly)}
              />
            </EuiFlexItem>
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiFormRow display="rowCompressed" label={txt.get("admin.invoices.matching.day_range")}>
            <EuiFilterGroup contentEditable={false} compressed={true} prefix="s" style={{ width: "260px" }}>
              {dayRanges.map((count: string, i: number) => (
                <EuiFilterButton
                  key={`button-${i}`}
                  hasActiveFilters={count === dayRange}
                  onClick={(e: any) => {
                    setDayRange(count);
                  }}
                >
                  {count}
                </EuiFilterButton>
              ))}
            </EuiFilterGroup>
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem grow={false} style={{ justifyContent: "center" }}>
          <EuiFormRow
            display="rowCompressed"
            label={txt.get("admin.invoices.matching.on_hand")}
            style={{ width: "190px" }}
          >
            <EuiSwitch
              compressed={true}
              label={<span style={{ whiteSpace: "nowrap" }}>{txt.get("admin.invoices.matching.on_hand")}</span>}
              checked={isMatchingOnHand}
              onChange={(e) => setIsMatchingOnHand(!isMatchingOnHand)}
            />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiSpacer size="xs" />
          <EuiSpacer size="m" />
          <EuiButton
            size="s"
            onClick={() => {
              loadInvoiceMatches();
            }}
          >
            {txt.get("generic.reload")}
          </EuiButton>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiFlexGroup alignItems="flexEnd">
        <EuiFlexItem>
          <EuiText textAlign="right" size="xs">
            {resultCountInfo()}
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size="s" />
      <EuiHorizontalRule margin="none" style={{ height: 1 }} />
      <EuiBasicTable
        className="match-table"
        loading={isLoading}
        tableLayout="auto"
        itemId="id"
        items={invoiceMatches}
        columns={columns}
        pagination={pagination}
        sorting={sorting}
        rowProps={getRowProps}
        noItemsMessage={error ? error : txt.uf("generic.found_no_x", txt.get("admin.invoices.page_title"))}
        onChange={onInvoiceMatchesChange}
      />
    </Fragment>
  );
}

export default MMInvoiceMatching;
