import { EuiButton, EuiFlexItem, EuiSpacer } from "@elastic/eui";
import MMPage, { PageLink } from "components/layouts/page/page";
import AuthenticationHelper from "helpers/authentication-helper";
import FileUploadHelper from "helpers/file-upload-helper";
import txt from "helpers/text-helper";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toastAdd } from "store/components/toast/toast";
import { adminNav } from "../admin";
import MMReferrersList from "./referrers-list";
function MMAdminReferrers() {
  const [buttonsToShow, setButtonsToShow] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [subPages, setSubPages] = useState<PageLink[]>([]);
  useEffect(() => {
    const getSubPages = async () => {
      setSubPages(await adminNav("/admin/locations"));
    };

    getSubPages();
  }, []);

  const handleImportReferrers = async () => {
    setIsLoading(true);

    let fileUploadHelper = new FileUploadHelper();

    //api details should be somewhere else...
    const path: string = "referrers/import";
    const key: string = "import_file";

    try {
      await fileUploadHelper.uploadFile(path, key, null);
      dispatch(toastAdd(txt.get("admin.referrers.imported"), null, "success"));
      navigate("/admin/referrers");
    } catch (e: any) {
      console.error("something went wrong uploading referrers", e);
      dispatch(toastAdd(txt.get("admin.referrers.import_error"), null, "danger"));
    }
    setIsLoading(false);
  };
  useEffect(() => {
    const setInterfaceForPermissions = async () => {
      let buttons: any[] = [];
      if (await AuthenticationHelper.hasPermission(["referrers#edit_all"])) {
        buttons.push(
          <EuiButton
            key="add_referrer"
            color="accent"
            size="s"
            fill
            iconType="plus"
            onClick={() => navigate("/admin/referrers/new")}
          >
            {txt.get("generic.add_new_x", txt.lo("admin.referrers.name"))}
          </EuiButton>
        );
        buttons.push(
          <EuiButton
            isLoading={isLoading}
            key="import_referrers"
            size="s"
            iconType="importAction"
            onClick={() => handleImportReferrers()}
          >
            {txt.get("admin.referrers.import")}
          </EuiButton>
        );
      }
      setButtonsToShow(buttons);
    };
    setInterfaceForPermissions();
  }, [isLoading]);

  const buttons = () => {
    return buttonsToShow;
  };

  return (
    <MMPage
      title={`${txt.get("admin.page_title")} ${txt.lo("admin.referrers.page_title")}`}
      subPages={subPages}
      backTo="/admin"
      backToText={txt.uf("generic.back_to", txt.get("admin.page_title"))}
      sideActions={buttons()}
    >
      <EuiFlexItem>
        <EuiSpacer />
        <MMReferrersList />
      </EuiFlexItem>
    </MMPage>
  );
}
export default MMAdminReferrers;
