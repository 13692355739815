import { EuiComboBox, EuiFlexGroup, EuiFlexItem, EuiFormRow, useIsWithinBreakpoints } from "@elastic/eui";
import AuthenticationHelper from "helpers/authentication-helper";
import txt from "helpers/text-helper";
import { useEffect, useState } from "react";
import { MMOrderLineFieldProps, MMOrderLineProductInput } from "../order-line-entry";

const toSelectedCommunicationReasons = (selection: any) => {
  return selection && selection.communicationReasons
    ? selection.communicationReasons.split(",").map((reason: string) => ({ label: reason }))
    : [];
};

function MMOrderAdditionalCommunication(props: MMOrderLineFieldProps) {
  const isMobile: boolean = useIsWithinBreakpoints(["xs", "s"]);

  const [communicationReasons] = useState<any[]>(props.communicationReasons || []);
  const [communicationReasonOptions, setCommunicationReasonOptions] = useState<any[]>([]);

  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isLoading] = useState<boolean>(false);
  const [selectedCommunicationReasons, setSelectedCommunicationReasons] = useState<any[]>(
    toSelectedCommunicationReasons(props.selection)
  );

  useEffect(() => {
    const setInterfaceForPermissions = async () => {
      setIsVisible(await AuthenticationHelper.hasPermission(["orders#comment"]));
    };

    setInterfaceForPermissions();
  }, []);
  useEffect(() => {
    setSelectedCommunicationReasons(toSelectedCommunicationReasons(props.selection));
  }, [props.selection]);

  const onCommunicationReasonsChange = (communicationReasons: any) => {
    if (props.onChange) {
      props.onChange({
        ...(props.selection || {}),
        communicationReasons: communicationReasons.map((reason: any) => reason.label).join(","),
      } as MMOrderLineProductInput);
    }
  };

  useEffect(() => {
    if (communicationReasons && communicationReasons.map) {
      const result = communicationReasons.map((communicationReason: any) => ({
        // value: communicationReason,
        label: communicationReason,
      }));
      setCommunicationReasonOptions(result);
    }
  }, [communicationReasons]);

  const onCreateOption = (searchValue: string, flattenedOptions: any = []) => {
    const normalizedSearchValue = searchValue.trim().toLowerCase();

    if (!normalizedSearchValue) {
      return;
    }

    const newOption = {
      //   value: searchValue,
      label: searchValue,
    };
    if (
      flattenedOptions.findIndex((option: any) => option.label.trim().toLowerCase() === normalizedSearchValue) === -1
    ) {
      onCommunicationReasonsChange([...selectedCommunicationReasons, newOption]);
      setCommunicationReasonOptions([...communicationReasonOptions, newOption]);
    }
  };
  return (
    <EuiFlexGroup gutterSize="xs">
      <EuiFlexItem>
        <EuiFormRow
          data-testid="additional-communication-input"
          className="product-input"
          display="rowCompressed"
          label={props.showTitle ? txt.get("orders.order.additional_communication") : ""}
          style={!isVisible ? { display: "none" } : {}}
        >
          <EuiComboBox
            className={selectedCommunicationReasons.length > 0 ? `option-selected` : ""}
            style={isMobile ? {} : {}}
            color="accent"
            isLoading={isLoading}
            //   singleSelection={{ asPlainText: true }}
            compressed={true}
            placeholder={txt.get("orders.order.additional_communication")}
            options={communicationReasonOptions}
            selectedOptions={selectedCommunicationReasons}
            onChange={(selected) => onCommunicationReasonsChange(selected)}
            onCreateOption={onCreateOption}
          />
        </EuiFormRow>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
}

export default MMOrderAdditionalCommunication;
