export const ORDER_SORTABLE_FIELDS: string[] = [
  "id",
  "ordered_at",
  "ordered_by",
  "practitioner",
  "location",
  "delivery_method",
  "client_code",
  "hand",
  "status",
  "updated_at",
  "created_at",
  "referral.updated_at",
  "last_quoted_at",
];

export const INSURERS_SORTABLE_FIELDS: string[] = ["id", "name", "is_active", "uvozi_code", "created_at", "updated_at"];

export const UZOVI_CODES_SORTABLE_FIELDS: string[] = ["id", "name", "code", "insurer_id", "created_at", "updated_at"];

export const INVOICE_DETAILS_SORTABLE_FIELDS: string[] = [
  "id",
  "order_id",
  "order_line_id",
  "vlot_order_id",
  "vlot_order_line_id",
  "vlot_invoice_id",
];

export const INVOICE_MATCHES_SORTABLE_FIELDS: string[] = [
  // "order_id",
  // "order_line_id",
  "client_code",
  "order_date",
];

export const CONTRACTS_SORTABLE_FIELDS: string[] = ["id", "insurer_name", "base_article_code"];

export const COSTINGS_SORTABLE_FIELDS: string[] = [
  "id",
  "authorization",
  "is_quoted",
  "is_authorized",
  "is_invoiced",
  "order_line.order.client_code",
  "contract.insurer_id",
  "contract.base_article_code",
  "order",
  "created_at",
  "updated_at",
];

export const ORGANISATION_SORTABLE_FIELDS: string[] = ["id", "name", "acronym", "created_at", "updated_at"];

export const USER_SORTABLE_FIELDS: string[] = [
  "id",
  "first_name",
  "last_name",
  "email",
  "organisation_id",
  "created_at",
  "updated_at",
];

export const LOCATION_SORTABLE_FIELDS: string[] = [
  "id",
  "name",
  "city",
  "is_active",
  "organisation_id",
  "created_at",
  "updated_at",
];

export const REFERRER_SORTABLE_FIELDS: string[] = [
  "id",
  "is_active",
  "organisation_id",
  "alternative_organisation",
  "note",
  "created_at",
  "updated_at",
  "agb_code",
  "organisation_agb_code",
];

export const APPOINTMENT_SORTABLE_FIELDS: string[] = ["id", "start", "practitioner_id", "location_id"];

export const WORKFLOW_SORTABLE_FIELDS: string[] = ["id", "name", "acronym", "is_active", "created_at", "updated_at"];

export const PRODUCTIONS_SORTABLE_FIELDS: string[] = [
  "id",
  "code",
  "order_id",
  "product",
  "client",
  "order_date",
  "created_at",
  "updated_at",
  "order.location",
  "order.delivery_method",
];

export const ORDER_MATCH_SORTABLE_FIELDS: string[] = ["order_id", "order_line_id", "order_date"];
export const ORDER_FILTERABLE_FIELDS: string[] = [
  "id",
  "ordered_at",
  "ordered_by",
  "practitioner",
  "created_at",
  "client_code",
  "hand",
  "status",
];
export const ORDER_DEFAULT_SINCE = "2022-10-01";
