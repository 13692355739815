import { EuiFlexItem, EuiSpacer } from "@elastic/eui";
import MMPage, { PageLink } from "components/layouts/page/page";
import txt from "helpers/text-helper";
import { useEffect, useState } from "react";
import { adminNav } from "../admin";
import MMPermissionsGrid from "./permissions-grid";
function MMAdminPermissions() {
  const [subPages, setSubPages] = useState<PageLink[]>([]);
  useEffect(() => {
    const getSubPages = async () => {
      setSubPages(await adminNav("/admin/permissions"));
    };

    getSubPages();
  }, []);

  return (
    <MMPage
      title={`${txt.get("admin.page_title")} ${txt.lo("admin.permissions.page_title")}`}
      subPages={subPages}
      backTo="/admin"
      backToText={txt.uf("generic.back_to", txt.get("admin.page_title"))}
    >
      <EuiFlexItem>
        <EuiSpacer />
        <MMPermissionsGrid />
      </EuiFlexItem>
    </MMPage>
  );
}
export default MMAdminPermissions;
