class UrlHelper {
  static queryParams(paramString?: string): any {
    let result: any = {};

    const queryParams = new URLSearchParams(paramString || window.location.search);
    queryParams.forEach((value, key) => {
      result[key] = value;
    });
    return result;
  }

  static queryParam(key: string): any {
    const params = UrlHelper.queryParams();
    return params[key] ? params[key] : "";
  }

  static toQueryString(queryParams: any) {
    let result = "";
    for (const key in queryParams) {
      if (Object.prototype.hasOwnProperty.call(queryParams, key)) {
        let value = queryParams[key];
        if (value instanceof Array) {
          for (let i = 0; i < value.length; i++) {
            const arrayValue = value[i];
            if (arrayValue !== undefined) {
              result += `${key}=${encodeURIComponent(arrayValue)}&`;
            }
          }
        } else if (value !== undefined) {
          result += `${key}=${encodeURIComponent(value)}&`;
        }
      }
    }
    return result;
  }
}

export default UrlHelper;
