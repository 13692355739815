import { EuiCheckbox } from "@elastic/eui";
import { useEffect, useState } from "react";

export interface MMPermissionProps {
  permissions: any[];
  clientId: string;
  resourceId: string;
  scopeId: string;
  policyId: string;
  onPermissionUpdate: Function;
}

function MMPermission({ permissions, clientId, resourceId, scopeId, policyId, onPermissionUpdate }: MMPermissionProps) {
  const [isChecked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsChecked(permissions.length > 0);
    setIsLoading(false);
  }, [permissions]);

  const onPermissionCheck = (e: any) => {
    setIsLoading(true);
    onPermissionUpdate(clientId, resourceId, scopeId, policyId, e.target.checked);
    //setIsChecked(e.target.checked);
  };

  return (
    <EuiCheckbox
      disabled={isLoading}
      checked={isChecked}
      data-client-id={clientId}
      data-resource-id={resourceId}
      data-scope-id={scopeId}
      data-policy-id={policyId}
      id={resourceId}
      onChange={onPermissionCheck}
    ></EuiCheckbox>
  );
}

export default MMPermission;
