import {
  EuiButtonEmpty,
  EuiFieldText,
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiPanel,
  EuiText,
} from "@elastic/eui";
import MMTitle from "components/layouts/title/title";
import txt from "helpers/text-helper";
import { onStringValue, updateField } from "hoc/helper-hooks";
import { useEffect, useState } from "react";
import { Client } from "store/data/client/client";

export interface MMIdInfoProps {
  client: Client | null;
  isEditable?: boolean;
  isHeaderShown?: boolean;
}

function MMIdInfo(props: MMIdInfoProps) {
  const [client, setClient] = useState<Client>();
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [isHeaderShown] = useState<boolean>(false);

  useEffect(() => {
    setIsEditable(!!props.isEditable);
    if (props.client) {
      setClient(props.client);
    } else {
      setClient(undefined);
    }
  }, [props.client, props.isEditable, props.isHeaderShown]);

  const onClient = (client: Client) => {
    setClient(client);
  };

  return client ? (
    <EuiFlexGrid gutterSize="s" className="subtle-editing">
      <EuiFlexItem>
        <MMTitle text={txt.get("clients.id_info.name")} />
      </EuiFlexItem>
      <EuiPanel className="subtle-panel">
        <EuiFlexItem>
          <EuiFlexGroup gutterSize="xs" style={{ maxWidth: "400px" }}>
            <EuiFlexItem grow={6}>
              <EuiFormRow
                display="rowCompressed"
                label={isHeaderShown ? txt.get("clients.client_code") : undefined}
                title={txt.get("clients.client_code")}
              >
                <EuiFieldText
                  append={<EuiButtonEmpty disabled={!isEditable} iconType="check" size="xs" color="success" />}
                  placeholder={txt.get("clients.client_code")}
                  compressed={true}
                  disabled={true || !isEditable}
                  name="client_code"
                  value={client.client_code || ""}
                  onChange={(e) => onClient(updateField(client, "street_number", onStringValue(e)))}
                />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem grow={2} style={{ padding: "6px 7.5px" }}>
              <EuiText size="xs" textAlign="right" color="subdued">
                {txt.get("clients.client_code")}
              </EuiText>
            </EuiFlexItem>
          </EuiFlexGroup>

          <EuiFlexGroup gutterSize="xs" style={{ maxWidth: "400px" }}>
            <EuiFlexItem grow={6}>
              <EuiFormRow
                display="rowCompressed"
                label={isHeaderShown ? txt.get("clients.vlot_client_code") : undefined}
                title={txt.get("clients.vlot_client_code")}
              >
                <EuiFieldText
                  append={<EuiButtonEmpty disabled={!isEditable} iconType="check" size="xs" color="success" />}
                  placeholder={txt.get("clients.vlot_client_code")}
                  compressed={true}
                  disabled={true || !isEditable}
                  name="client_code"
                  value={client.vlot_client_code || ""}
                  onChange={(e) => onClient(updateField(client, "street_number", onStringValue(e)))}
                />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem grow={2} style={{ padding: "6px 7.5px" }}>
              <EuiText size="xs" textAlign="right" color="subdued">
                {txt.get("clients.vlot_client_code")}
              </EuiText>
            </EuiFlexItem>
          </EuiFlexGroup>

          <EuiFlexGroup gutterSize="xs" style={{ maxWidth: "400px" }}>
            <EuiFlexItem grow={6}>
              <EuiFormRow
                display="rowCompressed"
                label={isHeaderShown ? txt.get("clients.vlot_id") : undefined}
                title={txt.get("clients.vlot_id")}
              >
                <EuiFieldText
                  placeholder={txt.get("clients.vlot_id")}
                  compressed={true}
                  disabled={true || !isEditable}
                  name="vlot_id"
                  value={client.vlot_id || ""}
                  onChange={(e) => onClient(updateField(client, "vlot_id", onStringValue(e)))}
                />
              </EuiFormRow>
            </EuiFlexItem>{" "}
            <EuiFlexItem grow={2} style={{ padding: "6px 7.5px" }}>
              <EuiText size="xs" textAlign="right" color="subdued">
                {txt.get("clients.vlot_id")}
              </EuiText>
            </EuiFlexItem>
          </EuiFlexGroup>

          <EuiFlexGroup gutterSize="xs" style={{ maxWidth: "400px" }}>
            <EuiFlexItem grow={6}>
              <EuiFormRow
                display="rowCompressed"
                label={isHeaderShown ? txt.get("clients.app_id", txt.get("brand.app_short")) : undefined}
                title={txt.get("clients.app_id", txt.get("brand.app_short"))}
              >
                <EuiFieldText
                  placeholder={txt.get("clients.app_id", txt.get("brand.app_short"))}
                  compressed={true}
                  disabled={true || !isEditable}
                  name="id"
                  value={client.id || ""}
                  onChange={(e) => onClient(updateField(client, "id", onStringValue(e)))}
                />
              </EuiFormRow>
            </EuiFlexItem>{" "}
            <EuiFlexItem grow={2} style={{ padding: "6px 7.5px" }}>
              <EuiText size="xs" textAlign="right" color="subdued">
                {txt.get("clients.app_id", txt.get("brand.app_short"))}
              </EuiText>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiPanel>
    </EuiFlexGrid>
  ) : (
    <></>
  );
}

export default MMIdInfo;
