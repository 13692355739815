import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiProgress, EuiSpacer } from "@elastic/eui";
import { ApiResponse, ApiResponseStatus } from "api/api-helper";
import ConnectAPIHelper from "api/connect-api-helper";
import ManoXAPIHelper from "api/manox-api-helper";
import MMPage, { PageLink } from "components/layouts/page/page";
import AuthenticationHelper from "helpers/authentication-helper";
import txt from "helpers/text-helper";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AlertConfirmActionState, confirmAsk, confirmGet } from "store/components/alert/confirm";
import { toastAdd } from "store/components/toast/toast";
import { AssignedDevice, Scanner, SCANNER_EMPTY } from "store/data/scan/scanner";
import { v4 as uuid } from "uuid";
import { adminNav } from "../admin";
import MMScanner from "./scanner";
function MMAdminScanner() {
  const api = new ManoXAPIHelper();
  const connectApi = new ConnectAPIHelper();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [deleteRef] = useState("delete_scanner_" + uuid());
  const { scannerId } = useParams();

  const [buttonsToShow, setButtonsToShow] = useState<any[]>([]);
  const [scanner, setScanner] = useState<Scanner | null>(null);
  const [subPages, setSubPages] = useState<PageLink[]>([]);
  useEffect(() => {
    const getSubPages = async () => {
      setSubPages(await adminNav("/admin/scanners"));
    };

    getSubPages();
  }, []);

  const isNew: boolean = scannerId === "new";

  useEffect(() => {
    const loadScanner = async () => {
      if (isNew) {
        setScanner(SCANNER_EMPTY);
      } else {
        const scannerIdParam: number = Number(scannerId);
        if (isNaN(scannerIdParam)) {
          navigate("/404");
        } else {
          const result = await api.getScanner(scannerIdParam);
          if (result.status === "OK" && result.result && result.result.id) {
            //scanner endpoint search?
            console.log("setting scanner", result.result);

            setScanner(result.result);
          } else {
            navigate("/404");
          }
        }
      }
    };
    loadScanner();
  }, [scannerId]);

  const saveScanner = async (scanner: Scanner, assignedDevices: AssignedDevice[]) => {
    let result;
    if (scanner.id) {
      // Call manox-api.
      result = await api.updateScanner(scanner);
    } else {
      //not implemented create scanner yet
      return false;
      // result = await api.createScanner(scanner);
    }

    // Call connect-api.
    if (result.status === "OK") {
      if (assignedDevices && assignedDevices.length > 0) {
        console.log("going to save assigned devices", assignedDevices);
        const assignedDevicesResult = await connectApi.updateAssignedDevices(assignedDevices);
        console.group("save device asssignments", assignedDevicesResult);
      } else {
        console.log("going to clear assigned devices", assignedDevices, scanner.id);
        const deleteAssignedDevicesResult: ApiResponse = await connectApi.deleteAssignedDevices(scanner.id);
        if (deleteAssignedDevicesResult && deleteAssignedDevicesResult.status === ApiResponseStatus.OK) {
          console.group("save device asssignments", deleteAssignedDevicesResult);
        } else if (deleteAssignedDevicesResult?.status === ApiResponseStatus.Error) {
          dispatch(
            toastAdd(
              deleteAssignedDevicesResult.message
                ? deleteAssignedDevicesResult.message
                : txt.get("admin.scanners.error_saving"),
              null,
              "danger"
            )
          );
        }
      }

      if (!scanner.id) {
        navigate(`/admin/scanners/${result.result.scanner_id}`);
      }

      dispatch(toastAdd(txt.get("generic.is_saved", txt.get("admin.scanners.name")), null, "success"));
    } else {
      dispatch(toastAdd(result.message ? result.message : txt.get("admin.scanners.error_saving"), null, "danger"));
    }
  };

  const handleDelete = async () => {
    dispatch(
      confirmAsk(
        `${txt.uf("generic.delete_x", txt.get("admin.scanners.name"))}.`,
        txt.get("admin.scanners.delete_confirm"),
        deleteRef,
        null,
        null,
        `${txt.get("generic.yes")}, ${txt.lo("generic.delete_x", txt.get("admin.scanners.name"))}`
      )
    );
  };

  const alertConfirm = useSelector(confirmGet);
  useEffect(() => {
    if (alertConfirm.actionState === AlertConfirmActionState.Perform && alertConfirm.actionKey === deleteRef) {
      deleteScanner();
    }
  }, [alertConfirm]);

  const deleteScanner = async () => {
    if (scannerId && !isNaN(Number(scannerId))) {
      await api.deleteScanner(Number(scannerId));
      dispatch(toastAdd(txt.get("generic.is_deleted", txt.get("admin.scanner.name")), null, "success"));
    }
    navigate("/admin/scanners");
  };

  useEffect(() => {
    const setInterfaceForPermissions = async () => {
      let buttons: any[] = [];
      if (!isNew && (await AuthenticationHelper.hasPermission("scanners#delete_all"))) {
        buttons.push(
          <EuiButton key="delete" size="s" onClick={handleDelete} color="danger" iconType={"cross"}>
            {txt.uf("generic.delete_x", txt.get("admin.scanners.name"))}
          </EuiButton>
        );
      }

      // buttons.push(
      //   <EuiButton key="scanner-status" color="success">
      //     {txt.get("admin.scanners.status.online")}
      //   </EuiButton>
      // );
      setButtonsToShow(buttons);
    };
    setInterfaceForPermissions();
  }, []);

  const buttons = () => {
    return buttonsToShow;
  };

  return (
    <MMPage
      title={`${scanner ? scanner.serial : "..."}`}
      subPages={subPages}
      backTo="/admin/scanners"
      backToText={txt.uf("generic.back_to", txt.get("admin.scanners.page_title"))}
      sideActions={buttons()}
    >
      <EuiFlexItem>
        <EuiSpacer />
        {scanner ? (
          <EuiFlexGroup>
            <EuiFlexItem>
              <MMScanner
                handleSubmit={(scanner: Scanner, assignedDevices: AssignedDevice[]) =>
                  saveScanner(scanner, assignedDevices)
                }
                fields={{ scanner }}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        ) : (
          <EuiProgress size="s" color="accent" />
        )}
      </EuiFlexItem>
    </MMPage>
  );
}
export default MMAdminScanner;
