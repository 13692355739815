import { EuiBasicTableColumn, EuiFlexGrid, EuiFlexItem, EuiPanel } from "@elastic/eui";
import MMCell from "components/layouts/table/cell";
import MMMemoryTable from "components/layouts/table/memory-table";
import MMTitle from "components/layouts/title/title";
import StringHelper from "helpers/string-helper";
import txt from "helpers/text-helper";
import { useEffect, useState } from "react";
import { Client } from "store/data/client/client";
import {
  Costing,
  CostingGroup,
  costingWaitingForDescription,
  OrderCostingType,
  orderLineCostingTotal,
  toGroupedCostings,
} from "store/data/costing/costing";
import { isOrderLineEndStatus, OrderLineGroup } from "store/data/order/order";
import { handDescription } from "store/data/scan/scan";

export interface MMCostingInfoProps {
  client: Client | null;
  costings: Costing[];
  isEditable?: boolean;
  isHeaderShown?: boolean;
}

function MMCostingInfo(props: MMCostingInfoProps) {
  const [client, setClient] = useState<Client>();
  const [groupedCostings, setGroupedCostings] = useState<CostingGroup[]>([]);

  useEffect(() => {
    if (props.client) {
      setClient(props.client);
    } else {
      setClient(undefined);
    }
    if (props.costings) {
      const grouped: CostingGroup[] = toGroupedCostings(props.costings);
      setGroupedCostings(grouped);
    } else {
      setGroupedCostings([]);
    }
  }, [props.costings, props.client]);

  const getColumns = (): Array<EuiBasicTableColumn<any>> => [
    {
      name: "",
      field: "lines",
      sortable: false,
      render: (lines: Costing[], group: CostingGroup) => (
        <MMCell
          size="xs"
          text={`${
            lines.length > 0 ? lines[0].order_line?.product_selection.type : "-"
          } ${lines.length > 1 ? `(${lines.length}x)` : ""}`}
          wrap={true}
        />
      ),
    },
    {
      name: "",
      field: "lines",
      // sortable: true,
      render: (lines: Costing[], group: OrderLineGroup) => (
        <MMCell
          align="center"
          size="xs"
          text={
            lines.length > 0 && lines[0].order_line?.hand
              ? handDescription(lines[0].order_line?.hand as string, "scanning.scans.scan.hand", true)
              : "-"
          }
          wrap={false}
        />
      ),
    },
    {
      name: "",
      field: "lines",
      sortable: false,
      render: (lines: Costing[]) => (
        <MMCell
          size="xs"
          align="center"
          text={
            <MMCell
              size="xs"
              text={
                lines && lines.length > 0
                  ? lines[0].order_line && isOrderLineEndStatus(lines[0].order_line.status)
                    ? costingWaitingForDescription(lines[0])
                    : txt.get("clients.care_info.cash_collection.waiting_for_delivery")
                  : ""
              }
            />
          }
          wrap={false}
        />
      ),
    },
    {
      name: "",
      field: "orderCostingId",
      sortable: false,
      render: (orderCostingId: number, group: CostingGroup) => (
        <MMCell
          size="xs"
          align="center"
          text={
            group.orderCosting?.type === OrderCostingType.InvoicePrivate
              ? txt.get("clients.care_info.cash_collection.private")
              : group.insurer
                ? group.insurer?.acronym
                : txt.get("generic.unknown")
          }
          subText={StringHelper.currency(orderLineCostingTotal(group.lines))}
          wrap={true}
        />
      ),
    },
    // {
    //   name: "",
    //   field: "updated_at",
    //   sortable: false,
    //   render: (updatedAt: Date, costing: Costing) => (
    //     <MMCell
    //       align="center"
    //       text={<MMCell text={DateHelper.toDate(updatedAt)} />}
    //       wrap={false}
    //     />
    //   ),
    // },
  ];

  return client ? (
    <EuiFlexGrid gutterSize="s" className="subtle-editing">
      <EuiFlexItem>
        <MMTitle text={txt.get("clients.care_info.cash_collection.name")} />
      </EuiFlexItem>
      <EuiPanel className="subtle-panel">
        <EuiFlexItem>
          <MMMemoryTable items={groupedCostings} columns={getColumns()} groupKey="orderCostingId" />
        </EuiFlexItem>
      </EuiPanel>
    </EuiFlexGrid>
  ) : (
    <></>
  );
}

export default MMCostingInfo;
