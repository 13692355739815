import { EuiGlobalToastList, EuiText } from "@elastic/eui";
import { Toast } from "@elastic/eui/src/components/toast/global_toast_list";
import txt from "helpers/text-helper";
import { useDispatch, useSelector } from "react-redux";
import { ToastInfo, toastRemove, toastsGet } from "store/components/toast/toast";

const DEFAULT_TOAST_LIFE_TIME = 6000;

function MMToasts() {
  const dispatch = useDispatch();
  const toastList: ToastInfo[] = useSelector(toastsGet);

  // console.log('toasts',toastList);
  const dismissToast = (toast: Toast) => {
    dispatch(toastRemove(toast.id));
  };

  const toastListToEuiToasts = (): Toast[] => {
    const toasts: Toast[] = toastList.map((toast: ToastInfo) => {
      return {
        id: toast.id,
        text: (
          <EuiText>
            <strong>{toast.title}</strong>
            <br />
            {toast.text ? <EuiText>{toast.text}</EuiText> : <></>}
            {toast.code ? <EuiText>{txt.get("generic.error_code", toast.code)}</EuiText> : <></>}
          </EuiText>
        ),
        color: toast.importance || "",
      } as Toast;
    });

    return toasts;
  };

  return (
    <EuiGlobalToastList
      data-testid="toasts-list"
      side="left"
      toasts={toastListToEuiToasts()}
      dismissToast={dismissToast}
      toastLifeTimeMs={DEFAULT_TOAST_LIFE_TIME}
    />
  );
}

export default MMToasts;
