import txt from "./text-helper";

export interface ValidationResult {
  result: boolean;
  message: string;
  messages?: string[];
}

export interface GenericInputValidator {
  (input: any): ValidationResult;
}
export interface InputValidator extends GenericInputValidator {
  (input: string): ValidationResult;
}
export interface InputMultiValidator extends GenericInputValidator {
  (input: string | string[]): ValidationResult;
}

export interface Input {
  visible: boolean;
  mandatory: boolean;
  // validation?: ValidationResult;
  validator?: GenericInputValidator | InputValidator | InputMultiValidator;
}

export const validateMinimalThreeStrings: InputValidator = (input: string): ValidationResult => {
  const nonEmptyString = (i: any) => i && i !== "";
  const count = input?.split(",")?.filter(nonEmptyString)?.length || 0;
  const valid = 3 <= count;

  return toValidationResult(valid, txt.get("validations.at_least_three"));
};

export const validateNotEmptyNorFalse: InputMultiValidator = (inputs: string | string[]): ValidationResult => {
  if (typeof inputs === "string") {
    inputs = [inputs];
  }
  const valid = inputs.findIndex((input: string) => input !== "" && input !== "false") >= 0;

  return toValidationResult(valid, txt.get("validations.not_empty"));
};

export const validateNotEmpty: InputValidator = (input: string): ValidationResult => {
  return toValidationResult(input !== "" && input !== undefined && input !== null, txt.get("validations.not_empty"));
};

export const validateAnyNotEmpty: InputMultiValidator = (inputs: string | string[]) => {
  if (typeof inputs === "string") {
    inputs = [inputs];
  }
  return toValidationResult(
    inputs.findIndex((input: string) => input === "") >= 0,
    txt.get("validations.any_not_empty")
  );
};

export const validateNotEmptyNumber: InputValidator = (input: string): ValidationResult => {
  const result = mergeValidationResults([
    validateNotEmpty(input),
    toValidationResult(!!input && !isNaN(Number(input)), txt.get("validations.number")),
  ]);

  return result;
};

export const validateNotEmptyNumberZeroAllowed: InputValidator = (input: string | number): ValidationResult => {
  const result = toValidationResult(
    input !== null && input !== undefined && (input === "0" || input === 0 || (!!input && !isNaN(Number(input)))),
    txt.get("validations.number")
  );

  return result;
};

const toValidationResult = (result: boolean, message: string): ValidationResult => {
  return { result, message, messages: [message] } as ValidationResult;
};

const mergeValidationResults = (results: ValidationResult[]) => {
  let result: ValidationResult | null = null;

  for (let i = 0; i < results.length; i++) {
    const currentResult: ValidationResult = results[i];
    if (!result) {
      result = { ...currentResult };
    }
    result.result = result.result && currentResult.result;
    if (!currentResult.result) {
      result.message = currentResult.message;
      result.messages = result.messages?.concat(currentResult.messages || []);
    }
  }
  return result || { result: true, message: "", messages: [] };
};
