import { pdfnltobr } from "components/pdf/pdf-quotation";
import StringHelper from "helpers/string-helper";
import txt from "helpers/text-helper";
import { Client } from "store/data/client/client";
import { indicationDescription } from "store/data/indication/indication";
import { handleLegacyNeeds, handleLegacyOrderLines } from "store/data/need/legacy-fallback";
import { getNeed, getNeeds, Need } from "store/data/need/need";
import {
  Order,
  OrderLine,
  orderLinesGroupedByHandFinger,
  orderLinesGroupedByType,
  orderLinesGroupedByUse,
} from "store/data/order/order";
import { genderedLastName, genderedName, genderedPronoun } from "store/data/personal-details/personal-details";
import { ProductUse, productUseDescription, toFunctionDescriptionList } from "store/data/product/product";
import { handDescription } from "store/data/scan/scan";

const toTitle = (text: string) => `**${text.toUpperCase()}**`;

const toDescription = (text: string) => StringHelper.upfirst(text).replace("<em>", "").replace("</em>", "");

const toRationaleSection = (title: string, description: string) =>
  `${toTitle(title)}
${toDescription(description)}`;

const toNeedsList = (needs: Need[], separator?: string) => {
  if (!separator)
    separator = `
`;
  let list: string[] = [];
  for (let i = 0; i < needs.length; i++) {
    const need: Need = needs[i];
    if (need.answer === "true") {
      list.push(txt.get(`needs.questions.${need.question}`));
    } else if (need.answer && need.answer !== "false") {
      let result: string = need.answer.replaceAll("\n", " ");
      //handle tags as commas
      result = result.replaceAll(", ", ",").replaceAll(",", ", ");
      list.push(result);
    }
  }

  return list.join(separator);
};
const toNeedsSummary = (needs: Need[]) => {
  let summary: string = "";
  if (needs.length > 0) {
    summary += needs
      .map((need: Need, i: number) =>
        i === 0
          ? need.answer === "true"
            ? txt.lf(`needs.questions.${need.question}`)
            : StringHelper.lofirst(need.answer)
          : i < needs.length - 1
            ? `, ${
                need.answer === "true" ? txt.lf(`needs.questions.${need.question}`) : StringHelper.lofirst(need.answer)
              }`
            : ` ${txt.lo("orders.order.costing.rationale.and")} ${
                need.answer === "true" ? txt.lf(`needs.questions.${need.question}`) : StringHelper.lofirst(need.answer)
              }`
      )
      .join("");
    summary = summary.trim();
    summary += summary.endsWith(".") ? "" : ".";
  }
  return summary;
};

export const productDescription = (type: string, short: boolean = false) => {
  let productName: string = short && type.indexOf(",") > 0 ? type.substring(0, type.indexOf(",")) : type;

  productName = txt.get(
    "orders.order.costing.rationale.custom_solution",
    productName.replace("Special", txt.get("orders.order.costing.rationale.special_solution"))
  );

  return productName;
};

const toProductListDescriptions = (orderLines: OrderLine[], short: boolean = false) => {
  let descriptions: string[] = [];
  let groupedByType: any = orderLinesGroupedByType(orderLines);
  let types: any[] = Object.keys(groupedByType);
  for (let i = 0; i < types.length; i++) {
    const type = types[i];
    let description: string =
      groupedByType[type].length > 1
        ? `${groupedByType[type].length}x ${txt.lo(
            "orders.order.costing.rationale.an"
          )} ${productDescription(type, short)}`
        : `${txt.lo("orders.order.costing.rationale.an")} ${productDescription(type, short)} (${groupedByType[type]
            .map((line: OrderLine) =>
              handDescription(line.hand as string, "orders.order.costing.rationale").toLowerCase()
            )
            .filter((value: string, index: number, array: string[]) => array.indexOf(value) === index)
            .join(" & ")})`;
    if (types.length > 1 && i < types.length - 2) {
      description += `, `;
    } else if (types.length > 1 && i < types.length - 1) {
      description += ` ${txt.lo("orders.order.costing.rationale.and")}`;
    }
    descriptions.push(description);
  }
  return descriptions.join(" ");
};

const lang: string = "nl"; //txt.lang();

export const toProductFunctionMotivation = (
  orderLine: OrderLine,
  product?: any,
  delimiter: string = ", ",
  end: string = ". "
) => {
  let result: string = "";

  if (orderLine.function_description || product?.editable_properties) {
    result += `${(orderLine.function_description || "").split(",").join(delimiter)}${end}`;
  } else if (product && product[`function_description_${lang}`]) {
    result += `${toFunctionDescriptionList(product[`function_description_${lang}`]).join(delimiter)}${end}`;
  }

  return result;
};

export const toProductDescription = (
  orderLine: OrderLine,
  product?: any,
  delimiter: string = ", ",
  end: string = ". "
) => {
  let result: string = "";

  if (product?.editable_properties && orderLine.product_description) {
    result += `${orderLine.product_description}${end}`;
  } else if (!product?.editable_properties) {
    let size: string = orderLine.product_selection?.size || "";
    let color: string = orderLine.product_selection?.color || "";
    if (size.toLowerCase() === "n.v.t" || size.toLowerCase() === "n/a") {
      size = "";
    }
    if (color.toLowerCase() === "n.v.t" || color.toLowerCase() === "n/a") {
      color = "";
    }

    if (size && color) {
      result += `${size}${delimiter}${color}`;
    } else if (size) {
      result += `${size}`;
    } else if (orderLine.product_selection?.color) {
      result += `${color}`;
    }
    if (orderLine.product_selection?.size || orderLine.product_selection?.color) {
      result += `${end}`;
    }
  }

  return result;
};

const toProductAidMotivation = (orderLine: OrderLine, delimiter: string = ", ", end: string = ". ") => {
  let result: string = "";
  let previous_aid = orderLine.previous_aid ? orderLine.previous_aid.trim() : "";
  let argumentation = orderLine.argumentation ? orderLine.argumentation.trim() : "";

  result += `${
    orderLine.has_previous_aid
      ? txt.get("orders.order.costing.rationale.product_previous_aid")
      : txt.get("orders.order.costing.rationale.product_no_previous_aid")
  } `;

  result += previous_aid ? `${previous_aid.trim()}${argumentation ? delimiter : end}` : "";

  result += argumentation ? `${argumentation.trim()}${end}` : "";

  return result;
};

export const toProductMotivations = (
  orderLines: OrderLine[],
  short: boolean = false,
  products?: any[],
  delimiter: string = ", ",
  end: string = ". ",
  append: string = "*"
): string[] => {
  let productMovitations: string[] = orderLines
    .map((orderLine: OrderLine) => {
      let result: string = "";
      let product = products?.find((product: any) => product.code === orderLine.code);

      if (!short) {
        result += "**";
        let productDescriptionString: string = productDescription(orderLine.product_selection.type, true);
        if (orderLine.hand) {
          productDescriptionString += " " + handDescription(orderLine.hand).toLowerCase();
        }
        result += txt.get("orders.order.costing.rationale.criteria_motivation_for_product", productDescriptionString);
        result += `${append} **`;
        result += end;
      } else {
        result += "**";
        result += productDescription(orderLine.product_selection.type, true);
        if (orderLine.hand) {
          result += " " + handDescription(orderLine.hand);
        }
        result += `${append} **`;
        result += end;
      }

      result += `_${toProductDescription(orderLine, product, ", ", `${"_"}${end}`)}`;

      result += `
_${txt.get("orders.order.costing.rationale.goal")}_
${toProductFunctionMotivation(orderLine, product, delimiter, end)}`;

      result += `
_${txt.get("orders.order.costing.rationale.selection_details")}_
${toProductAidMotivation(orderLine, delimiter, end)}`;

      return result;
    })
    .filter((motivation: string) => motivation && motivation.trim() !== "");
  productMovitations = StringHelper.arrayUnique(productMovitations);
  return productMovitations;
};
const toProductListDescriptionsByUse = (orderLines: OrderLine[], short: boolean = false): string => {
  let result: string = "";
  let groupedByUse: any = orderLinesGroupedByUse(orderLines);
  let uses: any[] = Object.keys(groupedByUse);

  if (uses.length === 0) {
    result = "-";
  } else if (uses.length > 0) {
    result = txt.get(
      "orders.order.costing.rationale.product",
      toProductListDescriptions(groupedByUse[uses[0]], short),
      productUseDescription(
        uses[0] === "NONE" ? ProductUse.Day : uses[0],
        "orders.order.costing.rationale"
      ).toLowerCase()
    );
  }
  if (uses.length > 1) {
    result +=
      "; " +
      txt.get(
        "orders.order.costing.rationale.product",
        toProductListDescriptions(groupedByUse[uses[1]], short),
        productUseDescription(
          uses[0] === "NONE" ? ProductUse.Day : uses[1],
          "orders.order.costing.rationale"
        ).toLowerCase()
      );
  }
  return (result += ".");
};

const toRationaleProductDescription = (order: Order, client: string, pronoun: string) => {
  let title: string = "";
  let productDescription: string = "";

  title += txt.get("orders.order.costing.rationale.product_description_title");

  let groupedByUse: any = orderLinesGroupedByUse(order.order_lines);
  let uses: any[] = Object.keys(groupedByUse);

  if (uses.length === 0) {
    productDescription = txt.get("orders.order.product");
  } else if (uses.length > 0) {
    let descriptions: string = toProductListDescriptions(groupedByUse[uses[0]], true);

    productDescription += `${txt.get(
      "orders.order.costing.rationale.product_description",
      client,
      txt.get(
        "orders.order.costing.rationale.product",
        descriptions,
        productUseDescription(
          uses[0] === "NONE" ? ProductUse.Day : uses[0],
          "orders.order.costing.rationale"
        ).toLowerCase()
      )
    )}.`;
  }
  if (uses.length > 1) {
    let descriptions: string = toProductListDescriptions(groupedByUse[uses[1]], true);

    productDescription += ` ${txt.get(
      "orders.order.costing.rationale.product_description_also",
      txt.get(
        "orders.order.costing.rationale.product",
        descriptions,
        productUseDescription(
          uses[1] === "NONE" ? ProductUse.Day : uses[1],
          "orders.order.costing.rationale"
        ).toLowerCase()
      )
    )}.`;
  }
  return toRationaleSection(title, productDescription);
};

const toRationaleReason = (order: Order, client: string, pronoun: string) => {
  let title: string = "";
  let reasonDescription: string = "";
  title = txt.get("orders.order.costing.rationale.reason_title");

  let needs: Need[] = handleLegacyNeeds(order.needs);

  reasonDescription += `${txt.get("orders.order.costing.rationale.reason_default_intro", client)}.`;

  let indications: string = order?.referral?.indications ? indicationDescription(order.referral.indications) : "";
  let needsDescription: string = toNeedsList(getNeeds(needs, "care_needs"), ", ").toLowerCase();
  let groupedByUse: any = orderLinesGroupedByUse(order.order_lines);
  let uses: any = Object.keys(groupedByUse);

  if (indications && needsDescription) {
    reasonDescription += ` ${txt.get(
      "orders.order.costing.rationale.reason_indications_needs",
      pronoun,
      indications,
      needsDescription
    )}`;
  } else if (indications) {
    reasonDescription += ` ${txt.get("orders.order.costing.rationale.reason_indications", pronoun, indications)}`;
  } else if (needsDescription) {
    reasonDescription += ` ${txt.get("orders.order.costing.rationale.reason_needs", pronoun, needsDescription)}`;
  }

  let anatomicalDescription: string = toNeedsSummary(getNeeds(needs, "anatomy."));

  if (anatomicalDescription) {
    reasonDescription += `${txt.ufo("orders.order.costing.rationale.reason_anatomy", client, anatomicalDescription)}`;
  }

  if (uses.length === 0) {
    reasonDescription += `
${txt.get(
  "orders.order.costing.rationale.reason_default_intro_use",
  productUseDescription(ProductUse.Day, "orders.order.costing.rationale").toLowerCase()
)}.`;
    reasonDescription += ` ${txt.get("orders.order.costing.rationale.reason_default_details_day", pronoun)}.`;
  }
  if (uses.length > 0) {
    reasonDescription += `
${txt.get(
  "orders.order.costing.rationale.reason_default_intro_use",
  productUseDescription(uses[0] === "NONE" ? ProductUse.Day : uses[0], "orders.order.costing.rationale").toLowerCase()
)}.`;
    reasonDescription += ` ${txt.get(
      `orders.order.costing.rationale.reason_default_details_${(uses[0] === "NONE" ? ProductUse.Day : uses[0])
        .toString()
        .toLowerCase()}`,
      pronoun
    )}.`;
  }
  if (uses.length > 1) {
    reasonDescription += ` ${txt.get(
      "orders.order.costing.rationale.reason_default_intro_use_also",
      productUseDescription(
        uses[1] === "NONE" ? ProductUse.Day : uses[1],
        "orders.order.costing.rationale"
      ).toLowerCase()
    )}.`;
    reasonDescription += ` ${txt.get(
      `orders.order.costing.rationale.reason_default_details_${(uses[1] === "NONE" ? ProductUse.Day : uses[1])
        .toString()
        .toLowerCase()}`,
      client
    )}.`;
  }

  return toRationaleSection(title, reasonDescription);
};

const toRationaleGoal = (order: Order, client: string, pronoun: string) => {
  let title: string = "";
  let goalDescription: string = "";
  title = txt.get("orders.order.costing.rationale.goal_title");
  let needs: Need[] = handleLegacyNeeds(order.needs);
  let activities: string = toNeedsList(getNeeds(needs, "activities"), ", ");
  let usage: string = toNeedsList(
    getNeeds(needs, "usage").filter((need: Need) => need.question !== "usage.explanation"),
    ", "
  );
  let usageExplanation: string = getNeed(needs, "usage.explanation");
  // console.log("toRationaleGoal", activities, usage, usageExplanation);
  let groupedByUse: any = orderLinesGroupedByUse(order.order_lines);
  let uses: any = Object.keys(groupedByUse);
  let activitiesAndUsage = activities && usage ? `${activities}, ${usage}` : activities ? activities : usage;
  activitiesAndUsage = activitiesAndUsage.toLowerCase();

  if (usageExplanation) {
    if (activitiesAndUsage) {
      activitiesAndUsage += `. ${usageExplanation.replaceAll("\n", " ").replaceAll("  ", " ")}`;
    } else {
      activitiesAndUsage += `${usageExplanation.replaceAll("\n", " ").replaceAll("  ", " ")}`;
    }
  }
  if (activitiesAndUsage) {
    goalDescription += `${txt.get("orders.order.costing.rationale.goal_details", client, activitiesAndUsage)}.`
      .replaceAll(". .", ".")
      .replaceAll("..", ".");
    goalDescription += `
`;
  }

  goalDescription += `${txt.get("orders.order.costing.rationale.goal_intro")}.
`;

  if (uses.length === 0) {
    goalDescription += `${txt.get(
      "orders.order.costing.rationale.goal_result",
      pronoun,
      txt.get("orders.order.costing.rationale.goal_result_day")
    )}.`;
  }
  if (uses.length > 0) {
    goalDescription += `${txt.get(
      "orders.order.costing.rationale.goal_result",
      pronoun,
      txt.lf(
        `orders.order.costing.rationale.goal_result_${(uses[0] === "NONE" ? ProductUse.Day : uses[0])
          .toString()
          .toLowerCase()}`
      )
    )}.`;
  }
  if (uses.length > 1) {
    goalDescription += ` ${txt.get(
      "orders.order.costing.rationale.goal_result_also",
      pronoun,
      txt.lf(
        `orders.order.costing.rationale.goal_result_${(uses[1] === "NONE" ? ProductUse.Day : uses[1])
          .toString()
          .toLowerCase()}`
      )
    )}.`;
  }

  return toRationaleSection(title, goalDescription);
};

const toRationaleRequirements = (order: Order, client: string, pronoun: string) => {
  let title: string = "";
  let requirementsDescription: string = "";
  title = txt.get("orders.order.costing.rationale.requirements_title");
  let needs: Need[] = handleLegacyNeeds(order.needs);
  let groupedByHandFinger: any = orderLinesGroupedByHandFinger(order.order_lines);
  let groups: any[] = Object.keys(groupedByHandFinger);
  let productsName: string =
    order.order_lines.length <= 1
      ? txt.lf("orders.order.costing.rationale.product_generic")
      : txt.lf("orders.order.costing.rationale.products_generic");

  if (groups.length === 0) {
    requirementsDescription += `${txt.get(
      "orders.order.costing.rationale.requirements_intro",
      productsName,
      txt.lf("orders.order.costing.rationale.requirements_default")
    )}.`;
  }
  if (groups.includes("SPECIAL")) {
    requirementsDescription += `${txt.get(
      "orders.order.costing.rationale.requirements_intro",
      productsName,
      txt.lf("orders.order.costing.rationale.requirements_default")
    )}`;
  } else if (groups.length === 1) {
    requirementsDescription += `${txt.get(
      "orders.order.costing.rationale.requirements_intro",
      productsName,
      txt.lf(
        `orders.order.costing.rationale.requirements_${(groups[0] === "NONE" ? "FINGER" : groups[0]).toLowerCase()}`
      )
    )}`;
  } else if (groups.length > 1) {
    requirementsDescription += `${txt.get(
      "orders.order.costing.rationale.requirements_intro",
      productsName,
      `${txt.lf("orders.order.costing.rationale.requirements_hand")} ${txt.lf(
        "orders.order.costing.rationale.also"
      )} ${txt.lf("orders.order.costing.rationale.requirements_finger")}`
    )}`;
  }
  requirementsDescription += ".";

  requirementsDescription += `
${txt.get("orders.order.costing.rationale.requirements_overall", productsName)}.`;

  let charNeeds: Need[] = getNeeds(needs, "characteristics");
  console.log("charNeeds", charNeeds);
  if (charNeeds.length === 1 && charNeeds[0].question === "characteristics.none") {
    //no bijzonderheden
  } else {
    if (charNeeds.filter((need: Need) => need.question.startsWith("characteristics.allergies")).length > 1) {
      charNeeds = charNeeds.filter((need: Need) => need.question !== "characteristics.allergies");
    }
    let characteristicsDescription: string = `${toNeedsList(charNeeds, ", ").toLowerCase()}`;

    if (characteristicsDescription) {
      requirementsDescription += `
${txt.get(
  "orders.order.costing.rationale.requirements_characteristics",
  client,

  characteristicsDescription + "."
)}`;
    }
  }

  //   let usageDescription: string = toNeedsSummary(
  //     getNeeds(order.needs, "usage.")
  //   );

  //   if (usageDescription) {
  //     requirementsDescription += `
  // ${txt.get(
  //   "orders.order.costing.rationale.requirements_context",
  //   client,
  //   productsName,
  //   usageDescription
  // )}`;
  // }

  return toRationaleSection(title, requirementsDescription);
};

const toRationaleCriteria = (order: Order, client: string, pronoun: string, products?: any[]) => {
  let title: string = "";
  let criteriaDescription: string = "";
  title = txt.get("orders.order.costing.rationale.criteria_title");
  let orderLines: OrderLine[] = handleLegacyOrderLines(order.order_lines, order.needs);

  // let oldProductUsed: string = getNeed(order.needs, "old_product.used");
  // let oldProductDescription: string =
  //   oldProductUsed === "true"
  //     ? getNeed(order.needs, "old_product.description")
  //     : getNeed(order.needs, "old_product.not_used_description");
  // oldProductDescription = oldProductDescription.trim();
  // if (oldProductDescription.endsWith("."))
  //   oldProductDescription = oldProductDescription.substring(
  //     0,
  //     oldProductDescription.length - 1
  //   );
  // if (oldProductUsed === "true" || oldProductUsed === "false") {
  //   criteriaDescription += `${txt.get(
  //     "orders.order.costing.rationale.criteria_before",
  //     client,
  //     oldProductUsed === "true"
  //       ? txt.lo("orders.order.costing.rationale.did")
  //       : txt.lo("orders.order.costing.rationale.did_not")
  //   )}`;
  //   criteriaDescription = criteriaDescription.trim();
  //   if (!criteriaDescription.endsWith(".")) criteriaDescription += ". ";
  // }
  // if (oldProductDescription) {
  //   criteriaDescription += ` ${txt.lf(
  //     "orders.order.costing.rationale.criteria_before_description",
  //     oldProductDescription
  //   )} `;
  // } else if (criteriaDescription) {
  //   criteriaDescription += ` `;
  // }

  // let groupedByConfection: any = orderLinesGroupedByConfection(orderLines);
  // let groups: any[] = Object.keys(groupedByConfection);
  // if (groups.length === 0) {
  //   criteriaDescription += `${txt.get(
  //     "orders.order.costing.rationale.criteria_choice",
  //     txt.lf("orders.order.costing.rationale.criteria_custom").trim()
  //   )}`;
  //   criteriaDescription = criteriaDescription.trim();
  //   if (!criteriaDescription.endsWith(".")) criteriaDescription += ". ";
  // }
  // if (groups.length > 0) {
  //   criteriaDescription += `${txt.get(
  //     "orders.order.costing.rationale.criteria_choice",
  //     txt.lf(
  //       `orders.order.costing.rationale.criteria_${(groups[0] === "NONE"
  //         ? "CUSTOM"
  //         : groups[0]
  //       ).toLowerCase()}`
  //     )
  //   )}`;
  //   criteriaDescription = criteriaDescription.trim();
  //   if (!criteriaDescription.endsWith(".")) criteriaDescription += ". ";
  // }

  // if (groups.length > 1) {
  //   criteriaDescription += `${txt.get(
  //     "orders.order.costing.rationale.criteria_choice_also",
  //     txt.lf(
  //       `orders.order.costing.rationale.criteria_${(groups[1] === "NONE"
  //         ? "CUSTOM"
  //         : groups[1]
  //       ).toLowerCase()}`
  //     )
  //   )}`;
  //   criteriaDescription = criteriaDescription.trim();
  //   if (!criteriaDescription.endsWith(".")) criteriaDescription += ". ";
  // }

  let productMotivations: string[] = toProductMotivations(
    orderLines,
    true,
    products,
    `
 `,
    `
 `,
    ""
  );

  // ${txt.get("orders.order.costing.rationale.criteria_motivation_short")}:
  if (productMotivations && productMotivations.length >= 1) {
    criteriaDescription += `${productMotivations.join(`
`)}`;
  }

  return toRationaleSection(title, criteriaDescription);
};

export const toBlankRationale = (order?: Order | null, client?: Client | null): string => {
  let result = "";

  const clientDescription: string = client
    ? genderedLastName(client, "orders.order.costing.rationale")
    : txt.lo("orders.order.costing.rationale.client");

  const products: string = order ? toProductListDescriptions(order.order_lines) : "";

  result += toRationaleSection(
    txt.get("orders.order.costing.rationale.product_description_title"),
    `${clientDescription}
    ${products}

`
  );
  result += toRationaleSection(
    txt.get("orders.order.costing.rationale.reason_title"),
    `

`
  );
  result += toRationaleSection(
    txt.get("orders.order.costing.rationale.goal_title"),
    `

`
  );
  result += toRationaleSection(
    txt.get("orders.order.costing.rationale.requirements_title"),
    `

`
  );
  result += toRationaleSection(
    txt.get("orders.order.costing.rationale.criteria_title"),
    `

`
  );

  return result;
};

export const toCleanRationale = (order?: Order | null, client?: Client | null, products?: any[]): string => {
  let result = "";

  let needs: Need[] = handleLegacyNeeds(order?.needs || []);
  let orderLines: OrderLine[] = handleLegacyOrderLines(order?.order_lines || [], order?.needs || []);

  const clientDescription: string = client
    ? genderedName(client, "orders.order.costing.rationale")
    : txt.lo("orders.order.costing.rationale.client");

  const productsShort: string = order ? toProductListDescriptionsByUse(orderLines, true) : "";

  let productMotivations: string[] = toProductMotivations(
    orderLines,
    true,
    products,
    `
`,
    `
`
  );

  result += toRationaleSection(
    txt.get("orders.order.costing.rationale.application_title"),
    `${txt.get("orders.order.costing.rationale.application_for")}: ${clientDescription}
${txt.get("orders.order.costing.rationale.application_what")}: ${productsShort}

`
  );

  result += toRationaleSection(
    txt.get("orders.order.costing.rationale.care_needs_title"),
    `${toNeedsList(getNeeds(needs, "care_needs"))}

`
  );

  let activitiesList: Need[] = getNeeds(needs, "activities.activities");
  let hobbiesList: Need[] = getNeeds(needs, "activities.hobbies");

  let sectionContent: string = "";
  if (activitiesList.length > 0) {
    sectionContent += `${txt.get("orders.order.costing.rationale.adl")}: ${toNeedsList(activitiesList)}
`;
  }
  if (hobbiesList.length > 0) {
    sectionContent += `${txt.get("orders.order.costing.rationale.hobbies")}: ${toNeedsList(hobbiesList)}
`;
  }

  let usageList: Need[] = getNeeds(needs, "usage");

  if (
    usageList.length === 1 &&
    usageList[0].question === "usage.none" &&
    activitiesList.length === 0 &&
    hobbiesList.length === 0
  ) {
    //keep usage.none in there
  } else {
    usageList = usageList.filter((need: Need) => need.question !== "usage.none");
  }

  sectionContent += `${toNeedsList(usageList)}
`;

  result += toRationaleSection(txt.get("orders.order.costing.rationale.care_usage_title"), `${sectionContent}`);

  let needsCharacteristics: Need[] = getNeeds(needs, "characteristics");
  console.log({ needsCharacteristics });
  if (
    needsCharacteristics.findIndex(
      (need: Need) => need.question === "characteristics.allergies" && need.answer === "true"
    ) >= 0 &&
    needsCharacteristics.findIndex(
      (need: Need) => need.question.indexOf(".allergies_") >= 0 && need.answer === "true"
    ) >= 0
  ) {
    needsCharacteristics = needsCharacteristics.filter((need: Need) => need.question !== "characteristics.allergies");
  }
  result += toRationaleSection(
    txt.get("orders.order.costing.rationale.care_characteristics_title"),
    `${toNeedsList(needsCharacteristics)}

`
  );

  result += toRationaleSection(
    txt.get(`orders.order.costing.rationale.product_selection_title${productMotivations.length > 1 ? "_plural" : ""}`),
    `${productMotivations.join(`
`)}

** *${txt.get("orders.order.costing.rationale.product_requirements")}**
${pdfnltobr(txt.get("orders.order.costing.rationale.general_requirements"))}
`
  );

  return result;
};

export const toRationale = (order?: Order | null, client?: Client | null, products?: any[]): string => {
  if (!order) {
    return "";
  }
  const clientDescription: string = client
    ? genderedName(client, "orders.order.costing.rationale")
    : txt.lo("orders.order.costing.rationale.client");

  const pronoun: string = client
    ? genderedPronoun(client, "orders.order.costing.rationale")
    : txt.lo("orders.order.costing.rationale.client");
  let rationaleParts: string[] = [];
  rationaleParts.push(toRationaleProductDescription(order, clientDescription, pronoun));
  rationaleParts.push(toRationaleReason(order, clientDescription, pronoun));
  rationaleParts.push(toRationaleGoal(order, clientDescription, pronoun));
  rationaleParts.push(toRationaleRequirements(order, clientDescription, pronoun));
  rationaleParts.push(toRationaleCriteria(order, clientDescription, pronoun, products));
  return rationaleParts.join(`

`);
};
