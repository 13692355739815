import { Text, View } from "@react-pdf/renderer";
import txt from "helpers/text-helper";
import { formattedAddressParts } from "store/data/adress/address";
import { Client, clientName } from "store/data/client/client";
import { styles } from "../styles";

export interface MMPdfClientAddressProps {
  client: Client;
  salutation?: string;
}

function MMPdfClientAddress(props: MMPdfClientAddressProps) {
  return (
    <View style={styles.addressPane}>
      <Text style={styles.soft}>{props.salutation ? props.salutation : txt.get("pdfs.quotation.to")}:</Text>
      <Text>{clientName(props.client)}</Text>
      <Text>{formattedAddressParts(props.client, ["street_name", "street_number", "street_number_suffix"])}</Text>
      <Text>{formattedAddressParts(props.client, ["postal_area", "city"])}</Text>
    </View>
  );
}

export default MMPdfClientAddress;
