import { EuiFlexGroup, EuiFlexItem, EuiImage, EuiText, useIsWithinBreakpoints } from "@elastic/eui";
import ConnectAPIHelper from "api/connect-api-helper";
import MMSecureImage from "components/layouts/image/secure-image";
import txt from "helpers/text-helper";
import { useState } from "react";

export interface MMProductMeasurementInstructionsProps {
  product?: any;
  hideText?: boolean;
  hideImage?: boolean;
}
function MMProductMeasurementInstructions(props: MMProductMeasurementInstructionsProps) {
  const isSmall: boolean = useIsWithinBreakpoints(["xs", "l"]);

  const api = new ConnectAPIHelper();

  const [instructionText] = useState(
    props.product && props.product.translations[txt.lang()]
      ? props.product.translations[txt.lang()].instruction_text
      : props.product
        ? props.product.instruction_text
        : ""
  );

  return props.product ? (
    <EuiFlexGroup
      gutterSize="xl"
      style={{ maxWidth: "591px" }}
      alignItems={isSmall ? "flexStart" : "center"}
      justifyContent="spaceBetween"
      direction={isSmall ? "column" : "row"}
    >
      {!props.hideImage ? (
        <EuiFlexItem grow={2} style={{ minWidth: "120px" }}>
          <EuiFlexGroup alignItems="center" justifyContent="center">
            {props.product.instruction_image ? (
              <EuiImage
                style={
                  props.hideText
                    ? {
                        objectFit: "contain",
                        maxHeight: "284px",
                        maxWidth: "300px",
                        minWidth: "230px",
                        width: "100vw",
                      }
                    : {
                        objectFit: "contain",
                        maxHeight: "192px",
                        maxWidth: "150px",
                        minWidth: "115px",
                        width: "100vw",
                      }
                }
                src={`/instructions/${props.product.instruction_image}`}
                alt="instructions"
              />
            ) : props.product.product_photos && props.product.product_photos.length > 0 ? (
              <MMSecureImage url={props.product.product_photos[0]} showScanOverlay={false} api={api} />
            ) : (
              <></>
            )}
          </EuiFlexGroup>
        </EuiFlexItem>
      ) : (
        <></>
      )}

      {!props.hideText ? (
        <EuiFlexItem grow={4}>
          <EuiText size="xs" style={{ minWidth: "220px", maxWidth: "440px" }}>
            {instructionText &&
              instructionText.split("\n").map((line: string, index: number) => (
                <span key={`line-${index}`}>
                  {line}
                  <br />
                </span>
              ))}
            {!instructionText && props.product.is_standardized && (
              <span>{txt.get("orders.order.standard_explanation")}</span>
            )}
            {!instructionText && !props.product.is_standardized && (
              <span>{txt.get("orders.order.non_standard_explanation")}</span>
            )}
          </EuiText>
        </EuiFlexItem>
      ) : (
        <></>
      )}
    </EuiFlexGroup>
  ) : (
    <></>
  );
}

export default MMProductMeasurementInstructions;
