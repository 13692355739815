import txt from "helpers/text-helper";

export enum ProcessingRunStatus {
  Pending = "PENDING",
  Starting = "STARTING",
  Running = "RUNNING",
  Finished = "FINISHED",
  Failed = "FAILED",
  None = "NONE",
}

export enum ProcessingRunResult {
  Success = "success",
  Failure = "failure",
}

export enum ProcessingRunQuality {
  Critical = "CRITICAL",
  Low = "LOW",
  Medium = "MEDIUM",
  High = "HIGH",
}

export interface ProcessingRun {
  run_id: number;
  status: ProcessingRunStatus;
  quality?: ProcessingRunQuality;
}

export interface ProcessingRunSummary {
  run_id: number;
  status: ProcessingRunStatus;
  quality: ProcessingRunQuality;
  count: number;
}

export const processingRunQualityRank = (status: ProcessingRunQuality) => {
  switch (status) {
    case ProcessingRunQuality.Critical:
      return 1;
    case ProcessingRunQuality.Low:
      return 2;
    case ProcessingRunQuality.Medium:
      return 3;
    case ProcessingRunQuality.High:
      return 4;
    default:
      return 0;
  }
};

export const processingRunQualityCompare = (status1: ProcessingRunQuality, status2: ProcessingRunQuality) => {
  return processingRunQualityRank(status1) < processingRunQualityRank(status2)
    ? -1
    : processingRunQualityRank(status1) > processingRunQualityRank(status2)
      ? 1
      : 0;
};

export const processingRunStatusDescription = (status: ProcessingRunStatus) => {
  return status
    ? txt.get(`scanning.scans.processing_run.status.${status.toString().toLowerCase()}`)
    : txt.get("generic.unknown");
};

export const processingRunQualityDescription = (quality: ProcessingRunQuality) => {
  return quality
    ? txt.get(`scanning.scans.processing_run.quality.${quality.toString().toLowerCase()}`)
    : txt.get("generic.unknown");
};

export const processingRunStatusToHealth = (status: ProcessingRunStatus) => {
  switch (status) {
    case ProcessingRunStatus.Pending:
    case ProcessingRunStatus.Starting:
    case ProcessingRunStatus.Running:
      return "warning";
    case ProcessingRunStatus.Finished:
      return "success";
    case ProcessingRunStatus.Failed:
      return "danger";
    case ProcessingRunStatus.None:
    default:
      return "default";
  }
};

export const processingRunQualityToHealth = (status: ProcessingRunQuality) => {
  switch (status) {
    case ProcessingRunQuality.Critical:
      return "danger";
    case ProcessingRunQuality.Low:
      return "warning";
    case ProcessingRunQuality.Medium:
    case ProcessingRunQuality.High:
      return "success";
    default:
      return "default";
  }
};
