import {
  EuiButtonEmpty,
  EuiFieldText,
  EuiFilterButton,
  EuiFilterGroup,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiIcon,
  EuiSpacer,
} from "@elastic/eui";
import MMSectionTitle from "components/layouts/title/section-title";
import { feat, Feature } from "feats";
import txt from "helpers/text-helper";
import { updateField } from "hoc/helper-hooks";
import { useEffect, useState } from "react";
import {
  Indication,
  IndicationGrouped,
  indicationsGrouped,
  IndicationSide,
  indicationsUngrouped,
  INDICATION_EMPTY,
  INDICATION_GROUPED_EMPTY,
} from "store/data/indication/indication";
import { ORDER_EMPTY } from "store/data/order/order";
import { Referral, REFERRAL_EMPTY } from "store/data/referral/referral";
import { MMOrderEntryInputProps } from "../order-entry";

function MMOrderIndication(props: MMOrderEntryInputProps) {
  const [groupedIndications, setGroupedIndications] = useState<IndicationGrouped[]>([]);

  const [isDisabledReferral, setIsDisabledReferral] = useState(false);

  useEffect(() => {
    if (feat(Feature.OrdersDuplicateReferral)) {
      setIsDisabledReferral(!props.isFirstOrderOfReferral);
    }
  }, [props.isFirstOrderOfReferral]);

  useEffect(() => {
    if (props.order && props.order.referral && props.order.referral.indications) {
      const grouped: IndicationGrouped[] = indicationsGrouped(props.order.referral.indications);

      setGroupedIndications(grouped.length > 0 ? grouped : [INDICATION_GROUPED_EMPTY]);
    } else {
      setGroupedIndications([INDICATION_GROUPED_EMPTY]);
    }
  }, [props.order]);

  const onIndicationsChanged = (updatedIndications: IndicationGrouped[], updateOrder: boolean = true) => {
    if (props.onChange && updateOrder) {
      const referral: Referral = updateField(
        props.order?.referral || REFERRAL_EMPTY,
        "indications",
        indicationsUngrouped(updatedIndications)
      );
      props.onChange({
        ...ORDER_EMPTY,
        ...props.order,
        referral,
      });
    } else {
      setGroupedIndications((indications: IndicationGrouped[]) => updatedIndications);
    }
  };

  const onSideChange = (sides: IndicationSide[], currentIndex: number) => {
    if (groupedIndications.length > currentIndex) {
      let updatedGroupedIndications: IndicationGrouped[] = [];
      for (let i = 0; i < groupedIndications.length; i++) {
        const indicationGrouped: IndicationGrouped = groupedIndications[i];
        if (i === currentIndex) {
          updatedGroupedIndications.push({
            ...indicationGrouped,
            sides,
            indications: sides.map((side: IndicationSide) => ({
              ...INDICATION_EMPTY,
              indication: indicationGrouped.indication,
              side: side,
            })),
          });
        } else {
          updatedGroupedIndications.push({ ...indicationGrouped });
        }
      }
      onIndicationsChanged(updatedGroupedIndications);
    }
  };

  const onIndicationChange = (indicationText: string, currentIndex: number, updateOrder: boolean = true) => {
    if (groupedIndications.length > currentIndex) {
      let updatedGroupedIndications: IndicationGrouped[] = [];
      for (let i = 0; i < groupedIndications.length; i++) {
        const groupedIndication: IndicationGrouped = groupedIndications[i];
        if (i === currentIndex) {
          updatedGroupedIndications.push({
            ...groupedIndication,
            indication: indicationText,
            indications: groupedIndication.indications.map((indication: Indication) => ({
              ...indication,
              indication: indicationText,
            })),
          });
        } else {
          updatedGroupedIndications.push({ ...groupedIndication });
        }
      }
      onIndicationsChanged(updatedGroupedIndications, updateOrder);
    }
  };

  const addIndication = () => {
    setGroupedIndications((groupedIndications: IndicationGrouped[]) =>
      groupedIndications.concat([INDICATION_GROUPED_EMPTY])
    );
  };

  const deleteIndication = (i: number) => {
    let updatedGroupedIndications: IndicationGrouped[] = [
      ...groupedIndications.slice(0, i),
      ...groupedIndications.slice(i + 1),
    ];
    updatedGroupedIndications =
      updatedGroupedIndications.length > 0 ? updatedGroupedIndications : [INDICATION_GROUPED_EMPTY];

    onIndicationsChanged(updatedGroupedIndications, true);
  };
  const renderGroupedIndications = (groupedIndications: IndicationGrouped[]) => (
    <EuiFlexItem grow={false}>
      <EuiFlexGroup direction="column" gutterSize="s">
        {groupedIndications.map((groupedIndication: IndicationGrouped, i: number) =>
          renderIndicationGrouped(groupedIndication, i)
        )}
      </EuiFlexGroup>
    </EuiFlexItem>
  );

  const editingEnabled = (props.isEditable || props.isCorrigible || false) && !isDisabledReferral;

  const renderIndicationGrouped = (groupedIndication: IndicationGrouped, i: number) => (
    <EuiFlexItem key={`indication-grouped-${i}`} grow={false}>
      <EuiFlexGroup gutterSize="s" alignItems="center">
        <EuiFlexItem grow={true}>
          <EuiFormRow
            display="rowCompressed"
            className={!editingEnabled ? "readable-input" : ""}
            isDisabled={!editingEnabled}
          >
            <EuiFieldText
              data-testid={`indication-${i}-indication`}
              disabled={!editingEnabled}
              compressed={true}
              value={groupedIndication.indication || ""}
              onChange={(e) => onIndicationChange(e.target.value, i, false)}
              onBlur={(e) => onIndicationChange(e.target.value, i)}
            />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiFilterGroup contentEditable={false} compressed={true}>
            <EuiFilterButton
              data-testid={`indication-${i}-left`}
              disabled={!editingEnabled}
              hasActiveFilters={
                groupedIndication.sides.includes(IndicationSide.Left) &&
                !groupedIndication.sides.includes(IndicationSide.Right)
              }
              onClick={(e: any) =>
                onSideChange(
                  groupedIndication.sides.includes(IndicationSide.Left) &&
                    !groupedIndication.sides.includes(IndicationSide.Right)
                    ? []
                    : [IndicationSide.Left],
                  i
                )
              }
            >
              {txt.get("indications.side.left_short")}
            </EuiFilterButton>
            <EuiFilterButton
              data-testid={`indication-${i}-both`}
              disabled={!editingEnabled}
              hasActiveFilters={
                groupedIndication.sides.includes(IndicationSide.Left) &&
                groupedIndication.sides.includes(IndicationSide.Right)
              }
              onClick={(e: any) =>
                onSideChange(
                  groupedIndication.sides.includes(IndicationSide.Left) &&
                    groupedIndication.sides.includes(IndicationSide.Right)
                    ? []
                    : [IndicationSide.Right, IndicationSide.Left],
                  i
                )
              }
            >
              {txt.get("indications.side.both")}
            </EuiFilterButton>
            <EuiFilterButton
              data-testid={`indication-${i}-right`}
              disabled={!editingEnabled}
              hasActiveFilters={
                !groupedIndication.sides.includes(IndicationSide.Left) &&
                groupedIndication.sides.includes(IndicationSide.Right)
              }
              onClick={(e: any) =>
                onSideChange(
                  !groupedIndication.sides.includes(IndicationSide.Left) &&
                    groupedIndication.sides.includes(IndicationSide.Right)
                    ? []
                    : [IndicationSide.Right],
                  i
                )
              }
            >
              {txt.get("indications.side.right_short")}
            </EuiFilterButton>
          </EuiFilterGroup>
        </EuiFlexItem>
        {editingEnabled ? (
          <EuiFlexItem grow={false}>
            <EuiIcon
              style={{ cursor: "pointer" }}
              size="s"
              type="cross"
              color="subdued"
              onClick={(e: any) => deleteIndication(i)}
            />
          </EuiFlexItem>
        ) : (
          <></>
        )}
      </EuiFlexGroup>
      <EuiSpacer size="s" />
    </EuiFlexItem>
  );

  return (
    <EuiFlexGroup gutterSize="s" direction="column" justifyContent="flexStart" alignItems="flexStart">
      <MMSectionTitle
        text={txt.get("indications.name_number_unsure")}
        tip={txt.html(`orders.order.i.indication`)}
        icon="indication"
      />
      {renderGroupedIndications(groupedIndications)}
      {editingEnabled ? (
        <EuiFlexItem grow={false}>
          <EuiButtonEmpty
            size="s"
            iconType="plus"
            color="text"
            onClick={() => {
              addIndication();
            }}
          >
            {txt.uf("generic.add_x", txt.get("indications.name"))}
          </EuiButtonEmpty>
        </EuiFlexItem>
      ) : (
        <></>
      )}
    </EuiFlexGroup>
  );
}

export default MMOrderIndication;
