import moment from "moment-timezone";
import DateHelper from "./date-helper";

class MomentHelper {
  static toMoment(date: Date) {
    return moment(DateHelper.format(date, "yyyy-MM-dd HH:mm:ss"));
  }

  static toDate(moment: any) {
    return DateHelper.parseDate(moment && moment.format ? moment.format() : null);
  }

  static toDateMidDay(moment: any) {
    if (moment) {
      // This is done because the related column in the database is a timestamp.
      // The function expects a date (yyyy-mm-dd) value from a datepicker.
      // Here, it sets the "time" part to combat timezone confusion.
      moment.second(0);
      moment.minute(0);
      moment.hour(12);
    }
    return MomentHelper.toDate(moment);
  }
}

export default MomentHelper;
