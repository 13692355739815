import * as Sentry from "@sentry/react";
import env from "helpers/env-helper";
import React from "react";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";

export const sentryInit = () => {
  const sentryBaseSamplingRate = parseFloat(env("REACT_APP_SENTRY_BASE_SAMPLING_RATE") ?? "0.0");

  Sentry.init({
    dsn: env("REACT_APP_SENTRY_DSN") ?? "",
    enabled: (env("REACT_APP_SENTRY_ENABLED") ?? "0") === "1",
    environment: env("REACT_APP_SENTRY_ENVIRONMENT") ?? "development",
    integrations: [
      new Sentry.BrowserProfilingIntegration(),
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
      new Sentry.Replay(),
    ],
    profilesSampleRate: 1.0,
    release: env("REACT_APP_SENTRY_RELEASE") ?? "",
    replaysOnErrorSampleRate: 1.0,
    replaysSessionSampleRate: sentryBaseSamplingRate / 50,
    tracePropagationTargets: [
      "localhost",
      env("REACT_APP_API_CLIENTS"),
      env("REACT_APP_API_CONNECT"),
      env("REACT_APP_API_MANOX"),
    ],
    tracesSampleRate: sentryBaseSamplingRate,
  });
};
