import { EuiFlexGrid, EuiFlexItem } from "@elastic/eui";
import { useEffect, useState } from "react";
import { Appointment } from "store/data/appointment/appointment";
import { Client } from "store/data/client/client";
import { ClientContactMoment } from "store/data/client/contact-moment";
import MMAppointmentInfo from "./appointment-info";
import MMContactMomentInfo from "./contact-moment-info";

export interface MMCommunicationInfoProps {
  client: Client | null;
  appointments: Appointment[] | null;
  contactMoments: ClientContactMoment[] | null;
}

function MMCommunicationInfo(props: MMCommunicationInfoProps) {
  const [client, setClient] = useState<Client | null>(null);
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [contactMoments, setContactMoments] = useState<any[]>([]);

  useEffect(() => {
    if (props.client) {
      setClient(props.client);
    } else {
      setClient(null);
    }
    if (props.appointments) {
      setAppointments(props.appointments);
    } else {
      setAppointments([]);
    }
    if (props.contactMoments) {
      setContactMoments(props.contactMoments);
    } else {
      setContactMoments([]);
    }
  }, [props]);

  return client ? (
    <EuiFlexGrid className="subtle-editing">
      {appointments ? (
        <EuiFlexItem>
          <MMAppointmentInfo client={client} appointments={appointments} />
        </EuiFlexItem>
      ) : (
        <></>
      )}
      {contactMoments ? (
        <EuiFlexItem>
          <MMContactMomentInfo client={client} contactMoments={contactMoments} />
        </EuiFlexItem>
      ) : (
        <></>
      )}
    </EuiFlexGrid>
  ) : (
    <></>
  );
}

export default MMCommunicationInfo;
