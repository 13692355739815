import { Text, View } from "@react-pdf/renderer";
import DateHelper from "helpers/date-helper";
import txt from "helpers/text-helper";
import { useEffect, useState } from "react";
import { ClientContactMoment } from "store/data/client/contact-moment";
import { styles } from "../styles";

export interface MMPdfClientContactMomentsProps {
  contactMoments: ClientContactMoment[];
}

function MMPdfClientContactMoments(props: MMPdfClientContactMomentsProps) {
  const [contactMoments, setContactMoments] = useState<ClientContactMoment[]>(props.contactMoments);

  useEffect(() => {
    setContactMoments(props.contactMoments);
  }, [props]);
  return (
    <View>
      <View style={styles.spacer} />
      <View style={styles.columns}>
        <View style={[styles.section_12, styles.tableHeader]}>
          <Text style={styles.title}>{txt.get("pdfs.client_dossier.contact_moments")}</Text>
        </View>
      </View>
      <View style={styles.table}>
        {contactMoments.length > 0 ? (
          contactMoments.map((contactMoment: ClientContactMoment, i: number) => (
            <View key={`contact-moment-${i}`} style={{ ...styles.tableRow, ...styles.columns }}>
              <View style={styles.section_3}>
                <Text>{DateHelper.toDateHM(`${DateHelper.fromDutch(contactMoment.datum)} ${contactMoment.tijd}`)}</Text>
              </View>
              <View style={styles.section_2}>
                <Text>{contactMoment.behandelaar}</Text>
              </View>
              <View style={styles.section_8}>
                <Text>{contactMoment.omschrijving}</Text>
              </View>
            </View>
          ))
        ) : (
          <View>
            <View style={[styles.section_12, styles.soft]}>
              <Text>{txt.get("pdfs.table.no_x", txt.lo("pdfs.client_dossier.contact_moments"))}</Text>
            </View>
          </View>
        )}
      </View>
    </View>
  );
}

export default MMPdfClientContactMoments;
