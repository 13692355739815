import AuthenticationHelper from "helpers/authentication-helper";
import env from "helpers/env-helper";

const ENV_PREPEND: string = "REACT_APP_FEATURE_";

export enum Feature {
  OrdersFittingQuality = "ORDERS_FITTING_QUALITY",
  TextToSentence = "TEXT_TO_SENTENCE",
  OrdersCleanRationale = "ORDERS_CLEAN_RATIONALE",
  OrdersVlotMatching = "ORDERS_VLOT_MATCHING",
  OrdersDuplicateReferral = "ORDERS_DUPLICATE_REFERRAL",
  OrdersLegacyIntakeScreens = "ORDERS_LEGACY_INTAKE_SCREENS",
}

export const feat = (feature: Feature): boolean => {
  const envKey = `${ENV_PREPEND}${feature}`;
  const envValue = env(envKey);
  if (envValue === "1") {
    return true;
  }
  let allowedIds: string[] = envValue.split(",").map((item: string) => item.trim());
  let personalDetailsIdString: string = "u" + AuthenticationHelper.getPersonalDetailsId();
  let organisationIdString: string = "o" + AuthenticationHelper.getOrganisationId();
  return allowedIds.findIndex((id: string) => id === personalDetailsIdString || id === organisationIdString) >= 0;
};
