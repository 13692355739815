import { EuiButton, EuiFlexItem, EuiProgress } from "@elastic/eui";
import ManoXAPIHelper from "api/manox-api-helper";
import MMPage from "components/layouts/page/page";
import txt from "helpers/text-helper";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Scan } from "store/data/scan/scan";
import MMScanDetail from "./scan-detail";

function MMScan() {
  const navigate = useNavigate();
  const { scanId } = useParams();

  const [scan, setScan] = useState<any>({} as Scan);
  const [isLoading, setIsLoading] = useState(true);

  const api = new ManoXAPIHelper();

  useEffect(() => {
    if (!scanId) {
      console.error("no scan ID given");
    } else {
      const loadScan = async () => {
        const result = await api.getScan(scanId);
        if (result.status === "OK") {
          setScan(result.result);
        } else {
          setScan({});
        }
        setIsLoading(false);
      };

      loadScan();
    }
  }, []);

  return (
    <MMPage
      title={scan ? scan.name : "..."}
      share={true}
      topActions={[
        <EuiButton
          color="accent"
          aria-label={txt.get("generic.back_to", txt.get("orders.scans"))}
          iconType="arrowLeft"
          size="s"
          onClick={() => navigate("/scanning/scans")}
        >
          {txt.get("generic.back_to", txt.get("scanning.scans.page_title"))}
        </EuiButton>,
      ]}
    >
      <EuiFlexItem>{isLoading ? <EuiProgress size="xs" color="accent" /> : <MMScanDetail scan={scan} />}</EuiFlexItem>
    </MMPage>
  );
}

export default MMScan;
