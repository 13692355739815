import txt from "helpers/text-helper";
import { File } from "../file/file";
export enum OrganisationOrdering {
  Internal = "INTERNAL",
  External = "EXTERNAL",
  B2B = "B2B",

  // A special OrganisationOrdering. In the backend, it
  // is used for filtering OrderCosting.type instead.
  Private = "PRIVATE",
}

export const ORGANISATION_EMPTY: Organisation = {
  id: null,
  name: "",
  acronym: "",
  client_reference: "",
  client_naming: "",
  legacy_portfolio: {},
  ordering: OrganisationOrdering.External,
};

export interface Organisation {
  id: number | null;
  name: string;
  acronym: string;
  client_reference: string;
  client_naming: string;
  legacy_portfolio: {
    reference?: string;
    scanning?: string;
  };
  pricelist?: File;
  ordering: OrganisationOrdering;
  created_at?: Date;
  updated_at?: Date;
  auth_id?: string;
}

export const toOrganisationOrderingDescription = (value: string | OrganisationOrdering) => {
  let key: string = typeof value === "string" ? value : OrganisationOrdering[value];
  key = key.toLowerCase();
  return txt.get(`admin.organisations.ordering_option.${key}`);
};
