declare var MessageBirdChatWidget: any;

class MessageBirdHelper {
  protected static hasWidget() {
    const result = MessageBirdChatWidget !== undefined;
    if (!result) {
      console.warn("MessageBirdHelper.hasWidget", false);
    } else {
      // console.log("MessageBirdChatWidget", MessageBirdChatWidget);
    }
    return result;
  }
  protected static widget() {
    return MessageBirdChatWidget;
  }
  static setLanguage(lang: string) {
    console.log("MessageBirdHelper.setLanguage()", lang);
    if (!MessageBirdHelper.hasWidget()) {
      console.log("MessageBirdHelper widget not found");
      return false;
    }
    const widget = MessageBirdHelper.widget();
    if (widget?.setLanguage) {
      return MessageBirdHelper.widget().setLanguage(lang);
    }
    return false;
  }
  static logout() {
    console.log("MessageBirdHelper.logout()");
    if (!MessageBirdHelper.hasWidget()) {
      console.log("MessageBirdHelper widget not found");
      return false;
    }
    return MessageBirdHelper.widget().logout();
  }
  static identify(identifier: string, userInfo?: any) {
    console.log("MessageBirdHelper.identify()", identifier, userInfo);
    if (!MessageBirdHelper.hasWidget()) {
      console.log("MessageBirdHelper widget not found");
      return false;
    }
    return MessageBirdHelper.widget().identify(identifier, userInfo);
  }
  static setUserInfo(organisationId: string, firstName: string, lastName: string, email: string, phoneNumber?: string) {
    console.log("MessageBirdHelper.setUserInfo()", organisationId, firstName, lastName, email, phoneNumber);
    if (!MessageBirdHelper.hasWidget()) {
      console.log("MessageBirdHelper widget not found");
      return false;
    }
    let attributes: any = {
      organisation_id: organisationId,
      first_name: firstName,
      last_name: lastName,
      email: email,
    };
    if (phoneNumber) attributes.phone_number = phoneNumber;
    return MessageBirdHelper.widget().setAttributes(attributes);
  }
  static show() {
    console.log("MessageBirdHelper.show()");
    if (!MessageBirdHelper.hasWidget()) {
      console.log("MessageBirdHelper widget not found");
      return false;
    }
    MessageBirdHelper.widget().show();
  }
  static hide() {
    console.log("MessageBirdHelper.hide()");
    if (!MessageBirdHelper.hasWidget()) {
      console.log("MessageBirdHelper widget not found");
      return false;
    }
    MessageBirdHelper.widget().hide();
  }
  static open() {
    console.log("MessageBirdHelper.open()");
    if (!MessageBirdHelper.hasWidget()) {
      console.log("MessageBirdHelper widget not found");
      return false;
    }
    MessageBirdHelper.widget().toggleChat(true);
  }
  static close() {
    console.log("MessageBirdHelper.close()");
    if (!MessageBirdHelper.hasWidget()) {
      console.log("MessageBirdHelper widget not found");
      return false;
    }
    MessageBirdHelper.widget().toggleChat(false);
  }
}

export default MessageBirdHelper;
