import {
  EuiBasicTable,
  EuiButton,
  EuiDatePicker,
  EuiDatePickerRange,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiHorizontalRule,
  EuiSpacer,
  EuiText,
} from "@elastic/eui";
import ClientsAPIHelper from "api/clients-api.helper";
import ConnectAPIHelper from "api/connect-api-helper";
import MMCell from "components/layouts/table/cell";
import DateHelper from "helpers/date-helper";
import MomentHelper from "helpers/moment-helper";
import txt from "helpers/text-helper";
import { Fragment, useEffect, useState } from "react";
import { Appointment } from "store/data/appointment/appointment";
import { fullName, PersonalDetails } from "store/data/personal-details/personal-details";
import { useLocalStorage } from "store/local-storage";
import { useDebounce } from "use-debounce";

export const PAGE_SIZE_OPTIONS = 1000; //[10, 25, 50, 100]; //,0]; //0 would mean without limit
export const DEFAULT_PAGE_SIZE = 1000;
export const SEARCH_DEBOUNCE_DELAY = 1200;
export const DEFAULT_SORT_BY = "Datum";
export const DEFAULT_SORT_ORDER = "asc";
export const DEFAULT_TAG_SINCE_UNTIL = "_default_";
export const DEFAULT_AGENDA_DAYS = 21;

export interface MMAppointmentsListProps {
  onAppointmentsChanged?: Function;
}

function MMAppointmentsList(props: MMAppointmentsListProps) {
  const clientApi: ClientsAPIHelper = new ClientsAPIHelper();
  const api: ConnectAPIHelper = new ConnectAPIHelper();

  const [appointments, setAppointments] = useState([]);
  const [practitioners, setPractitioners] = useState<PersonalDetails[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  const [onlyClientAppointments] = useLocalStorage("appointments_ony_clients", true);

  const [limit, setLimit] = useLocalStorage("appointments_limit", DEFAULT_PAGE_SIZE);
  const [offset, setOffset] = useLocalStorage("appointments_offset", 0);
  const [since, setSince] = useLocalStorage("appointments_since", DEFAULT_TAG_SINCE_UNTIL);
  const [until, setUntil] = useLocalStorage("appointments_until", DEFAULT_TAG_SINCE_UNTIL);

  const [sinceValue] = useDebounce(since, SEARCH_DEBOUNCE_DELAY);
  const [untilValue] = useDebounce(until, SEARCH_DEBOUNCE_DELAY);

  const [total, setTotal] = useState(0);
  const [sortBy, setSortBy] = useLocalStorage("appointments_sort_by", DEFAULT_SORT_BY);
  const [sortOrder, setSortOrder] = useLocalStorage("appointments_sort_order", DEFAULT_SORT_ORDER);
  const [error, setError] = useState("");

  useEffect(() => {
    const loadPractitioners = async () => {
      const practitioners: PersonalDetails[] = await api.getPractitioners();
      setPractitioners(practitioners);
    };
    loadPractitioners();
  }, []);

  const addPractitionerDetails = (appointment: any) => {
    const practitioner: PersonalDetails | undefined = practitioners.find((practitioner: PersonalDetails) =>
      fullName(practitioner).startsWith(appointment.vlot_data.behandelaarnaam)
    );

    if (practitioner) {
      return {
        ...appointment,
        vlot_data: {
          ...appointment.vlot_data,
          behandelaarnaam: fullName(practitioner),
        },
      };
    }
    return appointment;
  };

  const handleAddAppointments = async (removeExisting: boolean = true) => {
    setIsLoading(true);

    let sheetAppointments: Array<any> = appointments
      // .filter((appointment: any) => appointment.client_code)
      .map((appointment: any) => ({
        clientId: appointment.client_code,
        date: new Date(appointment.start),
        time: DateHelper.toTime(appointment.start),
        duration: DateHelper.duration(appointment.start, appointment.end, true),
        appointment: appointment.appointment,
        practitioner: appointment.vlot_data.behandelaarnaam,
        location: appointment.vlot_data.locatie.omschrijving,
        unstructured: Object.values(appointment.vlot_data)
          .filter((value: any) => !!value)
          .toString(),
        comment: "",
        name: "",
        birthDate: "",
        address: "",
        phoneNumber: "",
      }));

    api
      .post("appointments/create", {}, { remove_existing: removeExisting, appointments: sheetAppointments })
      .then((result) => {
        console.log("sheets updated", result);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("something went wrong", err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const loadAppointments = async () => {
      setIsLoading(true);

      let filters: any = {};
      if (since) {
        filters.since =
          sinceValue === DEFAULT_TAG_SINCE_UNTIL ? DateHelper.parseDate() : DateHelper.parseDate(sinceValue);
      }
      if (until) {
        filters.until =
          untilValue === DEFAULT_TAG_SINCE_UNTIL
            ? DateHelper.addDays(DEFAULT_AGENDA_DAYS)
            : DateHelper.parseDate(untilValue);
      }

      filters.only_client_appointments = onlyClientAppointments;

      const result = await clientApi.getAppointments(
        filters,
        limit,
        offset,
        appointmentFieldToSortKey(sortBy),
        sortOrder
      );

      if (result.status === "OK") {
        setAppointments(result.result.map((result: any) => addPractitionerDetails(result)));
        setTotal(result.meta_data.result_set.total);
        setError("");
      } else {
        setAppointments([]);
        setTotal(0);
        setError(`${result.status} (${result.code}): ${result.message}`);
      }
      setIsLoading(false);
    };
    if (practitioners && practitioners.length > 0) {
      loadAppointments();
    }
  }, [practitioners, sortOrder, sortBy, limit, offset, sinceValue, untilValue]);

  const resultCountInfo = () =>
    total === 0
      ? txt.uf("generic.found_no_x", txt.get("appointments.page_title"))
      : txt.uf(
          "generic.showing_x_of_y_found_z",
          limit === 0 ? txt.get("generic.all") : `${offset + 1}-${Math.min(total, offset + limit)}`,
          total,
          txt.get("appointments.page_title")
        ) + ".";

  const appointmentFieldToSortKey = (field: string) => {
    switch (field) {
      default:
        return field;
    }
  };

  const onAppointmentsChange = ({ page = {} as any, sort = {} as any }) => {
    if (page.size) {
      const newLimit = page.size;
      const newOffset = Math.max(0, page.index * page.size);
      if (limit !== newLimit) setLimit(newLimit);
      if (offset !== newOffset) setOffset(newOffset);
    }
    if (sort.field) {
      setSortBy(sort.field);
      setSortOrder(sort.direction ?? "asc");
    }
  };

  const columns = [
    {
      name: txt.get("clients.client_code"),
      field: "client_code",
      render: (clientCode: string) => <MMCell wrap={false} text={clientCode} />,
    },
    {
      name: txt.get("appointments.date"),
      field: "start",
      render: (start: Date) => <MMCell wrap={false} text={DateHelper.toDate(start)} />,
    },
    {
      name: txt.get("appointments.time"),
      field: "start",
      render: (start: Date) => <MMCell wrap={false} text={DateHelper.toTime(start)} />,
    },
    {
      name: txt.get("appointments.duration"),
      field: "start",
      render: (start: Date, appointment: Appointment) => (
        <MMCell wrap={false} text={DateHelper.duration(start, appointment.end, true)} />
      ),
    },
    {
      name: txt.get("appointments.name"),
      field: "appointment",
      sortable: api.appointmentIsSortableBy(appointmentFieldToSortKey("appointment")),
      render: (appointment: string) => <MMCell text={appointment} />,
    },
    {
      name: txt.get("appointments.practitioner"),
      field: "vlot_data.behandelaarnaam",
      render: (practitioner: string) => <MMCell text={practitioner} />,
    },
    {
      name: txt.get("appointments.location"),
      field: "location",
      render: (location: string) => <MMCell text={location} />,
    },
    {
      name: txt.get("appointments.client"),
      field: "vlot_data.klantnaam",
      render: (clientName: string) => <MMCell text={clientName} />,
    },
  ];

  const getRowProps = (appointment: any) => {
    const { id } = appointment;
    return {
      "data-id": `row-${id}`,
      onClick: (e: any) => {
        // if (
        //   e.target.tagName !== "BUTTON" &&
        //   e.target.tagName !== "INPUT" &&
        //   e.target.tagName !== "A"
        // ) {
        //   navigate(`/appointments/${id}`);
        // }
      },
    };
  };

  return (
    <Fragment>
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiFormRow
            display="rowCompressed"
            label={`${txt.get("appointments.since")}/${txt.get("appointments.until")}`}
          >
            <EuiDatePickerRange
              startDateControl={
                <EuiDatePicker
                  dateFormat={"DD-MM-yyyy"}
                  selected={MomentHelper.toMoment(
                    DateHelper.parseDate(since === DEFAULT_TAG_SINCE_UNTIL ? null : since)
                  )}
                  onChange={(moment: any) => {
                    setSince(MomentHelper.toDate(moment));
                  }}
                ></EuiDatePicker>
              }
              endDateControl={
                <EuiDatePicker
                  dateFormat={"DD-MM-yyyy"}
                  selected={MomentHelper.toMoment(
                    DateHelper.parseDate(
                      until === DEFAULT_TAG_SINCE_UNTIL ? DateHelper.addDays(DEFAULT_AGENDA_DAYS) : until
                    )
                  )}
                  onChange={(moment: any) => {
                    setUntil(MomentHelper.toDate(moment));
                  }}
                ></EuiDatePicker>
              }
            />
          </EuiFormRow>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiFlexGroup alignItems="flexEnd">
        <EuiFlexItem>
          <EuiText textAlign="right" size="xs">
            {resultCountInfo()}
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size="s" />
      <EuiHorizontalRule margin="none" style={{ height: 1 }} />
      <EuiBasicTable
        loading={isLoading}
        tableLayout="auto"
        itemId="id"
        items={appointments}
        columns={columns}
        // // pagination={pagination}
        // sorting={sorting}
        rowProps={getRowProps}
        noItemsMessage={error ? error : txt.uf("generic.found_no_x", txt.get("appointments.page_title"))}
        onChange={onAppointmentsChange}
      />
      <EuiSpacer />
      <EuiFlexGroup>
        <EuiFlexGroup alignItems="flexEnd">
          <EuiButton
            onClick={(e: any) => {
              handleAddAppointments();
            }}
            isLoading={isLoading}
          >
            {txt.get("appointments.sheet_sync.replace")}
          </EuiButton>
          <EuiButton
            onClick={(e: any) => {
              handleAddAppointments(false);
            }}
            isLoading={isLoading}
          >
            {txt.get("appointments.sheet_sync.append")}
          </EuiButton>
        </EuiFlexGroup>
      </EuiFlexGroup>
    </Fragment>
  );
}

export default MMAppointmentsList;
