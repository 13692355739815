import {
  EuiFieldText,
  EuiFilterButton,
  EuiFilterGroup,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiSpacer,
} from "@elastic/eui";
import txt from "helpers/text-helper";
import { updateField } from "hoc/helper-hooks";
import { useEffect, useState } from "react";
import { EMPTY_PRODUCT_MEASUREMENTS, ProductMeasurements } from "store/data/order/order";
import { Digit } from "store/data/scan/scan";
import { MeasurementValueType, toMeasurementValue } from "../order-inputs";
import { MMOrderLineFieldProps } from "../order-line-entry";

function MMOrderMeasurements(props: MMOrderLineFieldProps) {
  const [values, setValues] = useState<ProductMeasurements>(props.selection.measurements || EMPTY_PRODUCT_MEASUREMENTS);
  const [enabledMeasurements, setEnabledMeasurements] = useState<any[]>([]);

  const [sides, setSides] = useState<any[]>([]);
  const [joints, setJoints] = useState<any[]>([]);

  const isMeasurementEnabled = (measurement: string): boolean =>
    enabledMeasurements.findIndex(
      (enabledMeasurement: string) => enabledMeasurement.startsWith("all") || enabledMeasurement.startsWith(measurement)
    ) >= 0;

  const isMeasurementRequired = (measurement: string): boolean => {
    const foundMeasurement: string = enabledMeasurements.find(
      (enabledMeasurement: string) => enabledMeasurement.startsWith("all") || enabledMeasurement.startsWith(measurement)
    );
    return foundMeasurement ? !foundMeasurement.endsWith("?") : false;
  };

  useEffect(() => {
    const enabledMeasurements: string[] =
      props.selection.variation && props.selection.variation.measurements ? props.selection.variation.measurements : [];
    setEnabledMeasurements(enabledMeasurements);
    setValues(props.selection.measurements || EMPTY_PRODUCT_MEASUREMENTS);
  }, [props.selection]);

  useEffect(() => {
    let sideOptions: any[] = [];

    if (!isMeasurementRequired("s1")) {
      sideOptions.push({
        value: "n.v.t.",
        inputDisplay: txt.get("generic.n_a"),
      });
    }

    sideOptions.push({
      value: "Ulnair",
      inputDisplay: txt.get("orders.order.ulnar"),
    });

    sideOptions.push({
      value: "Radiaal",
      inputDisplay: txt.get("orders.order.radial"),
    });

    setSides(sideOptions);
    if (sideOptions.length === 1) {
      setValues(updateField(values, "s1", sideOptions[0].value));
    }

    const jointOptions: any[] = [];

    if (!isMeasurementRequired("j1")) {
      jointOptions.push({
        value: "n.v.t.",
        inputDisplay: txt.get("generic.n_a"),
      });
    }
    if (props.selection && props.selection.digits && props.selection.digits.includes(Digit.Thumb)) {
      jointOptions.push({
        value: "IP",
        inputDisplay: txt.get("orders.order.ip"),
      });
    } else {
      jointOptions.push({
        value: "PIP",
        inputDisplay: txt.get("orders.order.pip"),
      });
      jointOptions.push({
        value: "DIP",
        inputDisplay: txt.get("orders.order.dip"),
      });
    }

    setJoints(jointOptions);
    if (jointOptions.length === 1) {
      setValues(updateField(values, "j1", jointOptions[0].value));
    }
  }, [enabledMeasurements, props.selection]);

  const onValueChanged = (values: ProductMeasurements) => {
    if (props.onChange) {
      props.onChange({ ...props.selection, measurements: values });
      // } else {
      //   setValues(values);
    }
  };

  return (
    <EuiFlexGroup gutterSize="none" direction={props.direction || "column"}>
      {(!props.measurementsShown || props.measurementsShown.includes("o1")) &&
      (!props.hideNonOptions || isMeasurementEnabled("o1")) ? (
        <EuiFlexItem grow={false} style={{ flexBasis: 0 }}>
          <EuiFormRow
            display="rowCompressed"
            className="product-input"
            isInvalid={props.measurementsMissing && props.measurementsMissing.includes("o1")}
            label={
              props.showTitle
                ? `${
                    props.titleLong ? txt.get("orders.order.measurement_1_long") : txt.get("orders.order.measurement_1")
                  } ${txt.get("orders.order.measurement_unit")}`
                : undefined
            }
          >
            <EuiFieldText
              disabled={!props.isEditable || !isMeasurementEnabled("o1")}
              className={values.o1 ? "has-input" : ""}
              value={values.o1 || ""}
              compressed={true}
              style={{ width: "45px" }}
              placeholder={isMeasurementEnabled("o1") ? txt.get("orders.order.measurement_unit") : undefined}
              onChange={(e) => setValues(updateField(values, "o1", toMeasurementValue(e.target.value)))}
              onBlur={(e) => onValueChanged(updateField(values, "o1", toMeasurementValue(e.target.value, true)))}
              isInvalid={props.measurementsMissing && props.measurementsMissing.includes("o1")}
              data-testid="input-measurement-o1"
            />
          </EuiFormRow>
        </EuiFlexItem>
      ) : (
        <></>
      )}

      {(!props.measurementsShown || props.measurementsShown.includes("o2")) &&
      (!props.hideNonOptions || isMeasurementEnabled("o2")) ? (
        <EuiFlexItem grow={false} style={{ flexBasis: 0 }}>
          <EuiFormRow
            display="rowCompressed"
            className="product-input"
            label={
              props.showTitle
                ? `${
                    props.titleLong ? txt.get("orders.order.measurement_2_long") : txt.get("orders.order.measurement_2")
                  } ${txt.get("orders.order.measurement_unit")}`
                : undefined
            }
            isInvalid={props.measurementsMissing && props.measurementsMissing.includes("o2")}
          >
            <EuiFieldText
              disabled={!props.isEditable || !isMeasurementEnabled("o2")}
              className={values.o2 ? "has-input" : ""}
              value={values.o2 || ""}
              compressed={true}
              style={{ width: "45px" }}
              placeholder={isMeasurementEnabled("o2") ? txt.get("orders.order.measurement_unit") : undefined}
              onChange={(e) => setValues(updateField(values, "o2", toMeasurementValue(e.target.value)))}
              onBlur={(e) => onValueChanged(updateField(values, "o2", toMeasurementValue(e.target.value, true)))}
              isInvalid={props.measurementsMissing && props.measurementsMissing.includes("o2")}
              data-testid="input-measurement-o2"
            />
          </EuiFormRow>
          <EuiSpacer size="s" />
        </EuiFlexItem>
      ) : (
        <></>
      )}
      {(!props.measurementsShown || props.measurementsShown.includes("o3")) &&
      (!props.hideNonOptions || isMeasurementEnabled("o3")) ? (
        <EuiFlexItem grow={false} style={{ flexBasis: 0 }}>
          <EuiFormRow
            display="rowCompressed"
            className="product-input"
            label={
              props.showTitle
                ? `${
                    props.titleLong ? txt.get("orders.order.measurement_3_long") : txt.get("orders.order.measurement_3")
                  } ${txt.get("orders.order.measurement_unit")}`
                : undefined
            }
            isInvalid={props.measurementsMissing && props.measurementsMissing.includes("o3")}
          >
            <EuiFieldText
              disabled={!props.isEditable || !isMeasurementEnabled("o3")}
              className={values.o3 ? "has-input" : ""}
              value={values.o3 || ""}
              compressed={true}
              style={{ width: "45px" }}
              placeholder={isMeasurementEnabled("o3") ? txt.get("orders.order.measurement_unit") : undefined}
              onChange={(e) => setValues(updateField(values, "o3", toMeasurementValue(e.target.value)))}
              onBlur={(e) => onValueChanged(updateField(values, "o3", toMeasurementValue(e.target.value, true)))}
              isInvalid={props.measurementsMissing && props.measurementsMissing.includes("o3")}
              data-testid="input-measurement-o3"
            />
          </EuiFormRow>
          <EuiSpacer size="s" />
        </EuiFlexItem>
      ) : (
        <></>
      )}
      {(!props.measurementsShown || props.measurementsShown.includes("o4")) &&
      (!props.hideNonOptions || isMeasurementEnabled("o4")) ? (
        <EuiFlexItem grow={false} style={{ flexBasis: 0 }}>
          <EuiFormRow
            display="rowCompressed"
            className="product-input"
            label={
              props.showTitle
                ? `${
                    props.titleLong ? txt.get("orders.order.measurement_4_long") : txt.get("orders.order.measurement_4")
                  } ${txt.get("orders.order.measurement_unit")}`
                : undefined
            }
            isInvalid={props.measurementsMissing && props.measurementsMissing.includes("o4")}
          >
            <EuiFieldText
              disabled={!props.isEditable || !isMeasurementEnabled("o4")}
              className={values.o4 ? "has-input" : ""}
              value={values.o4 || ""}
              compressed={true}
              style={{ width: "45px" }}
              placeholder={isMeasurementEnabled("o4") ? txt.get("orders.order.measurement_unit") : undefined}
              onChange={(e) => setValues(updateField(values, "o4", toMeasurementValue(e.target.value)))}
              onBlur={(e) => onValueChanged(updateField(values, "o4", toMeasurementValue(e.target.value, true)))}
              isInvalid={props.measurementsMissing && props.measurementsMissing.includes("o4")}
              data-testid="input-measurement-o4"
            />
          </EuiFormRow>
          <EuiSpacer size="s" />
        </EuiFlexItem>
      ) : (
        <></>
      )}
      {(!props.measurementsShown || props.measurementsShown.includes("l1")) &&
      (!props.hideNonOptions || isMeasurementEnabled("l1")) ? (
        <EuiFlexItem grow={false} style={{ flexBasis: 0 }}>
          <EuiFormRow
            display="rowCompressed"
            className="product-input"
            label={
              props.showTitle
                ? `${
                    props.titleLong
                      ? txt.get("orders.order.measurement_l1_long")
                      : txt.get("orders.order.measurement_l1")
                  } ${txt.get("orders.order.measurement_unit")}`
                : undefined
            }
            isInvalid={props.measurementsMissing && props.measurementsMissing.includes("l1")}
          >
            <EuiFieldText
              disabled={!props.isEditable || !isMeasurementEnabled("l1")}
              className={values.l1 ? "has-input" : ""}
              value={values.l1 || ""}
              compressed={true}
              style={{ width: "45px" }}
              placeholder={isMeasurementEnabled("l1") ? txt.get("orders.order.measurement_unit") : undefined}
              onChange={(e) =>
                setValues(
                  updateField(values, "l1", toMeasurementValue(e.target.value, false, [MeasurementValueType.Numeric]))
                )
              }
              onBlur={(e) =>
                onValueChanged(
                  updateField(values, "l1", toMeasurementValue(e.target.value, true, [MeasurementValueType.Numeric]))
                )
              }
              isInvalid={props.measurementsMissing && props.measurementsMissing.includes("l1")}
              data-testid="input-measurement-l1"
            />
          </EuiFormRow>
          <EuiSpacer size="s" />
        </EuiFlexItem>
      ) : (
        <></>
      )}
      {(!props.measurementsShown || props.measurementsShown.includes("l2")) &&
      (!props.hideNonOptions || isMeasurementEnabled("l2")) ? (
        <EuiFlexItem grow={false} style={{ flexBasis: 0 }}>
          <EuiFormRow
            display="rowCompressed"
            className="product-input"
            label={
              props.showTitle
                ? `${
                    props.titleLong
                      ? txt.get("orders.order.measurement_l2_long")
                      : txt.get("orders.order.measurement_l2")
                  } ${txt.get("orders.order.measurement_unit")}`
                : undefined
            }
            isInvalid={props.measurementsMissing && props.measurementsMissing.includes("l2")}
          >
            <EuiFieldText
              disabled={!props.isEditable || !isMeasurementEnabled("l2")}
              className={values.l2 ? "has-input" : ""}
              value={values.l2 || ""}
              compressed={true}
              style={{ width: "45px" }}
              placeholder={isMeasurementEnabled("l2") ? txt.get("orders.order.measurement_unit") : undefined}
              onChange={(e) =>
                setValues(
                  updateField(values, "l2", toMeasurementValue(e.target.value, false, [MeasurementValueType.Numeric]))
                )
              }
              onBlur={(e) =>
                onValueChanged(
                  updateField(values, "l2", toMeasurementValue(e.target.value, true, [MeasurementValueType.Numeric]))
                )
              }
              isInvalid={props.measurementsMissing && props.measurementsMissing.includes("l2")}
              data-testid="input-measurement-l2"
            />
          </EuiFormRow>
          <EuiSpacer size="s" />
        </EuiFlexItem>
      ) : (
        <></>
      )}
      {(!props.measurementsShown || props.measurementsShown.includes("s1")) &&
      (!props.hideNonOptions || isMeasurementEnabled("s1")) ? (
        <EuiFlexItem grow={false} style={{ flexBasis: 0 }}>
          <EuiFormRow
            display="rowCompressed"
            className="product-input"
            label={
              props.showTitle
                ? props.titleLong
                  ? txt.get("orders.order.measurement_s_long")
                  : txt.get("orders.order.measurement_s")
                : undefined
            }
          >
            <EuiFilterGroup contentEditable={false} compressed={true}>
              {sides.map((side: any, i: number) => (
                <EuiFilterButton
                  key={`side-${i}`}
                  disabled={!props.isEditable || !isMeasurementEnabled("s1")}
                  hasActiveFilters={values.s1 === side.value}
                  onClick={(e: any) =>
                    onValueChanged(updateField(values, "s1", values.s1 === side.value ? null : side.value))
                  }
                >
                  {side.inputDisplay}
                </EuiFilterButton>
              ))}
            </EuiFilterGroup>
          </EuiFormRow>
          <EuiSpacer size="s" />
        </EuiFlexItem>
      ) : (
        <></>
      )}
      {(!props.measurementsShown || props.measurementsShown.includes("j1")) &&
      (!props.hideNonOptions || isMeasurementEnabled("j1")) ? (
        <EuiFlexItem style={{ flexBasis: 0 }}>
          <EuiFormRow
            display="rowCompressed"
            className="product-input"
            label={
              props.showTitle
                ? props.titleLong
                  ? txt.get("orders.order.measurement_j_long")
                  : txt.get("orders.order.measurement_j")
                : undefined
            }
          >
            <EuiFilterGroup contentEditable={false} compressed={true}>
              {joints.map((joint: any, i: number) => (
                <EuiFilterButton
                  key={`joint-${i}`}
                  disabled={!props.isEditable || !isMeasurementEnabled("j1")}
                  hasActiveFilters={values.j1 === joint.value}
                  onClick={(e: any) =>
                    onValueChanged(updateField(values, "j1", values.j1 === joint.value ? null : joint.value))
                  }
                >
                  {joint.inputDisplay}
                </EuiFilterButton>
              ))}
            </EuiFilterGroup>
          </EuiFormRow>

          <EuiSpacer size="s" />
        </EuiFlexItem>
      ) : (
        <></>
      )}
    </EuiFlexGroup>
  );
}

export default MMOrderMeasurements;
