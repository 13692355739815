import {
  EuiButton,
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiNotificationBadge,
  EuiPanel,
  EuiSpacer,
  EuiTab,
  EuiTabs,
  EuiText,
  EuiTitle,
} from "@elastic/eui";
import ConnectAPIHelper from "api/connect-api-helper";
import MMPage from "components/layouts/page/page";
import MMOrdersShortlist from "components/orders/order-shortlist";
import AuthenticationHelper from "helpers/authentication-helper";
import env from "helpers/env-helper";
import txt from "helpers/text-helper";
import UrlHelper from "helpers/url-helper";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { OrderStatus } from "store/data/order/order";
import { START_ORDER_VALUE_CONNECT, totalOrderCount } from "store/data/order/order-count";
import { PersonalDetails } from "store/data/personal-details/personal-details";

const MAIN_PRACTITIONER_IDS = env("REACT_APP_MAIN_PRACTITIONER_IDS").split(",");

function MMOrderValues() {
  const navigate = useNavigate();
  const api = new ConnectAPIHelper();
  const [buttonsToShow, setButtonsToShow] = useState<any[]>([]);
  const [selectedPendingTab, setSelectedPendingTab] = useState(UrlHelper.queryParam("referrals_pending_tab"));
  const [selectedPractitionerTab, setSelectedPractitionerTab] = useState(
    UrlHelper.queryParam("referrals_practitioner_tab")
  );

  const [orderCounts, setOrderCounts] = useState<any[]>([]);
  const [mainPractitioners, setMainPractitioners] = useState<PersonalDetails[]>([]);

  const paymentInfoExpectedStatusses = [
    OrderStatus.Submitted,
    OrderStatus.Accepted,
    OrderStatus.Produced,
    OrderStatus.ProducedPartial,
    OrderStatus.Packaged,
    OrderStatus.PackagedPartial,
    OrderStatus.Returned,
    OrderStatus.Delivered,
    OrderStatus.Finalized,
  ];
  const loadOrderCounts = async () => {
    const result = await api.getOrderCounts([], undefined, START_ORDER_VALUE_CONNECT);
    setOrderCounts(result);
  };

  useEffect(() => {
    let params: any = {
      referrals_practitioner_tab: selectedPractitionerTab.toString().toLowerCase(),
      referrals_pending_tab: selectedPendingTab.toString().toLowerCase(),
    };
    const orderParam = UrlHelper.queryParam("order");
    if (orderParam) {
      params.order = orderParam;
    }

    window.history.replaceState(
      null,
      txt.get("finance.order_values.page_title"),
      `/finance/order-values?${UrlHelper.toQueryString(params)}`
    );
    loadOrderCounts();
  }, [selectedPendingTab, selectedPractitionerTab]);

  useEffect(() => {
    const load = async () => {
      const result = await api.getPractitioners(
        [AuthenticationHelper.getInternalOrganisationId()],
        MAIN_PRACTITIONER_IDS
      );
      setMainPractitioners(result);
      loadOrderCounts();
    };

    const setInterfaceForPermissions = async () => {
      let buttons: any[] = [];

      if (await AuthenticationHelper.hasPermission(["finance#read_all"])) {
        buttons.push(
          <EuiButton
            aria-label={txt.get("finance.page_title")}
            size="s"
            style={{ width: "40px", minInlineSize: "40px" }}
            iconType="stats"
            onClick={() => navigate("/finance")}
          />
        );
      }

      if (await AuthenticationHelper.hasPermission(["referrals#edit_all"])) {
        buttons.push(
          <EuiButton size="s" color="accent" onClick={() => navigate("/finance/referrals")}>
            {txt.get("referrals.page_title")}
          </EuiButton>
        );
      }
      if (await AuthenticationHelper.hasPermission(["invoices#edit_all"])) {
        buttons.push(
          <EuiButton fill={true} size="s" onClick={() => navigate("/finance/order-values")}>
            {txt.get("finance.order_values.page_title")}{" "}
          </EuiButton>
        );
      }
      if (await AuthenticationHelper.hasPermission(["invoices#edit_all"])) {
        buttons.push(
          <EuiButton size="s" onClick={() => navigate("/finance/authorizations")}>
            {txt.get("finance.authorizations.page_title")}
          </EuiButton>
        );
      }
      if (await AuthenticationHelper.hasPermission(["finance#read_all"])) {
        buttons.push(
          <EuiButton size="s" onClick={() => navigate("/finance/declarations")}>
            {txt.get("finance.declarations.page_title")}
          </EuiButton>
        );
      }
      setButtonsToShow(buttons);
    };
    setInterfaceForPermissions();
    load();
  }, []);

  useEffect(() => {
    const currentPractitionerTab = UrlHelper.queryParam("referrals_practitioner_tab");

    if (currentPractitionerTab !== "") {
      setSelectedPractitionerTab((current: any) => currentPractitionerTab);
    } else {
      setSelectedPractitionerTab((current: any) =>
        mainPractitioners.length > 0 ? mainPractitioners[0].id?.toString().toLowerCase() : ""
      );
    }

    const currentPendingTab = UrlHelper.queryParam("referrals_pending_tab");

    if (currentPendingTab !== "") {
      setSelectedPendingTab((current: any) => currentPendingTab);
    } else {
      setSelectedPendingTab((current: any) => "internal");
    }
  }, [mainPractitioners]);

  const renderPendingTabs = () => {
    const tabs = ["internal", "external", "b2b"];

    return (
      <EuiTabs>
        {tabs.map((tabName: string) => (
          <EuiTab
            key={`tab-${tabName}`}
            onClick={() => setSelectedPendingTab(tabName)}
            isSelected={selectedPendingTab === tabName}
            append={
              <EuiNotificationBadge color={tabName === "check" ? "accent" : "subdued"}>
                {totalOrderCount(orderCounts, [
                  {
                    has_payment_info: [false],
                    needs_payment_info: [true],
                    status: paymentInfoExpectedStatusses,
                    organisation_ordering: tabName.toUpperCase(),
                  },
                ])}
              </EuiNotificationBadge>
            }
          >
            <span>{txt.get(`finance.order_values.${tabName}`)}</span>
          </EuiTab>
        ))}
      </EuiTabs>
    );
  };

  const renderOrderValuesTab = () =>
    selectedPendingTab ? (
      <EuiPanel key={`referral-${selectedPendingTab}`} grow={false}>
        <MMOrdersShortlist
          showPerPageOptions={true}
          filters={{
            hasPaymentInfo: false,
            status: paymentInfoExpectedStatusses,
            needsPaymentInfo: true,
            sortBy: "updated_at",
            sortOrder: "desc",
            ordering: selectedPendingTab.toUpperCase(),
            since: START_ORDER_VALUE_CONNECT,
          }}
          columns={["order_id", "client_code", "practitioner", "ordered_at", "last_update", "products"]}
          clickable={true}
          name={`order_values_${selectedPendingTab}`}
          localStoragePrefixForLimit="all_finance_shortlist"
          orderTab="costing"
        />
      </EuiPanel>
    ) : (
      <></>
    );

  return (
    <MMPage
      title={`${txt.get("finance.order_values.missing_values")} - ${txt.get("finance.order_values.after_connect")}`}
      hideTitle={true}
      topActions={buttonsToShow}
    >
      <EuiFlexItem>
        <EuiSpacer size="xl" />
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <EuiFlexGrid>
              <EuiFlexItem>
                <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                  <EuiIcon type="documentation" size="l" />
                  <EuiTitle>
                    <EuiText>
                      {txt.get("finance.order_values.missing_values")}
                      <span style={{ fontSize: "1rem" }}> ({txt.get("finance.order_values.after_connect")})</span>
                    </EuiText>
                  </EuiTitle>
                </div>
                <EuiSpacer />

                {renderPendingTabs()}

                <EuiSpacer />
                {renderOrderValuesTab()}
              </EuiFlexItem>
            </EuiFlexGrid>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </MMPage>
  );
}

export default MMOrderValues;
