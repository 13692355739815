import {
  EuiBasicTable,
  EuiButton,
  EuiFieldSearch,
  EuiFilterButton,
  EuiFilterGroup,
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiHealth,
  EuiHorizontalRule,
  EuiIcon,
  EuiLink,
  EuiLoadingSpinner,
  EuiPopover,
  EuiRadioGroup,
  EuiSpacer,
  EuiSuperSelect,
  EuiTableSelectionType,
  EuiText,
} from "@elastic/eui";
import { Fragment, useEffect, useState } from "react";

import { ApiResponse, ApiResponseStatus } from "api/api-helper";
import ConnectAPIHelper from "api/connect-api-helper";
import MMFiles from "components/layouts/files/files";
import MMCell from "components/layouts/table/cell";
import MMColumnFormatted from "components/layouts/table/column-formatted";
import DateHelper from "helpers/date-helper";
import StringHelper from "helpers/string-helper";
import txt from "helpers/text-helper";
import { columnString } from "hoc/helper-hooks";
import { useDispatch } from "react-redux";
import { toastAdd } from "store/components/toast/toast";
import { PERSON_EMPTY } from "store/data/adress/address";
import { formalName } from "store/data/personal-details/personal-details";
import { Referral } from "store/data/referral/referral";
import { Referrer } from "store/data/referrer/referrer";
import { useLocalStorage } from "store/local-storage";
import { useDebounce } from "use-debounce";

export const PAGE_SIZE_OPTIONS = [2, 10, 25, 50, 100]; //,0]; //0 would mean without limit
export const DEFAULT_PAGE_SIZE = 10;
export const SEARCH_DEBOUNCE_DELAY = 700;
export const DEFAULT_SORT_BY = "name";
export const DEFAULT_SORT_ORDER = "asc";

const AGB_VEKTIS_PERSON_PUBLIC_URL_BASE: string = "https://www.vektis.nl/agb-register/zorgverlener-";

const AGB_VEKTIS_ORGANISATION_PUBLIC_URL_BASE: string = "https://www.vektis.nl/agb-register/onderneming-";

function MMReferrersList() {
  const dispatch = useDispatch();
  const api = new ConnectAPIHelper();

  const [referrers, setReferrers] = useState<Referrer[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [limit, setLimit] = useLocalStorage("referrer_limit", DEFAULT_PAGE_SIZE);
  const [offset, setOffset] = useLocalStorage("referrer_offset", 0);
  const [total, setTotal] = useState(0);
  const [sortBy, setSortBy] = useLocalStorage("referrer_sort_by", DEFAULT_SORT_BY);
  const [sortOrder, setSortOrder] = useLocalStorage("referrer_sort_order", DEFAULT_SORT_ORDER);
  const [searchFieldValue, setSearchFieldValue] = useLocalStorage("referrer_search", "");
  const [search] = useDebounce(searchFieldValue, SEARCH_DEBOUNCE_DELAY);
  const [error, setError] = useState("");
  const [isActiveValue, setIsActiveValue] = useLocalStorage("referrer_only_abg", "_");
  const [isOnlyAgbSelected, setIsOnlyAgbSelected] = useLocalStorage("referrer_only_abg", "");

  const [isOnlyOrganisationAgbSelected, setIsOnlyOrganisationAgbSelected] = useLocalStorage(
    "referrer_only_organisation_agb",
    ""
  );

  const [isDuplicatesSelected, setIsDuplicatesSelected] = useLocalStorage("referrer_duplicates", "");

  const [selectedReferrers, setSelectedReferrers] = useState<Referrer[]>([]);
  const [selectedReferrerForMerge, setSelectedReferrerForMerge] = useState<number>();
  const [isMergeOpen, setIsMergeOpen] = useState<boolean>(false);

  const [selectedReferrerAgbId, setSelectedReferrerAgbId] = useState<number>();
  const [referrerAgb, setReferrerAgb] = useState<any>();
  const [selectedReferrerAgbOrganisation, setSelectedReferrerAgbOrganisation] = useState<string>();

  const [selectedReferrerReferralsId, setSelectedReferrerReferralsId] = useState<number>();
  const [referrerReferrals, setReferrerReferrals] = useState<Referral[] | string>();

  const loadReferrers = async () => {
    setIsLoading(true);

    let filters: any = {};
    if (isActiveValue && isActiveValue !== "_") {
      filters.is_active = isActiveValue === "yes";
    }

    if (isOnlyAgbSelected === true) {
      filters.has_agb = true;
    } else if (isOnlyAgbSelected === false) {
      filters.has_agb = false;
    }

    if (isDuplicatesSelected === true) {
      filters.duplicates = true;
    } else if (isDuplicatesSelected === false) {
      filters.duplicates = false;
    }
    if (isOnlyOrganisationAgbSelected === true) {
      filters.has_organisation_agb = true;
    } else if (isOnlyOrganisationAgbSelected === false) {
      filters.has_organisation_agb = false;
    }

    if (search) {
      filters.search = search;
    }

    const result = await api.getAdminReferrers(filters, limit, offset, referrerFieldToSortKey(sortBy), sortOrder);
    if (result.status === "OK") {
      setReferrers(result.result);
      setTotal(result.meta_data.result_set.total);
      setError("");
    } else {
      setReferrers([]);
      setTotal(0);
      setError(`${result.status} (${result.code}): ${result.message}`);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadReferrers();
  }, [
    sortOrder,
    sortBy,
    search,
    limit,
    offset,
    isActiveValue,
    isOnlyAgbSelected,
    isOnlyOrganisationAgbSelected,
    isDuplicatesSelected,
  ]);

  useEffect(() => {
    const handleReferrerAgb = async (selectedReferrerAgbId?: number) => {
      if (selectedReferrerAgbId) {
        const referrerAgbResult: ApiResponse = await api.getAdminReferrerAgb(selectedReferrerAgbId);
        if (referrerAgbResult.status === ApiResponseStatus.OK) {
          setReferrerAgb(referrerAgbResult.result);
          console.log("going to set reffer org?", referrerAgbResult.result.relations);
          if (referrerAgbResult.result.relations) {
            if (referrerAgbResult.result.relations.length === 0) {
              setSelectedReferrerAgbOrganisation(undefined);
            } else if (referrerAgbResult.result.relations.length === 1) {
              setSelectedReferrerAgbOrganisation(referrerAgbResult.result.relations[0].agb_code);
            } else {
              const selectedReferrer: Referrer | undefined = referrers.find(
                (referrer: Referrer) => referrer.id === selectedReferrerAgbId
              );
              if (selectedReferrer && selectedReferrer.organisation_agb_code) {
                const relationOrganisation: any = referrerAgbResult.result.relations.find(
                  (relation: any) => relation.agb_code === selectedReferrer.organisation_agb_code
                );
                if (relationOrganisation) {
                  setSelectedReferrerAgbOrganisation(relationOrganisation.agb_code);
                }
              } else {
                setSelectedReferrerAgbOrganisation(undefined);
              }
            }
          }
        } else {
          setReferrerAgb(referrerAgbResult.message);
          setSelectedReferrerAgbOrganisation(undefined);
        }
      } else {
        setReferrerAgb(undefined);
        setSelectedReferrerAgbOrganisation(undefined);
      }
    };
    handleReferrerAgb(selectedReferrerAgbId);
  }, [selectedReferrerAgbId]);

  useEffect(() => {
    const handleReferrerReferrals = async (selectedReferrerReferralsId?: number) => {
      setReferrerReferrals(undefined);
      if (selectedReferrerReferralsId) {
        const referrerReferralsResult: ApiResponse = await api.getReferrals(
          { referrer_id: selectedReferrerReferralsId },
          20
        );
        if (referrerReferralsResult.status === ApiResponseStatus.OK) {
          setReferrerReferrals(referrerReferralsResult.result);
        } else {
          setReferrerReferrals(referrerReferralsResult.message);
        }
      } else {
        setReferrerReferrals(undefined);
      }
    };
    handleReferrerReferrals(selectedReferrerReferralsId);
  }, [selectedReferrerReferralsId]);

  const handleMerge = async (referrers: Referrer[], mergeTo: number) => {
    setIsLoading(true);
    let ids: number[] = [];
    referrers.forEach((referrer: Referrer) => {
      if (referrer.id) ids.push(referrer.id);
    });

    const result: ApiResponse = await api.mergeReferrers(ids, mergeTo);
    if (result.status === ApiResponseStatus.OK) {
      loadReferrers();
    } else {
      dispatch(toastAdd(txt.get("admin.referrers.merge_failed"), result.message, "danger"));
    }
    setIsLoading(false);
  };

  const handleUpdateReferrerWithAgb = async (referrer: Referrer, agbInfo: any) => {
    setIsLoading(true);
    if (referrer.id) {
      let newReferrer: Referrer = {
        ...referrer,
        specialty: agbInfo.qualification
          ? agbInfo.qualification
          : agbInfo.care_type
            ? StringHelper.ucwords(agbInfo.care_type.replace("_", " ").toLowerCase())
            : "",
        person: { ...(referrer.person || PERSON_EMPTY), ...agbInfo.person },
      };

      if (selectedReferrerAgbOrganisation && selectedReferrerAgbOrganisation !== "_") {
        let organisation: any = agbInfo.relations
          ? agbInfo.relations.find((relation: any) => relation.agb_code === selectedReferrerAgbOrganisation)
          : undefined;
        if (organisation) {
          newReferrer.alternative_organisation = organisation.name;
          newReferrer.organisation_agb_code = organisation.agb_code;
        }
      }
      console.log("handleUpdateReferrerWithAgb", newReferrer);

      const updateReferrerResult: ApiResponse = await api.updateAdminReferrer(referrer.id, newReferrer);
      if (updateReferrerResult.status === ApiResponseStatus.OK) {
        const referrerResult = await api.getAdminReferrer(updateReferrerResult.result.referrer_id);
        if (referrerResult.status === ApiResponseStatus.OK) {
          setReferrers(
            referrers.map((referrer: Referrer) =>
              referrer.id === referrerResult.result.id ? referrerResult.result : referrer
            )
          );
        }
        setSelectedReferrerAgbId(undefined);
        setIsLoading(false);
      }
    }
  };
  const onSelectionChange = (selectedItems: Referrer[]) => {
    setSelectedReferrers(selectedItems || []);
  };

  const referrerSelection: EuiTableSelectionType<Referrer> = {
    onSelectionChange,
    // initialSelected: onlineUsers,
  };

  const resultCountInfo = () =>
    total === 0
      ? txt.uf("generic.found_no_x", txt.get("admin.referrers.page_title"))
      : txt.uf(
          "generic.showing_x_of_y_found_z",
          limit === 0 ? txt.get("generic.all") : `${offset + 1}-${Math.min(total, offset + limit)}`,
          total,
          txt.get("admin.referrers.page_title")
        ) + ".";

  const limitOffsetToPage = (limit: number, offset: number) => {
    //pages in EUI are zero based
    const page = limit > 0 ? Math.max(0, offset / limit) : 0;
    return page;
  };

  const pagination: any = {
    pageIndex: limitOffsetToPage(limit, offset),
    pageSize: limit,
    totalItemCount: total,
    pageSizeOptions: PAGE_SIZE_OPTIONS,
    showPerPageOptions: true,
  };

  const sorting: any = {
    sort: {
      field: sortBy,
      direction: sortOrder,
    },
    enableAllColumns: false,
    // readOnly: false,
  };

  const referrerFieldToSortKey = (field: string) => {
    switch (field) {
      default:
        return field;
    }
  };

  const onSearchChange = (event: any) => {
    setSearchFieldValue(event.target.value);
  };

  const onReferrersChange = ({ page = {} as any, sort = {} as any }) => {
    if (page.size) {
      const newLimit = page.size;
      const newOffset = Math.max(0, page.index * page.size);
      if (limit !== newLimit) setLimit(newLimit);
      if (offset !== newOffset) setOffset(newOffset);
    }
    if (sort.field) {
      setSortBy(sort.field);
      setSortOrder(sort.direction ?? "asc");
    }
  };

  const handleValueChange = async (type: string, value: string, referrer: Referrer) => {
    if (referrer.id) {
      const result: ApiResponse = await api.updateAdminReferrerFields(referrer.id, { [type]: value });
      if (result && result.status === ApiResponseStatus.OK) {
        const updatedReferrer: Referrer = result.result;
        const updatedReferrers: Referrer[] = referrers.map((referrer: Referrer) =>
          referrer.id === updatedReferrer.id ? updatedReferrer : referrer
        );
        setReferrers(updatedReferrers);
      }
      console.log("handleValueChange", type, value, referrer.id);
    }
  };

  const renderReferrals = (referrer: Referrer) =>
    referrer.id ? (
      <EuiPopover
        isOpen={!!referrer.id && referrer.id === selectedReferrerReferralsId}
        closePopover={() => {
          setSelectedReferrerReferralsId(undefined);
        }}
        button={
          <EuiIcon
            style={{ cursor: "pointer" }}
            onClick={() => {
              if (selectedReferrerReferralsId === undefined) {
                if (referrer.id) {
                  setSelectedReferrerReferralsId(referrer.id);
                }
              } else if (referrer.id === selectedReferrerReferralsId) {
                setSelectedReferrerReferralsId(undefined);
              }
            }}
            type="eye"
          />
        }
      >
        {!referrerReferrals ? (
          <EuiLoadingSpinner />
        ) : typeof referrerReferrals === "string" ? (
          <EuiText color="danger">{referrerReferrals}</EuiText>
        ) : referrerReferrals.length === 0 ? (
          <EuiText>{txt.get("admin.referrers.no_referrals")}</EuiText>
        ) : (
          <EuiFlexGrid
            style={{
              maxWidth: "120px",
              maxHeight: "500px",
              overflow: "auto",
            }}
          >
            <EuiFlexItem grow={true}>
              {referrerReferrals.map((referral: Referral, i: number) => (
                <Fragment key={`referral-${i}`}>
                  <EuiText size="s" style={{ fontWeight: "bold" }}>
                    {referral.referred_at
                      ? DateHelper.toDate(referral.referred_at)
                      : txt.get("admin.referrers.no_referral_date")}
                    {referral.orders && referral.orders.length > 0 ? (
                      <EuiText size="xs" color="subdued" style={{ float: "right" }}>
                        PN
                        {referral.orders[0].client_code}
                      </EuiText>
                    ) : (
                      <></>
                    )}
                  </EuiText>
                  <MMFiles files={referral.files} previewAll={true} />
                </Fragment>
              ))}
            </EuiFlexItem>
          </EuiFlexGrid>
        )}
      </EuiPopover>
    ) : (
      <></>
    );

  const columns = [
    {
      name: txt.get("admin.referrers.id"),
      field: "id",
      sortable: api.referrerIsSortableBy(referrerFieldToSortKey("id")),
      style: { minWidth: "60px" },
    },
    {
      name: txt.get("admin.referrers.name"),
      field: "person",
      sortable: api.referrerIsSortableBy(referrerFieldToSortKey("name")),
      render: (person: any, referrer: Referrer) =>
        person ? (
          <EuiLink href={`/admin/referrers/${referrer.id}`}>
            <MMColumnFormatted value={formalName(person)} noWrap={true} highlight={search} />
          </EuiLink>
        ) : (
          "-"
        ),
    },
    {
      name: txt.get("admin.referrers.agb_code"),
      field: "agb_code",
      sortable: api.referrerIsSortableBy(referrerFieldToSortKey("agb_code")),
      render: (agbCode: string, referrer: Referrer) => (
        <span style={{ whiteSpace: "nowrap" }}>
          {renderReferrerAgb(referrer)}
          <MMColumnFormatted
            value={columnString(agbCode)}
            noWrap={true}
            highlight={search}
            isEditable={true}
            onChange={(value: string) => handleValueChange("agb_code", value, referrer)}
          />
          {agbCode ? (
            <EuiLink href={`${AGB_VEKTIS_PERSON_PUBLIC_URL_BASE}${agbCode}`} external={true} target="_blank"></EuiLink>
          ) : (
            <></>
          )}
        </span>
      ),
    },
    {
      name: txt.get("referrers.specialty"),
      field: "specialty",
      sortable: api.referrerIsSortableBy(referrerFieldToSortKey("specialty")),
      render: (specialty: string, referrer: Referrer) => (
        <MMColumnFormatted
          value={columnString(specialty)}
          noWrap={true}
          highlight={search}
          isEditable={true}
          onChange={(value: string) => handleValueChange("specialty", value, referrer)}
        />
      ),
    },
    {
      name: txt.get("admin.organisations.name"),
      field: "alternative_organisation",
      sortable: api.referrerIsSortableBy(referrerFieldToSortKey("alternative_organisation")),
      render: (alternative_organisation: string, referrer: Referrer) => (
        <MMColumnFormatted
          value={columnString(referrer.organisation?.name || alternative_organisation)}
          noWrap={false}
          highlight={search}
          isEditable={true}
          onChange={(value: string) => handleValueChange("alternative_organisation", value, referrer)}
        />
      ),
    },
    {
      name: txt.get("admin.referrers.organisation_agb_code"),
      field: "organisation_agb_code",
      sortable: api.referrerIsSortableBy(referrerFieldToSortKey("organisation_agb_code")),
      render: (organisationAgbCode: string, referrer: Referrer) => (
        <span>
          <MMColumnFormatted
            value={columnString(organisationAgbCode)}
            noWrap={true}
            highlight={search}
            isEditable={true}
            onChange={(value: string) => handleValueChange("organisation_agb_code", value, referrer)}
          />{" "}
          {organisationAgbCode ? (
            <EuiLink
              href={`${AGB_VEKTIS_ORGANISATION_PUBLIC_URL_BASE}${organisationAgbCode}`}
              external={true}
              target="_blank"
            ></EuiLink>
          ) : (
            <></>
          )}
        </span>
      ),
    },
    {
      name: txt.get("referrals.page_title"),
      field: "referrals",
      render: (referrals: number[], referrer: Referrer) => (
        <MMCell
          text={
            !referrals || referrals.length === 0 ? (
              "-"
            ) : (
              <span style={{ whiteSpace: "nowrap" }}>
                {txt.get(
                  `admin.referrers.${referrals.length === 1 ? "x_referral" : "x_referrals"}`,
                  referrals.length + ""
                )}{" "}
                {referrals.length > 0 ? renderReferrals(referrer) : <></>}
              </span>
            )
          }
        />
      ),
    },
    {
      name: txt.get("admin.referrers.note"),
      field: "note",
      sortable: api.referrerIsSortableBy(referrerFieldToSortKey("note")),
      render: (note: string, referrer: Referrer) => (
        <MMColumnFormatted
          value={columnString(note)}
          noWrap={false}
          highlight={search}
          isEditable={true}
          onChange={(value: string) => handleValueChange("note", value, referrer)}
        />
      ),
    },

    {
      name: txt.get("generic.is_active"),
      sortable: api.referrerIsSortableBy(referrerFieldToSortKey("is_active")),

      type: "boolean",
      field: "is_active",
      render: (is_active: boolean) => (
        <EuiHealth color={is_active ? "success" : "danger"}>
          <MMColumnFormatted
            value={is_active ? txt.get("generic.yes") : txt.get("generic.no")}
            noWrap={true}
            highlight={search}
          />
        </EuiHealth>
      ),
    },
  ];

  const getRowProps = (referrer: any) => {
    const { id } = referrer;
    return {
      "data-id": `row-${id}`,
    };
  };

  const isActiveOptions = [
    {
      value: "_",
      dropDownDisplay: (
        <EuiHealth color="success" style={{ lineHeight: "inherit" }}>
          {txt.get("generic.active")}
        </EuiHealth>
      ),
      inputDisplay: (
        <EuiText color="subdued" size="xs">
          {txt.uf("generic.select_x", txt.get("generic.active_state"))}
        </EuiText>
      ),
    },
    {
      value: "yes",

      inputDisplay: (
        <EuiHealth textSize="xs" color="success" style={{ lineHeight: "inherit" }}>
          {txt.get("generic.active")}
        </EuiHealth>
      ),
    },
    {
      value: "no",
      inputDisplay: (
        <EuiHealth textSize="xs" color="danger" style={{ lineHeight: "inherit" }}>
          {txt.get("generic.inactive")}
        </EuiHealth>
      ),
    },
  ];
  const onIsActiveChange = (value: string) => {
    setIsActiveValue(value);
  };

  const renderReferrerAgb = (referrer: Referrer) =>
    referrer.agb_code ? (
      <EuiPopover
        isOpen={!!referrer.id && referrer.id === selectedReferrerAgbId}
        closePopover={() => {
          setSelectedReferrerAgbId(undefined);
        }}
        button={
          <EuiIcon
            style={{ cursor: "pointer" }}
            onClick={() => {
              if (selectedReferrerAgbId === undefined) {
                if (referrer.id) {
                  setSelectedReferrerAgbId(referrer.id);
                }
              } else if (referrer.id === selectedReferrerAgbId) {
                setSelectedReferrerAgbId(undefined);
              }
            }}
            type="userAvatar"
          />
        }
      >
        {!referrerAgb ? (
          <EuiLoadingSpinner />
        ) : typeof referrerAgb === "string" ? (
          <EuiText color="danger">{referrerAgb}</EuiText>
        ) : (
          <EuiFlexGrid>
            <EuiFlexItem>
              <EuiText>
                <EuiText size="s">{referrerAgb.full_name}</EuiText>
                <EuiText size="s">
                  {referrerAgb.qualification
                    ? referrerAgb.qualification
                    : referrerAgb.care_type
                      ? StringHelper.ucwords(referrerAgb.care_type.replace("_", " ").toLowerCase())
                      : ""}
                </EuiText>
              </EuiText>
            </EuiFlexItem>
            {referrerAgb.relations ? (
              <EuiFlexItem>
                <EuiFormRow display="rowCompressed">
                  <EuiRadioGroup
                    options={referrerAgb.relations
                      .map((relation: any) => ({
                        id: relation.agb_code || "",
                        label: `${relation.agb_code || "[no agb]"} | ${relation.name}`,
                      }))
                      .concat([
                        {
                          id: "_",
                          label: (
                            <EuiText size="s" color="gray">
                              {txt.get("admin.referrers.no_organisation_change")}
                            </EuiText>
                          ),
                        },
                      ])}
                    idSelected={selectedReferrerAgbOrganisation}
                    onChange={(id) => setSelectedReferrerAgbOrganisation(id)}
                  />
                </EuiFormRow>
              </EuiFlexItem>
            ) : (
              <></>
            )}
            <EuiFlexGroup justifyContent="flexEnd">
              <EuiFlexItem grow={false}>
                <EuiButton
                  disabled={isLoading}
                  size="s"
                  iconSide="right"
                  iconType="check"
                  fill={true}
                  onClick={() => {
                    handleUpdateReferrerWithAgb(referrer, referrerAgb);
                  }}
                >
                  {txt.get("admin.referrers.update_to_agb")}
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexGrid>
        )}
      </EuiPopover>
    ) : (
      <></>
    );

  const renderMergeButton = () => (
    <EuiPopover
      panelPaddingSize="l"
      closePopover={() => {
        setIsMergeOpen(false);
      }}
      isOpen={isMergeOpen}
      button={
        <EuiButton
          size="s"
          onClick={() => {
            if (!isMergeOpen) {
              setSelectedReferrerForMerge(undefined);
            }
            setIsMergeOpen(!isMergeOpen);
          }}
        >
          {txt.get("admin.referrers.merge")}...
        </EuiButton>
      }
    >
      <EuiFlexGrid>
        <EuiText style={{ fontWeight: "bold" }}>{txt.get("admin.referrers.merge_target")}:</EuiText>

        <EuiFormRow display="rowCompressed">
          <EuiRadioGroup
            // compressed={true}
            options={selectedReferrers.map((referrer: Referrer) => ({
              id: referrer.id?.toString() || "",
              label: `${referrer.id}: ${referrer.agb_code} | ${referrer.person ? formalName(referrer.person) : "-"} | ${referrer.specialty} | ${referrer.alternative_organisation}`,
            }))}
            idSelected={selectedReferrerForMerge?.toString()}
            onChange={(id) => setSelectedReferrerForMerge(isNaN(parseInt(id)) ? undefined : parseInt(id))}
            name={txt.get("admin.referrers.merge_target")}
          />
        </EuiFormRow>
        <EuiFlexGroup justifyContent="flexEnd">
          <EuiFlexItem grow={false}>
            <EuiButton
              size="s"
              iconSide="right"
              iconType="check"
              fill={true}
              disabled={selectedReferrers.length === 0 || !selectedReferrerForMerge}
              onClick={() => {
                if (selectedReferrerForMerge) {
                  setIsMergeOpen(false);
                  handleMerge(selectedReferrers, selectedReferrerForMerge);
                }
              }}
            >
              {txt.get("admin.referrers.merge")}
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexGrid>
    </EuiPopover>
  );

  return (
    <Fragment>
      <EuiFlexGroup alignItems="flexStart">
        <EuiFlexItem grow={false}>
          <EuiFieldSearch
            compressed={true}
            placeholder={txt.get("generic.search")}
            value={searchFieldValue}
            isLoading={isLoading}
            isClearable={!isLoading}
            contentEditable={!isLoading}
            onChange={(event: any) => onSearchChange(event)}
            aria-label={txt.get("generic.search")}
          />
        </EuiFlexItem>

        <EuiFlexItem grow={false}>
          <EuiSuperSelect
            compressed={true}
            style={{ width: "225px" }}
            options={isActiveOptions}
            valueOfSelected={isActiveValue}
            onChange={(value) => onIsActiveChange(value)}
          ></EuiSuperSelect>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiFormRow display="rowCompressed" label={""}>
            <EuiFilterGroup contentEditable={false} compressed={true} style={{ width: "200px" }}>
              <EuiFilterButton
                hasActiveFilters={isDuplicatesSelected === true}
                onClick={(e: any) => {
                  setIsDuplicatesSelected(isDuplicatesSelected === true ? "" : true);
                }}
              >
                {txt.get("admin.referrers.duplicates")}
              </EuiFilterButton>
              <EuiFilterButton
                hasActiveFilters={isDuplicatesSelected === false}
                onClick={(e: any) => {
                  setIsDuplicatesSelected(isDuplicatesSelected === false ? "" : false);
                }}
              >
                {txt.get("admin.referrers.no_duplicates")}
              </EuiFilterButton>
            </EuiFilterGroup>
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiFormRow display="rowCompressed" label={""}>
            <EuiFilterGroup contentEditable={false} compressed={true} style={{ width: "180px" }}>
              <EuiFilterButton
                hasActiveFilters={isOnlyAgbSelected === true}
                onClick={(e: any) => {
                  setIsOnlyAgbSelected(isOnlyAgbSelected === true ? "" : true);
                }}
              >
                {txt.get("admin.referrers.only_agb")}
              </EuiFilterButton>
              <EuiFilterButton
                hasActiveFilters={isOnlyAgbSelected === false}
                onClick={(e: any) => {
                  setIsOnlyAgbSelected(isOnlyAgbSelected === false ? "" : false);
                }}
              >
                {txt.get("admin.referrers.without_agb")}
              </EuiFilterButton>
            </EuiFilterGroup>
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiFormRow display="rowCompressed" label={""}>
            <EuiFilterGroup contentEditable={false} compressed={true} style={{ width: "220px" }}>
              <EuiFilterButton
                hasActiveFilters={isOnlyOrganisationAgbSelected === true}
                onClick={(e: any) => {
                  setIsOnlyOrganisationAgbSelected(isOnlyOrganisationAgbSelected === true ? "" : true);
                }}
              >
                {txt.get("admin.referrers.only_organisation_agb")}
              </EuiFilterButton>
              <EuiFilterButton
                hasActiveFilters={isOnlyOrganisationAgbSelected === false}
                onClick={(e: any) => {
                  setIsOnlyOrganisationAgbSelected(isOnlyOrganisationAgbSelected === false ? "" : false);
                }}
              >
                {txt.get("admin.referrers.without_organisation_agb")}
              </EuiFilterButton>
            </EuiFilterGroup>
          </EuiFormRow>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiFlexGroup alignItems="flexEnd">
        <EuiFlexItem>
          <EuiText textAlign="right" size="xs">
            {resultCountInfo()}
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size="s" />
      <EuiFlexGroup>{renderMergeButton()}</EuiFlexGroup>
      <EuiSpacer size="s" />
      <EuiHorizontalRule margin="none" style={{ height: 1 }} />
      <EuiBasicTable
        loading={isLoading}
        tableLayout="auto"
        itemId="id"
        items={referrers}
        columns={columns}
        pagination={pagination}
        sorting={sorting}
        rowProps={getRowProps}
        noItemsMessage={error ? error : txt.uf("generic.found_no_x", txt.get("admin.referrers.page_title"))}
        onChange={onReferrersChange}
        selection={referrerSelection}
      />
    </Fragment>
  );
}

export default MMReferrersList;
