import { EuiButton, EuiFlexGroup, EuiFlexItem } from "@elastic/eui";
import MMPage from "components/layouts/page/page";
import AuthenticationHelper from "helpers/authentication-helper";
import txt from "helpers/text-helper";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function MMScanning() {
  const navigate = useNavigate();

  const [buttons, setButtons] = useState<any[]>([]);

  useEffect(() => {
    const setInterfaceForPermissions = async () => {
      let buttons: any[] = [];
      if (await AuthenticationHelper.hasPermission(["scans#read_all", "scans#read_org"])) {
        buttons.push(
          <EuiButton iconType="crosshairs" onClick={() => navigate("/scanning/scans")}>
            {txt.uf("generic.view_x", txt.get("scanning.scans.page_title"))}
          </EuiButton>
        );
      }
      setButtons(buttons);
    };
    setInterfaceForPermissions();
  }, []);
  return (
    <MMPage title="Scanning">
      <EuiFlexGroup>
        {buttons.map((button: any, i: number) => (
          <EuiFlexItem key={`button-${i}`} grow={false}>
            {button}
          </EuiFlexItem>
        ))}
      </EuiFlexGroup>
    </MMPage>
  );
}

export default MMScanning;
