import env from "helpers/env-helper";
import ReactGA from "react-ga4";

export const trackInit = () => {
  const trackingIsOn = env("REACT_APP_GA_IS_ON") === "1";
  const trackingId = env("REACT_APP_GA");

  if (trackingId) {
    ReactGA.initialize(trackingId, {
      testMode: !trackingIsOn,
    });
    return true;
  } else {
    return false;
  }
};

export const trackView = (page: string) => {
  ReactGA.send({ hitType: "pageview", page });
};

export const trackEvent = (category: string, action: string, label?: string, value?: number) => {
  let eventOptions: any = {
    category,
    action,
  };

  if (label !== undefined) {
    eventOptions.label = label;
  }

  if (value !== undefined) {
    eventOptions.value = value;
  }

  ReactGA.event(eventOptions);
};
