import {
  EuiBasicTable,
  EuiButton,
  EuiButtonEmpty,
  EuiFlexGrid,
  EuiFlexGroup,
  EuiImage,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiRadio,
  EuiSpacer,
  EuiText,
  EuiTitle,
  useIsWithinMaxBreakpoint,
} from "@elastic/eui";
import ManoXAPIHelper from "api/manox-api-helper";
import txt from "helpers/text-helper";
import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toastAdd } from "store/components/toast/toast";
import { viewName } from "store/data/location/location";
import { Order } from "store/data/order/order";
import { Organisation } from "store/data/organisation/organisation";
import { fullName, PersonalDetails } from "store/data/personal-details/personal-details";
import { Hand, isScanAccepted, ScanSummary } from "store/data/scan/scan";
import { AssignedDeviceDetails } from "store/data/scan/scanner";
import { useLocalStorage } from "store/local-storage";
import MMOrderScan from "./order-scan";

export interface MMOrderScansProps {
  order: Order;
  currentClientCode?: number;
  isScanInstructionVisible?: boolean;
  isScansVisible?: boolean;
  closeAfterScan?: boolean;
  isScanButtonVisible?: boolean;
  handleAddScanWithSave?: Function;
  organisation?: Organisation;
  maxHeight?: string;
  isInstructionsVisibleChanged?: Function;
  assignedDevices?: AssignedDeviceDetails[];
  hands?: Hand[];
  personalDetails?: PersonalDetails | null;
}

function MMOrderScans(props: MMOrderScansProps) {
  const api = new ManoXAPIHelper();

  const dispatch = useDispatch();
  const isMobileScreen: boolean = useIsWithinMaxBreakpoint("xs");
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  // const lastEvent: Event | undefined = useSelector(eventLastGet);
  const [lastAssignedDevice, setLastAssignedDevice] = useLocalStorage("assigned_device", null);

  const [scans, setScans] = useState<ScanSummary[]>([]);
  const [isScanInstructionVisible, setIsScanInstructionVisible] = useState(props.isScanInstructionVisible);
  const [isScansVisible] = useState(props.isScansVisible);
  const [closeAfterScan] = useState(props.closeAfterScan);
  const [isLoading, setIsLoading] = useState(false);
  const [didReload, setDidReload] = useState(false);
  const [order] = useState(props.order);
  const [assignedDevices, setAssignedDevices] = useState(props.assignedDevices);
  const [canSendToDevice, setCanSendToDevice] = useState<boolean>(
    !!props.assignedDevices && props.assignedDevices.length > 0
  );
  const [selectedDevice, setSelectedDevice] = useState<number | null>(null);

  useEffect(() => {
    setLastAssignedDevice(selectedDevice);
  }, [selectedDevice]);
  useEffect(() => {
    setIsScanInstructionVisible(props.isScanInstructionVisible);
  }, [props.isScanInstructionVisible]);

  useEffect(() => {
    setAssignedDevices(props.assignedDevices);
    setCanSendToDevice(!!props.assignedDevices && props.assignedDevices.length > 0);

    if (props.assignedDevices && props.assignedDevices.length === 1) {
      setSelectedDevice(props.assignedDevices[0].device.device_id);
    } else if (props.assignedDevices && lastAssignedDevice) {
      setSelectedDevice(lastAssignedDevice);
    }
  }, [props.assignedDevices, lastAssignedDevice]);

  const navigateBack = () => {
    const lastVisited = localStorage.getItem("last_visited_orders_view");
    if (lastVisited === "orders") {
      navigate("/my-dashboard");
    } else if (lastVisited === "search") {
      navigate("/orders");
    } else {
      navigate("/my-dashboard");
    }
  };

  const loadScans = async () => {
    setIsLoading(true);
    let newScans: ScanSummary[] = [];

    //get scans of this order
    let orderResult: any = {};
    if (order && order.id) {
      orderResult = await api.getOrderScans(order.id);

      if (orderResult.result) {
        orderResult.result.forEach((scan: ScanSummary) => {
          if (isScanAccepted(scan)) {
            newScans.push(scan);
          }
        });
      }
    }

    //get scans of this client, and add them to the list, if not already in there
    if (props.currentClientCode) {
      const clientResult = await api.getClientScans(props.currentClientCode.toString());

      if (clientResult.result) {
        clientResult.result.forEach((scan: ScanSummary) => {
          if (
            scan.processable &&
            (!orderResult.result ||
              (orderResult.result &&
                orderResult.result.findIndex((currentScan: ScanSummary) => currentScan.scan_id === scan.scan_id) < 0))
          ) {
            console.log("adding client scan", scan.scan_id, scan.client_number);
            newScans.push(scan);
          }
        });
      }
    }

    setScans(newScans);
    setIsLoading(false);
    handleReload();
  };

  const handleReload = () => {
    setDidReload(true);
    setTimeout(() => {
      setDidReload(false);
    }, 1000);
  };

  useEffect(() => {
    if (isScansVisible) {
      loadScans();
    } else {
      setScans([]);
    }
  }, [isScansVisible, props.currentClientCode]);

  const handleAddScan = async () => {
    console.log("handleAddScan", props.handleAddScanWithSave);
    if (props.handleAddScanWithSave) {
      props.handleAddScanWithSave();
    } else {
      setIsScanInstructionVisible(true);
    }
  };

  const columns = () => [
    {
      name: "",
      field: "scan_id",
      truncateText: true,
      width: "100%",
      render: (scan_id: number, scan: ScanSummary) => (
        <MMOrderScan order={order} isLoading={isLoading || didReload} scan={scan} />
      ),
    },
  ];

  const handleSendToScanner = async () => {
    setIsLoading(true);
    if (!selectedDevice || !order || !order.id || !props.hands || props.hands.length === 0) {
      console.log("handleSendToScanner missing info", selectedDevice, order, props.hands);

      dispatch(toastAdd(txt.get("orders.order.scan_info_missing"), null, "danger"));

      return false;
    }

    const deleteResult = await api.deleteScanRequests(selectedDevice, order.id);

    if (deleteResult.status !== "OK") {
      dispatch(toastAdd(`${txt.get("orders.order.scan_delete_error")}: ${deleteResult.message}`, null, "danger"));
    }

    for (let i = 0; i < props.hands.length; i++) {
      const hand: string = props.hands[i].toLowerCase();
      const name: string = props.personalDetails ? fullName(props.personalDetails) : "";

      const result = await api.addScanRequest(selectedDevice, order.id, hand, name);
      if (result.status === "OK") {
        const device: AssignedDeviceDetails | null | undefined = assignedDevices
          ? assignedDevices?.find((details: AssignedDeviceDetails) => details.device.device_id === selectedDevice)
          : null;

        dispatch(
          toastAdd(
            txt.get(
              "orders.order.scan_sent",
              order.id,
              txt.uc(`scanning.scans.scan.hand.${hand}`),
              device ? device.device.device_serial : selectedDevice
            ),
            null,
            "success"
          )
        );
      } else {
        dispatch(toastAdd(`${txt.get("orders.order.scan_send_error")}: ${result.message}`, null, "danger"));
      }
      console.log("handleSendToScanner result", result);
    }
    setIsScanInstructionVisible(false);
    setIsLoading(false);
  };

  const getScans = () => {
    return scans;
  };

  const renderDevices = (assignedDevices: AssignedDeviceDetails[] = []) =>
    assignedDevices.map((details: AssignedDeviceDetails, i: number) => (
      <EuiRadio
        name="assigned-device"
        key={`details-${i}`}
        id={`details-${details.device.device_id}`}
        checked={details.device.device_id === selectedDevice}
        onChange={() => {
          setSelectedDevice(details.device.device_id);
        }}
        value={`${details.device.device_id}`}
        label={`${txt.get("scanning.scanners.name")} ${details.device.device_serial}${
          details.device.device_description
            ? `: ${details.device.device_description}`
            : details.location
              ? `: ${viewName(details.location)}`
              : details.user
                ? `: ${fullName(details.user)}`
                : ""
        }`}
      />
    ));

  return (
    <EuiFlexGrid gutterSize="none">
      {isScansVisible && scans.length > 0 ? (
        <EuiFlexGroup gutterSize="xs" direction="column">
          <EuiTitle size="xs">
            <EuiText color="grey">{txt.get("scanning.scans.page_title")}</EuiText>
          </EuiTitle>
          <EuiSpacer size="s" />

          <EuiText size="s">
            {txt.get(
              "orders.order.scan_explanation",
              txt.lo(
                `${
                  props.organisation && props.organisation.client_naming
                    ? props.organisation.client_naming
                    : "orders.external_client_reference_name.patient"
                }.articled`
              )
            )}
          </EuiText>
          <EuiSpacer size="s" />
          <EuiBasicTable
            className={didReload ? "scan-table mm-reloaded mm-reloadable" : "scan-table mm-reloadable"}
            style={!isMobileScreen && props.maxHeight ? { overflowX: "auto", maxHeight: props.maxHeight } : {}}
            itemId="id"
            items={getScans()}
            columns={columns()}
            noItemsMessage={txt.uf("generic.found_no_x", txt.get("scanning.scans.name"))}
          />
          <EuiSpacer size="s" />
        </EuiFlexGroup>
      ) : (
        <></>
      )}
      {props.isScanButtonVisible ? (
        <EuiButton isLoading={isLoading || didReload} onClick={handleAddScan} size="s" iconType="questionInCircle">
          {txt.get("orders.order.add_scan")}
        </EuiButton>
      ) : (
        <></>
      )}
      {isScanInstructionVisible && order && order.id ? (
        <EuiModal
          onClose={() => {
            setIsScanInstructionVisible(false);
          }}
        >
          <EuiModalHeader>
            <EuiTitle>
              <EuiText>{txt.get("orders.order.add_scan")}</EuiText>
            </EuiTitle>
          </EuiModalHeader>
          <EuiModalBody>
            <EuiText>
              {canSendToDevice
                ? txt.get("orders.order.scan_send_instructions")
                : txt.get("orders.order.scan_instructions")}
              <EuiSpacer size="s" />

              {txt.get("orders.order.scan_instructions_order_number")}
              <pre
                style={{
                  marginLeft: "10px",
                  padding: "0px",
                  display: "inline",
                }}
              >
                <span
                  style={{
                    backgroundColor: "#FF000010",
                    padding: "5px 5px 5px 10px",
                  }}
                >
                  ON
                </span>
                <span
                  style={{
                    padding: "5px 10px 5px 2.5px",
                    backgroundColor: "#FF000022",
                    color: "#36786A",
                  }}
                >
                  {order.id}
                </span>
              </pre>
            </EuiText>
            <EuiSpacer />
            {canSendToDevice ? (
              renderDevices(props.assignedDevices)
            ) : (
              <EuiImage
                caption={txt.get("orders.order.scan_instructions_screen")}
                alt="Scan Instructions"
                url={`/images/add-scan-on-${txt.lang()}.png`}
              />
            )}
            <EuiSpacer size="s" />
          </EuiModalBody>
          <EuiModalFooter>
            {canSendToDevice ? (
              <Fragment>
                <EuiButtonEmpty
                  onClick={() => {
                    if (props.isInstructionsVisibleChanged) {
                      props.isInstructionsVisibleChanged(false);
                    } else {
                      setIsScanInstructionVisible(false);
                    }
                  }}
                >
                  {txt.get("generic.cancel")}
                </EuiButtonEmpty>
                <EuiButton
                  isLoading={isLoading}
                  iconSide="right"
                  iconType="push"
                  onClick={() => {
                    handleSendToScanner();
                  }}
                  fill={true}
                >
                  {txt.get("orders.order.scan_send_now")}
                </EuiButton>
              </Fragment>
            ) : (
              <EuiButton
                onClick={() => {
                  if (props.isInstructionsVisibleChanged) {
                    props.isInstructionsVisibleChanged(false);
                  } else {
                    setIsScanInstructionVisible(false);
                  }

                  if (closeAfterScan) {
                    navigateBack();
                  }
                }}
                fill={true}
              >
                {txt.get("generic.close")}
              </EuiButton>
            )}
          </EuiModalFooter>
        </EuiModal>
      ) : (
        <></>
      )}
    </EuiFlexGrid>
  );
}

export default MMOrderScans;
