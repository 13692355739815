import { EuiFlexGroup, EuiFlexItem, EuiLoadingSpinner, EuiPanel, EuiText, useIsWithinBreakpoints } from "@elastic/eui";
import { OrbitControls } from "@react-three/drei";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import ManoXAPIHelper from "api/manox-api-helper";
import { useEffect, useLayoutEffect, useState } from "react";

/* work around no typescript - require the old fashioned way */
var modelLoader: any = require("three/examples/jsm/loaders/OBJLoader");
// var materialLoader: any = require('three/examples/jsm/loaders/MTLLoader');

export interface MMScan3DProps {
  model?: any;
  material?: any;
  texture?: any;
}

interface DollyProps {
  light?: any;
}

function MMScan3D(props: MMScan3DProps) {
  const [model3D, setModel3D] = useState<any>(null);
  // const [material3D,setMaterial3D] = useState<any>(null);
  const [modelScale, setModelScale] = useState(0);
  const [light, setLight] = useState<any>();

  const [isLoadingMaterial] = useState(false);
  const [isLoadingModel, setIsLoadingModel] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const isMobileScreen = useIsWithinBreakpoints(["xs"]);

  const api = new ManoXAPIHelper();

  useEffect(() => {
    if (props.model && !model3D) {
      const load3DModel = async () => {
        setIsLoadingModel(true);
        const modelBlob = await api.getAssetByUrl(props.model);
        const model = modelBlob ? await modelBlob.text() : null;
        const model3D = model ? await new modelLoader.OBJLoader().parse(model) : null;
        // console.log("loaded model3D", model3D);
        setModel3D(model3D);
        setIsLoadingModel(false);
        setIsLoading(false);
      };

      load3DModel();
    }
  }, []);

  useEffect(() => {
    if (model3D) {
      const fitModelToScale = () => {
        const geometry = model3D.children[0].geometry;
        geometry.computeBoundingSphere();
        const scale = 2.0 * (1.0 / model3D.children[0].geometry.boundingSphere.radius);
        setModelScale(scale);
      };

      fitModelToScale();
    }
  }, [model3D]);

  function Precompile() {
    const { gl, scene, camera } = useThree();
    useLayoutEffect(() => {
      gl.compile(scene, camera);
    }, []);
    return null;
  }

  function Dolly(props: DollyProps) {
    // This one makes the light move along the camera
    useFrame(({ clock, camera }) => {
      props.light.position.copy(camera.position);
    });
    return null;
  }

  const cameraSetup = () => {
    return { fov: 45 };
  };
  const lightingSetup = () => {
    return [
      <ambientLight key="ambient_light_1" color={0xffffff} intensity={1.0} />,
      <directionalLight key="directional_light_1" color={0x888888} intensity={1.0} />,
      <pointLight ref={setLight} key="point_light_1" position={[100, 100, 100]} />,
      light ? <Dolly key="dolly_light_1" light={light} /> : null,
    ];
  };

  const controlSetup = () => {
    return <OrbitControls key="controls_1" />;
  };

  const modelSetup = () => {
    return <primitive key="model_1" object={model3D} scale={modelScale} />;
  };

  return (
    <EuiPanel paddingSize="none">
      <EuiFlexGroup alignItems="center" justifyContent="center">
        <EuiFlexItem
          style={{
            width: "100%",
            height: isMobileScreen ? "200px" : "380px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {isLoading ? (
            <EuiLoadingSpinner />
          ) : (
            <Canvas camera={cameraSetup()}>
              <Precompile />
              {lightingSetup()}
              {controlSetup()}
              {/* { testSetup() } */}
              {modelSetup()}
            </Canvas>
          )}
          {isLoadingMaterial ? <EuiText size="s">Loading Material...</EuiText> : <></>}
          {isLoadingModel ? <EuiText size="s">Loading Model...</EuiText> : <></>}
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPanel>
  );
}

export default MMScan3D;
