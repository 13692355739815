import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiHeaderSectionItemButton,
  EuiImage,
  EuiLink,
  EuiPopover,
  EuiText,
  useGeneratedHtmlId,
} from "@elastic/eui";
import MessageBirdHelper from "helpers/messagebird-helper";

import txt from "helpers/text-helper";
import { useState } from "react";

function MMHeaderSupport() {
  const supportPopoverId = useGeneratedHtmlId({
    prefix: "headerUserPopover",
  });
  const [isOpen, setIsOpen] = useState(false);

  // const startSurfly = () => {
  //   surfly.session().startLeader();
  //   setIsOpen(false);
  // };

  const startMessagebird = () => {
    MessageBirdHelper.show();
    MessageBirdHelper.open();
    setIsOpen(false);
  };

  const onMenuButtonClick = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const button = (
    <EuiHeaderSectionItemButton
      aria-controls={supportPopoverId}
      aria-expanded={isOpen}
      aria-haspopup="true"
      aria-label={txt.get("support.name")}
      onClick={onMenuButtonClick}
      iconType="help"
    ></EuiHeaderSectionItemButton>
  );

  // if (!AuthenticationHelper.isAuthenticated()) {
  //   return null;
  // }
  return (
    <EuiPopover
      id={supportPopoverId}
      button={button}
      isOpen={isOpen}
      anchorPosition="downRight"
      closePopover={closeMenu}
      panelPaddingSize="none"
    >
      <div style={{ minWidth: 300, maxWidth: 320 }}>
        <EuiLink>
          <EuiFlexGroup
            gutterSize="xs"
            onClick={startMessagebird}
            alignItems="center"
            className="euiHeaderProfile"
            responsive={false}
          >
            <EuiFlexItem grow={false}>
              <EuiImage alt="Chat" src="/images/chat.png" />
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiText>{txt.html("support.messagebird")}</EuiText>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiLink>
        {/* <EuiLink>
          <EuiFlexGroup
            gutterSize="xs"
            onClick={startSurfly}
            alignItems="center"
            className="euiHeaderProfile"
            responsive={false}
          >
            <EuiFlexItem grow={false}>
              <EuiImage alt="Cobrowse" src="/images/cobrowse.png" />
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiText>{txt.html("support.surfly")}</EuiText>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiLink> */}
        <EuiLink href="https://api.whatsapp.com/send?phone=31702211626">
          <EuiFlexGroup gutterSize="xs" alignItems="center" className="euiHeaderProfile" responsive={false}>
            <EuiFlexItem grow={false}>
              <EuiImage alt="Whatsapp" src="/images/whatsapp.png" />
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiText style={{ whiteSpace: "nowrap" }}>{txt.html("support.whatsapp")}</EuiText>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiLink>

        <EuiFlexGroup gutterSize="xs" alignItems="center" className="euiHeaderProfile" responsive={false}>
          <EuiFlexItem grow={false}>{/* <EuiImage alt="Email" src="/images/email.png" /> */}</EuiFlexItem>
          <EuiFlexItem>
            <EuiLink href="mailto:hulp@manometric.nl">{txt.get("support.email")}: hulp@manometric.nl</EuiLink>
            <br />
            <EuiLink href="tel:+31702211626"> {txt.get("support.phone")}: 070 - 2211 - 626</EuiLink>
          </EuiFlexItem>
        </EuiFlexGroup>
      </div>
    </EuiPopover>
  );
}

export default MMHeaderSupport;
