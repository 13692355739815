import txt from "helpers/text-helper";
import { Insurer } from "../insurer/insurer";
import { OrderLine } from "../order/order";
import { ProductUse } from "../product/product";

export interface Contract {
  id: number;
  insurer_id: number;
  insurer?: Insurer;
  year?: number;
  base_article_code?: string;
  insurer_product_code?: string;
  product_declaration_code?: string;
  product_declaration_description?: string;
  product_declaration_additional_code1?: string;
  product_declaration_additional_code2?: string;
  invoice_line_description?: string;
  authorization?: boolean;
  product_declaration_value?: number;
  measuring_fee_first_code?: string;
  measuring_fee_first?: number;
  measuring_fee_consecutive_code?: string;
  measuring_fee_consecutive?: number;
  //   modified_warning:boolean,
  //   night_ruling:number,
  usage_term?: number;
  warranty_year_1?: number;
  warranty_year_2?: number;
  warranty_year_3?: number;
  hourly_code?: string;
  hourly_fee?: number;
}

//VEKTIS STANDARD CODES SOMETIMES USED BY INSURERS
export const AGPH1_FIRST: string = "011001";
export const AGPH1_REPEAT: string = "011002";
export const AGPH1_NIGHT: string = "011006";

export const isRepeat = (orderLine: OrderLine) => orderLine.order_type.toLowerCase().startsWith("repeat:");

export const isNight = (orderLine: OrderLine) =>
  orderLine.use && (orderLine.use as ProductUse).toString().toLowerCase() === ProductUse.Night.toString().toLowerCase();

export const isSpare = (orderLine: OrderLine) => false;

export const toAPGHForOrderLine = (orderLine?: OrderLine | any) => {
  if (!orderLine) return "";

  const options: string[] = orderLine.costing?.contract?.product_declaration_additional_code1
    ? orderLine.costing?.contract?.product_declaration_additional_code1.split(",")
    : [];

  if (isRepeat(orderLine) && options.includes(AGPH1_REPEAT)) {
    return AGPH1_REPEAT;
  }

  if (isNight(orderLine) && options.includes(AGPH1_NIGHT)) {
    return AGPH1_NIGHT;
  }

  if (options.includes(AGPH1_FIRST)) {
    return AGPH1_FIRST;
  }
  return "";
};
export const toAGPHDescription = (code: string) => {
  const translationKey: string = `admin.contracts.agph.agph_${code}`;
  const translation: string = txt.get(translationKey);
  return translation === `[${translationKey}]` ? code : translation;
};
