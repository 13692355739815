import {
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLoadingSpinner,
  EuiSpacer,
  useIsWithinBreakpoints,
} from "@elastic/eui";
import APIHelper from "api/api-helper";
import MMSectionTitle from "components/layouts/title/section-title";
import { snakeCase } from "lodash";
import { useEffect, useState } from "react";
import MMProductCard from "./product-card";

export type OnProductSelect = (product: any, selected: boolean) => void;

export interface MMProductCardsProps {
  products: any[] | null;
  selectable?: boolean;
  onSelect?: OnProductSelect;
  api?: APIHelper;
  columns?: 1 | 2 | 3 | 4;
}

function MMProductCards(props: MMProductCardsProps) {
  const isMobileScreen = useIsWithinBreakpoints(["xs"]);
  const isSmallScreen = useIsWithinBreakpoints(["s", "m", "l"]);
  const isMediumScreen = useIsWithinBreakpoints(["xl"]);

  const [columns, setColumns] = useState<1 | 2 | 3 | 4>(4);

  useEffect(() => {
    if (props.columns) {
      setColumns(props.columns);
    } else {
      setColumns(isMobileScreen ? 1 : isSmallScreen ? 2 : isMediumScreen ? 3 : 4);
    }
  }, [props.columns]);
  return props.products === null ? (
    <EuiLoadingSpinner />
  ) : (
    <EuiFlexGroup
      className="product-cards"
      direction="column"
      style={{
        maxWidth: "1000px",
        marginBottom: "25px",
        marginRight: "25px",
      }}
    >
      {props.products.map((category: any, i: number) => (
        <EuiFlexItem key={`c-${snakeCase(category.data_category)}`}>
          {i > 0 ? <EuiSpacer /> : <></>}
          <MMSectionTitle text={category.label} underline={false} />
          <EuiFlexGrid gutterSize="xl" columns={columns}>
            {category.options.map((product: any, j: number) => (
              <EuiFlexItem key={`c-${snakeCase(category.data_category)}-${i}-p-${snakeCase(product.label)}-${j}`}>
                <MMProductCard product={product} api={props.api} onSelect={props.onSelect} />
              </EuiFlexItem>
            ))}
          </EuiFlexGrid>
        </EuiFlexItem>
      ))}
    </EuiFlexGroup>
  );
}

export default MMProductCards;
