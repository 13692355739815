import { Styles, StyleSheet } from "@react-pdf/renderer";

const A4_SIZE_W: number = 210;
const A4_SIZE_H: number = 297;
const A4_MARGIN_W: number = 25;
const A4_MARGIN_H: number = 10;
const A4_PADDING_S: number = 2.5;
const A4_PADDING_L: number = 5;
const A4_BORDER_W: number = 0.25;
const A4_MM = "mm";

export const A4_B_W: string = A4_BORDER_W + A4_MM;
export const A4_P_S: string = A4_PADDING_S + A4_MM;
export const A4_P_L: string = A4_PADDING_L + A4_MM;
export const A4_W: string = A4_SIZE_W + A4_MM;
export const A4_H: string = A4_SIZE_H + A4_MM;
export const A4_M_H: string = A4_MARGIN_H + A4_MM;
export const A4_M_W: string = A4_MARGIN_W + A4_MM;
export const A4_W_1: string = ((A4_SIZE_W - A4_MARGIN_W * 2) * 1) / 12 + A4_MM;
export const A4_W_1_5: string = ((A4_SIZE_W - A4_MARGIN_W * 2) * 1.5) / 12 + A4_MM;
export const A4_W_2: string = ((A4_SIZE_W - A4_MARGIN_W * 2) * 2) / 12 + A4_MM;
export const A4_W_3: string = ((A4_SIZE_W - A4_MARGIN_W * 2) * 3) / 12 + A4_MM;
export const A4_W_4: string = ((A4_SIZE_W - A4_MARGIN_W * 2) * 4) / 12 + A4_MM;
export const A4_W_4_5: string = ((A4_SIZE_W - A4_MARGIN_W * 2) * 4.5) / 12 + A4_MM;
export const A4_W_5: string = ((A4_SIZE_W - A4_MARGIN_W * 2) * 5) / 12 + A4_MM;
export const A4_W_6: string = ((A4_SIZE_W - A4_MARGIN_W * 2) * 6) / 12 + A4_MM;
export const A4_W_7: string = ((A4_SIZE_W - A4_MARGIN_W * 2) * 7) / 12 + A4_MM;
export const A4_W_8: string = ((A4_SIZE_W - A4_MARGIN_W * 2) * 8) / 12 + A4_MM;
export const A4_W_9: string = ((A4_SIZE_W - A4_MARGIN_W * 2) * 9) / 12 + A4_MM;
export const A4_W_10: string = ((A4_SIZE_W - A4_MARGIN_W * 2) * 10) / 12 + A4_MM;
export const A4_W_11: string = ((A4_SIZE_W - A4_MARGIN_W * 2) * 11) / 12 + A4_MM;
export const A4_W_12: string = ((A4_SIZE_W - A4_MARGIN_W * 2) * 12) / 12 + A4_MM;
export const A4_H_0_5: string = ((A4_SIZE_H - A4_MARGIN_H * 2) * 0.5) / 24 + A4_MM;
export const A4_H_1: string = ((A4_SIZE_H - A4_MARGIN_H * 2) * 1) / 24 + A4_MM;
export const A4_H_1_5: string = ((A4_SIZE_H - A4_MARGIN_H * 2) * 1.5) / 24 + A4_MM;
export const A4_H_2: string = ((A4_SIZE_H - A4_MARGIN_H * 2) * 2) / 24 + A4_MM;
export const A4_H_3: string = ((A4_SIZE_H - A4_MARGIN_H * 2) * 3) / 24 + A4_MM;
export const A4_H_4: string = ((A4_SIZE_H - A4_MARGIN_H * 2) * 4) / 24 + A4_MM;
export const A4_H_5: string = ((A4_SIZE_H - A4_MARGIN_H * 2) * 5) / 24 + A4_MM;
export const A4_H_6: string = ((A4_SIZE_H - A4_MARGIN_H * 2) * 6) / 24 + A4_MM;
export const A4_H_7: string = ((A4_SIZE_H - A4_MARGIN_H * 2) * 7) / 24 + A4_MM;
export const A4_H_8: string = ((A4_SIZE_H - A4_MARGIN_H * 2) * 8) / 24 + A4_MM;
export const A4_H_9: string = ((A4_SIZE_H - A4_MARGIN_H * 2) * 9) / 24 + A4_MM;
export const A4_H_10: string = ((A4_SIZE_H - A4_MARGIN_H * 2) * 10) / 24 + A4_MM;
export const A4_H_11: string = ((A4_SIZE_H - A4_MARGIN_H * 2) * 11) / 24 + A4_MM;
export const A4_H_12: string = ((A4_SIZE_H - A4_MARGIN_H * 2) * 12) / 24 + A4_MM;
export const A4_H_13: string = ((A4_SIZE_H - A4_MARGIN_H * 2) * 13) / 24 + A4_MM;
export const A4_H_14: string = ((A4_SIZE_H - A4_MARGIN_H * 2) * 14) / 24 + A4_MM;
export const A4_H_15: string = ((A4_SIZE_H - A4_MARGIN_H * 2) * 15) / 24 + A4_MM;
export const A4_H_16: string = ((A4_SIZE_H - A4_MARGIN_H * 2) * 16) / 24 + A4_MM;
export const A4_H_17: string = ((A4_SIZE_H - A4_MARGIN_H * 2) * 17) / 24 + A4_MM;
export const A4_H_18: string = ((A4_SIZE_H - A4_MARGIN_H * 2) * 18) / 24 + A4_MM;
export const A4_H_19: string = ((A4_SIZE_H - A4_MARGIN_H * 2) * 19) / 24 + A4_MM;
export const A4_H_20: string = ((A4_SIZE_H - A4_MARGIN_H * 2) * 20) / 24 + A4_MM;
export const A4_H_21: string = ((A4_SIZE_H - A4_MARGIN_H * 2) * 21) / 24 + A4_MM;
export const A4_H_22: string = ((A4_SIZE_H - A4_MARGIN_H * 2) * 22) / 24 + A4_MM;
export const A4_H_23: string = ((A4_SIZE_H - A4_MARGIN_H * 2) * 23) / 24 + A4_MM;
export const A4_H_24: string = ((A4_SIZE_H - A4_MARGIN_H * 2) * 24) / 24 + A4_MM;

export const A4_W_1_P: string = ((A4_SIZE_W - A4_PADDING_S * 2 - A4_MARGIN_W * 2) * 1) / 12 + A4_MM;
export const A4_W_2_P: string = ((A4_SIZE_W - A4_PADDING_S * 2 - A4_MARGIN_W * 2) * 2) / 12 + A4_MM;
export const A4_W_3_P: string = ((A4_SIZE_W - A4_PADDING_S * 2 - A4_MARGIN_W * 2) * 3) / 12 + A4_MM;
export const A4_W_4_P: string = ((A4_SIZE_W - A4_PADDING_S * 2 - A4_MARGIN_W * 2) * 4) / 12 + A4_MM;
export const A4_W_5_P: string = ((A4_SIZE_W - A4_PADDING_S * 2 - A4_MARGIN_W * 2) * 5) / 12 + A4_MM;
export const A4_W_6_P: string = ((A4_SIZE_W - A4_PADDING_S * 2 - A4_MARGIN_W * 2) * 6) / 12 + A4_MM;
export const A4_W_7_P: string = ((A4_SIZE_W - A4_PADDING_S * 2 - A4_MARGIN_W * 2) * 7) / 12 + A4_MM;
export const A4_W_8_P: string = ((A4_SIZE_W - A4_PADDING_S * 2 - A4_MARGIN_W * 2) * 8) / 12 + A4_MM;
export const A4_W_9_P: string = ((A4_SIZE_W - A4_PADDING_S * 2 - A4_MARGIN_W * 2) * 9) / 12 + A4_MM;
export const A4_W_10_P: string = ((A4_SIZE_W - A4_PADDING_S * 2 - A4_MARGIN_W * 2) * 10) / 12 + A4_MM;
export const A4_W_11_P: string = ((A4_SIZE_W - A4_PADDING_S * 2 - A4_MARGIN_W * 2) * 11) / 12 + A4_MM;
export const A4_W_12_P: string = ((A4_SIZE_W - A4_PADDING_S * 2 - A4_MARGIN_W * 2) * 12) / 12 + A4_MM;
export const A4_H_1_P: string = ((A4_SIZE_H - A4_PADDING_S * 2 - A4_MARGIN_H * 2) * 1) / 24 + A4_MM;
export const A4_H_2_P: string = ((A4_SIZE_H - A4_PADDING_S * 2 - A4_MARGIN_H * 2) * 2) / 24 + A4_MM;
export const A4_H_3_P: string = ((A4_SIZE_H - A4_PADDING_S * 2 - A4_MARGIN_H * 2) * 3) / 24 + A4_MM;
export const A4_H_4_P: string = ((A4_SIZE_H - A4_PADDING_S * 2 - A4_MARGIN_H * 2) * 4) / 24 + A4_MM;
export const A4_H_5_P: string = ((A4_SIZE_H - A4_PADDING_S * 2 - A4_MARGIN_H * 2) * 5) / 24 + A4_MM;
export const A4_H_6_P: string = ((A4_SIZE_H - A4_PADDING_S * 2 - A4_MARGIN_H * 2) * 6) / 24 + A4_MM;
export const A4_H_7_P: string = ((A4_SIZE_H - A4_PADDING_S * 2 - A4_MARGIN_H * 2) * 7) / 24 + A4_MM;
export const A4_H_8_P: string = ((A4_SIZE_H - A4_PADDING_S * 2 - A4_MARGIN_H * 2) * 8) / 24 + A4_MM;
export const A4_H_9_P: string = ((A4_SIZE_H - A4_PADDING_S * 2 - A4_MARGIN_H * 2) * 9) / 24 + A4_MM;
export const A4_H_10_P: string = ((A4_SIZE_H - A4_PADDING_S * 2 - A4_MARGIN_H * 2) * 10) / 24 + A4_MM;
export const A4_H_11_P: string = ((A4_SIZE_H - A4_PADDING_S * 2 - A4_MARGIN_H * 2) * 11) / 24 + A4_MM;
export const A4_H_12_P: string = ((A4_SIZE_H - A4_PADDING_S * 2 - A4_MARGIN_H * 2) * 12) / 24 + A4_MM;
export const A4_H_13_P: string = ((A4_SIZE_H - A4_PADDING_S * 2 - A4_MARGIN_H * 2) * 13) / 24 + A4_MM;
export const A4_H_14_P: string = ((A4_SIZE_H - A4_PADDING_S * 2 - A4_MARGIN_H * 2) * 14) / 24 + A4_MM;
export const A4_H_15_P: string = ((A4_SIZE_H - A4_PADDING_S * 2 - A4_MARGIN_H * 2) * 15) / 24 + A4_MM;
export const A4_H_16_P: string = ((A4_SIZE_H - A4_PADDING_S * 2 - A4_MARGIN_H * 2) * 16) / 24 + A4_MM;
export const A4_H_17_P: string = ((A4_SIZE_H - A4_PADDING_S * 2 - A4_MARGIN_H * 2) * 17) / 24 + A4_MM;
export const A4_H_18_P: string = ((A4_SIZE_H - A4_PADDING_S * 2 - A4_MARGIN_H * 2) * 18) / 24 + A4_MM;
export const A4_H_19_P: string = ((A4_SIZE_H - A4_PADDING_S * 2 - A4_MARGIN_H * 2) * 19) / 24 + A4_MM;
export const A4_H_20_P: string = ((A4_SIZE_H - A4_PADDING_S * 2 - A4_MARGIN_H * 2) * 20) / 24 + A4_MM;
export const A4_H_21_P: string = ((A4_SIZE_H - A4_PADDING_S * 2 - A4_MARGIN_H * 2) * 21) / 24 + A4_MM;
export const A4_H_22_P: string = ((A4_SIZE_H - A4_PADDING_S * 2 - A4_MARGIN_H * 2) * 22) / 24 + A4_MM;
export const A4_H_23_P: string = ((A4_SIZE_H - A4_PADDING_S * 2 - A4_MARGIN_H * 2) * 23) / 24 + A4_MM;
export const A4_H_24_P: string = ((A4_SIZE_H - A4_PADDING_S * 2 - A4_MARGIN_H * 2) * 24) / 24 + A4_MM;

const baseStyles: Styles = {
  spacer: {
    height: A4_H_1,
    width: A4_W_12,
  },
  spacerXS: {
    height: A4_H_0_5,
    width: A4_W_12,
  },
  header: {
    left: "0pt",
    top: "0pt",
    position: "absolute",
    height: `${A4_H_1_5}`,
    margin: `${A4_M_H} ${A4_M_W}`,
    // backgroundColor: "#f0f000",
  },
  table: {},
  tableHeader: {
    borderBottomWidth: A4_B_W,
    borderColor: "#c0c0c0",
  },
  tableFooter: {
    // borderBottomWidth: A4_B_W,
    // borderColor: "#5c5c5c",
  },
  tableBetweenRow: {
    borderBottomWidth: A4_B_W,
    // borderColor: "#777777",
  },
  tableRow: {
    borderBottomWidth: A4_B_W,
    borderColor: "#c0c0c0",
  },
  page: {
    padding: `${A4_H_3} ${A4_M_W} ${A4_H_3}`,
    // backgroundColor: "#f000F0",
  },
  footer: {
    margin: `${A4_M_H} ${A4_M_W}`,
    left: "0pt",
    bottom: "0pt",
    position: "absolute",
    height: `${A4_H_1_5}`,
  },
  columns: {
    width: A4_W_12_P,
    flexDirection: "row",
  },
  columns_np: {
    width: A4_W_12,
    flexDirection: "row",
  },
  section: {
    flexGrow: 1,
    padding: A4_P_S,
  },
  section_1: {
    padding: A4_P_S,
    width: A4_W_1_P,
  },
  section_1_5: {
    padding: `${A4_P_S} 0pt`,
    width: A4_W_1_5,
  },
  section_4_5: {
    padding: `${A4_P_S} 0pt`,
    width: A4_W_4_5,
  },
  section_2: {
    padding: A4_P_S,
    width: A4_W_2_P,
  },
  section_2_np: {
    padding: `${A4_P_S} 0pt`,
    width: A4_W_2_P,
  },
  section_3: {
    padding: A4_P_S,
    width: A4_W_3_P,
  },
  section_4: {
    padding: A4_P_S,
    width: A4_W_4_P,
  },
  section_5: {
    padding: A4_P_S,
    width: A4_W_5_P,
  },
  section_6: {
    padding: A4_P_S,
    width: A4_W_6_P,
  },
  section_7: {
    flexGrow: 1,
    padding: A4_P_S,
    width: A4_W_7_P,
  },
  section_8: {
    padding: A4_P_S,
    width: A4_W_8_P,
  },
  section_9: {
    padding: A4_P_S,
    width: A4_W_9_P,
  },
  section_10: {
    padding: A4_P_S,
    width: A4_W_10_P,
  },
  section_11: {
    padding: A4_P_S,
    width: A4_W_11_P,
  },
  section_12: {
    padding: A4_P_S,
    width: A4_W_12_P,
  },
  font: {
    fontFamily: "futura-pt",
    fontSize: "10pt",
  },
  title: {
    fontWeight: 600,
    fontSize: "14pt",
    lineHeight: "10pt",
  },
  subtitle: {
    fontWeight: 600,
    fontSize: "10pt",
  },
  small: {
    fontSize: "9pt",
  },
  soft: {
    color: "#777777",
  },
  logo: {
    width: "120pt",
    height: "18pt",
    objectFit: "contain",
  },
  logoFooter: {
    width: "80pt",
    height: "12pt",
    objectFit: "contain",
  },
  right: {
    textAlign: "right",
  },
  addressPaneHolder: {
    height: A4_H_5,
    width: A4_W_10,
    padding: A4_P_S,
    justifyContent: "center",
    // borderWidth: 1,
  },
  addressPane: {
    // flexGrow: 1,
  },
  p: {
    padding: `${A4_P_S} 0pt`,
  },
};

export const style = (customStyles?: Styles) => {
  return StyleSheet.create({ ...baseStyles, ...(customStyles || {}) });
};
