import txt from "./text-helper";

const productLabelFormatter = (title: string, subtitle: string) => {
  return subtitle ? `${title}, ${subtitle}` : `${title}`;
};

export const productLabelFromOrderLineProduct = (product: any) => {
  const subtitle = product?.subtitle ?? "";
  const title = product?.title ?? "";

  return productLabelFormatter(title, subtitle);
};

export const productLabelFromProductField = (productField: any) => {
  const subtitle = productField?.product_subtitle ?? "";
  const title = productField?.product_title ?? "";

  return productLabelFormatter(title, subtitle);
};

export const productLabelFromTranslation = (translation?: any) => {
  const subtitle = translation?.subtitle?.[txt.lang()] ?? "";
  const title = translation?.title?.[txt.lang()] ?? "";

  return productLabelFormatter(title, subtitle);
};

export const productShortLabelFromTranslation = (translation?: any, maxChars: number = 13) => {
  const full = productLabelFromTranslation(translation);
  return full.length < maxChars ? full : full.substring(0, maxChars) + "...";
};

export const productVariantDescription = (productSelection: any, translation?: any) => {
  // Its size description and its color description.
  return (
    `${productSelection.size && productSelection.size !== "n.v.t." ? " " + productSelection.size : ""}` +
    `${
      translation && translation.color && translation.color[txt.lang()] && translation.color[txt.lang()] !== "n.v.t."
        ? " " + translation.color[txt.lang()]
        : productSelection.color && productSelection.color !== "n.v.t."
          ? " " + productSelection.color
          : ""
    }`
  );
};

export const productDescription = (productSelection: any, translation?: any) => {
  return (
    `${translation?.name?.[txt.lang()] ?? productSelection.type}` +
    `${productVariantDescription(productSelection, translation)}`
  );
};

export const productShortDescription = (productSelection: any, translation?: any, maxChars: number = 13) => {
  const description = productDescription(productSelection, translation);
  return description.length < maxChars ? description : description.substring(0, maxChars) + "...";
};

export const productTinyDescription = (productSelection: any, translation?: any) => {
  return `${
    translation && translation.name && translation.name[txt.lang()]
      ? translation.name[txt.lang()]
      : productSelection.type
  }`;
};
