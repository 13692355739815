import { EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiSuperSelect, EuiText, useIsWithinBreakpoints } from "@elastic/eui";
import DateHelper from "helpers/date-helper";
import txt from "helpers/text-helper";
import { Fragment, useEffect, useState } from "react";
import { MMOrderLineFieldProps, MMOrderLineProductInput } from "../order-line-entry";

const toSelectedClientProductionOption = (selection: any, clientProductions: any) => {
  let result: any;

  if (selection && clientProductions) {
    result = clientProductions.find((clientProduction: any) => clientProduction.ref === selection.replacementFor);
  }
  if (result) {
    result = result.ref;
  }

  return result || "_";
};

const isShownForOrderType = (selection?: MMOrderLineProductInput, orderTypes?: any[]) => {
  if (!selection || !selection.orderType) return false;
  const orderType: any = orderTypes?.find((type: any) => type.name === selection.orderType);
  console.log("isShownForOrderType", selection, orderTypes, "orderType", orderType);

  if (!orderType) return false;
  return orderType.replacement_for_required;
};

function MMOrderReplacementFor(props: MMOrderLineFieldProps) {
  const isMobile: boolean = useIsWithinBreakpoints(["xs", "s"]);

  const [clientProductions] = useState<any[]>(props.clientProductions || []);
  const [clientProductionOptions, setClientProductionOptions] = useState<any[]>([]);
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const [isLoading] = useState<boolean>(false);
  const [selectedClientProduction, setSelectedClientProduction] = useState<any>(
    toSelectedClientProductionOption(props.selection, props.clientProductions)
  );

  useEffect(() => {
    setIsVisible(isShownForOrderType(props.selection, props.orderTypes));
  }, [props.selection, props.orderTypes]);

  useEffect(() => {
    setSelectedClientProduction(toSelectedClientProductionOption(props.selection, clientProductions));
  }, [props.selection, clientProductions]);

  const onClientProductionChange = (clientProduction: any) => {
    // console.log("onClientProductionChange", clientProduction, !!props.onChange);
    if (props.onChange) {
      props.onChange({
        ...(props.selection || {}),
        replacementFor: clientProduction,
      } as MMOrderLineProductInput);
    } else {
      setSelectedClientProduction(clientProduction);
    }
  };

  useEffect(() => {
    let result: any[] = [];
    const noSelection = [
      {
        value: "_",
        inputDisplay: txt.get("orders.order.replaces_product"),
      },
      {
        value: "unknown",
        inputDisplay: txt.get("generic.unknown"),
        dropdownDisplay: (
          <Fragment>
            <strong>{txt.get("generic.unknown")}</strong>
          </Fragment>
        ),
      },
    ];
    // console.log("clientProductions", clientProductions);
    result = noSelection.concat(
      !clientProductions
        ? []
        : clientProductions.map((production) => {
            return {
              value: production.ref,
              inputDisplay: production.code,
              dropdownDisplay: (
                <Fragment>
                  <EuiText size="s" style={{ fontWeight: "bold" }}>
                    {DateHelper.toDate(production.order_date)}
                    <EuiText size="xs" color="subdued" style={{ float: "right" }}>
                      {txt.get("orders.order.client_code")}: PN
                      {production.client}
                    </EuiText>
                  </EuiText>
                  <EuiText size="s" color="subdued">
                    <p style={{ whiteSpace: "nowrap" }}>
                      {production.code}
                      <br />
                      {production.order_type}
                      <br />
                      {production.technician} {production.location}
                    </p>
                  </EuiText>
                </Fragment>
              ),
            };
          })
    );

    setClientProductionOptions(result);
  }, [clientProductions]);

  return isVisible ? (
    <EuiFlexGroup gutterSize="s">
      <EuiFlexItem>
        <EuiFormRow
          className="product-input"
          display="rowCompressed"
          label={props.showTitle ? txt.get("orders.order.replaces_product") : ""}
        >
          <EuiSuperSelect
            className={selectedClientProduction ? `option-selected` : ""}
            style={isMobile ? {} : {}}
            data-testid="select-replacement-for"
            isLoading={isLoading}
            compressed={true}
            color="accent"
            fullWidth
            hasDividers={true}
            name={txt.get("orders.order.replaces_product")}
            placeholder={txt.get("orders.order.replaces_product")}
            options={clientProductionOptions}
            valueOfSelected={selectedClientProduction}
            onChange={(value) => onClientProductionChange(value)}
          />
        </EuiFormRow>
      </EuiFlexItem>
    </EuiFlexGroup>
  ) : (
    <></>
  );
}

export default MMOrderReplacementFor;
