import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiImage,
  EuiLoadingSpinner,
  EuiPanel,
  EuiProgress,
  EuiSpacer,
} from "@elastic/eui";
import ConnectAPIHelper from "api/connect-api-helper";
import MMPage from "components/layouts/page/page";
import env from "helpers/env-helper";
import FileUploadHelper from "helpers/file-upload-helper";
import { selectableProductOptions } from "helpers/product-helper";
import txt from "helpers/text-helper";
import { Fragment, useEffect, useState } from "react";
import { BigPlayButton, Player } from "video-react";
import MMProductCards from "./product-cards";

// const FILE_PORTFOLIO_THUMB = env("REACT_APP_FILE_PORTFOLIO_THUMB");
const FILE_PORTFOLIO = env("REACT_APP_FILE_PORTFOLIO");
const FILE_PORTFOLIO_DE = env("REACT_APP_FILE_PORTFOLIO_DE");
// const FILE_PRICES = env("REACT_APP_FILE_PRICES");
const FILE_VIDEO_MEASUREMENTS = env("REACT_APP_FILE_VIDEO_MEASUREMENTS");
const FILE_VIDEO_ADJUSTMENTS = env("REACT_APP_FILE_VIDEO_ADJUSTMENTS");

function MMProducts() {
  const [isDownloading, setIsDownloading] = useState(false);
  const [videoMeasurements, setVideoMeasurements] = useState("");
  const [videoAdjustments, setVideoAdjustments] = useState("");

  /**
   * Commenting out price files - can introduce again with B2B updates
   */
  // const [filePrices, setFilePrices] = useState("");

  const api: ConnectAPIHelper = new ConnectAPIHelper();
  const lang: string = txt.lang();

  const [products, setProducts] = useState<any[] | null>(null);

  const fileUploadHelper = new FileUploadHelper();

  useEffect(() => {
    const loadVideos = async () => {
      setVideoMeasurements(await fileUploadHelper.downloadFileUrl(FILE_VIDEO_MEASUREMENTS));
      setVideoAdjustments(await fileUploadHelper.downloadFileUrl(FILE_VIDEO_ADJUSTMENTS));
    };
    const loadProducts = async () => {
      const products: any[] = await api.getProducts();
      setProducts(selectableProductOptions(products || [], lang));
    };
    loadVideos();
    loadProducts();
  }, []);

  const triggerDownload = async (url: string) => {
    let filename: any = url.split("/");
    filename = filename.length > 0 ? filename[filename.length - 1] : "download";
    setIsDownloading(true);
    await fileUploadHelper.triggerDownloadFileUrl(url, filename);
    setIsDownloading(false);
  };

  return (
    <MMPage title={txt.get("products.portfolio.page_title")}>
      {isDownloading ? (
        <EuiProgress size="xs" color="accent" />
      ) : (
        <Fragment>
          <EuiSpacer size="xs" style={{ height: "2px" }} />
        </Fragment>
      )}
      <EuiSpacer size="xs" />
      <EuiFlexGroup style={isDownloading ? { cursor: "wait" } : {}}>
        <EuiFlexItem grow={4} style={{ alignSelf: "flex-start" }}>
          <MMProductCards api={api} products={products} />
        </EuiFlexItem>
        <EuiFlexItem grow={1}>
          <EuiSpacer size="xxl" />
          <EuiSpacer size="xl" />
          <EuiFlexGroup direction="column">
            <EuiFlexItem grow={false}>
              <EuiFlexGroup>
                <EuiFlexItem grow={1} style={{ minWidth: "275px", maxWidth: "325px" }}>
                  <EuiPanel>
                    <EuiImage
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        triggerDownload(FILE_PORTFOLIO);
                      }}
                      size={"xl"}
                      alt="fingersplints portfolio"
                      src="/images/fingersplints_3.0.png"
                    />

                    <EuiSpacer size="s" />
                    <EuiFlexGroup justifyContent="spaceBetween" direction="column" gutterSize="s">
                      <EuiButton
                        fill={false}
                        iconSide="right"
                        iconType={"download"}
                        onClick={() => {
                          triggerDownload(FILE_PORTFOLIO);
                        }}
                      >
                        {txt.get("generic.download")} (EN)
                      </EuiButton>
                      <EuiButton
                        fill={false}
                        iconSide="right"
                        iconType={"download"}
                        onClick={() => {
                          triggerDownload(FILE_PORTFOLIO_DE);
                        }}
                      >
                        {txt.get("generic.download")} (DE)
                      </EuiButton>
                    </EuiFlexGroup>
                  </EuiPanel>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiPanel style={{ minWidth: "400px", maxWidth: "650px" }}>
                    {videoMeasurements ? (
                      <Player poster="/video/swanneck.png">
                        {videoMeasurements ? <source src={videoMeasurements} /> : <EuiLoadingSpinner />}
                        <BigPlayButton position="center" />
                      </Player>
                    ) : (
                      <EuiFlexItem grow={false} style={{ width: "316px", position: "relative" }}>
                        <EuiLoadingSpinner
                          size="l"
                          style={{
                            position: "absolute",
                            zIndex: 2,
                            left: "calc(50% - 13.5px)",
                            top: "15%",
                          }}
                        />
                        <EuiImage alt="swanneck instructions" src="/video/swanneck.png" />
                      </EuiFlexItem>
                    )}
                  </EuiPanel>
                  <EuiSpacer />
                  <EuiPanel style={{ minWidth: "400px", maxWidth: "450px" }}>
                    {videoAdjustments ? (
                      <Player width={650} poster="/video/double-swanneck.png">
                        <BigPlayButton position="center" />
                        {videoAdjustments ? <source src={videoAdjustments} /> : null}
                      </Player>
                    ) : (
                      <EuiFlexItem style={{ width: "316px", position: "relative" }}>
                        <EuiLoadingSpinner
                          size="l"
                          style={{
                            position: "absolute",
                            zIndex: 2,
                            left: "calc(50% - 13.5px)",
                            top: "15%",
                          }}
                        />
                        <EuiImage alt="swanneck instructions" src="/video/double-swanneck.png" />
                      </EuiFlexItem>
                    )}
                  </EuiPanel>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    </MMPage>
  );
}

export default MMProducts;
