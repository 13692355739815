import {
  EuiAvatar,
  EuiCollapsibleNav,
  EuiHeader,
  EuiHeaderBreadcrumbs,
  EuiHeaderLink,
  EuiHeaderLinks,
  EuiHeaderLogo,
  EuiHeaderSection,
  EuiHeaderSectionItem,
  EuiHeaderSectionItemButton,
  EuiIcon,
  EuiListGroup,
  EuiListGroupItem,
  EuiSpacer,
  EuiSwitch,
  EuiText,
  useIsWithinBreakpoints,
} from "@elastic/eui";
import AuthenticationHelper from "helpers/authentication-helper";
import txt from "helpers/text-helper";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import {
  notificationCountGet,
  notificationsHide,
  notificationsIsShown,
  notificationsShow,
} from "store/components/notification/notification";
import MMHeaderSupport from "./support";

function MMHeader() {
  const [navigationIsOpen, setNavigationIsOpen] = useState(false);
  const [navigationIsDocked, setNavigationIsDocked] = useState(false);
  const [breadcrumbs, setBreadcrumbs] = useState<any[]>([]);
  const [menuItems, setMenuItems] = useState<any[]>([]);
  const [isAdmin, setIsAdmin] = useState(false);

  let navigationLocation = useLocation();
  let navigate = useNavigate();
  let dispatch = useDispatch();
  let isNotificationsShown = useSelector(notificationsIsShown);
  const notifcationCount = useSelector(notificationCountGet);
  const isLoggedIn = AuthenticationHelper.isAuthenticated();
  const groups = AuthenticationHelper.getGroups();
  const isMobileScreen = useIsWithinBreakpoints(["xs"]);

  const toggleNotifications = () => {
    if (isNotificationsShown) {
      dispatch(notificationsHide());
    } else {
      dispatch(notificationsShow());
    }
  };

  const initMenuItems = async () => {
    let items: any[] = [];
    if (
      isLoggedIn &&
      (await AuthenticationHelper.hasPermission(["orders#read", "orders#read_org", "orders#read_all"]))
    ) {
      items.push(
        <EuiListGroupItem
          key="my-dashboard"
          label={
            <NavLink
              to="/"
              onClick={() => {
                handleNav();
              }}
            >
              <EuiText style={{ fontWeight: "bold" }}>{txt.get("dashboard.page_title")}</EuiText>
            </NavLink>
          }
        ></EuiListGroupItem>
      );
    }

    if (!isLoggedIn) {
      items.push(
        <EuiListGroupItem
          key="login"
          label={
            <NavLink
              to="/login"
              onClick={() => {
                handleNav();
              }}
            >
              <EuiText>{txt.get("login.login")}</EuiText>
            </NavLink>
          }
        ></EuiListGroupItem>
      );
      return;
    }

    if (await AuthenticationHelper.hasPermission("sales#read_all")) {
      items.push(
        <EuiListGroupItem
          key="sales"
          label={
            <NavLink
              to="/sales"
              onClick={() => {
                handleNav();
              }}
            >
              <EuiText style={{ color: "#ccc" }}>{txt.get("sales.page_title")}</EuiText>
            </NavLink>
          }
        ></EuiListGroupItem>
      );
    }
    if (await AuthenticationHelper.hasPermission(["orders#read", "orders#read_org", "orders#read_all"])) {
      items.push(
        <EuiListGroupItem
          key="orders"
          label={
            <NavLink
              to="/orders"
              onClick={() => {
                handleNav();
              }}
            >
              <EuiText>{txt.get("orders.page_title")}</EuiText>
            </NavLink>
          }
        ></EuiListGroupItem>
      );
    }

    if (
      await AuthenticationHelper.hasPermission(["appointments#create", "appointments#delete", "productions#read_all"])
    ) {
      items.push(
        <EuiListGroupItem
          key="production"
          label={
            <NavLink
              to="/production"
              onClick={() => {
                handleNav();
              }}
            >
              <EuiText>{txt.get("production.page_title")}</EuiText>
            </NavLink>
          }
        ></EuiListGroupItem>
      );
    }

    if (await AuthenticationHelper.hasPermission("finance#read_all")) {
      items.push(
        <EuiListGroupItem
          key="finance"
          label={
            <NavLink
              to="/finance"
              onClick={() => {
                handleNav();
              }}
            >
              <EuiText>{txt.get("finance.page_title")}</EuiText>
            </NavLink>
          }
        ></EuiListGroupItem>
      );
    }

    if (await AuthenticationHelper.hasPermission("products#read_all")) {
      items.push(
        <EuiListGroupItem
          key="products"
          label={
            <NavLink
              to="/products"
              onClick={() => {
                handleNav();
              }}
            >
              <EuiText>{txt.get("products.page_title")}</EuiText>
            </NavLink>
          }
        ></EuiListGroupItem>
      );
    }

    if (
      (await AuthenticationHelper.hasPermission(["scans#read_all", "scanners#read_all"])) ||
      ((await AuthenticationHelper.hasPermission(["scans#read_org", "scanners#read_org"])) &&
        AuthenticationHelper.hasRole("scanner"))
    ) {
      items.push(
        <EuiListGroupItem
          key="scanning"
          label={
            <NavLink
              to="/scanning"
              onClick={() => {
                handleNav();
              }}
            >
              <EuiText>{txt.get("scanning.page_title")}</EuiText>
            </NavLink>
          }
        ></EuiListGroupItem>
      );
    }

    if (await AuthenticationHelper.hasPermission(["finance#read_all", "sales#read_all", "finance#read_all"])) {
      items.push(
        <EuiListGroupItem
          key="quick-links"
          label={
            <NavLink
              to="/quick-links"
              onClick={() => {
                handleNav();
              }}
            >
              <EuiText>{txt.get("quick_links.page_title")}</EuiText>
            </NavLink>
          }
        ></EuiListGroupItem>
      );
    }

    if (await AuthenticationHelper.hasPermission("okrs#read_all")) {
      items.push(
        <EuiListGroupItem
          key="okrs"
          label={
            <NavLink
              to="/okrs"
              onClick={() => {
                handleNav();
              }}
            >
              <EuiText style={{ color: "#ccc" }}>{txt.get("okrs.page_title")}</EuiText>
            </NavLink>
          }
        ></EuiListGroupItem>
      );
    }

    setMenuItems(items);
  };

  useEffect(() => {
    console.log({ menuItems });
  }, [menuItems]);
  useEffect(() => {
    const getIsAdmin = async () => {
      if (isLoggedIn) {
        const isAdmin = await AuthenticationHelper.hasPermission([
          "personal_details#read_all",
          "organisations#read_all",
          "permissions#read_all",
          "locations#read_all",
          "referrers#edit_all",
        ]);
        // console.log("getIsAdmin", isAdmin);
        setIsAdmin(isAdmin);
      } else {
        setIsAdmin(false);
      }
    };
    getIsAdmin();
    initMenuItems();
  }, [isLoggedIn]);

  useEffect(() => {
    let locationParts: string[] = navigationLocation.pathname.split("/").filter((value) => value !== "");
    let newBreadcrumbs: any[] = [];

    locationParts.forEach((part, i) => {
      newBreadcrumbs.push({
        text: isNaN(parseInt(part))
          ? txt.uf(
              `${[...locationParts]
                .slice(0, i + 1)
                .join(".")
                .replace("-", "_")}.page_title`
            )
          : `#${part}`,
        onClick: (e: any) => {
          e.preventDefault();
          navigate(`/${locationParts.slice(0, i + 1).join("/")}`);
        },
      });
    });

    setBreadcrumbs(newBreadcrumbs);
  }, [navigationLocation]);

  const handleNav = () => {
    if (!navigationIsDocked) {
      closeNav();
    }
  };

  const toggleDocked = () => {
    if (navigationIsDocked) {
      setNavigationIsOpen(false);
      setNavigationIsDocked(false);
    } else {
      setNavigationIsOpen(true);
      setNavigationIsDocked(true);
    }
  };

  const toggleNav = () => {
    setNavigationIsOpen(!navigationIsOpen);
  };

  const closeNav = () => {
    setNavigationIsOpen(false);
  };

  const renderBreadcrumbs = () => {
    return <EuiHeaderBreadcrumbs aria-label="Navigate back to the main pages" breadcrumbs={breadcrumbs} />;
  };

  return (
    <EuiHeader position="fixed" style={{ backgroundColor: "#d1dfdf" }}>
      <EuiHeaderSection style={{ position: "relative", top: 3 }}>
        <EuiCollapsibleNav
          id="header-navigation"
          aria-label="Main navigation"
          isOpen={navigationIsOpen}
          isDocked={navigationIsDocked}
          button={
            !isMobileScreen ? undefined : (
              <EuiHeaderSectionItemButton aria-label="Toggle main navigation" onClick={() => toggleNav()}>
                <EuiIcon type={"menu"} size="m" aria-hidden="true" />
              </EuiHeaderSectionItemButton>
            )
          }
          onClose={() => closeNav()}
        >
          <EuiListGroup>
            <Fragment>{menuItems}</Fragment>
            <EuiSpacer />
            <EuiListGroupItem
              label={
                <EuiSwitch
                  label={txt.get("generic.stick")}
                  checked={navigationIsDocked}
                  onChange={() => {
                    toggleDocked();
                  }}
                ></EuiSwitch>
              }
            />
          </EuiListGroup>
        </EuiCollapsibleNav>

        <EuiHeaderLogo
          title={txt.get("brand.name")}
          style={{ cursor: "pointer" }}
          onClick={() => {
            console.log(
              "navigating to",
              menuItems.length > 2
                ? "/"
                : menuItems[menuItems.length - 2]
                  ? `${menuItems[menuItems.length - 2].key}`
                  : isLoggedIn
                    ? menuItems[0]
                      ? menuItems[0].key
                      : "#"
                    : "#"
            );
            navigate(
              menuItems.length > 2
                ? "/"
                : menuItems[menuItems.length - 2]
                  ? `${menuItems[menuItems.length - 2].key}`
                  : isLoggedIn
                    ? menuItems[0]
                      ? menuItems[0].key
                      : "#"
                    : "#"
            );
          }}
          iconType="/logo.svg"
          aria-label={txt.get("generic.go_to", txt.get("dashboard.page_title"))}
        >
          {" "}
          {groups.includes("/manometric")
            ? txt.get("brand.app_short")
            : txt.get("brand.app_name_external_orders_short")}{" "}
        </EuiHeaderLogo>
      </EuiHeaderSection>
      {renderBreadcrumbs()}
      <EuiHeaderSection>
        <EuiHeaderSectionItem>
          <MMHeaderSupport />
        </EuiHeaderSectionItem>
        <EuiHeaderSectionItem>
          <EuiHeaderLinks>
            {false && AuthenticationHelper.isAuthenticated() ? (
              <EuiHeaderSectionItemButton
                onClick={toggleNotifications}
                aria-label="Notifications"
                id="tour_2_notifications_step_1"
                notification={notifcationCount}
                iconType="bell"
              ></EuiHeaderSectionItemButton>
            ) : (
              <></>
            )}
            {isAdmin ? (
              <NavLink to="/admin">
                <EuiHeaderSectionItemButton aria-label="Admin" iconType="gear"></EuiHeaderSectionItemButton>
              </NavLink>
            ) : (
              <></>
            )}
            {AuthenticationHelper.isAuthenticated() ? (
              <NavLink to="/my-account">
                <EuiHeaderSectionItemButton aria-label="My account" id="tour_1_language_step_1">
                  <EuiAvatar name={AuthenticationHelper.getFullName()} size="s" />
                </EuiHeaderSectionItemButton>
              </NavLink>
            ) : (
              <EuiHeaderLink href="/login">Login</EuiHeaderLink>
            )}
          </EuiHeaderLinks>
        </EuiHeaderSectionItem>
      </EuiHeaderSection>
    </EuiHeader>
  );
}

export default MMHeader;
