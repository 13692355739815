import StringHelper from "helpers/string-helper";
import { fullName, PersonalDetails } from "../personal-details/personal-details";

export interface EventLog {
  id: string;
  type: string;
  payload: any;
  object_id: number;
  object_type: string;
  emitted_by: string;
  emitted_by_id: number;
  personal_details?: PersonalDetails;
  emitted_at: Date;
}

export const toEventDescription = (event: EventLog) =>
  event?.type ? StringHelper.replace(StringHelper.replace(event.type, "_", " "), ".", " ") : "-";

export const toEventEmitterDescription = (event: EventLog) =>
  event.emitted_by_id && event.personal_details ? fullName(event.personal_details) : event.emitted_by;
