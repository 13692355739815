import { EuiComboBox, EuiFlexGrid, EuiFlexGroup, EuiFlexItem, EuiFormRow } from "@elastic/eui";
import txt from "helpers/text-helper";
import { updateField } from "hoc/helper-hooks";
import { useEffect, useState } from "react";
import { OrderCosting, OrderCostingType, ORDER_COSTING_EMPTY } from "store/data/costing/costing";
import { Order } from "store/data/order/order";
import { MMCostingDetailsProps } from "../costing-inputs";

const toSelectedType = (type: OrderCostingType) => {
  if (type) {
    return [
      {
        label: txt.get(`orders.order.costing.costing_type_option.${type.toString().toLowerCase()}`),
        value: type,
      },
    ];
  }
  return [];
};
function MMCostingType(props: MMCostingDetailsProps) {
  const [order, setOrder] = useState<Order | null | undefined>(props.order);
  const [type, setType] = useState<OrderCostingType>(props.order?.costing?.type || OrderCostingType.Declaration);
  const costingTypeOptions = [
    {
      label: txt.get("orders.order.costing.costing_type_option.declaration"),
      value: OrderCostingType.Declaration,
    },
    {
      label: txt.get("orders.order.costing.costing_type_option.invoice_private"),
      value: OrderCostingType.InvoicePrivate,
    },
  ];

  useEffect(() => {
    setOrder(props.order);
    setType(props.order?.costing?.type || OrderCostingType.Declaration);
  }, [props.order]);

  const onCostingTypeChanged = (selectedType: any) => {
    if (!selectedType) {
      return;
    }
    if (props.onChange) {
      const newCosting: OrderCosting = updateField(order?.costing || ORDER_COSTING_EMPTY, "type", selectedType.value);
      props.onChange(updateField(order, "costing", newCosting));
    }
  };

  return (
    <EuiFlexGrid>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="xs">
          <EuiFormRow label={txt.get("orders.order.costing.costing_type")}>
            <EuiComboBox
              data-testid="input-costing-type"
              style={{ width: "280px" }}
              color="accent"
              isClearable={false}
              singleSelection={{ asPlainText: true }}
              compressed={true}
              placeholder={txt.uf("generic.select_x", txt.get("orders.order.costing.costing_type"))}
              options={costingTypeOptions}
              selectedOptions={toSelectedType(type)}
              onChange={(selected) => onCostingTypeChanged(selected.length > 0 ? selected[0] : null)}
            />
          </EuiFormRow>
        </EuiFlexGroup>
      </EuiFlexItem>
    </EuiFlexGrid>
  );
}

export default MMCostingType;
