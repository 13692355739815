import {
  EuiDatePicker,
  EuiFieldText,
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiPanel,
  EuiSelect,
  EuiSpacer,
  EuiText,
} from "@elastic/eui";
import MMTitle from "components/layouts/title/title";
import DateHelper from "helpers/date-helper";
import MomentHelper from "helpers/moment-helper";
import txt from "helpers/text-helper";
import { onStringValue, updateField } from "hoc/helper-hooks";
import { useEffect, useState } from "react";
import { Client, clientName, Sex } from "store/data/client/client";

export interface MMPersonalInfoProps {
  client: Client | null;
  isEditable?: boolean;
}

function MMPersonalInfo(props: MMPersonalInfoProps) {
  const [client, setClient] = useState<Client>();
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [isHeaderShown] = useState<boolean>(false);
  const [selectedSex, setSelectedSex] = useState<any>(Sex.Unknown);

  const sexOptions: any[] = [
    { value: Sex.Female, text: txt.get("clients.sex.female") },
    { value: Sex.Male, text: txt.get("clients.sex.male") },
    { value: Sex.Unknown, text: txt.get("clients.sex.unknown") },
  ];

  useEffect(() => {
    setIsEditable(!!props.isEditable);
    if (props.client) {
      setClient(props.client);
    } else {
      setClient(undefined);
    }
    setSelectedSex(props.client?.sex || Sex.Unknown);
  }, [props.client, props.isEditable]);

  const onClient = (client: Client) => {
    setClient(client);
  };

  return client ? (
    <EuiFlexGrid className="subtle-editing">
      <EuiFlexItem>
        <MMTitle text={txt.get("clients.personal_info.name")} />
        <EuiText
          size="xs"
          style={{
            fontWeight: "600",
            position: "relative",
            top: "14px",
            paddingBottom: "6px",
          }}
        >
          {clientName(client)} (#{client.client_code})
        </EuiText>
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiPanel className="subtle-panel">
          <EuiFlexGroup gutterSize="xs" style={{ maxWidth: "400px" }}>
            <EuiFlexItem grow={2}>
              <EuiFormRow
                display="rowCompressed"
                label={isHeaderShown ? txt.get("person.initials") : undefined}
                title={txt.get("person.initials")}
              >
                <EuiFieldText
                  placeholder={txt.get("person.initials")}
                  compressed={true}
                  disabled={!isEditable}
                  name="initials"
                  value={client.initials || ""}
                  onChange={(e) => onClient(updateField(client, "initials", onStringValue(e)))}
                />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem grow={7}>
              <EuiFormRow
                display="rowCompressed"
                label={isHeaderShown ? txt.get("person.first_name") : undefined}
                title={txt.get("person.first_name")}
              >
                <EuiFieldText
                  placeholder={txt.get("person.first_name")}
                  compressed={true}
                  disabled={!isEditable}
                  name="first_name"
                  value={client.first_name || ""}
                  onChange={(e) => onClient(updateField(client, "first_name", onStringValue(e)))}
                />
              </EuiFormRow>
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiSpacer size="s" />
          <EuiFlexGroup gutterSize="xs" style={{ maxWidth: "400px" }}>
            <EuiFlexItem grow={2}>
              <EuiFormRow
                display="rowCompressed"
                label={isHeaderShown ? txt.get("person.infix") : undefined}
                title={txt.get("person.infix")}
              >
                <EuiFieldText
                  placeholder={txt.get("person.infix")}
                  compressed={true}
                  disabled={!isEditable}
                  name="infix"
                  value={client.infix || ""}
                  onChange={(e) => onClient(updateField(client, "infix", onStringValue(e)))}
                />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem grow={7}>
              <EuiFormRow
                display="rowCompressed"
                label={isHeaderShown ? txt.get("person.last_name") : undefined}
                title={txt.get("person.last_name")}
              >
                <EuiFieldText
                  placeholder={txt.get("person.last_name")}
                  compressed={true}
                  disabled={!isEditable}
                  name="last_name"
                  value={client.last_name || ""}
                  onChange={(e) => onClient(updateField(client, "last_name", onStringValue(e)))}
                />
              </EuiFormRow>
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiSpacer size="s" />
          <EuiFlexGroup gutterSize="xs" style={{ maxWidth: "400px" }}>
            <EuiFlexItem grow={3}>
              <EuiFormRow
                display="rowCompressed"
                label={isHeaderShown ? txt.get("clients.sex.name") : undefined}
                title={txt.get("clients.sex.name")}
              >
                <EuiSelect
                  disabled={!isEditable}
                  aria-placeholder={txt.get("clients.sex.name")}
                  compressed={true}
                  options={sexOptions}
                  value={selectedSex}
                  onChange={(e) => setSelectedSex(e.target.value)}
                  aria-label={txt.get("clients.sex.name")}
                />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem grow={3}>
              <EuiFormRow
                display="rowCompressed"
                label={isHeaderShown ? txt.get("clients.birth_date") : undefined}
                title={txt.get("clients.birth_date")}
              >
                <EuiDatePicker
                  placeholder={txt.get("clients.birth_date")}
                  dateFormat={"DD-MM-YYYY"}
                  compressed={true}
                  disabled={!isEditable}
                  selected={
                    client.birth_date ? MomentHelper.toMoment(DateHelper.parseDate(client.birth_date)) : undefined
                  }
                  onChange={(moment: any) => {
                    setClient(updateField(client, "birth_date", MomentHelper.toDate(moment)));
                  }}
                ></EuiDatePicker>
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem grow={3}>
              <EuiFormRow
                display="rowCompressed"
                label={isHeaderShown ? txt.get("clients.bsn") : undefined}
                title={txt.get("clients.bsn")}
              >
                <EuiFieldText
                  placeholder={txt.get("clients.bsn")}
                  compressed={true}
                  disabled={!isEditable}
                  name="bsn"
                  value={client.bsn || ""}
                  onChange={(e) => onClient(updateField(client, "bsn", onStringValue(e)))}
                />
              </EuiFormRow>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiPanel>
      </EuiFlexItem>
    </EuiFlexGrid>
  ) : (
    <></>
  );
}

export default MMPersonalInfo;
