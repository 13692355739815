import { createSlice, nanoid, PayloadAction } from "@reduxjs/toolkit";
import DateHelper from "helpers/date-helper";

import { RootState } from "store/store";

export enum NotificationStatus {
  New = "NEW",
  Shown = "SHOWN",
  Read = "READ",
}
export interface NotificationAction {
  title: string;
  link?: string;
}
export interface Notification {
  id: string;
  title: string;
  text?: string;
  status: NotificationStatus;
  date: string; //date
  importance?: string;
  topic?: string;
  actions?: NotificationAction[];
}

export interface NotificationState {
  notifications: Notification[];
  notificationCount: number;
  notificationsShown: boolean;
}

const initialState: NotificationState = {
  notifications: [
    {
      id: "123123",
      title: "Scan received",
      text: "The scan for your order 1234 is in. Check it out.",
      actions: [
        { title: "To Order", link: "/orders/10066" },
        { title: "To Scan", link: "/scanning/scans/20" },
      ],
      date: "2023-02-11",
      status: NotificationStatus.New,
      importance: "success",
      topic: "scans",
    },
    {
      id: "123123123",
      title: "Scanner offline",
      text: "Warning,  your scanner seems offline, make sure to connect it to keep uploading scans",
      date: "2023-03-22",
      importance: "danger",
      status: NotificationStatus.New,
      topic: "scans",
      actions: [{ title: "To Scanners", link: "/scanning/scanners" }],
    },
    {
      id: "1123223123123",
      title: "Order accepted",
      text: "your order 1234 has just been accepted. Production will start tomorrow.",
      date: "2022-12-31 12:00:00",
      status: NotificationStatus.New,
      topic: "orders",
      actions: [{ title: "To Order", link: "/orders/10066" }],
    },
  ],
  notificationCount: 3,
  notificationsShown: false,
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    notificationsShow: {
      reducer(state) {
        console.log("showing");
        state.notificationsShown = true;
      },
      prepare() {
        return { payload: {} };
      },
    },
    notificationsHide: {
      reducer(state) {
        console.log("hiding");
        state.notificationsShown = false;
      },
      prepare() {
        return { payload: {} };
      },
    },
    notificationAdd: {
      reducer(state, action: PayloadAction<Notification>) {
        state.notifications.push(action.payload);
        state.notificationCount = state.notifications.length;
      },
      prepare(title, text?, importance?) {
        return {
          payload: {
            id: nanoid(),
            title,
            text,
            importance,
            status: NotificationStatus.New,
            date: DateHelper.toDate(),
          },
        };
      },
    },
    notificationSetShown: {
      reducer(state, action: PayloadAction<string>) {
        const notifications = [...state.notifications];
        const index = notifications.findIndex((notification) => notification.id === action.payload);
        if (index >= 0) {
          notifications[index].status = NotificationStatus.Shown;
        }
        state.notifications = notifications;
      },
      prepare(id) {
        return { payload: id };
      },
    },
    notificationSetRead: {
      reducer(state, action: PayloadAction<string>) {
        const notifications = [...state.notifications];
        const index = notifications.findIndex((notification) => notification.id === action.payload);
        if (index >= 0) {
          notifications[index].status = NotificationStatus.Read;
        }
        state.notifications = notifications;
      },
      prepare(id) {
        return { payload: id };
      },
    },
    notificationRemove: {
      reducer(state, action: PayloadAction<string>) {
        state.notifications = state.notifications.filter((notification) => notification.id !== action.payload);
        state.notificationCount = state.notifications.length;
      },
      prepare(id) {
        return { payload: id };
      },
    },
  },
});

export const notificationsGet = (state: RootState) => state.notification.notifications;

export const notificationCountGet = (state: RootState) => state.notification.notificationCount;

export const notificationsIsShown = (state: RootState) => state.notification.notificationsShown;

export const {
  notificationAdd,
  notificationRemove,
  notificationSetRead,
  notificationSetShown,
  notificationsHide,
  notificationsShow,
} = notificationSlice.actions;

export default notificationSlice.reducer;
