import { EuiComboBox, EuiFlexGroup, EuiFlexItem, EuiFormRow } from "@elastic/eui";
import txt from "helpers/text-helper";
import { useEffect, useState } from "react";

import { Fitting, FITTING_EMPTY } from "store/data/fitting/fitting";
import { MMOption, MMOrderLineDeliveryFieldProps } from "../order-line-delivery";

const toSelectedReasonsRejected = (fitting: Fitting | null | undefined) => {
  return fitting && fitting.reason_unfit
    ? fitting.reason_unfit.split(",").map((reason: string) => ({ label: reason }))
    : [];
};

function MMDeliveryReasonRejected(props: MMOrderLineDeliveryFieldProps) {
  const [fitting, setFitting] = useState<Fitting | undefined | null>();

  useEffect(() => {
    setFitting(props.fitting);
  }, [props.fitting]);

  const [misfitReasons] = useState<MMOption[]>(props.misfitReasons || []);
  const [selectedReasonsRejected, setSelectedReasonsRejected] = useState<any[]>(
    toSelectedReasonsRejected(props.fitting)
  );

  useEffect(() => {
    setSelectedReasonsRejected(toSelectedReasonsRejected(props.fitting));
  }, [props.fitting]);

  const onReasonsRejectedChange = (reasonsRejected: any) => {
    if (props.onChange) {
      props.onChange(
        {
          ...(fitting || FITTING_EMPTY),
          reason_unfit: reasonsRejected.map((reason: any) => reason.label).join(","),
        } as Fitting,
        props.orderLineId
      );
    }
  };

  return (
    <EuiFlexGroup gutterSize="xs">
      <EuiFlexItem>
        <EuiFormRow className="product-input" display="rowCompressed">
          <EuiComboBox
            className={misfitReasons.length > 0 ? `option-selected` : ""}
            color="accent"
            singleSelection={{ asPlainText: true }}
            compressed={true}
            placeholder={txt.get("orders.delivery.reason")}
            options={misfitReasons}
            selectedOptions={selectedReasonsRejected}
            onChange={(selected) => onReasonsRejectedChange(selected)}
            // onCreateOption={onCreateOption}
          />
        </EuiFormRow>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
}

export default MMDeliveryReasonRejected;
