import {
  EuiDatePicker,
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiIcon,
  EuiText,
  EuiTextArea,
} from "@elastic/eui";
import MMFiles from "components/layouts/files/files";
import DateHelper from "helpers/date-helper";
import MomentHelper from "helpers/moment-helper";
import txt from "helpers/text-helper";
import { useEffect, useState } from "react";
import { Client } from "store/data/client/client";
import { Indication, indicationDescription } from "store/data/indication/indication";
import { fullName } from "store/data/personal-details/personal-details";
import { Referral } from "store/data/referral/referral";
import { Referrer } from "store/data/referrer/referrer";
import { Hand, handDescription } from "store/data/scan/scan";

export interface MMReferralInfoProps {
  client: Client | null;
  referral: Referral;
  isEditable?: boolean;
  isHeaderShown?: boolean;
}

function MMReferralInfo(props: MMReferralInfoProps) {
  const [referral, setReferral] = useState<Referral>();
  const [isEditable, setIsEditable] = useState<boolean>(false);

  useEffect(() => {
    setIsEditable(!!props.isEditable);
    if (props.referral) {
      setReferral(props.referral);
    } else {
      setReferral(undefined);
    }
  }, [props.referral, props.isEditable]);

  const toReferrerSummaryText = (referrer: Referrer) => {
    let result: string = "";
    result += referrer.person ? fullName(referrer.person) : "-";
    result += "\n";
    result += referrer.alternative_organisation;
    return result;
  };

  const toIndicationSummaryText = (indications: Indication[]) => {
    let result: string = "";
    let leftDescription: string = indicationDescription(indications, Hand.Left);
    let rightDescription: string = indicationDescription(indications, Hand.Right);
    if (leftDescription) {
      result += `${handDescription(Hand.Left)}: ${leftDescription}`;
    }
    if (leftDescription && rightDescription) {
      result += " - ";
    }
    if (rightDescription) {
      result += `${handDescription(Hand.Right)}: ${rightDescription}`;
    }
    return result;
  };

  return referral ? (
    <EuiFlexGrid className="subtle-editing" gutterSize="s">
      <EuiFlexGroup gutterSize="xs">
        <EuiFlexItem grow={false}>
          <div
            style={{
              padding: "10px",
            }}
          >
            {referral.files && referral.files.length > 0 ? (
              <EuiFlexItem>
                <MMFiles
                  tinyView={true}
                  previewAll={true}
                  downloadAll={true}
                  detailedPreview={true}
                  files={referral.files.filter((file: any) => file.attachment.type === "referral_letter_reference")}
                />
              </EuiFlexItem>
            ) : (
              <EuiIcon size="xl" type="image" color="#cccccc" />
            )}
          </div>
        </EuiFlexItem>
        <EuiFlexItem grow={true}>
          <EuiFormRow display="rowCompressed">
            <EuiTextArea
              rows={2}
              resize="none"
              compressed={true}
              disabled={!isEditable}
              placeholder={txt.get("clients.care_info.referrals.referrer_details")}
              value={referral.referrer ? toReferrerSummaryText(referral.referrer) : ""}
            />
          </EuiFormRow>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiFlexItem grow={true}>
        <EuiFormRow display="rowCompressed">
          <EuiTextArea
            fullWidth={true}
            rows={1}
            resize="none"
            compressed={true}
            disabled={!isEditable}
            placeholder={txt.get("clients.care_info.referrals.indication_details")}
            value={
              referral.indications && referral.indications.length > 0
                ? toIndicationSummaryText(referral.indications)
                : ""
            }
          />
        </EuiFormRow>
      </EuiFlexItem>
      <EuiFlexGroup>
        <EuiFlexItem grow={false} style={{ padding: "8px 7.5px" }}>
          <EuiText size="xs" color="subdued">
            {txt.get("clients.care_info.referrals.referred_at")}
          </EuiText>
        </EuiFlexItem>
        <EuiFlexItem grow={true}>
          <EuiDatePicker
            placeholder={txt.get("clients.care_info.referrals.referral_date")}
            dateFormat={"DD-MM-YYYY"}
            compressed={true}
            disabled={!isEditable}
            selected={
              referral.referred_at ? MomentHelper.toMoment(DateHelper.parseDate(referral.referred_at)) : undefined
            }
            onChange={(moment: any) => {
              //   setClient(
              //     updateField(
              //       client,
              //       "birth_date",
              //       MomentHelper.toDate(moment)
              //     )
              //   );
            }}
          ></EuiDatePicker>
        </EuiFlexItem>
        <EuiFlexItem grow={false} style={{ padding: "8px 7.5px" }}>
          <EuiText size="xs" color="subdued">
            {txt.get("clients.care_info.referrals.intake_at")}
          </EuiText>
        </EuiFlexItem>
        <EuiFlexItem grow={true}>
          <EuiDatePicker
            placeholder={txt.get("clients.care_info.referrals.intake_date")}
            dateFormat={"DD-MM-YYYY"}
            compressed={true}
            disabled={!isEditable}
            selected={referral.intake_at ? MomentHelper.toMoment(DateHelper.parseDate(referral.intake_at)) : undefined}
            onChange={(moment: any) => {
              //   setClient(
              //     updateField(
              //       client,
              //       "birth_date",
              //       MomentHelper.toDate(moment)
              //     )
              //   );
            }}
          ></EuiDatePicker>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiFlexGrid>
  ) : (
    <></>
  );
}

export default MMReferralInfo;
