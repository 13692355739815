import { EuiFlexGroup, EuiFlexItem, EuiText, useIsWithinBreakpoints } from "@elastic/eui";
import ConnectAPIHelper from "api/connect-api-helper";
import MMProductCards, { OnProductSelect } from "components/products/product-cards";
import MMProductFilters, { OnCategoryChange } from "components/products/product-filters";
import { selectableCategoryOptions, selectableProductOptions } from "helpers/product-helper";
import txt from "helpers/text-helper";
import { snakeCase } from "lodash";
import { useEffect, useState } from "react";
import { Order, ORDER_EMPTY } from "store/data/order/order";
import { MMOrderEntryInputProps } from "../order-entry";
import { MMOrderLineProductInput, orderLinesToProducts, productsToOrderLines } from "../order-line-entry";
import MMOrderProductSelectDetails from "./order-product-select-details";

export type OnProductLine = (orderLine: MMOrderLineProductInput, i?: number | null) => void;

function MMOrderProductSelect(props: MMOrderEntryInputProps) {
  const isMobileScreen = useIsWithinBreakpoints(["xs"]);
  const isSmallScreen = useIsWithinBreakpoints(["s", "m"]);
  const isMediumScreen = useIsWithinBreakpoints(["l"]);
  const isLargeScreen = useIsWithinBreakpoints(["xl"]);

  const [filteredProducts, setFilteredProducts] = useState<any[]>(props.products || []);
  const [currentProductOption, setCurrentProductOption] = useState<any | null>(null);

  const [order] = useState<Order | null>(props.order || null);
  const [selectedCategories, setSelectedCategories] = useState<any[]>([]);
  const [orderLineProducts, setOrderLineProducts] = useState<MMOrderLineProductInput[]>(
    props.order && props.order.order_lines && props.order.order_lines.length > 0
      ? orderLinesToProducts(props.order.order_lines, props.products || [])
      : []
  );
  const [columns, setColumns] = useState<1 | 2 | 3 | 4>(4);
  const api: ConnectAPIHelper = new ConnectAPIHelper();
  const lang: string = txt.lang();

  const locationCat: string = txt.lo("products.categories.body_part");
  const colorCat: string = txt.lo("products.categories.color");
  const materialCat: string = txt.lo("products.categories.material");

  useEffect(() => {
    if (order?.order_lines && order?.order_lines.length > 0) {
      setColumns(isMobileScreen ? 1 : isSmallScreen ? 1 : isMediumScreen ? 2 : isLargeScreen ? 3 : 3);
    } else {
      setColumns(isMobileScreen ? 1 : isSmallScreen ? 2 : isMediumScreen ? 3 : isLargeScreen ? 4 : 4);
    }
  }, [order?.order_lines, isMobileScreen, isLargeScreen, isMediumScreen, isSmallScreen]);

  useEffect(() => {
    if (props.products) {
      if (selectedCategories.length > 0) {
        let result: any[] = [...props.products];
        for (let i = 0; i < selectedCategories.length; i++) {
          const cat: any = selectedCategories[i];
          const currentCategoryName: string = cat.category.replace("_", " ");
          if (cat.select) {
            if (currentCategoryName === locationCat) {
              result = result.filter((product: any) => snakeCase(product[`general_location_${lang}`]) === cat.select);
            }
            if (currentCategoryName === colorCat) {
              result = result.filter((product: any) => snakeCase(product[`color_${lang}`]) === cat.select);
            }
            if (currentCategoryName === materialCat) {
              result = result.filter(
                (product: any) => snakeCase(product[`material_for_statement_${lang}`]) === cat.select
              );
            }
            if (currentCategoryName === "search") {
              console.log("searching", cat.category, cat.select);
              result = result.filter((product: any) => {
                return (
                  (product[`translation_family_${lang}`]
                    ? product[`translation_family_${lang}`]
                        .toLowerCase()
                        .replace(" ", "")
                        .replace("-", "")
                        .indexOf(cat.select.toLowerCase().replace(" ", "").replace("-", ""))
                    : product.family
                        .toLowerCase()
                        .replace(" ", "")
                        .replace("-", "")
                        .indexOf(cat.select.toLowerCase().replace(" ", "").replace("-", ""))) >= 0
                );
              });
            }
          }
        }

        setFilteredProducts(result);
      } else {
        setFilteredProducts(props.products);
      }
    } else {
      setFilteredProducts([]);
    }
  }, [props.products, selectedCategories]);

  const onProductCategoryChange: OnCategoryChange = (categories: any[]) => {
    console.log("onProductCategoryChange", categories);
    setSelectedCategories(categories);
  };

  useEffect(() => {
    setOrderLineProducts(
      props.order && props.order.order_lines && props.order.order_lines.length > 0
        ? orderLinesToProducts(props.order.order_lines, props.products || [])
        : []
    );
  }, [props.order]);

  const onAddProductLine: OnProductLine = (addedOrderLine: MMOrderLineProductInput, index?: number | null) => {
    let newLines: MMOrderLineProductInput[] = [];

    //default settings
    if (props.orderTypes && props.orderTypes.length === 1) {
      addedOrderLine.orderType = props.orderTypes[0].name;
    }

    if (index !== undefined && index !== null && !isNaN(index)) {
      for (let i = 0; i < orderLineProducts.length; i++) {
        const orderLine = orderLineProducts[i];
        if (index === i) {
          newLines.push(addedOrderLine);
        }
        newLines.push(orderLine);
      }
    } else {
      newLines = orderLineProducts.concat([addedOrderLine]);
    }

    if (props.onChange) {
      const orderLines = productsToOrderLines(newLines);
      console.log("onAddProductLine", newLines, orderLines);
      props.onChange(
        {
          ...ORDER_EMPTY,
          ...order,
          order_lines: orderLines,
        },
        true
      );
    } else {
      setOrderLineProducts(newLines);
    }
    setCurrentProductOption(null);
  };

  const onDeleteProductLine: OnProductLine = (deletedOrderLine: MMOrderLineProductInput, index?: number | null) => {
    console.log("onDeleteProductLine");
    let newLines: MMOrderLineProductInput[] = [];

    if (index !== undefined && index !== null && !isNaN(index)) {
      for (let i = 0; i < orderLineProducts.length; i++) {
        const orderLine = orderLineProducts[i];
        if (index !== i) {
          newLines.push(orderLine);
        }
      }
    } else {
      newLines = [...orderLineProducts];
    }

    if (props.onChange) {
      props.onChange({
        ...ORDER_EMPTY,
        ...order,
        order_lines: productsToOrderLines(newLines),
      });
    } else {
      setOrderLineProducts(newLines);
    }
    setCurrentProductOption(null);
  };

  const onProductSelect: OnProductSelect = (product: any, selected: boolean) => {
    if (selected) {
      console.log("onProductSelect", product);
      setCurrentProductOption(product);
    } else {
      setCurrentProductOption(null);
    }
  };

  const renderProductSelectDetail = (currentProductOption: any) => {
    return currentProductOption ? (
      <MMOrderProductSelectDetails
        api={api}
        order={order}
        products={props.products || []}
        // selection={{ ...EMPTY_PRODUCT_INPUT, name: currentProductOption.value }}
        productOption={currentProductOption}
        onCancel={() => setCurrentProductOption(null)}
        onSelect={props.isEditable ? onAddProductLine : undefined}
        onDelete={props.isEditable ? onDeleteProductLine : undefined}
        isEditable={props.isEditable}
        isCorrigible={props.isCorrigible}
        orderTypes={props.orderTypes}
        remakeReasons={props.remakeReasons}
        clientProductions={props.clientProductions}
        showProductionDetails={props.showProductionDetails}
      />
    ) : (
      <></>
    );
  };

  return (
    <EuiFlexGroup className="product-select-holder">
      <EuiFlexItem
        grow={1}
        className="product-filters-holder"
        style={{ maxHeight: "calc(-450px + 100vh)", overflowY: "auto" }}
      >
        <MMProductFilters
          onChange={onProductCategoryChange}
          categories={selectableCategoryOptions(filteredProducts, lang)}
          showLocations={true}
          showSearch={true}
          treeHeight="calc(100vh - 410px)"
        />
      </EuiFlexItem>
      <EuiFlexItem grow={4}>
        <EuiFlexGroup
          className="product-filters"
          direction="column"
          style={{
            maxHeight: "calc(100vh - 450px)",
            overflowY: "auto",
          }}
        >
          {props.hasValidation && !(props.order?.order_lines && props.order.order_lines.length > 0) ? (
            <EuiText size="s" color="danger">
              {txt.get("validations.order_lines_not_empty")}
            </EuiText>
          ) : (
            <></>
          )}
          <MMProductCards
            api={api}
            products={selectableProductOptions(filteredProducts, lang)}
            onSelect={onProductSelect}
            columns={columns}
          />
        </EuiFlexGroup>
      </EuiFlexItem>
      {renderProductSelectDetail(currentProductOption)}
    </EuiFlexGroup>
  );
}

export default MMOrderProductSelect;
