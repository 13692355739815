import { EuiFlexGroup, EuiIcon, EuiLoadingSpinner, EuiPanel, EuiText, EuiTitle } from "@elastic/eui";
import { trackEvent } from "helpers/analytics-helper";
import AuthenticationHelper from "helpers/authentication-helper";
import { formatApiError } from "helpers/error-helper";
import FileUploadHelper, { FileResult, FileType } from "helpers/file-upload-helper";
import txt from "helpers/text-helper";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AlertConfirmActionState, confirmAsk, confirmGet } from "store/components/alert/confirm";
import { toastAdd } from "store/components/toast/toast";
import { AttachedToType, File } from "store/data/file/file";

interface MMFileUploadAttachmentOptions {
  attachedToType: string;
  attachedToId?: number;
  orderId?: number;
  type?: string;
}

interface MMFileAdded {
  (file: File): void;
}

interface MMFileUploadProps {
  multiple: boolean;
  fileTypes: FileType[];
  attachmentOptions?: MMFileUploadAttachmentOptions;
  onAdded?: MMFileAdded;
  title?: string;
  size?: "large" | "small";
  isInvalid?: boolean;
  "data-testid"?: string;
}

function MMFileUpload(props: MMFileUploadProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  const uploadHelper = new FileUploadHelper();

  const handleCopyPaste = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    let files: any[] = e.dataTransfer
      ? Array.from(e.dataTransfer.files)
      : e.clipboardData
        ? Array.from(e.clipboardData.files)
        : [];

    console.log("handleCopyPaste", e, files);

    files = files.filter(
      (fileData) => props.fileTypes.findIndex((type: string) => fileData.type.indexOf(type.replace("*", ""))) >= 0
    );

    //pick the first if multiple is false
    if (!props.multiple) {
      files = files.splice(0, 1);
    }

    if (files.length <= 0) {
      return;
    }

    const fileData = files[0];
    const actionData: any[] = [];
    for (let i = 0; i < files.length; i++) {
      const fileData = files[i];
      actionData.push({
        name: fileData.name,
        type: fileData.type,
        size: fileData.size,
        content: await uploadHelper.fileToBase64(fileData),
      });
    }

    dispatch(
      confirmAsk(
        `${txt.get("generic.attach")}.`,
        files.length === 1
          ? `${txt.get(
              "orders.order.attachments.attach_confirm",
              fileData.name
            )}<br/><br/><span style="font-size:14px">(${txt.get(
              "orders.order.attachments.attach_file_type_explanation"
            )})</span>`
          : `${txt.get("orders.order.attachments.attach_confirm_multiple")}<br><br><ul>${files
              .map((file) => `<li>${file.name}</li>`)
              .join("")}</ul><br/><span style="font-size:14px">(${txt.get(
              "orders.order.attachments.attach_file_type_explanation"
            )})</span>`,
        "copy_paste_file",
        actionData
      )
    );
  };

  const alertConfirm = useSelector(confirmGet);

  useEffect(() => {
    if (alertConfirm.actionState === AlertConfirmActionState.Perform && alertConfirm.actionKey === "copy_paste_file") {
      handleUpload(alertConfirm.actionData);
    }
  }, [alertConfirm]);

  const handleUpload = async (files: any[]) => {
    const toApiAttachOptions = (attachmentOptions: MMFileUploadAttachmentOptions) => {
      const { attachedToType, attachedToId, orderId, type } = attachmentOptions;

      const options: any = {};

      if (attachedToId) {
        options.folder = `${attachedToId}`;
        if (attachedToType === AttachedToType.Order) {
          options.order_id = attachedToId;
        } else {
          options.attached_to_id = attachedToId;
          options.order_id = orderId;
        }
      } else {
        options.folder = `_temp_${AuthenticationHelper.getUsername()}`;
      }

      options.attach_to_type = attachedToType || "";
      options.attach_type = type || "";
      return options;
    };

    try {
      console.log("handleUpload", files);
      setIsLoading(true);

      if (!props.attachmentOptions) {
        console.warn("Attachment Options missing");
        return;
      }
      // Todo: abstract this to any type and let server decide where to store
      if (
        ![
          AttachedToType.Order,
          AttachedToType.Referral,
          AttachedToType.OrderLine,
          AttachedToType.Fitting,
          AttachedToType.OrderCosting,
        ].includes(props.attachmentOptions.attachedToType as AttachedToType)
      ) {
        console.warn("Unknown attachedToType");
        return;
      }

      const attachOptions = toApiAttachOptions(props.attachmentOptions);

      for (let i = 0; i < files.length; i++) {
        const fileData = files[i];

        const file = await uploadHelper.upload("orders/attach", "attach_file", fileData.content, {
          filename: fileData.name,
          file_type: fileData.type,
          ...attachOptions,
        });

        if (!file.attachment) {
          file.attachment = {
            type: attachOptions.attach_type,
          };
        }

        trackEvent(
          "order",
          `${attachOptions.attached_to_type || "order"}_uploaded_${attachOptions.attach_type || "attachment"}`,
          `order ${attachOptions.attached_to_id || "_temp"}`
        );

        if (props.onAdded) {
          props.onAdded(file);
        }
      }

      setIsLoading(false);
    } catch (e: any) {
      const { title, message, importance, code } = formatApiError(e);
      dispatch(toastAdd(title, message, importance, code));
    }
  };

  const handleSelect = async () => {
    console.log("handleSelect");

    const selectedFiles: FileResult[] | string = await uploadHelper.selectFiles(
      (info: string) => {
        if (info === "uploading") {
          setIsLoading(true);
        }
      },
      props.multiple,
      props.fileTypes
    );
    if (typeof selectedFiles === "string") {
      console.log("nothing selected?");
    } else {
      //heic/heif files are not types by select, so force the file type prop
      const filesData = selectedFiles.map((file: FileResult) => ({
        name: file.file?.name,
        type: file.file?.type
          ? file.file?.type
          : file.file?.name && (file.file.name.endsWith(".heic") || file.file.name.endsWith(".heif"))
            ? "image/heic"
            : "",
        size: file.file?.size,
        content: file.content,
      }));

      await handleUpload(filesData);
    }

    setIsLoading(false);
  };

  return (
    <EuiPanel
      style={{
        padding: props.size && props.size === "large" ? "40px 20px 80px" : "10px",
      }}
      data-testid={props["data-testid"] ? props["data-testid"] : undefined}
      className="paste-and-drag-location"
      tabIndex={0}
      onDragEnter={(e: any) => {
        e.target.focus();
      }}
      onDragLeave={(e: any) => {
        e.target.blur();
      }}
      onDragOver={(e: any) => {
        e.preventDefault();
      }}
      onPaste={(e: any) => handleCopyPaste(e)}
      onDrop={(e: any) => handleCopyPaste(e)}
      onClick={(e: any) => handleSelect()}
    >
      <EuiFlexGroup
        gutterSize={props.size && props.size === "large" ? "m" : "s"}
        alignItems="center"
        direction={props.size && props.size === "large" ? "column" : "row"}
      >
        {isLoading ? (
          <EuiLoadingSpinner size="s" />
        ) : (
          <EuiIcon type="documents" size={props.size && props.size === "large" ? "l" : "s"} />
        )}
        {props.title ? (
          <EuiTitle size="xxs">
            <EuiText color={props.isInvalid ? "danger" : "subdued"}>{props.title}</EuiText>
          </EuiTitle>
        ) : (
          <></>
        )}
        <EuiText
          size="s"
          color={props.isInvalid ? "danger" : "grey"}
          style={{
            fontSize: "12px",
            lineHeight: "1.3em",
            textAlign: props.size && props.size === "large" ? "center" : "left",
          }}
        >
          {props.title
            ? txt.html("orders.order.attachments.drag_paste_info.any")
            : txt.html(
                `orders.order.attachments.drag_paste_info.${
                  props.attachmentOptions && props.attachmentOptions.type ? props.attachmentOptions.type : "any"
                }`
              )}
        </EuiText>
      </EuiFlexGroup>
    </EuiPanel>
  );
}

export default MMFileUpload;
