import {
  EuiBadge,
  EuiBasicTable,
  EuiButton,
  EuiCheckbox,
  EuiComboBox,
  EuiFilterButton,
  EuiFilterGroup,
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiIcon,
  EuiSpacer,
  EuiSwitch,
  EuiText,
  EuiTitle,
  EuiToolTip,
} from "@elastic/eui";
import { ApiResponse, ApiResponseStatus } from "api/api-helper";
import ClientsAPIHelper from "api/clients-api.helper";
import ConnectAPIHelper from "api/connect-api-helper";
import { MMLink } from "components/layouts/navigation/link";
import MMPage from "components/layouts/page/page";
import MMCell from "components/layouts/table/cell";
import MMColumnFormatted from "components/layouts/table/column-formatted";
import { feat, Feature } from "feats";
import AuthenticationHelper from "helpers/authentication-helper";
import DateHelper from "helpers/date-helper";
import { formatApiError } from "helpers/error-helper";
import { productDescription, productLabelFromTranslation } from "helpers/product-name-helper";
import StringHelper from "helpers/string-helper";
import txt from "helpers/text-helper";
import { toSelectOptions } from "hoc/helper-hooks";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toastAdd } from "store/components/toast/toast";
import { Client, clientName } from "store/data/client/client";
import { AGPH1_FIRST, AGPH1_NIGHT, AGPH1_REPEAT, toAGPHDescription } from "store/data/contract/contract";
import { Costing, costingTypeDescription, OrderCostingType } from "store/data/costing/costing";
import { indicationDescription } from "store/data/indication/indication";
import { Insurer } from "store/data/insurer/insurer";
import { VlotInvoiceDetails } from "store/data/invoices/vlot-invoice-details";
import {
  OrderLineStatus,
  orderLineStatusDescription,
  orderLineToHealth,
  ORDER_LINE_EMPTY,
} from "store/data/order/order";
import { OrganisationOrdering } from "store/data/organisation/organisation";
import { formalName, fullName, PersonalDetails } from "store/data/personal-details/personal-details";
import { ProductUse } from "store/data/product/product";
import { ReferralStatus, referralStatusDescription, referralToHealth } from "store/data/referral/referral";
import { Referrer } from "store/data/referrer/referrer";
import { handDescription } from "store/data/scan/scan";
import { useLocalStorage } from "store/local-storage";

export const PAGE_SIZE_OPTIONS = [10, 25, 50, 100]; //,0]; //0 would mean without limit
export const DEFAULT_PAGE_SIZE = 10;
export const SEARCH_DEBOUNCE_DELAY = 700;
export const DEFAULT_SORT_BY = "order";
export const DEFAULT_SORT_ORDER = "desc";

function MMDeclarations() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const api = new ConnectAPIHelper();
  const [buttonsToShow, setButtonsToShow] = useState<any[]>([]);
  const [insurers, setInsurers] = useState<Insurer[]>([]);
  const [practitioners, setPractitioners] = useState<PersonalDetails[]>([]);
  const [costingsWithInputError, setCostingsWithInputError] = useState<any>({});

  const [selectedInsurers, setSelectedInsurers] = useLocalStorage("declarations_insurers", []);

  const [selectedOrdering, setSelectedOrdering] = useLocalStorage("declarations_ordering", [
    OrganisationOrdering.Internal,
  ]);

  const [selectedPractitioners, setSelectedPractitioners] = useLocalStorage("declarations_practitioners", []);

  const [incompleteReasons, setIncompleteReasons] = useState<any[]>([]);
  const [selectedIncompleteReasons, setSelectedIncompleteReasons] = useLocalStorage("declarations_reasons", []);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [clients, setClients] = useState<any>({});
  const [isOnlyDelivered, setIsOnlyDelivered] = useLocalStorage("declarations_is_delivered", true);
  const [isOnlyValued, setIsOnlyValued] = useLocalStorage("declarations_is_valued", true);
  const [isOnlyFirsts, setIsOnlyFirsts] = useLocalStorage("declarations_is_firsts", true);

  const [referredType, setReferredType] = useLocalStorage("declarations_referred_type", ["ACCEPTED", "NOT_APPLICABLE"]);

  const [isOnlyRationale, setIsOnlyRationale] = useLocalStorage("declarations_is_rationale", true);
  const [isOnlyComplete, setIsOnlyComplete] = useLocalStorage("declarations_is_complete", null);
  const isVlotMatchingShown = feat(Feature.OrdersVlotMatching);

  const [costings, setCostings] = useState<Costing[]>([]);
  const [limit, setLimit] = useLocalStorage("declarations_limit", DEFAULT_PAGE_SIZE);
  const [offset, setOffset] = useLocalStorage("declarations_offset", 0);
  const [total, setTotal] = useState(0);
  const [sortBy, setSortBy] = useLocalStorage("declarations_sort_by", DEFAULT_SORT_BY);
  const [sortOrder, setSortOrder] = useLocalStorage("declarations_sort_order", DEFAULT_SORT_ORDER);

  useEffect(() => {
    console.log("costingsWithInputError", { costingsWithInputError });
  }, [costingsWithInputError]);

  const loadCostings = async () => {
    setIsLoading(true);
    let filters: any = {};
    filters.ready_to_invoice = true;
    if (isOnlyRationale) {
      filters.is_with_rationale = true;
    }
    if (isOnlyValued) {
      filters.is_with_order_value = true;
    }

    if (referredType) {
      if (referredType === "ok") {
        filters.referral_status = ["ACCEPTED", "NOT_APPLICABLE"];
      } else if (referredType === "rejected") {
        filters.referral_status = ["REJECTED"];
      } else if (referredType === "added") {
        filters.referral_status = ["SUBMITTED"];
      } else if (referredType === "pending") {
        filters.referral_status = ["PENDING"];
      }
    }
    if (isOnlyDelivered) {
      filters.order_line_status = ["DELIVERED", "FINALIZED"];
    }
    if (isOnlyFirsts) {
      filters.order_line_order_type = ["1st fitting", "Repeat: previous Manometric", "Repeat: previous not Manometric"];
    }

    if (selectedIncompleteReasons.length > 0) {
      filters.incomplete_reasons = selectedIncompleteReasons.map((reason: any) => reason.label);
    }
    if (isOnlyComplete === true) {
      filters.is_complete = true;
    } else if (isOnlyComplete === false) {
      filters.is_complete = false;
    } else if (isOnlyComplete === "null") {
      filters.is_complete = "null";
    }

    if (selectedInsurers && selectedInsurers.length > 0) {
      filters.insurer_ids = selectedInsurers.map((insurer: any) => insurer["data-id"]);
    }

    if (selectedPractitioners && selectedPractitioners.length > 0) {
      filters.practitioner_ids = selectedPractitioners.map((practitioner: any) => practitioner["data-id"]);
    }
    if (selectedOrdering && selectedOrdering.length > 0) {
      filters.organisation_ordering = selectedOrdering;
    }

    const result: ApiResponse = await api.getCostings(filters, limit, offset, costingFieldToSortKey(sortBy), sortOrder);

    if (result.status === ApiResponseStatus.OK) {
      setCostings(result.result || []);
      setTotal(result.meta_data.result_set.total);
    } else {
      setCostings([]);
      setTotal(0);
      const { title, message, importance, code } = formatApiError(result);
      dispatch(toastAdd(title, message, importance, code));
    }

    setIsLoading(false);
  };

  useEffect(() => {
    const clientApi: ClientsAPIHelper = new ClientsAPIHelper();

    const handleClientSummaries = async (costings: Costing[]) => {
      if (await AuthenticationHelper.hasPermission("client_details#read_all")) {
        const clientCodes: any[] = [
          ...Array.from(new Set(costings.map((costing: Costing) => costing.order_line?.order?.client_code || ""))),
        ].filter((code: string) => code !== "");

        if (clientCodes.length > 0) {
          const result: any = await clientApi.getClientSummaryByClientCodes(clientCodes, true);
          if (result && result.status === ApiResponseStatus.OK) {
            const lookup: any = {};
            for (let i = 0; i < result.result.length; i++) {
              const client: Client = result.result[i];
              if (client.client_code) {
                lookup[client.client_code] = client;
              }
            }

            setClients(lookup);
          }
        } else {
          setClients({});
        }
      } else {
        setClients({});
      }
    };

    handleClientSummaries(costings || []);
  }, [costings]);

  useEffect(() => {
    const load = async () => {
      const insurers = await api.getAdminInsurers(undefined, undefined, "name", "asc");
      setInsurers(insurers.result);

      let practitioners = await api.getPractitioners();
      if (practitioners) {
        practitioners.sort((a: PersonalDetails, b: PersonalDetails) =>
          `${a.first_name}${a.last_name}` < `${b.first_name}${b.last_name}`
            ? -1
            : `${a.first_name}${a.last_name}` > `${b.first_name}${b.last_name}`
              ? 1
              : 0
        );
        practitioners = practitioners.map((practitioner: PersonalDetails) => ({
          ...practitioner,
          name: fullName(practitioner),
        }));
      }
      setPractitioners(practitioners);

      const reasons = await api.getIncompleteReasons();
      setIncompleteReasons(reasons.map((reason: string) => ({ label: reason })));
    };
    load();
  }, []);

  useEffect(() => {
    loadCostings();
  }, [
    sortOrder,
    sortBy,
    limit,
    offset,
    isOnlyDelivered,
    isOnlyFirsts,
    referredType,
    isOnlyRationale,
    isOnlyComplete,
    isOnlyValued,
    selectedIncompleteReasons,
    selectedInsurers,
    selectedPractitioners,
    selectedOrdering,
  ]);

  const addCostingInputError = (costing: Costing, field: string) => {
    console.log("addCostingInputError", costing.id, field);
    let updatedCostingsWithInputError: any = { ...costingsWithInputError };
    if (costing.id && !updatedCostingsWithInputError[costing.id]?.field) {
      if (!updatedCostingsWithInputError[costing.id]) {
        updatedCostingsWithInputError[costing.id] = {};
        updatedCostingsWithInputError[costing.id][field] = true;
      }
    }
    setCostingsWithInputError((old: any) => updatedCostingsWithInputError);
  };

  const removeCostingInputError = (costing: Costing, field?: string) => {
    console.log("removeCostingInputError", costing.id, field);
    let updatedCostingsWithInputError: any = { ...costingsWithInputError };
    if (field) {
      if (costing.id && updatedCostingsWithInputError[costing.id]?.field) {
        delete updatedCostingsWithInputError[costing.id][field];
      }
    } else {
      if (costing.id && updatedCostingsWithInputError[costing.id]) {
        delete updatedCostingsWithInputError[costing.id];
      }
    }
    setCostingsWithInputError((old: any) => updatedCostingsWithInputError);
  };

  useEffect(() => {
    const setInterfaceForPermissions = async () => {
      let buttons: any[] = [];
      if (await AuthenticationHelper.hasPermission(["finance#read_all"])) {
        buttons.push(
          <EuiButton
            aria-label={txt.get("finance.page_title")}
            size="s"
            style={{ width: "40px", minInlineSize: "40px" }}
            iconType="stats"
            onClick={() => navigate("/finance")}
          />
        );
      }

      if (await AuthenticationHelper.hasPermission(["referrals#edit_all"])) {
        buttons.push(
          <EuiButton size="s" onClick={() => navigate("/finance/referrals")}>
            {txt.get("referrals.page_title")}
          </EuiButton>
        );
      }
      if (await AuthenticationHelper.hasPermission(["invoices#edit_all"])) {
        buttons.push(
          <EuiButton size="s" onClick={() => navigate("/finance/order-values")}>
            {txt.get("finance.order_values.page_title")}{" "}
          </EuiButton>
        );
      }
      if (await AuthenticationHelper.hasPermission(["invoices#edit_all"])) {
        buttons.push(
          <EuiButton size="s" onClick={() => navigate("/finance/authorizations")}>
            {txt.get("finance.authorizations.page_title")}
          </EuiButton>
        );
      }
      if (await AuthenticationHelper.hasPermission(["invoices#read_all"])) {
        buttons.push(
          <EuiButton fill={true} size="s" onClick={() => navigate("/finance/declarations")}>
            {txt.get("finance.declarations.page_title")}
          </EuiButton>
        );
      }
      setButtonsToShow(buttons);
    };
    setInterfaceForPermissions();
  }, []);

  const onInsurerChange = (selectedOptions: any[]) => {
    setSelectedInsurers(selectedOptions);
  };

  const onPracititionerChange = (selectedOptions: any[]) => {
    setSelectedPractitioners(selectedOptions);
  };

  const resultCountInfo = () =>
    isLoading
      ? ""
      : total === 0
        ? `${txt.uf("generic.found_no_x", txt.get("finance.declarations.lines"))}.`
        : `${txt.uf(
            "generic.showing_x_of_y_found_z",
            limit === 0 ? txt.get("generic.all") : `${offset + 1}-${Math.min(total, offset + limit)}`,
            total,
            txt.get("finance.declarations.lines")
          )}.`;

  const limitOffsetToPage = (limit: number, offset: number) => {
    //pages in EUI are zero based
    const page = limit > 0 ? Math.max(0, offset / limit) : 0;
    return page;
  };

  const pagination: any = {
    pageIndex: limitOffsetToPage(limit, offset),
    pageSize: limit,
    totalItemCount: total,
    pageSizeOptions: PAGE_SIZE_OPTIONS,
    showPerPageOptions: true,
  };

  const sorting: any = {
    sort: {
      field: sortBy,
      direction: sortOrder,
    },
    enableAllColumns: false,
    // readOnly: false,
  };

  const costingFieldToSortKey = (field: string) => {
    switch (field) {
      default:
        return field;
    }
  };

  const handleIsInvoicedToggle = async (costing: Costing, value: boolean) => {
    if (!costing.id) {
      console.error("trying to update costing without id");
      return;
    }

    let fields: any = {};
    fields.is_invoiced = value;
    const result: ApiResponse = await api.updateCostingFields(costing.id, fields);

    if (result && result.status === ApiResponseStatus.OK) {
      const updatedCosting: Costing = result.result;
      const updatedCostings: Costing[] = costings.map((costing: Costing) =>
        costing.id === updatedCosting.id ? updatedCosting : costing
      );

      setCostings((old: Costing[]) => updatedCostings);
    } else {
      console.error("something went wrong", result.message);
    }
  };

  const handleVlotOrderDetailChange = async (field: string, value: string, costing: Costing) => {
    if (costing.order_line) {
      let result: ApiResponse;
      if (costing.order_line.vlot_invoice_detail && costing.order_line.vlot_invoice_detail.id) {
        result = await api.updateInvoiceDetails(
          costing.order_line.vlot_invoice_detail.id,
          costing.order_line.id,
          field === "vlot_order_id" && !isNaN(parseInt(value))
            ? parseInt(value)
            : costing.order_line.vlot_invoice_detail.vlot_order_id,
          field === "vlot_order_line_id" ? value : costing.order_line.vlot_invoice_detail.vlot_order_line_id
        );
      } else {
        result = await api.createInvoiceDetails(
          costing.order_line.id,
          field === "vlot_order_id" && !isNaN(parseInt(value)) ? parseInt(value) : null,
          field === "vlot_order_line_id" ? value : null
        );
      }
      if (result && result.status === ApiResponseStatus.OK) {
        const updatedVlotInvoiceDetails: VlotInvoiceDetails = result.result;
        const updatedCosting: Costing = { ...costing };
        if (!updatedCosting.order_line) {
          updatedCosting.order_line = ORDER_LINE_EMPTY; //should not happen......
        }
        updatedCosting.order_line.vlot_invoice_detail = updatedVlotInvoiceDetails;

        const updatedCostings: Costing[] = costings.map((costing: Costing) =>
          costing.id === updatedCosting.id ? updatedCosting : costing
        );
        setCostings(updatedCostings);
        removeCostingInputError(costing, field);
      } else {
        addCostingInputError(costing, field);
        dispatch(toastAdd(result.message, null, "danger"));
      }
    }
  };

  const unmatchInvoice = async (costing?: Costing) => {
    if (!costing?.order_line?.vlot_invoice_detail?.id) {
      console.error("no invoice details id");
      return;
    }

    const result: ApiResponse = await api.invoiceUnmatch(costing.order_line.vlot_invoice_detail.id);
    if (result && result.status === ApiResponseStatus.OK) {
      const updatedCosting: Costing = { ...costing };
      if (!updatedCosting.order_line) {
        updatedCosting.order_line = ORDER_LINE_EMPTY; //should not happen......
      }
      if (updatedCosting.order_line.vlot_invoice_detail) {
        delete updatedCosting.order_line.vlot_invoice_detail;
      }

      const updatedCostings: Costing[] = costings.map((costing: Costing) =>
        costing.id === updatedCosting.id ? updatedCosting : costing
      );
      setCostings((old: Costing[]) => updatedCostings);
    }
  };

  const handleOrderingChange = (changedOrdering: OrganisationOrdering) => {
    let current: OrganisationOrdering[] = [...selectedOrdering];
    if (current.includes(changedOrdering)) {
      current = [];
      // current.filter(
      //   (ordering: OrganisationOrdering) => ordering !== changedOrdering
      // );
    } else {
      current = [changedOrdering];
    }
    setSelectedOrdering(current);
  };

  const onCostingsChange = ({ page = {} as any, sort = {} as any }) => {
    if (page.size) {
      const newLimit = page.size;
      const newOffset = Math.max(0, page.index * page.size);
      if (limit !== newLimit) setLimit(newLimit);
      if (offset !== newOffset) setOffset(newOffset);
    }
    if (sort.field) {
      setSortBy(sort.field);
      setSortOrder(sort.direction ?? "asc");
    }
  };

  const renderGPHForCosting = (costing?: Costing) => {
    if (costing?.contract?.product_declaration_additional_code1) {
      const options: string[] = costing?.contract?.product_declaration_additional_code1.split(",");

      if (costing?.order_line?.order_type.toLowerCase().startsWith("repeat:") && options.includes(AGPH1_REPEAT)) {
        return (
          <EuiToolTip position="top" content={toAGPHDescription(AGPH1_REPEAT)}>
            <EuiText size="s">{AGPH1_REPEAT} </EuiText>
          </EuiToolTip>
        );
      }

      if ((costing?.order_line?.use as ProductUse) === ProductUse.Night && options.includes(AGPH1_NIGHT)) {
        return (
          <EuiToolTip position="top" content={toAGPHDescription(AGPH1_NIGHT)}>
            <EuiText size="s">{AGPH1_NIGHT} </EuiText>
          </EuiToolTip>
        );
      }

      if (options.includes(AGPH1_FIRST)) {
        return (
          <EuiToolTip position="top" content={toAGPHDescription(AGPH1_FIRST)}>
            <EuiText size="s">{AGPH1_FIRST} </EuiText>
          </EuiToolTip>
        );
      }
    }
    return "";
  };

  const renderAuthorization = (authorization: boolean, costing: Costing) =>
    authorization ? (
      <></>
    ) : (
      <EuiFlexItem>
        <EuiText size="xs" color="gray" style={{ whiteSpace: "nowrap" }}>
          {txt.get("orders.order.costing.no_authorization_needed")}
        </EuiText>
      </EuiFlexItem>
    );
  const renderIsQuoted = (isQuoted: boolean, costing: Costing) => (
    <EuiFlexGroup direction="column" gutterSize="none">
      <EuiFlexItem>
        <EuiText size="xs" style={{ whiteSpace: "nowrap" }}>
          {txt.get("orders.order.costing.is_quoted")}
          <EuiIcon size="s" type={isQuoted ? "check" : "cross"} />
        </EuiText>
      </EuiFlexItem>
      <EuiFlexItem>
        {isQuoted ? (
          <EuiText size="xs" color="gray">
            {costing.quotation_code}
          </EuiText>
        ) : (
          <></>
        )}
      </EuiFlexItem>
    </EuiFlexGroup>
  );

  const renderIsAuthorized = (isAuthorized: boolean, costing: Costing) => (
    <EuiFlexGroup direction="column" gutterSize="none">
      <EuiFlexItem>
        <EuiFlexItem>
          <EuiText size="xs" style={{ whiteSpace: "nowrap" }}>
            {txt.get("orders.order.costing.is_authorized")}
            <EuiIcon size="s" type={isAuthorized ? "check" : "cross"} />
          </EuiText>
        </EuiFlexItem>
      </EuiFlexItem>
      <EuiFlexItem>
        {isAuthorized ? (
          <EuiText size="xs" color="gray">
            {costing.authorization_code ? costing.authorization_code : ""}
            {costing.authorization_code ? <br /> : <></>}
            {costing.authorization_code_received_at ? DateHelper.toDate(costing.authorization_code_received_at) : ""}
          </EuiText>
        ) : (
          <></>
        )}
      </EuiFlexItem>
    </EuiFlexGroup>
  );

  const renderIsInvoiced = (isInvoiced: boolean, costing: Costing) => (
    <EuiFlexItem data-testid="declaration-authorization-info-is-invoiced-holder">
      <EuiSwitch
        data-testid="declaration-authorization-info-is-invoiced"
        compressed={true}
        label={
          <EuiText size="xs" style={{ whiteSpace: "nowrap" }}>
            {isInvoiced ? txt.get("generic.yes") : txt.get("generic.no")}
          </EuiText>
        }
        checked={isInvoiced}
        onChange={(e) => {
          handleIsInvoicedToggle(costing, !isInvoiced);
        }}
      />
    </EuiFlexItem>
  );

  const renderVlotOrderDetail = (costing: Costing) => (
    <EuiFlexGrid gutterSize="none" data-testid="declaration-vlot-info">
      <EuiFlexItem>
        <MMColumnFormatted
          isInvalid={costing.id && costingsWithInputError[costing.id]?.vlot_order_id}
          value={costing.order_line?.vlot_invoice_detail?.vlot_order_id?.toString() || ""}
          noWrap={true}
          isEditable={true}
          placeholder={
            <EuiText size="xs">
              <EuiIcon type="pencil" size="s" /> {txt.get("admin.invoices.vlot_order_id")}
            </EuiText>
          }
          onChange={(value: string) => handleVlotOrderDetailChange("vlot_order_id", value, costing)}
        />
      </EuiFlexItem>
      <EuiFlexItem>
        <MMColumnFormatted
          isInvalid={costing.id && costingsWithInputError[costing.id]?.vlot_order_line_id}
          value={costing.order_line?.vlot_invoice_detail?.vlot_order_line_id?.toString() || ""}
          noWrap={true}
          isEditable={true}
          placeholder={
            <EuiText size="xs" color="#aaaaaa">
              <EuiIcon type="pencil" size="s" color="#aaaaaa" /> {txt.get("admin.invoices.vlot_order_line_id")}
            </EuiText>
          }
          onChange={(value: string) => handleVlotOrderDetailChange("vlot_order_line_id", value, costing)}
        />
        {costing.order_line?.vlot_invoice_detail?.vlot_order_date ? (
          <EuiText size="xs" color="subdued">
            {DateHelper.toDate(costing.order_line?.vlot_invoice_detail?.vlot_order_date)}
          </EuiText>
        ) : (
          <></>
        )}
        {costing.order_line?.vlot_invoice_detail?.id ? (
          <EuiIcon
            style={{ cursor: "pointer" }}
            onClick={() => {
              if (costing.order_line?.vlot_invoice_detail) {
                unmatchInvoice(costing);
              }
            }}
            size="s"
            type={"unlink"}
          />
        ) : (
          <></>
        )}
      </EuiFlexItem>
    </EuiFlexGrid>
  );

  const columns = () => {
    let columns = [
      {
        name: txt.get("admin.invoices.order_id"),
        field: "id",
        sortable: api.costingIsSortableBy(costingFieldToSortKey("order")),
        style: { minWidth: "60px" },
        render: (id: number, costing: Costing) => (
          <EuiFlexGroup gutterSize="xs" direction="column" alignItems="center">
            <MMCell
              data-testid="declaration-order-info"
              text={
                costing.order_line?.order?.id ? (
                  <MMLink href={`?order=${costing.order_line?.order?.id}&order_tab=costing`}>
                    {costing.order_line.order_id}
                  </MMLink>
                ) : (
                  "-"
                )
              }
              subText={
                costing.order_line?.order?.ordered_at ? DateHelper.toDate(costing.order_line?.order?.ordered_at) : "-"
              }
            />
            {costing?.order_line ? (
              <EuiFlexItem
                grow={false}
                data-testid="declaration-order-line-status"
                data-status={costing.order_line.status}
              >
                <EuiBadge color={orderLineToHealth(costing.order_line.status as OrderLineStatus)}>
                  {orderLineStatusDescription(costing.order_line.status as OrderLineStatus)}
                </EuiBadge>
              </EuiFlexItem>
            ) : (
              <></>
            )}
            {costing?.order_line ? (
              <EuiFlexItem grow={false} data-testid="declaration-order-line-delivery-date">
                <EuiText size="s">
                  {costing.order_line.status_date_delivery
                    ? DateHelper.toDate(costing.order_line.status_date_delivery)
                    : ""}
                </EuiText>
              </EuiFlexItem>
            ) : (
              <></>
            )}
          </EuiFlexGroup>
        ),
      },
      {
        name: txt.get("orders.order.product"),
        field: "order_line.code",
        sortable: api.costingIsSortableBy(costingFieldToSortKey("order_line.code")),
        render: (code: string, costing: Costing) => {
          return (
            <EuiFlexItem style={{ minWidth: "100px" }}>
              <MMCell
                data-testid="declaration-order-line-product"
                text={
                  <EuiText size="s">
                    <b>{productLabelFromTranslation(costing?.order_line?.translations)}</b>
                    <br />
                    {productDescription(costing?.order_line?.product_selection, costing?.order_line?.translations)}
                  </EuiText>
                }
                subText={
                  <EuiText size="s">
                    {costing.order_line?.order_type}
                    <br />
                    {code}
                    <br />
                    {`${costing.order_line?.hand ? handDescription(costing.order_line.hand) : ""} ${costing.order_line?.digits ? ` ${costing.order_line?.digits.join(",")}` : ""}`}
                  </EuiText>
                }
                subSoft={true}
              />
            </EuiFlexItem>
          );
        },
      },
      {
        name: txt.get("orders.order.client_code"),
        field: "order_line.order.client_code",
        sortable: api.costingIsSortableBy(costingFieldToSortKey("order_line.order.client_code")),
        style: { minWidth: "75px" },
        render: (client_code: string) => (
          <EuiFlexItem style={{ minWidth: "100px" }}>
            <MMCell
              data-testid="declaration-client"
              bold={true}
              text={client_code ? client_code : "-"}
              subText={
                client_code && clients && clients[client_code] ? (
                  <EuiText size="s" style={{ maxWidth: "150px", whiteSpace: "normal" }}>
                    {clientName(clients[client_code])}
                    <br />
                    {clients[client_code].birth_date ? DateHelper.toDate(clients[client_code].birth_date) : ""}
                  </EuiText>
                ) : (
                  ""
                )
              }
              wrap={false}
            />
          </EuiFlexItem>
        ),
      },
      {
        name: txt.get("referrals.name"),
        sortable: api.costingIsSortableBy(costingFieldToSortKey("order_line.order.referral_id")),
        field: "order_line.order.referral.referrer",
        render: (referrer: Referrer, costing: Costing) => (
          <EuiFlexGroup direction="column" gutterSize="none" style={{ minWidth: "100px" }}>
            {referrer && referrer.person ? (
              <MMCell
                data-testid="declaration-referrer"
                text={formalName(referrer.person)}
                subSoft={true}
                subText={
                  referrer.alternative_organisation
                    ? referrer.alternative_organisation
                    : referrer.organisation
                      ? referrer.organisation.name
                      : "-"
                }
              />
            ) : (
              "-"
            )}
            {costing?.order_line?.order?.referral ? (
              <MMCell
                data-testid="declaration-referral"
                text={indicationDescription(
                  costing?.order_line?.order?.referral.indications,
                  costing.order_line?.hand || undefined
                )}
                subText={DateHelper.toDate(costing?.order_line.order.referral.referred_at)}
              />
            ) : (
              <></>
            )}
            {costing?.order_line?.order?.referral ? (
              <EuiFlexItem
                style={{ display: "inline-block" }}
                data-testid="declaration-referral-status"
                data-status={costing.order_line.order.referral.status}
              >
                <EuiBadge color={referralToHealth(costing.order_line.order.referral.status as ReferralStatus)}>
                  {referralStatusDescription(costing.order_line.order.referral.status as ReferralStatus)}
                </EuiBadge>
              </EuiFlexItem>
            ) : (
              <></>
            )}
          </EuiFlexGroup>
        ),
      },
      {
        name: txt.get("orders.order.practitioner"),
        sortable: false,
        field: "order_line.order.personal_details",
        render: (practitioner: PersonalDetails, costing: Costing) => (
          <MMCell
            data-testid="declaration-pracitioner"
            wrap={true}
            text={practitioner ? fullName(practitioner) : "-"}
            subText={costing.order_line?.order?.location ? costing.order_line?.order?.location.name : "-"}
            subSoft={true}
          />
        ),
      },
      {
        name: txt.get("admin.insurers.name"),
        field: "contract.insurer_id",
        sortable: api.costingIsSortableBy(costingFieldToSortKey("contract.insurer_id")),
        style: { minWidth: "60px" },
        render: (id: number, costing: Costing) => (
          <MMCell
            data-testid="declaration-insurance"
            wrap={false}
            text={
              costing.order_line?.order?.costing?.type === OrderCostingType.InvoicePrivate
                ? costing.order_line?.order?.costing?.type
                  ? costingTypeDescription(costing.order_line?.order?.costing?.type)
                  : "-"
                : costing.contract?.insurer?.acronym || "-"
            }
            subText={
              costing.order_line?.order?.costing?.type === OrderCostingType.InvoicePrivate ? (
                ""
              ) : costing.order_line?.order?.client_code &&
                clients[costing.order_line?.order?.client_code] &&
                clients[costing.order_line?.order?.client_code].client_insurances &&
                clients[costing.order_line?.order?.client_code].client_insurances.length > 0 ? (
                <EuiToolTip content={clients[costing.order_line?.order?.client_code].client_insurances[0].insurer_name}>
                  <EuiText size="s">
                    {clients[costing.order_line?.order?.client_code].client_insurances[0].insurer_uzovi_code}
                  </EuiText>
                </EuiToolTip>
              ) : (
                ""
              )
            }
          />
        ),
      },
      {
        name: txt.get("admin.contracts.invoice_line_description"),
        field: "contract.invoice_line_description",
        render: (invoice_line_description: string, costing: Costing) => (
          <MMCell
            data-testid="declaration-invoice-line"
            text={costing.contract?.insurer_product_code}
            subText={invoice_line_description}
            wrap={true}
          />
        ),
      },

      {
        name: `${txt.get("admin.contracts.product_declaration_code")} / ${txt.get("admin.contracts.product_declaration_additional_codes")}`,
        field: "contract.product_declaration_description",
        render: (product_declaration_description: string, costing: Costing) => (
          <EuiFlexGroup direction="column" gutterSize="none">
            <MMCell
              data-testid="declaration-declaration-info"
              text={product_declaration_description}
              subText={costing.contract?.product_declaration_code}
              subSoft={true}
              wrap={false}
            />
            <MMCell
              data-testid="declaration-gph-info"
              text={renderGPHForCosting(costing)}
              subText={
                costing.contract?.product_declaration_additional_code2
                  ? costing.contract.product_declaration_additional_code2
                      .split(",")
                      .map((codePart: string, i: number) => (
                        <EuiToolTip key={`code-${i}`} position="top" content={toAGPHDescription(codePart)}>
                          <EuiText size="s">{codePart} </EuiText>
                        </EuiToolTip>
                      ))
                  : ""
              }
            />
          </EuiFlexGroup>
        ),
      },

      {
        name: `${txt.get("orders.order.costing.amount")} (${txt.get("pdfs.quotation.vat_inclusive")} ${txt.get("pdfs.quotation.vat")})`,
        field: "amount",
        render: (amount: number, costing: Costing) =>
          costing.amount_effort ? (
            <EuiFlexGroup gutterSize="xs">
              <EuiFlexItem>
                <MMCell
                  data-testid="declaration-amount"
                  text={amount ? StringHelper.currency(amount) : "-"}
                  wrap={false}
                  subText={txt.get("orders.order.costing.amount_product")}
                  subSoft={true}
                />
              </EuiFlexItem>
              <EuiFlexItem>
                <MMCell
                  data-testid="declaration-amount-effort"
                  text={amount ? StringHelper.currency(costing.amount_effort) : "-"}
                  wrap={false}
                  subText={`${txt.get("orders.order.costing.amount_effort")}${costing.description_effort ? ` (${costing.description_effort})` : ""}`}
                  subSoft={true}
                />
              </EuiFlexItem>
            </EuiFlexGroup>
          ) : (
            <MMCell
              data-testid="declaration-amount"
              text={amount ? StringHelper.currency(amount) : "-"}
              wrap={false}
              subText={txt.get("orders.order.costing.amount_combined")}
              subSoft={true}
            />
          ),
      },
      {
        name: `${txt.get("orders.order.costing.authorization_needed")}`,
        field: "authorization",
        sortable: api.contractIsSortableBy(costingFieldToSortKey("authorization")),
        style: { minWidth: "60px" },
        render: (authorization: boolean, costing: Costing) => (
          <EuiFlexGrid gutterSize="none" data-testid="declaration-authorization-info">
            {renderAuthorization(costing.authorization || false, costing || null)}
            {costing.authorization ? renderIsQuoted(costing.is_quoted || false, costing || null) : <></>}
            {costing.authorization ? renderIsAuthorized(costing.is_authorized || false, costing || null) : <></>}
          </EuiFlexGrid>
        ),
      },

      {
        name: txt.get("orders.order.completion.completion"),
        field: "order_line.order.is_complete",
        render: (isComplete: boolean, costing: Costing) => (
          <EuiFlexGrid gutterSize="none">
            <EuiText
              color={isComplete === true ? "success" : isComplete === false ? "danger" : "text"}
              data-testid="declaration-completion-info"
              size="xs"
            >
              {isComplete === true
                ? txt.get("orders.order.completion.is_complete")
                : isComplete === false
                  ? txt.get("orders.order.completion.is_incomplete")
                  : "-"}
            </EuiText>
            {costing.order_line?.order?.incomplete_reasons ? (
              costing.order_line?.order?.incomplete_reasons.split(",").map((reason: string, i: number) => (
                <EuiText
                  color={isComplete === false ? "danger" : "text"}
                  data-testid="declaration-incomplete-reasons"
                  style={{ whiteSpace: "nowrap" }}
                  key={`reason-${i}`}
                  size="xs"
                >
                  {reason}
                </EuiText>
              ))
            ) : (
              <></>
            )}
          </EuiFlexGrid>
        ),
      },
    ];

    if (isVlotMatchingShown) {
      columns.push({
        name: txt.get("admin.invoices.vlot_order_and_line_id"),
        field: "contract.order_line.vlot_order_detail",
        render: (vlot_order_detail: any, costing: Costing) => renderVlotOrderDetail(costing),
      });
    }
    columns.push({
      name: txt.get("orders.order.costing.is_invoiced"),
      field: "is_invoiced",
      sortable: api.contractIsSortableBy(costingFieldToSortKey("is_invoiced")),
      style: { minWidth: "60px" },
      render: renderIsInvoiced,
    });

    return columns;
  };

  const getRowProps = (costing: Costing) => {
    return {
      "data-id": `row-${costing.id}`,
      "data-order-line-costing-id": `${costing.id}`,
      "data-order-line-id": `${costing.order_line?.id}`,
      "data-order-id": `${costing.order_line?.order_id}`,
      "data-testid": "costing-row",
    };
  };

  return (
    <MMPage title={txt.get("finance.declarations.page_title")} hideTitle={true} topActions={buttonsToShow}>
      <EuiSpacer size="xl" />

      <EuiFlexItem>
        <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
          <EuiIcon type="documentation" size="l" />
          <EuiTitle>
            <EuiText>{txt.get("finance.declarations.page_title")}</EuiText>
          </EuiTitle>
        </div>
        <EuiSpacer />
      </EuiFlexItem>
      <EuiFlexGroup alignItems="flexStart">
        <EuiFlexItem grow={false}>
          <EuiFilterGroup
            data-testid="declarations-filter-ordering"
            contentEditable={false}
            compressed={true}
            fullWidth={true}
          >
            <EuiFilterButton
              grow={true}
              hasActiveFilters={selectedOrdering.includes(OrganisationOrdering.Internal)}
              onClick={(e: any) => {
                handleOrderingChange(OrganisationOrdering.Internal);
              }}
            >
              {txt.get("finance.order_values.internal")}
            </EuiFilterButton>
            <EuiFilterButton
              grow={true}
              hasActiveFilters={selectedOrdering.includes(OrganisationOrdering.External)}
              onClick={(e: any) => {
                handleOrderingChange(OrganisationOrdering.External);
              }}
            >
              {txt.get("finance.order_values.external")}
            </EuiFilterButton>
            <EuiFilterButton
              grow={true}
              hasActiveFilters={selectedOrdering.includes(OrganisationOrdering.B2B)}
              onClick={(e: any) => {
                handleOrderingChange(OrganisationOrdering.B2B);
              }}
            >
              {txt.get("finance.order_values.b2b")}
            </EuiFilterButton>
            <EuiFilterButton
              grow={true}
              hasActiveFilters={selectedOrdering.includes(OrganisationOrdering.Private)}
              onClick={(e: any) => {
                handleOrderingChange(OrganisationOrdering.Private);
              }}
            >
              {txt.get("finance.order_values.private")}
            </EuiFilterButton>
          </EuiFilterGroup>
          <EuiSpacer size="xs" />

          <EuiComboBox
            data-testid="declarations-filter-insurers"
            compressed={true}
            style={{ width: "100%" }}
            aria-label={txt.uf("generic.select_x", txt.get("admin.insurers.name"))}
            placeholder={txt.uf("generic.select_x", txt.get("admin.insurers.name"))}
            // singleSelection={{ asPlainText: true }}
            options={toSelectOptions(insurers)}
            selectedOptions={selectedInsurers}
            onChange={onInsurerChange}
          />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiFilterGroup
            data-testid="declarations-filter-complete"
            contentEditable={false}
            compressed={true}
            fullWidth={true}
          >
            <EuiFilterButton
              className="danger"
              grow={true}
              hasActiveFilters={isOnlyComplete === false}
              onClick={(e: any) => {
                setIsOnlyComplete(isOnlyComplete === false ? null : false);
              }}
            >
              {txt.get("orders.order.completion.is_incomplete")}
            </EuiFilterButton>
            <EuiFilterButton
              grow={true}
              hasActiveFilters={isOnlyComplete === true}
              onClick={(e: any) => {
                setIsOnlyComplete(isOnlyComplete === true ? null : true);
              }}
            >
              {txt.get("orders.order.completion.is_complete")}
            </EuiFilterButton>
            <EuiFilterButton
              grow={true}
              hasActiveFilters={isOnlyComplete === "null"}
              onClick={(e: any) => {
                setIsOnlyComplete(isOnlyComplete === "null" ? null : "null");
              }}
            >
              {txt.get("orders.order.completion.not_checked")}
            </EuiFilterButton>
          </EuiFilterGroup>
          <EuiSpacer size="xs" />

          <EuiComboBox
            style={{ width: "100%" }}
            compressed={true}
            // singleSelection={{ asPlainText: true }}
            // isLoading={isLoading}
            color="accent"
            data-testid="declarations-filter-reasons"
            id="order-incomplete-reasons"
            fullWidth
            placeholder={txt.get("orders.order.completion.reasons")}
            options={incompleteReasons}
            selectedOptions={selectedIncompleteReasons}
            onChange={(selected) => setSelectedIncompleteReasons(selected)}
          />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiFilterGroup
            data-testid="declarations-filter-referral"
            contentEditable={false}
            compressed={true}
            fullWidth={true}
          >
            <EuiFilterButton
              className="danger"
              grow={true}
              hasActiveFilters={referredType === "pending"}
              onClick={(e: any) => {
                setReferredType(referredType === "pending" ? null : "pending");
              }}
            >
              {txt.get("finance.declarations.referred_type.pending")}
            </EuiFilterButton>
            <EuiFilterButton
              grow={true}
              hasActiveFilters={referredType === "added"}
              onClick={(e: any) => {
                setReferredType(referredType === "added" ? null : "added");
              }}
            >
              {txt.get("finance.declarations.referred_type.added")}
            </EuiFilterButton>
            <EuiFilterButton
              grow={true}
              hasActiveFilters={referredType === "rejected"}
              onClick={(e: any) => {
                setReferredType(referredType === "rejected" ? null : "rejected");
              }}
            >
              {txt.get("finance.declarations.referred_type.rejected")}
            </EuiFilterButton>
            <EuiFilterButton
              grow={true}
              hasActiveFilters={referredType === "ok"}
              onClick={(e: any) => {
                setReferredType(referredType === "ok" ? null : "ok");
              }}
            >
              {txt.get("finance.declarations.referred_type.ok")}
            </EuiFilterButton>
          </EuiFilterGroup>
          <EuiSpacer size="xs" />
          <EuiComboBox
            data-testid="declarations-filter-practitioner"
            compressed={true}
            style={{ width: "100%" }}
            aria-label={txt.uf("generic.select_x", txt.get("orders.order.practitioner"))}
            placeholder={txt.uf("generic.select_x", txt.get("orders.order.practitioner"))}
            // singleSelection={{ asPlainText: true }}
            options={toSelectOptions(practitioners)}
            selectedOptions={selectedPractitioners}
            onChange={onPracititionerChange}
          />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiCheckbox
            data-testid="declarations-filter-valued"
            id="is-only-valued"
            compressed={true}
            style={{ width: "225px" }}
            aria-label={txt.get("finance.declarations.only_valued")}
            label={txt.get("finance.declarations.only_valued")}
            onChange={(e: any) => setIsOnlyValued(e.target.checked)}
            checked={isOnlyValued}
          />
          <EuiCheckbox
            data-testid="declarations-filter-delivered"
            id="is-only-delivered"
            compressed={true}
            style={{ width: "225px" }}
            aria-label={txt.get("finance.declarations.only_delivered")}
            label={txt.get("finance.declarations.only_delivered")}
            onChange={(e: any) => setIsOnlyDelivered(e.target.checked)}
            checked={isOnlyDelivered}
          />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiCheckbox
            data-testid="declarations-filter-rationale"
            id="is-only-rationale"
            compressed={true}
            style={{ width: "225px" }}
            aria-label={txt.get("finance.declarations.only_rationale")}
            label={txt.get("finance.declarations.only_rationale")}
            onChange={(e: any) => setIsOnlyRationale(e.target.checked)}
            checked={isOnlyRationale}
          />
          <EuiCheckbox
            data-testid="declarations-filter-first"
            id="is-only-firsts"
            compressed={true}
            style={{ width: "225px" }}
            aria-label={txt.get("finance.declarations.only_firsts")}
            label={txt.get("finance.declarations.only_firsts")}
            onChange={(e: any) => setIsOnlyFirsts(e.target.checked)}
            checked={isOnlyFirsts}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiFlexGroup>
        <EuiSpacer size="s" />
        <EuiFlexItem>
          <EuiText textAlign="right" size="xs">
            {resultCountInfo()}
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size="s" />
      <EuiHorizontalRule margin="none" style={{ height: 1 }} />
      <EuiBasicTable
        loading={isLoading}
        tableLayout="auto"
        itemId="id"
        items={costings}
        columns={columns()}
        pagination={pagination}
        sorting={sorting}
        rowProps={getRowProps}
        noItemsMessage={isLoading ? txt.get("generic.loading") : "-"}
        onChange={onCostingsChange}
      />
    </MMPage>
  );
}

export default MMDeclarations;
