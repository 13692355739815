import { Image, Text, View } from "@react-pdf/renderer";
import { styles } from "../styles";

export interface MMPdfHeaderProps {
  title: string;
  withLogo?: boolean;
}

function MMPdfHeader(props: MMPdfHeaderProps) {
  const withLogo: boolean = props.withLogo !== undefined ? props.withLogo : true;
  return (
    <View fixed={true} style={styles.header}>
      <View style={styles.columns}>
        <View style={styles.section_8}>
          {withLogo ? <Image style={styles.logo} src="/pdf-assets/logo-manometric.png" /> : <></>}
        </View>
        <View style={styles.section_4}>
          <Text style={{ ...styles.title, textAlign: "right" }}>{props.title}</Text>
        </View>
      </View>
    </View>
  );
}

export default MMPdfHeader;
