import { EuiFlexGroup, EuiFlexItem, EuiProgress, EuiSpacer } from "@elastic/eui";
import ConnectAPIHelper from "api/connect-api-helper";
import MMPage, { PageLink } from "components/layouts/page/page";
import txt from "helpers/text-helper";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toastAdd } from "store/components/toast/toast";
import { Insurer, INSURER_EMPTY } from "store/data/insurer/insurer";
import { adminNav } from "../admin";
import MMInsurer from "./insurer";

function MMAdminInsurer() {
  const api = new ConnectAPIHelper();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [buttonsToShow] = useState<any[]>([]);
  const { insurerId } = useParams();
  const [insurer, setInsurer] = useState<Insurer | null>(null);
  const [specialties] = useState<string[]>([]);

  const isNew: boolean = insurerId === "new";
  const [subPages, setSubPages] = useState<PageLink[]>([]);
  useEffect(() => {
    const getSubPages = async () => {
      setSubPages(await adminNav("/admin/insurers"));
    };

    getSubPages();
  }, []);

  useEffect(() => {
    const loadInsurer = async () => {
      if (isNew) {
        setInsurer(INSURER_EMPTY);
      } else {
        const insurerIdParam: number = Number(insurerId);
        if (isNaN(insurerIdParam)) {
          navigate("/404");
        } else {
          const result = await api.getAdminInsurer(insurerIdParam);
          if (result.status === "OK" && result.result && result.result.id) {
            console.log("setting insurer", result.result);
            setInsurer(result.result);
          } else {
            navigate("/404");
          }
        }
      }
    };
    loadInsurer();
  }, [insurerId]);

  const saveInsurer = async (insurer: Insurer) => {
    let result;
    if (insurer.id) {
      result = await api.updateAdminInsurer(insurer.id, insurer);
    } else {
      result = await api.createAdminInsurer(insurer);
    }
    if (result.status === "OK") {
      if (!insurer.id) {
        navigate(`/admin/insurers/${result.result.insurer_id}`);
      }

      dispatch(toastAdd(txt.get("generic.is_saved", txt.get("admin.insurers.name")), null, "success"));
    } else {
      dispatch(toastAdd(result.message ? result.message : txt.get("admin.insurers.error_saving"), null, "danger"));
    }
  };

  // const handleDelete = async () => {
  //   dispatch(
  //     confirmAsk(
  //       `${txt.uf("generic.delete_x", txt.get("admin.referrers.name"))}.`,
  //       txt.get("admin.referrers.delete_confirm"),
  //       deleteRef,
  //       null,
  //       null,
  //       `${txt.get("generic.yes")}, ${txt.lo(
  //         "generic.delete_x",
  //         txt.get("admin.referrers.name")
  //       )}`
  //     )
  //   );
  // };

  // const alertConfirm = useSelector(confirmGet);
  // useEffect(() => {
  //   if (
  //     alertConfirm.actionState === AlertConfirmActionState.Perform &&
  //     alertConfirm.actionKey === deleteRef
  //   ) {
  //     deleteLocation();
  //   }
  // }, [alertConfirm]);

  // const deleteLocation = async () => {
  //   if (referrerId && !isNaN(Number(referrerId))) {
  //     await api.deleteAdminReferrer(Number(referrerId));
  //     dispatch(
  //       toastAdd(
  //         txt.get("generic.is_deleted", txt.get("admin.referrers.name")),
  //         null,
  //         "success"
  //       )
  //     );
  //   }
  //   navigate("/admin/referrers");
  // };

  // useEffect(() => {
  //   const setInterfaceForPermissions = async () => {
  //     let buttons: any[] = [];
  //     if (
  //       !isNew &&
  //       (await AuthenticationHelper.hasPermission("referrers#delete_all"))
  //     ) {
  //       buttons.push(
  //         <EuiButton
  //           size="s"
  //           onClick={handleDelete}
  //           color="danger"
  //           iconType={"cross"}
  //         >
  //           {txt.uf("generic.delete_x", txt.get("admin.referrers.name"))}
  //         </EuiButton>
  //       );
  //     }

  //     setButtonsToShow(buttons);
  //   };
  //   setInterfaceForPermissions();
  // }, []);

  const buttons = () => {
    return buttonsToShow;
  };

  return (
    <MMPage
      title={`${txt.get("admin.page_title")} ${txt.lo("admin.insurers.name")}`}
      subTitle={`#${insurerId || txt.get("generic.new")}`}
      subPages={subPages}
      backTo="/admin/insurers"
      backToText={txt.uf("generic.back_to", txt.get("admin.insurers.page_title"))}
      sideActions={buttons()}
    >
      <EuiFlexItem>
        <EuiSpacer />
        {insurer ? (
          <EuiFlexGroup>
            <EuiFlexItem>
              <MMInsurer handleSubmit={saveInsurer} fields={{ insurer, specialties }} />
            </EuiFlexItem>
          </EuiFlexGroup>
        ) : (
          <EuiProgress size="s" color="accent" />
        )}
      </EuiFlexItem>
    </MMPage>
  );
}
export default MMAdminInsurer;
