import {
  EuiButton,
  EuiComboBox,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiSpacer,
  EuiSwitch,
} from "@elastic/eui";
import AuthenticationHelper from "helpers/authentication-helper";
import txt from "helpers/text-helper";
import { onStringValue, updateField, useStateWithCallback } from "hoc/helper-hooks";
import { Fragment, useEffect, useState } from "react";
import { Person, PERSON_EMPTY } from "store/data/adress/address";
import { Referrer } from "store/data/referrer/referrer";

const REFERRER_IS_ACTIVE_DEFAULT = true;

export interface MMReferrerProps {
  formType?: "full" | "simple";
  fields: {
    referrer: Referrer | null;
    specialties: string[];
  };
  handleSubmit?: Function;
}

function MMReferrer(props: MMReferrerProps) {
  const [editAllowed, setEditAllowed] = useState(false);

  const [referrer, setReferrer] = useStateWithCallback(props.fields.referrer);

  const [specialtyOptions, setSpecialtyOptions] = useState<any[]>(
    props.fields.specialties.map((specialty: string) => ({ label: specialty }))
  );

  const [formType, setFormType] = useState<"full" | "simple">();

  useEffect(() => {
    const setPermissions = async () => {
      setEditAllowed(await AuthenticationHelper.hasPermission(["referrers#edit_all", "referrers#edit_org"]));
    };
    setPermissions();
  }, []);

  useEffect(() => {
    setFormType(props.formType || "full");
  }, [props.formType]);

  useEffect(() => {
    setSpecialtyOptions(
      props.fields.specialties.map((specialty: string) => ({
        label: specialty,
      }))
    );
  }, [props.fields.specialties]);

  const onSpecialty = (specialty: string) => {
    setReferrer({
      ...referrer,
      specialty,
    } as Referrer);
  };

  const onAlternativeOrganisation = (alternative_organisation: string) => {
    setReferrer({
      ...referrer,
      alternative_organisation,
    } as Referrer);
  };

  const onAgbCode = (agb_code: string) => {
    setReferrer({
      ...referrer,
      agb_code,
    } as Referrer);
  };

  const onOrganisationAgbCode = (organisation_agb_code: string) => {
    setReferrer({
      ...referrer,
      organisation_agb_code,
    } as Referrer);
  };

  const onPerson = (person: Person) => {
    setReferrer({ ...referrer, person } as Referrer);
  };

  const onActiveChange = (e: any) => {
    setReferrer(updateField(referrer, "is_active", e.target.checked));
  };

  const onSubmit = (e: any) => {
    if (props.handleSubmit) {
      props.handleSubmit(referrer);
    }
  };

  const onCreateOption = (searchValue: string, flattenedOptions: any = []) => {
    const normalizedSearchValue = searchValue.trim().toLowerCase();

    if (!normalizedSearchValue) {
      return;
    }

    const newOption = {
      //   value: searchValue,
      label: searchValue,
    };
    if (
      flattenedOptions.findIndex((option: any) => option.label.trim().toLowerCase() === normalizedSearchValue) === -1
    ) {
      onSpecialty(newOption.label);
      setSpecialtyOptions([...specialtyOptions, newOption]);
    }
  };

  return (
    <EuiForm>
      <EuiFlexGroup gutterSize="xs" style={{ maxWidth: "400px" }}>
        <EuiFlexItem grow={3}>
          <EuiFormRow display="rowCompressed" label={txt.get("person.salutation")}>
            <EuiFieldText
              compressed={true}
              disabled={!editAllowed}
              name="person_salutation"
              value={referrer?.person?.salutation || ""}
              onChange={(e) => onPerson(updateField(referrer?.person || PERSON_EMPTY, "salutation", onStringValue(e)))}
            />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem grow={2}>
          <EuiFormRow display="rowCompressed" label={txt.get("person.initials")}>
            <EuiFieldText
              compressed={true}
              disabled={!editAllowed}
              name="person_initials"
              value={referrer?.person?.initials || ""}
              onChange={(e) => onPerson(updateField(referrer?.person || PERSON_EMPTY, "initials", onStringValue(e)))}
            />
          </EuiFormRow>
        </EuiFlexItem>{" "}
        <EuiFlexItem grow={5}>
          <EuiFormRow display="rowCompressed" label={txt.get("person.first_name")}>
            <EuiFieldText
              compressed={true}
              disabled={!editAllowed}
              name="person_first_name"
              value={referrer?.person?.first_name || ""}
              onChange={(e) => onPerson(updateField(referrer?.person || PERSON_EMPTY, "first_name", onStringValue(e)))}
            />
          </EuiFormRow>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size="s" />
      <EuiFlexGroup gutterSize="xs" style={{ maxWidth: "400px" }}>
        <EuiFlexItem grow={3}>
          <EuiFormRow display="rowCompressed" label={txt.get("person.infix")}>
            <EuiFieldText
              compressed={true}
              disabled={!editAllowed}
              name="person_infix"
              value={referrer?.person?.infix || ""}
              onChange={(e) => onPerson(updateField(referrer?.person || PERSON_EMPTY, "infix", onStringValue(e)))}
            />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem grow={7}>
          <EuiFormRow display="rowCompressed" label={txt.get("person.last_name")}>
            <EuiFieldText
              compressed={true}
              disabled={!editAllowed}
              name="person_last_name"
              value={referrer?.person?.last_name || ""}
              onChange={(e) => onPerson(updateField(referrer?.person || PERSON_EMPTY, "last_name", onStringValue(e)))}
            />
          </EuiFormRow>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer />
      <EuiFormRow display="rowCompressed" label={txt.get("admin.referrers.agb_code")}>
        <EuiFieldText
          compressed={true}
          disabled={!editAllowed}
          name="agb_code"
          value={referrer?.agb_code || ""}
          onChange={(e) => onAgbCode(onStringValue(e))}
        />
      </EuiFormRow>
      <EuiFormRow display="rowCompressed" label={txt.get("referrers.specialty")}>
        <EuiComboBox
          singleSelection={{ asPlainText: true }}
          compressed={true}
          placeholder={txt.get("referrers.specialty")}
          options={specialtyOptions}
          selectedOptions={referrer?.specialty ? [{ label: referrer?.specialty }] : []}
          onChange={(selected) => onSpecialty(selected.length > 0 ? selected[0].label : "")}
          onCreateOption={formType === "simple" ? undefined : onCreateOption}
        />
      </EuiFormRow>
      <EuiFormRow
        display="rowCompressed"
        label={txt.get("admin.referrers.alternative_organisation")}
        helpText={txt.get("admin.referrers.alternative_organisation_help")}
      >
        <EuiFieldText
          compressed={true}
          disabled={!editAllowed}
          name="alternative_organisation"
          value={referrer?.alternative_organisation || ""}
          onChange={(e) => onAlternativeOrganisation(onStringValue(e))}
        />
      </EuiFormRow>
      <EuiSpacer />
      <EuiFormRow display="rowCompressed" label={txt.get("admin.referrers.organisation_agb_code")}>
        <EuiFieldText
          compressed={true}
          disabled={!editAllowed}
          name="organisation_agb_code"
          value={referrer?.organisation_agb_code || ""}
          onChange={(e) => onOrganisationAgbCode(onStringValue(e))}
        />
      </EuiFormRow>
      {formType === "full" ? (
        <EuiFormRow
          display="rowCompressed"
          label={<span id="referrer-is-active">{txt.get("admin.referrers.is_active")}</span>}
        >
          <EuiSwitch
            compressed={true}
            disabled={!editAllowed}
            onChange={onActiveChange}
            label={
              (
                referrer && referrer.is_active !== undefined && referrer.is_active !== null
                  ? referrer.is_active
                  : REFERRER_IS_ACTIVE_DEFAULT
              )
                ? txt.get("generic.yes")
                : txt.get("generic.no")
            }
            checked={
              referrer && referrer.is_active !== undefined && referrer.is_active !== null
                ? referrer.is_active
                : REFERRER_IS_ACTIVE_DEFAULT
            }
            aria-describedby="referrer-is-active"
          />
        </EuiFormRow>
      ) : (
        <></>
      )}

      {editAllowed ? (
        <Fragment>
          <EuiSpacer size="xxl" />
          <EuiFlexGroup>
            <EuiFlexItem grow={false}>
              <EuiButton size="s" onClick={onSubmit} fill={true} data-testid="button-save-referrer">
                {txt.get("generic.save")}
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </Fragment>
      ) : (
        <></>
      )}
    </EuiForm>
  );
}
export default MMReferrer;
