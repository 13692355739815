import { Text, View } from "@react-pdf/renderer";
import txt from "helpers/text-helper";
import { isNight, isRepeat, isSpare } from "store/data/contract/contract";
import { OrderLine } from "store/data/order/order";
import { handDescription } from "store/data/scan/scan";
import { styles } from "../styles";

export interface MMPdfProductDetailsProps {
  orderLine: OrderLine;
}

function MMPdfProductDetails(props: MMPdfProductDetailsProps) {
  return (
    <View>
      <Text>
        {props.orderLine.product_selection.type} ({handDescription(props.orderLine.hand as string).toLowerCase()})
        {props.orderLine.digits && props.orderLine.digits.length > 0
          ? `
${txt.get("pdfs.quotation.fingers")}: ${props.orderLine.digits.join(", ")}`
          : ""}
        {/* {orderLine.product_selection.size &&
            !["n/a"].includes(orderLine.product_selection.size)
              ? `${orderLine.product_selection.size
                  .toString()
                  .replace(/ /g, "")} `
              : ""}
            {orderLine.product_selection.color} */}
      </Text>
      <View style={[styles.columns, styles.soft]}>
        <View style={[styles.section_3, { paddingLeft: "0pt", paddingTop: "0pt" }]}>
          <Text>
            {txt.get("pdfs.quotation.use")}:{" "}
            {isNight(props.orderLine)
              ? txt.get("orders.order.use_option.pdf_quotation.night")
              : !isNight(props.orderLine) && isRepeat(props.orderLine)
                ? txt.get("pdfs.quotation.repeat_product")
                : isSpare(props.orderLine)
                  ? `, ${txt.get("pdfs.quotation.spare_product")}`
                  : txt.get("orders.order.use_option.pdf_quotation.day")}
          </Text>
        </View>
      </View>
    </View>
  );
}

export default MMPdfProductDetails;
