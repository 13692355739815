import {
  EuiButton,
  EuiCard,
  EuiFlexGrid,
  EuiFlexItem,
  EuiIcon,
  EuiPageSection,
  EuiSpacer,
  EuiText,
  EuiTitle,
  useIsWithinBreakpoints,
} from "@elastic/eui";
import MMPage from "components/layouts/page/page";
import AuthenticationHelper from "helpers/authentication-helper";
import txt from "helpers/text-helper";
import UrlHelper from "helpers/url-helper";
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function MMHome() {
  const [dashboardItems, setDashboardItems] = useState<any[]>([]);
  const navigate = useNavigate();
  const isLoggedIn = AuthenticationHelper.isAuthenticated();
  const isMobileScreen = useIsWithinBreakpoints(["xs"]);
  const isSmallScreen = useIsWithinBreakpoints(["s", "m"]);
  // let _this = this;
  let cardSize = 200;
  let cardStyle = {
    minWidth: cardSize,
  };

  useEffect(() => {
    if (isLoggedIn) {
      initDashboardItems();
    }
  }, [isLoggedIn]);

  const initDashboardItems = async () => {
    let items: any[] = [];

    if (await AuthenticationHelper.hasPermission("sales#read_all")) {
      items.push(
        <EuiFlexItem key="sales" style={cardStyle}>
          <EuiCard
            icon={<EuiIcon type="faceHappy" size="xl" />}
            key="sales"
            title={txt.get("sales.page_title")}
            onClick={() => navigate("sales")}
            description={txt.get("sales.intro")}
            footer={<EuiButton>{txt.get("generic.view_x", txt.up("sales.nps.acronym"))}</EuiButton>}
          ></EuiCard>
        </EuiFlexItem>
      );
    }
    if (await AuthenticationHelper.hasPermission(["orders#read", "orders#read_org", "orders#read_all"])) {
      items.push(
        <EuiFlexItem key="orders" style={cardStyle}>
          <EuiCard
            icon={<EuiIcon type="documentEdit" size="xl" />}
            key="orders"
            title={txt.get("orders.page_title")}
            onClick={() => navigate("my-dashboard")}
            description={txt.get("orders.intro")}
            footer={<EuiButton>{txt.get("generic.view_x", txt.get("orders.page_title"))}</EuiButton>}
          />
        </EuiFlexItem>
      );
    }

    if (await AuthenticationHelper.hasPermission(["appointments#create", "appointments#delete"])) {
      items.push(
        <EuiFlexItem key="production" style={cardStyle}>
          <EuiCard
            icon={<EuiIcon type="wrench" size="xl" />}
            key="production"
            title={txt.get("production.page_title")}
            onClick={() => navigate("production")}
            description={txt.get("production.intro")}
            footer={
              <EuiButton
                onClick={(e: any) => {
                  e.stopPropagation();
                  e.preventDefault();
                  navigate("production/productions");
                }}
              >
                {txt.get("production.productions.page_title")}
              </EuiButton>
            }
          />
        </EuiFlexItem>
      );
    }

    if (await AuthenticationHelper.hasPermission("finance#read_all")) {
      items.push(
        <EuiFlexItem key="finance" style={cardStyle}>
          <EuiCard
            betaBadgeProps={{
              label: txt.get("generic.new"),
            }}
            icon={<EuiIcon type="stats" size="xl" />}
            key="finance"
            title={txt.get("finance.page_title")}
            onClick={() => navigate("finance")}
            description={txt.get("finance.intro")}
            footer={
              <EuiButton
                onClick={(e: any) => {
                  e.stopPropagation();
                  e.preventDefault();
                  navigate("finance");
                }}
              >
                {txt.get("finance.referrals.page_title")}
              </EuiButton>
            }
          ></EuiCard>
        </EuiFlexItem>
      );
    }

    if (await AuthenticationHelper.hasPermission("products#read_all")) {
      items.push(
        <EuiFlexItem key="products" style={cardStyle}>
          <EuiCard
            icon={<EuiIcon type="layers" size="xl" />}
            key="products"
            title={txt.get("products.page_title")}
            onClick={() => navigate("products")}
            description={txt.get("products.intro")}
            footer={<EuiButton>{txt.get("products.portfolio.view")}</EuiButton>}
          ></EuiCard>
        </EuiFlexItem>
      );
    }

    if (
      (await AuthenticationHelper.hasPermission(["scans#read_all", "scanners#read_all"])) ||
      ((await AuthenticationHelper.hasPermission(["scans#read_org", "scanners#read_org"])) &&
        AuthenticationHelper.hasRole("scanner"))
    ) {
      items.push(
        <EuiFlexItem key="scanning" style={cardStyle}>
          <EuiCard
            icon={<EuiIcon type="compute" size="xl" />}
            key="scanning"
            title={txt.get("scanning.scans.page_title")}
            onClick={() => navigate("scanning/scans")}
            description={txt.get("scanning.intro")}
            footer={<EuiButton>{txt.get("generic.view_x", txt.get("scanning.scans.page_title"))}</EuiButton>}
          />
        </EuiFlexItem>
      );
    }

    if (await AuthenticationHelper.hasPermission(["finance#read_all", "sales#read_all", "finance#read_all"])) {
      items.push(
        <EuiFlexItem key="quick-links" style={cardStyle}>
          <EuiCard
            icon={<EuiIcon type="link" size="xl" />}
            key="quick-links"
            title={txt.get("quick_links.page_title")}
            onClick={() => navigate("quick-links")}
            description={txt.get("quick_links.intro", txt.get("brand.app_name"))}
            footer={<></>}
          ></EuiCard>
        </EuiFlexItem>
      );
    }

    if (await AuthenticationHelper.hasPermission("okrs#read_all")) {
      items.push(
        <EuiFlexItem key="okrs" style={cardStyle}>
          <EuiCard
            icon={<EuiIcon type="visGauge" size="xl" />}
            isDisabled
            key="okrs"
            title={txt.get("okrs.page_title")}
            onClick={() => navigate("okrs")}
            description={txt.get("okrs.intro")}
            footer={<></>}
          ></EuiCard>
        </EuiFlexItem>
      );
    }

    if (items.length === 1) {
      //if there is only 1 dashboard item, directly go there
      items[0].props.children.props.onClick();
    } else {
      setDashboardItems(items);
    }
  };

  const getDashboardItems = () => {
    return dashboardItems;
  };

  const handleLogin = (e: any) => {
    AuthenticationHelper.promptForLogin(UrlHelper.queryParam("to") || "");
  };

  return (
    <MMPage>
      {!isLoggedIn ? (
        <EuiPageSection alignment="center">
          <EuiFlexGrid>
            <EuiTitle>
              <EuiTitle size="m">
                <h1>{txt.get("brand.app_name")}</h1>
              </EuiTitle>
            </EuiTitle>
            <EuiButton onClick={handleLogin} style={{ textAlign: "center" }}>
              {txt.get("login.login")}
            </EuiButton>
            <EuiText size="s" style={{ textAlign: "center" }}>
              {txt.get("login.login_with", txt.get("brand.name"))}
            </EuiText>
          </EuiFlexGrid>
        </EuiPageSection>
      ) : (
        <Fragment>
          <EuiSpacer />

          <EuiFlexGrid columns={isMobileScreen ? 1 : isSmallScreen ? 2 : 4}>{getDashboardItems()}</EuiFlexGrid>
        </Fragment>
      )}
    </MMPage>
  );
}

export default MMHome;
