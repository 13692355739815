import {
  EuiBadge,
  EuiBasicTable,
  EuiButton,
  EuiButtonEmpty,
  EuiButtonIcon,
  EuiCheckbox,
  EuiCodeBlock,
  EuiComboBox,
  EuiDatePicker,
  EuiFieldText,
  EuiFilterButton,
  EuiFilterGroup,
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiLink,
  EuiPanel,
  EuiSpacer,
  EuiTab,
  EuiTabs,
  EuiText,
  EuiTextArea,
  EuiToolTip,
} from "@elastic/eui";
import { ApiResponse, ApiResponseStatus } from "api/api-helper";
import ConnectAPIHelper from "api/connect-api-helper";
import ManoXAPIHelper from "api/manox-api-helper";
import MMIcon, { MMIconType } from "components/layouts/icon/icon";
import MMCell from "components/layouts/table/cell";
import MMSectionTitle from "components/layouts/title/section-title";
import AuthenticationHelper from "helpers/authentication-helper";
import DateHelper from "helpers/date-helper";
import MomentHelper from "helpers/moment-helper";
import txt from "helpers/text-helper";
import UrlHelper from "helpers/url-helper";
import { onStringValue, toSelectOptions, updateField } from "hoc/helper-hooks";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AlertConfirmActionState, confirmAsk, confirmGet } from "store/components/alert/confirm";
import { Location, viewName } from "store/data/location/location";
import { Organisation } from "store/data/organisation/organisation";
import { fullName, PersonalDetails } from "store/data/personal-details/personal-details";
import {
  AssignedDevice,
  networkStateToHealth,
  Scanner,
  scannerDebugLinkDescription,
  ScannerMode,
  scannerModeOptions,
  scannerReleaseChannelDescription,
  scannerReleaseChannels,
  ScannerSession,
  scannerSshCommandDescription,
  scannerStatusDescription,
  scannerStatusOptions,
  SCANNER_EMPTY,
} from "store/data/scan/scanner";
import { v4 as uuid } from "uuid";

const LOCATION_CHAR_LENGTH: number = 32;
const DESCRIPTION_CHAR_LENGTH: number = 255;
export type ScannerSave = (scanner: Scanner, assignedDevices: AssignedDevice[]) => void;

export interface MMScannerProps {
  fields: { scanner: Scanner | null };
  handleSubmit?: ScannerSave;
}

const DEPLOYMENT_TYPES = ["location", "user"];
const PINLOCK_TYPES = ["no", "yes"];

function MMScanner(props: MMScannerProps) {
  const api = new ConnectAPIHelper();
  const manoxApi = new ManoXAPIHelper();

  const dispatch = useDispatch();
  const [saveRef] = useState("save_scanner_" + uuid());

  const tabs: string[] = ["settings", "connectivity"];

  const [scanner, setScanner] = useState<Scanner | null>(props.fields.scanner);
  const [latestSession, setLatestSession] = useState<ScannerSession | null>(null);
  const [isSessionLoading, setIsSessionLoading] = useState<boolean>(false);

  const [organisations, setOrganisations] = useState<Organisation[]>([]);
  const [locations, setLocations] = useState<Location[]>([]);
  const [users, setUsers] = useState<PersonalDetails[]>([]);
  const [statusOptions, setStatusOptions] = useState<any[]>([]);
  const [releaseChannels, setReleaseChannels] = useState<any[]>([]);
  const [scannerModes, setScannerModes] = useState<any[]>([]);

  const [deployment, setDeployment] = useState<"location" | "user" | null>(null);
  const [pinlock, setPinlock] = useState<"no" | "yes">("no");

  const [selectedOrganisations, setSelectedOrganisations] = useState<any[]>([]);
  const [selectedLocations, setSelectedLocations] = useState<any[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<any[]>([]);
  const [selectedStatusOptions, setSelectedStatusOptions] = useState<any[]>([]);
  const [selectedReleaseChannels, setSelectedReleaseChannels] = useState<any[]>([]);

  const [editAllowed, setEditAllowed] = useState(false);

  const [currentTab, setCurrentTab] = useState<string>(UrlHelper.queryParam("tab") || "settings");

  const getForSelection = (selections: any[], selectables: any[]) =>
    selections
      .map((selection: any) => selectables.find((selectable: any) => selectable.id === selection.value))
      .filter((found: any) => !!found);

  useEffect(() => {
    const load = async () => {
      setStatusOptions(scannerStatusOptions());
      setReleaseChannels(scannerReleaseChannels());
      setScannerModes(scannerModeOptions());

      setOrganisations(await api.getOrganisations());

      const locationResult = await api.getAdminLocations({}, 1000);
      if (locationResult && locationResult.status === "OK") {
        setLocations(locationResult.result);
      }

      const userResult = await api.getAdminUsers({}, 1000);
      if (userResult && userResult.status === "OK") {
        setUsers(userResult.result);
      }
    };

    load();

    const setPermissions = async () => {
      setEditAllowed(await AuthenticationHelper.hasPermission("scanners#edit_all"));
    };

    setPermissions();
  }, []);

  const loadLatestSession = async () => {
    setIsSessionLoading(true);
    if (!scanner?.id) {
      return;
    }

    const result: ApiResponse = await manoxApi.getScannerLatestSession(scanner.id);
    if (result.status === ApiResponseStatus.OK) {
      setLatestSession(result.result);
    } else {
      setLatestSession(null);
    }
    setIsSessionLoading(false);
  };

  useEffect(() => {
    loadLatestSession();
  }, [currentTab]);

  useEffect(() => {
    if (organisations.length > 0 && scanner) {
      setSelectedOrganisations(
        toSelectOptions(
          organisations
            .filter((organisation: Organisation) => organisation.id === scanner.organization_id)
            .map((organisation: Organisation) => ({
              name: organisation.name,
              id: organisation.id,
            }))
        )
      );
    }

    if (locations.length > 0 && scanner) {
      const loadSelectedLocations = toSelectOptions(
        locations
          .filter((location: Location) =>
            location.assigned_devices.find((device: AssignedDevice) => device.device_id === scanner.id)
          )
          .map((location: Location) => ({
            name: viewName(location),
            id: location.id,
          }))
      );
      if (loadSelectedLocations.length > 0) {
        setDeployment("location");
      }
      setSelectedLocations(loadSelectedLocations);
    }
    if (users.length > 0 && scanner) {
      const loadSelectedUsers = toSelectOptions(
        users
          .filter((user: PersonalDetails) =>
            user.assigned_devices.find((device: AssignedDevice) => device.device_id === scanner.id)
          )
          .map((user: PersonalDetails) => ({
            name: fullName(user),
            id: user.id,
          }))
      );
      if (loadSelectedUsers.length > 0) {
        setDeployment("user");
      }

      setSelectedUsers(loadSelectedUsers);
    }

    if (statusOptions.length > 0 && scanner) {
      setSelectedStatusOptions([{ label: scannerStatusDescription(scanner.status), value: scanner.status }]);
    }

    if (releaseChannels.length > 0 && scanner?.configuration) {
      setSelectedReleaseChannels([
        {
          label: scannerReleaseChannelDescription(scanner.configuration.software_channel),
          value: scanner.configuration.software_channel,
        },
      ]);
    }

    if (scanner && scanner.configuration?.pincode) {
      setPinlock(!!(scanner && scanner.configuration?.pincode) ? "yes" : "no");
    }
  }, [locations, users, organisations, statusOptions, releaseChannels, scannerModes]);

  const onOrganisationChange = (selectedOptions: any[]) => {
    setSelectedOrganisations(selectedOptions);

    let updatedScanner: Scanner = { ...SCANNER_EMPTY, ...scanner };
    const updatedOrganisationId = selectedOptions.length === 0 ? null : selectedOptions[0].value;

    updatedScanner = updateField(updatedScanner, "organization_id", updatedOrganisationId);
    setScanner(updatedScanner);
  };

  const onLocationChange = (selectedOptions: any[]) => {
    setSelectedLocations(selectedOptions);

    if (selectedOptions.length > 0) {
      setSelectedUsers([]);
      let updatedScanner: Scanner = { ...SCANNER_EMPTY, ...scanner };
      const foundLocations: Location[] = getForSelection(selectedOptions, locations);
      const locationDescription: string = foundLocations.map((location: any) => viewName(location)).join(", ");
      const locationText = foundLocations.map((location: any) => location.city).join(", ");

      updatedScanner = updateField(
        updatedScanner,
        "description",
        locationDescription.substring(0, LOCATION_CHAR_LENGTH)
      );
      updatedScanner = updateField(updatedScanner, "location", locationText);
      setScanner(updatedScanner);
    }
  };

  const onUserChange = (selectedOptions: any[]) => {
    console.log("onUserCahnge", selectedOptions);
    setSelectedUsers(selectedOptions);
    if (selectedOptions.length > 0) {
      setSelectedLocations([]);
      let updatedScanner: Scanner = { ...SCANNER_EMPTY, ...scanner };
      updatedScanner = updateField(updatedScanner, "location", "Mobile");

      let descriptionText: string = getForSelection(selectedOptions, users)
        .map((user: any) => fullName(user))
        .join(", ");

      updatedScanner = updateField(
        updatedScanner,
        "description",
        descriptionText.substring(0, DESCRIPTION_CHAR_LENGTH)
      );

      setScanner(updatedScanner);
    }
  };

  const onStatusChange = (selectedOptions: any[]) => {
    setSelectedStatusOptions(selectedOptions);
    let updatedScanner: Scanner = { ...SCANNER_EMPTY, ...scanner };
    updatedScanner = updateField(
      updatedScanner,
      "status",
      selectedOptions.length > 0 ? selectedOptions[0].value : null
    );
    setScanner(updatedScanner);
  };

  const onReleaseChannelCahnge = (selectedOptions: any[]) => {
    setSelectedReleaseChannels(selectedOptions);
    let updatedScanner: Scanner = { ...SCANNER_EMPTY, ...scanner };
    updatedScanner = updateField(
      updatedScanner,
      "configuration",
      updateField(
        updatedScanner.configuration,
        "software_channel",
        selectedOptions.length > 0 ? selectedOptions[0].value : null
      )
    );
    setScanner(updatedScanner);
  };

  const onDeploymentChange = (deploymentType: "location" | "user" | null) => {
    setDeployment(deploymentType);
  };
  const onPinlockChange = (pinlockType: "yes" | "no") => {
    setPinlock(pinlockType);
  };

  const onScannerModeChange = (mode: ScannerMode) => {
    setScanner(updateField(scanner, "configuration", updateField(scanner?.configuration, "scan_mode", mode)));
  };
  const onSubmit = (e: any) => {
    dispatch(
      confirmAsk(
        `${txt.uf("generic.save_x", txt.get("admin.scanners.name"))}?`,
        txt.get("admin.scanners.save_confirm"),
        saveRef,
        undefined,
        undefined,
        txt.get("admin.scanners.save_confirm_yes")
      )
    );
  };

  const handleTabChange = (tab: string) => {
    // Set the state
    setCurrentTab(tab);

    // Update the tab parameter
    const params = { ...UrlHelper.queryParams(), tab: tab };

    // Remove tab parameter if it's the default 'settings' value
    if (tab === "settings") {
      delete params.tab;
    }

    // Build new URL
    const queryString = UrlHelper.toQueryString(params);
    const url = queryString ? `${window.location.pathname}?${queryString}` : window.location.pathname;

    // Update URL
    window.history.pushState({}, "", url);
  };

  const alertConfirm = useSelector(confirmGet);
  useEffect(() => {
    if (alertConfirm.actionState === AlertConfirmActionState.Perform && alertConfirm.actionKey === saveRef) {
      doSubmit();
    }
  }, [alertConfirm]);

  const doSubmit = () => {
    if (props.handleSubmit && scanner) {
      let assignedDevices: AssignedDevice[] = [];

      const saveSelectedLocations = deployment === "location" ? selectedLocations : [];
      const saveSelectedUsers = deployment === "user" ? selectedUsers : [];
      if (pinlock === "no" && scanner?.configuration?.pincode) {
        scanner.configuration.pincode = "";
      }

      const assignedLocations: any = getForSelection(saveSelectedLocations, locations).map(
        (location: any) =>
          ({
            assigned_type: "location",
            assigned_id: location.id,
            device_id: scanner.id,
            device_serial: scanner.serial,
            device_description: `${scanner.location} ${scanner.description}`,
          }) as AssignedDevice
      );
      const assignedUsers: any = getForSelection(saveSelectedUsers, users).map(
        (user: any) =>
          ({
            assigned_type: "personal_details",
            assigned_id: user.id,
            device_id: scanner.id,
            device_serial: scanner.serial,
            device_description: `${scanner.location} ${scanner.description}`,
          }) as AssignedDevice
      );
      setSelectedLocations(saveSelectedLocations);
      setSelectedUsers(saveSelectedUsers);

      assignedDevices = assignedDevices.concat(assignedLocations, assignedUsers);
      console.log("Scanner.onSubmit", { scanner, assignedDevices });

      props.handleSubmit(scanner, assignedDevices);
    }
  };

  const renderCurrentTab = () => {
    switch (currentTab) {
      case "settings":
        return renderSettings();
      case "connectivity":
        return renderConnectivity();
    }
    return <></>;
  };

  const renderConfigurationTextField = (key: string, maxLength?: number, editable?: boolean) => {
    let label = txt.get(`admin.scanners.scanner.${key}`);

    return (
      <EuiFormRow label={label}>
        <EuiFieldText
          disabled={editable === undefined ? !editAllowed : !editable}
          maxLength={maxLength}
          data-testid={`scanner-form-input--${key}`}
          name={key}
          aria-label={label}
          placeholder={label}
          value={scanner?.configuration ? (scanner.configuration as any)[key] || "" : ""}
          onChange={(e) =>
            setScanner(
              updateField(scanner, "configuration", updateField(scanner?.configuration, key, onStringValue(e)))
            )
          }
        />
      </EuiFormRow>
    );
  };

  const renderScannerToggle = (
    key: string,
    options: string[],
    selectedOption: string,
    onChange: Function,
    clearable: boolean = true
  ) => (
    <EuiFormRow label={txt.get(`admin.scanners.scanner.${key}`)}>
      <EuiFilterGroup contentEditable={false} fullWidth={true}>
        {options.map((option: string) => (
          <EuiFilterButton
            key={`${key}-option-${option}`}
            isDisabled={!editAllowed}
            hasActiveFilters={option === selectedOption}
            onClick={(e: any) => onChange(clearable ? (option === selectedOption ? null : option) : option)}
          >
            {txt.get(`admin.scanners.${key}.${option}`)}
          </EuiFilterButton>
        ))}
      </EuiFilterGroup>
    </EuiFormRow>
  );
  const renderScannerSelect = (key: string, options: any[], selections: any[], onChange: any, placeholder?: string) => {
    const label = txt.get(`admin.scanners.scanner.${key}`);
    const placeholderLabel = txt.get(`admin.scanners.scanner.${placeholder || key}`);

    return (
      <EuiFormRow label={label}>
        <EuiComboBox
          data-testid={`scanner-form-input--${key}`}
          isDisabled={!editAllowed}
          aria-autocomplete="none"
          aria-label={label}
          placeholder={placeholderLabel}
          singleSelection={{ asPlainText: true }}
          options={options}
          selectedOptions={selections}
          onChange={onChange}
        />
      </EuiFormRow>
    );
  };

  const renderConfigurationTextArea = (key: string, rows: number = 8) => {
    const label = txt.get(`admin.scanners.scanner.${key}`);
    return (
      <EuiFormRow label={label}>
        <EuiTextArea
          rows={rows}
          data-testid={`scanner-form-input--${key}`}
          readOnly={!editAllowed}
          name={key}
          value={scanner?.configuration ? (scanner.configuration as any)[key] || "" : ""}
          onChange={(e) =>
            setScanner(
              updateField(scanner, "configuration", updateField(scanner?.configuration, key, onStringValue(e)))
            )
          }
        />
      </EuiFormRow>
    );
  };

  const renderSoftwareVersion = (latestSession: ScannerSession | null) => {
    const built = DateHelper.toDateSql(latestSession?.software_built_at) || "";
    const version = latestSession?.software_version || "";

    return built ? `${version} (${built})` : version;
  };

  const renderSettings = () => (
    <div className="scanner-detail-tab">
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiPanel hasShadow={false}>
            <EuiFlexGrid gutterSize="none" className="scanner-pannel">
              <MMSectionTitle text={txt.get("admin.scanners.scanner.device_info")} />
              <EuiFlexGroup>
                <EuiFlexItem grow={false}>
                  <EuiFormRow label={txt.get("admin.scanners.scanner.serial")} isDisabled={true}>
                    <EuiFieldText
                      name="serial"
                      value={scanner?.serial}
                      onChange={(e) => setScanner(updateField(scanner, "serial", onStringValue(e)))}
                    />
                  </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiFormRow label={txt.get("admin.scanners.scanner.revision")} isDisabled={true}>
                    <EuiFieldText
                      name="revision"
                      value={scanner?.revision}
                      onChange={(e) => setScanner(updateField(scanner, "serial", onStringValue(e)))}
                    />
                  </EuiFormRow>
                </EuiFlexItem>
              </EuiFlexGroup>
              <EuiSpacer size="m" />
              <EuiFormRow label={txt.get("admin.scanners.scanner.commissioned_at")} isDisabled={true}>
                <EuiDatePicker
                  placeholder={txt.get("admin.scanners.scanner.commissioned_at")}
                  dateFormat={"DD-MM-YYYY HH:MM"}
                  disabled={true}
                  selected={
                    scanner?.commissioned_at
                      ? MomentHelper.toMoment(DateHelper.parseDate(scanner.commissioned_at))
                      : undefined
                  }
                ></EuiDatePicker>
              </EuiFormRow>
              {renderScannerSelect("status", statusOptions, selectedStatusOptions, onStatusChange)}
              <MMSectionTitle text={txt.get("admin.scanners.scanner.software")} spaceAbove={true} />
              {renderScannerSelect("release_channel", releaseChannels, selectedReleaseChannels, onReleaseChannelCahnge)}
              <EuiFormRow label={txt.get("admin.scanners.scanner.version")} isDisabled={true}>
                <EuiFieldText
                  data-testid="scanner-form-input--version"
                  disabled={true}
                  name="version"
                  value={renderSoftwareVersion(latestSession)}
                />
              </EuiFormRow>
            </EuiFlexGrid>
          </EuiPanel>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiPanel hasShadow={false}>
            <EuiFlexGrid gutterSize="none" className="scanner-pannel">
              <MMSectionTitle
                text={txt.get("admin.scanners.scanner.deployment")}
                tip={txt.get("admin.scanners.i.deployment")}
              />
              <EuiFormRow label={txt.get(`admin.scanners.deployment.name`)}>
                <EuiFilterGroup contentEditable={false} fullWidth={true}>
                  {DEPLOYMENT_TYPES.map((deploymentType: any) => (
                    <EuiFilterButton
                      key={`deployment-type-${deploymentType}`}
                      data-testid={`scanner-form-input--deployment-${deploymentType}`}
                      isDisabled={!editAllowed}
                      hasActiveFilters={deployment === deploymentType}
                      onClick={(e: any) => onDeploymentChange(deploymentType === deployment ? null : deploymentType)}
                    >
                      {txt.get(`admin.scanners.deployment.${deploymentType}`)}
                    </EuiFilterButton>
                  ))}
                </EuiFilterGroup>
              </EuiFormRow>
              {deployment === "location" ? (
                <EuiFormRow label={txt.get("admin.locations.name")}>
                  <EuiComboBox
                    data-testid="scanner-form-input--location"
                    isDisabled={!editAllowed}
                    aria-autocomplete="none"
                    aria-label="Select location"
                    placeholder={txt.uf("generic.select_x", txt.get("admin.locations.name"))}
                    singleSelection={{ asPlainText: true }}
                    options={toSelectOptions(
                      locations.map((location: Location) => ({ name: viewName(location), id: location.id }))
                    )}
                    selectedOptions={selectedLocations}
                    onChange={onLocationChange}
                  />
                </EuiFormRow>
              ) : deployment === "user" ? (
                <EuiFormRow label={txt.get("admin.users.name")}>
                  <EuiComboBox
                    data-testid="scanner-form-input--user"
                    isDisabled={!editAllowed}
                    aria-autocomplete="none"
                    aria-label="Select user"
                    placeholder={txt.uf("generic.select_x", txt.get("admin.users.name"))}
                    singleSelection={{ asPlainText: true }}
                    options={toSelectOptions(
                      users.map((user: PersonalDetails) => ({ name: fullName(user), id: user.id }))
                    )}
                    selectedOptions={selectedUsers}
                    onChange={onUserChange}
                  />
                </EuiFormRow>
              ) : (
                <div style={{ height: "92px" }}>&nbsp;</div>
              )}

              {/* <EuiFormRow label={`${txt.get("admin.scanners.scanner.location")}  (max ${LOCATION_CHAR_LENGTH} chars)`}>
              <EuiFieldText
                data-testid="scanner-form-input--location-description"
                readOnly={!editAllowed}
                name="location"
                maxLength={LOCATION_CHAR_LENGTH}
                value={scanner?.location}
                onChange={(e) => setScanner(updateField(scanner, "location", onStringValue(e)))}
              />
            </EuiFormRow>
            <EuiFormRow
              label={`${txt.get("admin.scanners.scanner.description")} (max ${DESCRIPTION_CHAR_LENGTH} chars)`}
            >
              <EuiFieldText
                data-testid="scanner-form-input--further-description"
                readOnly={!editAllowed}
                name="description"
                maxLength={DESCRIPTION_CHAR_LENGTH}
                value={scanner?.description}
                onChange={(e) => setScanner(updateField(scanner, "description", onStringValue(e)))}
              />
            </EuiFormRow> */}
              {renderConfigurationTextArea("deployment_notes")}
            </EuiFlexGrid>
          </EuiPanel>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiPanel hasShadow={false}>
            <EuiFlexGrid gutterSize="none">
              <MMSectionTitle
                text={txt.get("admin.scanners.scanner.configuration")}
                tip={txt.get("admin.scanners.i.configuration")}
              />

              {renderScannerSelect(
                "organisation",
                toSelectOptions(organisations),
                selectedOrganisations,
                onOrganisationChange,
                "no_organisation"
              )}

              {renderScannerToggle(
                "scan_mode",
                scannerModes,
                scanner?.configuration?.scan_mode || "order",
                onScannerModeChange,
                false
              )}
              <EuiFormRow label={txt.get(`admin.scanners.pinlock.name`)}>
                <EuiFilterGroup contentEditable={false} fullWidth={true}>
                  {PINLOCK_TYPES.map((pinlockType: any) => (
                    <EuiFilterButton
                      data-testid={`scanner-form-input--pinlock-${pinlockType}`}
                      key={`pinlock-type-${pinlockType}`}
                      isDisabled={!editAllowed}
                      hasActiveFilters={pinlock === pinlockType}
                      onClick={(e: any) => onPinlockChange(pinlockType)}
                    >
                      {txt.get(`admin.scanners.pinlock.${pinlockType}`)}
                    </EuiFilterButton>
                  ))}
                </EuiFilterGroup>
              </EuiFormRow>
              {pinlock === "yes" ? renderConfigurationTextField("pincode", 4) : <></>}
            </EuiFlexGrid>
          </EuiPanel>
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  );

  const networkInterfaceColumn = (key: string, renderer?: any) => ({
    name: txt.get(`admin.scanners.scanner.network_interface.${key}`),
    field: key,

    render: renderer ? renderer : (value: any) => <MMCell text={value || "-"} />,
  });

  const networkInterfaceColumns = () => [
    networkInterfaceColumn("name"),
    networkInterfaceColumn("network"),
    networkInterfaceColumn("signal", (signal: any) =>
      signal ? <MMIcon title={`${signal}/5`} type={`signal-${signal}` as MMIconType} /> : "-"
    ),
    networkInterfaceColumn("enabled", (enabled: boolean) => (
      <EuiCheckbox id="enabled" onChange={(e) => {}} disabled={true} checked={enabled} />
    )),
    networkInterfaceColumn("interface"),
    networkInterfaceColumn("state", (state: string) => (
      <EuiBadge color={networkStateToHealth(state)}>
        {txt.get(`admin.scanners.network_state.${state.toLowerCase()}`)}
      </EuiBadge>
    )),
    networkInterfaceColumn("mac_address"),
    networkInterfaceColumn("ip_address"),
  ];
  const renderConnectivity = () => (
    <div className="scanner-detail-tab">
      <EuiFlexGrid gutterSize="l">
        <EuiFlexItem>
          <EuiPanel hasShadow={false}>
            <EuiFlexGroup>
              <MMSectionTitle
                text={txt.get("admin.scanners.scanner.connection")}
                sideAction={
                  <EuiButtonEmpty
                    size="xs"
                    color="text"
                    iconType="refresh"
                    iconSide="right"
                    onClick={() => {
                      loadLatestSession();
                    }}
                  >
                    <span style={{ color: "#909090" }}>
                      {latestSession?.end
                        ? DateHelper.ago(latestSession.end, txt.lang())
                        : txt.get("admin.scanners.scanner.no_session_found")}
                    </span>
                  </EuiButtonEmpty>
                }
              />
            </EuiFlexGroup>

            <EuiBasicTable
              loading={isSessionLoading}
              tableLayout="auto"
              itemId="id"
              items={latestSession?.network_interfaces || []}
              columns={networkInterfaceColumns()}
              noItemsMessage={txt.uf("generic.found_no_x", txt.get("admin.scanners.scanner.network"))}
            />
          </EuiPanel>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFlexGroup>
            {/* First column */}
            <EuiFlexItem>
              <EuiPanel hasShadow={false}>
                <MMSectionTitle text={txt.get("admin.scanners.scanner.sim_info")} />
                {renderConfigurationTextField("sim_mobile_number", undefined, false)}
                {renderConfigurationTextField("sim_icc", undefined, false)}
                {renderConfigurationTextField("sim_pin", undefined, false)}
                {renderConfigurationTextField("sim_puk", undefined, false)}
              </EuiPanel>
            </EuiFlexItem>

            {/* Second column */}
            <EuiFlexItem>
              {/* Remote access */}
              <EuiPanel hasShadow={false}>
                <MMSectionTitle text={txt.get("admin.scanners.scanner.remote_access")} />
                {/* SSH access */}
                <EuiText size="xs" style={{ fontWeight: "400", color: "#9C9CA8" }}>
                  {txt.get("admin.scanners.scanner.ssh_access")}
                </EuiText>
                <EuiSpacer size="xs" />
                <EuiCodeBlock paddingSize="s" className="code-corrected-padding-small" isCopyable={true}>
                  {scannerSshCommandDescription(scanner?.configuration)}
                </EuiCodeBlock>

                <EuiSpacer size="m" />

                {/* Debug page */}
                <EuiFlexGroup alignItems="center" gutterSize="xs">
                  <EuiFlexItem grow={false}>
                    <EuiText size="xs" style={{ fontWeight: "400", color: "#9C9CA8" }}>
                      {txt.get("admin.scanners.scanner.debug_page")}
                    </EuiText>
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <EuiToolTip position="right" content={txt.get("admin.scanners.scanner.debug_page_info")}>
                      <EuiButtonIcon style={{ marginLeft: -3, color: "#9C9CA8" }} iconType="iInCircle" size="xs" />
                    </EuiToolTip>
                  </EuiFlexItem>
                </EuiFlexGroup>
                <EuiSpacer size="xs" />
                <EuiLink
                  href={scannerDebugLinkDescription(scanner)}
                  className="code-corrected-padding-small"
                  target="_blank"
                >
                  {scannerDebugLinkDescription(scanner)}
                </EuiLink>
              </EuiPanel>
            </EuiFlexItem>

            {/* Third column (empty) */}
            <EuiFlexItem></EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGrid>
    </div>
  );

  return (
    <EuiForm data-testid="scanner-form" className="scanner-form">
      <EuiTabs>
        {tabs.map((tab: string) => (
          <EuiTab key={`tab-${tab}`} onClick={() => handleTabChange(tab)} isSelected={tab === currentTab}>
            {txt.get(`admin.scanners.tabs.${tab}`)}
          </EuiTab>
        ))}
      </EuiTabs>
      <EuiSpacer />
      <div className="scanner-tab">{renderCurrentTab()}</div>
      {editAllowed ? (
        <Fragment>
          <EuiSpacer size="l" />
          <EuiFlexGroup>
            <EuiFlexItem grow={false}>
              <EuiButton data-testid="scanner-form-action--save" size="s" onClick={onSubmit} fill={true}>
                {txt.get("generic.save")}
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </Fragment>
      ) : (
        <></>
      )}
    </EuiForm>
  );
}
export default MMScanner;
