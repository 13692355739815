import { feat, Feature } from "feats";
import AuthenticationHelper from "helpers/authentication-helper";
import {
  Input,
  validateAnyNotEmpty,
  validateMinimalThreeStrings,
  validateNotEmpty,
  validateNotEmptyNorFalse,
  validateNotEmptyNumber,
  validateNotEmptyNumberZeroAllowed,
  ValidationResult,
} from "helpers/input-helper";
import txt from "helpers/text-helper";
import { Need } from "store/data/need/need";
import { isOrderAuthorizationNeeded, Order, OrderLine } from "store/data/order/order";

export interface OrderLineMeasurementInputs {
  o1: Input;
  o2: Input;
  o3: Input;
  s1: Input;
  s2: Input;
  l1: Input;
  l2: Input;
  s: Input;
  j: Input;
}

export interface OrderCostingInputs {
  rationale: Input;
}

const optionalInput: Input = {
  mandatory: false,
  visible: true,
  validator: undefined,
};

export const ORDER_LINE_INPUTS_EMPTY: OrderLineInputs = {
  digits: optionalInput,
  hand: optionalInput,
  order_type: optionalInput,
  remake_reason: optionalInput,
  replacement_for: optionalInput,
  product_selection: optionalInput,
  argumentation: optionalInput,
  productDescription: optionalInput,
  has_previous_aid: optionalInput,
  previous_aid: optionalInput,
  measurements: {
    o1: optionalInput,
    o2: optionalInput,
    o3: optionalInput,
    s1: optionalInput,
    s2: optionalInput,
    l1: optionalInput,
    l2: optionalInput,
    s: optionalInput,
    j: optionalInput,
  },
};

export interface OrderLineProductSelectionInput {}
export interface OrderLineCostingInput {
  amount: Input;
}

export interface OrderLineInputs {
  digits: Input;
  hand: Input;
  measurements: OrderLineMeasurementInputs;
  order_type: Input;
  remake_reason: Input;
  replacement_for: Input;
  product_selection: OrderLineProductSelectionInput;
  costing?: OrderLineCostingInput;
  has_previous_aid: Input;
  previous_aid: Input;
  argumentation: Input;
  productDescription: Input;
  // code: Input; //is not a user input
  // status: Input;//is not a user input
}

export interface OrderInputs {
  ordered_by: Input;
  ordered_at: Input;
  client_code: Input;
  external_client_reference: Input;
  external_client_name: Input;
  external_client_birth_date: Input;
  external_client_postal_code: Input;
  external_client_street_number: Input;
  external_client_email: Input;
  external_client_phone_number: Input;
  delivery_method: Input;
  organisation_id: Input;
  location_id: Input;
  practitioner: Input;
  // delivery_method: Input;
  // organisation: Input;
  // status: Input;
  order_lines: OrderLineInputs[];
  referral: Input;

  //two options for legacy needs support
  needs:
    | {
        care_needs: Input;
        usage: Input;
        characteristics: Input;
        "activities.activities": Input;
      }
    | {
        "care_needs.needs": Input;
        "care_needs.activities": Input;
      };
  referrer: Input;
  referral_status: Input;
  costing: OrderCostingInputs;
  //   files: Input[];
}

export const VALIDATION_VISIBILITY_NONE: ValidationVisibility = {
  order: false,
  client: false,
  whoWhere: false,
  referral: false,
  referrer: false,
  needs: false,
  orderLines: false,
  measurements: false,
  quotation: false,
  orderLineCostings: false,
};
export interface ValidationVisibility {
  order: boolean;
  client: boolean;
  whoWhere: boolean;
  referral: boolean;
  referrer: boolean;
  needs: boolean;
  orderLines: boolean;
  measurements: boolean;
  quotation: boolean;
  orderLineCostings: boolean;
}
export const getOrderInput = (field: string, orderInputs: OrderInputs | undefined): Input | null => {
  if (!orderInputs) return null;
  const fields: any = { ...orderInputs };
  for (const key in fields) {
    if (key === field) return fields[key] as Input;
  }
  return null;
};

export enum MeasurementValueType {
  Numeric = "numeric",
  Alphabetic = "alphabetic",
}

export const toMeasurementValue = (
  value: string | number | null | undefined,
  toValidValue: boolean = false,
  types?: MeasurementValueType[]
): string => {
  if (!types || types.length === 0) {
    types = [MeasurementValueType.Numeric, MeasurementValueType.Alphabetic];
  }
  if (value === null || value === undefined) value = "";
  value = value as string;

  let sanitisedMeasurement: string | number =
    types.includes(MeasurementValueType.Numeric) && types.includes(MeasurementValueType.Alphabetic)
      ? value.toUpperCase().replace(/[^A-Z0-9.,]+/g, "")
      : types.includes(MeasurementValueType.Numeric)
        ? value.replace(/[^0-9.,]+/g, "")
        : value;

  if (sanitisedMeasurement.substring(0, 1).match(/[A-Z]/i)) {
    sanitisedMeasurement = sanitisedMeasurement.substring(0, 1);
  } else {
    sanitisedMeasurement = sanitisedMeasurement.replace(/[^0-9.,]+/g, "");
  }

  if (toValidValue) {
    if (sanitisedMeasurement.length === 1 && value.match(/[A-Z]/i)) {
      //it's a letter, keep as is
    } else if (sanitisedMeasurement.length > 0) {
      //it's a number, make sure to parse a float, to 1 decimal
      sanitisedMeasurement = sanitisedMeasurement.replace(",", "."); //no local dependency yet, so always , to .
      sanitisedMeasurement = (Math.floor(parseFloat(sanitisedMeasurement) * 10) / 10).toFixed(1);
    }
  }

  return sanitisedMeasurement as string;
};

export const isClientInputValid = (order?: Order | null, inputs?: OrderInputs) => {
  const keys = Object.keys(inputs || {}).filter(
    (key: string) =>
      ![
        "referral",
        "referrer",
        "needs",
        "order_lines",
        "location_id",
        "organisation_id",
        "delivery_method",
        "practitioner",
        "costing",
      ].includes(key)
  );

  const result = isOrderInputValid(order, inputs, keys);
  return result;
};

export const isWhoWhereInputValid = (order?: Order | null, inputs?: OrderInputs) => {
  //organisation_id ?
  const keys = ["location_id", "practitioner", "delivery_method"];
  return isOrderInputValid(order, inputs, keys);
};

export const isReferralInputValid = (order?: Order | null, inputs?: OrderInputs) => {
  if (!inputs?.referral) return true;
  if (!inputs?.referral.mandatory) return true;
  if (!order || !order.referral) return false;

  //could make an 'array input checker' ?
  return (
    (order.referral.files && order.referral.files.length > 0 && order.referral.referred_at) ||
    !!order.referral.no_referral_reasons
    // (!!order.referral.no_referral_reasons &&
    //   !!order.referral.no_referral_follow_up)
  );
};

export const isReferrerInputValid = (order?: Order | null, inputs?: OrderInputs) => {
  if (!inputs?.referrer) return true;
  if (!inputs?.referrer.mandatory) return true;

  //if you filled out a no_referral_reason, you do not have to fill out referrer
  if (order && order.referral && !!order.referral.no_referral_reasons) return true;

  if (!order || !order.referral || !order.referral.referrer) return false;

  return (
    (order.referral.referrer.person?.last_name || order.referral.referrer.person?.first_name) &&
    order.referral.referrer.specialty &&
    (order.referral.referrer.organisation_id || order.referral.referrer.alternative_organisation)
  );
};

export const validationOfNeedsInput = (order: any, fields: any, key: string): ValidationResult => {
  const validResult: ValidationResult = {
    result: true,
    message: "",
  };
  const invalidResult: ValidationResult = {
    result: false,
    message: txt.get("validations.any_not_empty"),
  };

  // Find the need Input definition for the key
  const needInput: Input = fields[key] as Input;

  // Find all needs that are matching the key to validate
  const needs: Need[] = order?.needs?.filter((need: Need) => need.question.startsWith(key));

  const mandatory = needInput.mandatory;

  if (!mandatory) {
    return validResult;
  }

  const validator = needInput.validator;

  if (validator) {
    if (needs.length <= 1) {
      // Validate with 1 string
      return validator(needs[0]?.answer || "");
    }

    // Validate with all strings
    return validator(needs.map((need: Need) => need.answer || ""));
  } else if (
    // Check the legacy way
    order?.needs?.findIndex(
      (need: Need) => need.question.startsWith(key) && need.answer !== "" && need.answer !== "false"
    ) < 0
  ) {
    // If there is no need that is not false or empty, nothing is filledout
    return invalidResult;
  }

  return invalidResult;
};

export const isNeedsInputValid = (order?: Order | null, inputs?: OrderInputs) => {
  if (!inputs) return true;
  if (!inputs?.needs) return true;
  if (!order || !order.needs) return false;

  const fields: any = { ...inputs.needs };
  let isValid: boolean = true;

  for (const key in fields) {
    const validation = validationOfNeedsInput(order, fields, key);
    isValid = isValid && !!validation.result;
  }

  return isValid;
};

const orderLineKeysToCheck: string[] = ["has_previous_aid", "previous_aid", "argumentation"];

export const isOrderLineInputValid = (orderLine: OrderLine, orderLineInputs: OrderLineInputs | null): boolean => {
  let isValid = true;

  if (orderLineInputs) {
    const inputFields: any = { ...orderLineInputs };
    const lineFields: any = { ...orderLine };
    for (const key in inputFields) {
      //for orderlines only to the order line checking
      if (!orderLineKeysToCheck.includes(key as string)) {
        continue;
      }
      const input: Input = inputFields[key] as Input;

      if (input) {
        if (["measurements"].includes(key)) {
          const measurementInputFields: any = { ...input };
          const measurementFields: any = { ...lineFields[key] };
          for (const mKey in measurementInputFields) {
            const measurementInput: Input = measurementInputFields[mKey] as Input;

            if (measurementInput && measurementInput.mandatory && !!!measurementFields[mKey]) {
              isValid = false;
              break;
            }
          }
        } else if (key === "costing") {
          continue;
        } else if (
          key !== "costing" &&
          input.mandatory &&
          input.validator &&
          !input.validator(lineFields[key]).result
        ) {
          isValid = false;
          break;
        }
      }
    }
  }

  return isValid;
};

export const isOrderLinesInputValid = (order?: Order | null, inputs?: OrderInputs) => {
  if (!inputs) return true;
  if (!inputs?.order_lines) return true;
  if (!order || !order.order_lines || order.order_lines.length === 0) return false;

  let isValid = true;
  for (let i = 0; i < order.order_lines.length; i++) {
    const line: OrderLine = order.order_lines[i];
    const orderLineInputs: OrderLineInputs | null = inputs.order_lines[i] ? inputs.order_lines[i] : null;

    if (!isOrderLineInputValid(line, orderLineInputs)) {
      isValid = false;
      break;
    }
  }

  return isValid;
};

export const isMeasurementsInputValid = (order?: Order | null, inputs?: OrderInputs) => {
  if (!inputs) return true;
  if (!inputs?.order_lines) return true;
  if (!order || !order.order_lines) return false;

  let isValid = true;
  for (let i = 0; i < order.order_lines.length; i++) {
    const line: OrderLine = order.order_lines[i];
    const orderLineInputs: OrderLineInputs | null = inputs.order_lines[i] ? inputs.order_lines[i] : null;

    if (orderLineInputs) {
      const inputFields: any = { ...orderLineInputs };
      const lineFields: any = { ...line };
      for (const key in inputFields) {
        //for measurements skip the order line checking
        if (orderLineKeysToCheck.includes(key as string)) {
          continue;
        }
        const input: Input = inputFields[key] as Input;

        if (input) {
          if (["measurements"].includes(key)) {
            const measurementInputFields: any = { ...input };
            const measurementFields: any = { ...lineFields[key] };
            for (const mKey in measurementInputFields) {
              const measurementInput: Input = measurementInputFields[mKey] as Input;

              if (measurementInput && measurementInput.mandatory && !!!measurementFields[mKey]) {
                isValid = false;
                break;
              }
            }
          } else if (key === "costing") {
            continue;
          } else if (key !== "costing" && input.mandatory && !!!lineFields[key]) {
            isValid = false;
            break;
          }
        }
      }
    }
  }

  return isValid;
};
export const isOrderLineCostingInputValid = (order?: Order | null, inputs?: OrderInputs) => {
  if (!inputs) return true;
  if (!inputs?.order_lines) return true;
  if (!order || !order.order_lines) return false;

  let isValid = true;
  for (let i = 0; i < order.order_lines.length; i++) {
    const line: OrderLine = order.order_lines[i];
    const orderLineInputs: OrderLineInputs | null = inputs.order_lines[i] ? inputs.order_lines[i] : null;

    if (orderLineInputs) {
      const inputFields: any = { ...orderLineInputs };
      const lineFields: any = { ...line };
      for (const key in inputFields) {
        const input: Input = inputFields[key] as Input;

        if (input) {
          if (["costing"].includes(key)) {
            const costingInputFields: any = { ...input };
            const costingFields: any = { ...lineFields[key] };
            for (const cKey in costingInputFields) {
              const costingInput: Input = costingInputFields[cKey] as Input;

              if (
                costingInput &&
                costingInput.mandatory &&
                (costingFields[cKey] === undefined || costingFields[cKey] === null || costingFields[cKey] === "")
              ) {
                isValid = false;
                break;
              }
            }
          }
        }
      }
    }
  }

  return isValid;
};

export const isOrderCostingInputValid = (order?: Order | null, inputs?: OrderInputs) => {
  if (!inputs) return true;
  if (!inputs?.costing) return true;
  if (!order || !order.costing) return false;

  return isOrderAuthorizationNeeded(order) ? !!order.costing.rationale : true;
};

export const isOrderInputValid = (order?: Order | null, inputs?: OrderInputs, keys?: string[]) => {
  if (!order) {
    return false;
  }
  if (!inputs) {
    return false;
  }
  if (!keys) {
    keys = Object.keys(inputs);
  }

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    //need to make generic
    if (key === "referrer") {
      if (isReferrerInputValid(order, inputs)) {
        continue;
      } else {
        return false;
      }
    }
    //need to make generic
    if (key === "referral") {
      if (isReferralInputValid(order, inputs)) {
        continue;
      } else {
        return false;
      }
    }
    if (key === "needs") {
      if (isNeedsInputValid(order, inputs)) {
        continue;
      } else {
        return false;
      }
    }
    if (key === "order_lines") {
      if (isOrderLinesInputValid(order, inputs)) {
        continue;
      } else {
        return false;
      }
    }
    //separately check on costing (because costing shows up in a different tab)
    if (key === "order_lines") {
      if (isOrderLineCostingInputValid(order, inputs)) {
        continue;
      } else {
        return false;
      }
    }

    if (key === "costing") {
      if (isOrderCostingInputValid(order, inputs)) {
        continue;
      } else {
        return false;
      }
    }

    const input: Input | null = getOrderInput(key, inputs);
    if (!input) {
      return false;
    }
    if (input.mandatory && !!!order[key as keyof typeof order]) {
      return false;
    }
    if (input.mandatory && input.validator && !input.validator(order[key as keyof typeof order]).result) {
      return false;
    }
  }

  return true;
};

export const determineOrderInputs = async (order: Order | null, products?: any[], orderTypes?: any[]) => {
  let orderInputs: OrderInputs = {} as OrderInputs;

  const isInternalUser: boolean = AuthenticationHelper.isInternalUser();
  let isClientInfoVisible: boolean =
    !!order &&
    !!order.organisation_id &&
    !AuthenticationHelper.isInternalOrganisationId(order.organisation_id) &&
    (await AuthenticationHelper.hasPermission(["clients#read_all", "clients#read_org", "clients#read_all"]));
  let isExternalClientCodeVisible: boolean =
    !!order && !!order.organisation_id && !AuthenticationHelper.isInternalOrganisationId(order.organisation_id);
  let isReferralVisible: boolean = false;
  let isReferrerVisible: boolean = false;
  let isReferring: boolean = await AuthenticationHelper.hasPermission("orders#refer");
  let isRegisteringNeeds: boolean = await AuthenticationHelper.hasPermission("orders#register_needs");
  let isNeedsVisible: boolean = false;

  let isQuotationVisible: boolean = false;

  //creating
  if (!order || !order.id || order.id === 0) {
    isReferralVisible = await AuthenticationHelper.hasPermission([
      "referrals#read_all",
      "referrals#read_org",
      "referrals#read_all",
    ]);

    isReferrerVisible = await AuthenticationHelper.hasPermission([
      "referrers#read_all",
      "referrers#read_org",
      "referrers#read_all",
    ]);

    isNeedsVisible = await AuthenticationHelper.hasPermission(["needs#read_all", "needs#read_org", "needs#read_all"]);

    //modifying
  } else if (order && order.id && order.id !== 0) {
    //if it's your organisation
    //should be more granular

    if (!!order.organisation_id && order.organisation_id === AuthenticationHelper.getOrganisationId()) {
      //take your permissions
      isReferralVisible = await AuthenticationHelper.hasPermission([
        "referrals#read_all",
        "referrals#read_org",
        "referrals#read",
      ]);

      isReferrerVisible = await AuthenticationHelper.hasPermission([
        "referrers#read_all",
        "referrers#read_org",
        "referrers#read",
      ]);

      isNeedsVisible = await AuthenticationHelper.hasPermission(["needs#read_all", "needs#read_org", "needs#read"]);

      //if it's not your organisation
    } else if (!!order.organisation_id && order.organisation_id !== AuthenticationHelper.getOrganisationId()) {
      //take your org permissions
      isReferralVisible = await AuthenticationHelper.hasPermission(["referrals#read_all", "referrals#read_org"]);

      isReferrerVisible = await AuthenticationHelper.hasPermission(["referrers#read_all", "referrers#read_org"]);

      isNeedsVisible = await AuthenticationHelper.hasPermission(["needs#read_all", "needs#read_org"]);
    }

    isReferralVisible = await AuthenticationHelper.hasPermission(["referrals#read_all", "referrals#read_org"]);

    isReferrerVisible = await AuthenticationHelper.hasPermission(["referrers#read_all", "referrers#read_org"]);

    isNeedsVisible = await AuthenticationHelper.hasPermission(["needs#read_all", "needs#read_org"]);

    isQuotationVisible = await AuthenticationHelper.hasPermission([
      "invoices#read_all",
      "costings#read_all",
      "invoices#edit_all",
      "costings#edit_all",
    ]);
  }

  const mandatoryInput: Input = {
    mandatory: true,
    visible: true,
    validator: validateNotEmpty,
  };

  const mandatoryMinimalThreeStringsInput: Input = {
    mandatory: true,
    visible: true,
    validator: validateMinimalThreeStrings,
  };

  const mandatoryNotEmptyNorFalseInput: Input = {
    mandatory: true,
    visible: true,
    validator: validateNotEmptyNorFalse,
  };

  const mandatoryNumberZeroAllowedInput: Input = {
    mandatory: true,
    visible: true,
    validator: validateNotEmptyNumberZeroAllowed,
  };

  const optionalInput: Input = {
    mandatory: false,
    visible: true,
    validator: validateNotEmpty,
  };

  if (isInternalUser) {
    orderInputs.client_code = {
      mandatory: true,
      visible: true,
      validator: validateNotEmptyNumber,
    };
  }

  const input: Input = {
    mandatory: !isInternalUser,
    visible: true,
    validator: validateNotEmpty,
  };

  if (isClientInfoVisible) {
    orderInputs.external_client_email = { ...input };
    orderInputs.external_client_name = { ...input };
    orderInputs.external_client_phone_number = { ...input };
    orderInputs.external_client_postal_code = { ...input };
    orderInputs.external_client_street_number = { ...input };
    orderInputs.external_client_birth_date = { ...input };
  }

  if (isExternalClientCodeVisible) {
    orderInputs.external_client_reference = { ...input };
  }

  //?
  if (isReferralVisible) {
    orderInputs.referral = {
      mandatory: isReferring,
      visible: true,
      validator: validateAnyNotEmpty,
    };

    if (
      (await AuthenticationHelper.hasPermission("referrals#accept_all")) ||
      ((await AuthenticationHelper.hasPermission("referrals#accept_external")) &&
        order &&
        order.organisation_id &&
        !AuthenticationHelper.isInternalOrganisationId(order.organisation_id))
    ) {
      orderInputs.referral_status = {
        mandatory: false,
        visible: true,
        validator: validateNotEmpty,
      };
    }
  }

  const canEditCosts: boolean = await AuthenticationHelper.hasPermission(["invoices#edit_all", "costings#edit_all"]);

  if (isReferrerVisible) {
    orderInputs.referrer = isReferring ? { ...mandatoryInput } : { ...optionalInput };
  }
  if (isNeedsVisible) {
    if (!feat(Feature.OrdersLegacyIntakeScreens)) {
      orderInputs.needs = {
        care_needs: isRegisteringNeeds ? { ...mandatoryNotEmptyNorFalseInput } : { ...optionalInput },
        usage: isRegisteringNeeds ? { ...mandatoryNotEmptyNorFalseInput } : { ...optionalInput },
        characteristics: isRegisteringNeeds ? { ...mandatoryNotEmptyNorFalseInput } : { ...optionalInput },
        "activities.activities": isRegisteringNeeds ? { ...mandatoryMinimalThreeStringsInput } : { ...optionalInput },
      };
    } else {
      orderInputs.needs = {
        "care_needs.needs": isRegisteringNeeds ? { ...mandatoryInput } : { ...optionalInput },
        "care_needs.activities": isRegisteringNeeds ? { ...mandatoryInput } : { ...optionalInput },
      };
    }
    if (isQuotationVisible) {
      orderInputs.costing = {
        rationale: canEditCosts ? { ...mandatoryInput } : { ...optionalInput },
      };
    }
  }

  let orderLineInputs: OrderLineInputs[] = [];
  if (order && order.order_lines) {
    for (let i = 0; i < order.order_lines.length; i++) {
      const line: OrderLine = order.order_lines[i];
      const product: any | null = products?.find(
        (product: any) => product.code === line.code || product.legacy_product_code === line.code
      );
      const orderType: any = orderTypes?.find((orderType: any) => orderType.name === line.order_type);

      orderLineInputs.push({
        digits: { ...mandatoryInput },
        hand: { ...mandatoryInput },
        measurements: {
          o1: product?.measurements.includes("o1") ? { ...mandatoryInput } : null,
          o2: product?.measurements.includes("o2") ? { ...mandatoryInput } : null,
          o3: product?.measurements.includes("o3") ? { ...mandatoryInput } : null,
          s1: product?.measurements.includes("s1") ? { ...mandatoryInput } : null,
          s2: product?.measurements.includes("s2") ? { ...mandatoryInput } : null,
          l1: product?.measurements.includes("l1") ? { ...mandatoryInput } : null,
          l2: product?.measurements.includes("l2") ? { ...mandatoryInput } : null,
          s: product?.measurements.includes("s") ? { ...mandatoryInput } : null,
          j: product?.measurements.includes("j") ? { ...mandatoryInput } : null,
        },
        order_type: { ...mandatoryInput },
        replacement_for: orderType && orderType.replacement_for_required ? { ...mandatoryInput } : null,
        product_selection: { ...mandatoryInput },
        remake_reason: orderType && orderType.reason_required ? { ...mandatoryInput } : null,
        costing: isQuotationVisible && canEditCosts ? { amount: { ...mandatoryNumberZeroAllowedInput } } : null,
        previous_aid: {
          ...(!feat(Feature.OrdersLegacyIntakeScreens) && isRegisteringNeeds ? mandatoryInput : optionalInput),
        },
        has_previous_aid: {
          ...(!feat(Feature.OrdersLegacyIntakeScreens) && isRegisteringNeeds ? mandatoryInput : optionalInput),
        },
        argumentation: {
          ...(isRegisteringNeeds ? mandatoryInput : optionalInput),
        },
        productDescription: { ...optionalInput },
      } as OrderLineInputs);
    }
  }

  orderInputs.order_lines = orderLineInputs;
  orderInputs.location_id = { ...mandatoryInput };
  orderInputs.practitioner = { ...mandatoryInput };
  orderInputs.delivery_method = { ...mandatoryInput };
  return orderInputs;
};

export const finalNumberCorrection = (props: any) => {
  let result: any = {};
  const keys: string[] = Object.keys(props);
  for (let i = 0; i < keys.length; i++) {
    const key: string = keys[i];
    const value: string = (props[key] || "") + "";

    // We don't want this function to act on the measurements.notes property.
    // Because it is just a proper human sentence; not a number to correct.
    if (["note", "notes"].includes(key)) {
      result[key] = value;
    } else {
      result[key] = value.indexOf(",") > 0 ? value.replace(",", ".") : value;
    }
  }
  return result;
};
