import { Text, View } from "@react-pdf/renderer";
import txt from "helpers/text-helper";
import { addressee, Addressee, formattedAddressParts } from "store/data/adress/address";
import { UzoviCode } from "store/data/insurer/insurer";
import { styles } from "../styles";

export interface MMPdfInsurerAddressProps {
  uzoviCode: UzoviCode;
}

function MMPdfInsurerAddress(props: MMPdfInsurerAddressProps) {
  let uzoviCodeAddressee: Addressee | null = addressee(props.uzoviCode, "financial");

  return (
    <View style={styles.addressPane}>
      <Text style={{ color: "#909090" }}>{txt.get("pdfs.quotation.to")}:</Text>
      {props.uzoviCode.insurer?.name ? <Text>{props.uzoviCode.insurer.name}</Text> : <></>}
      {props.uzoviCode.name ? <Text>{props.uzoviCode.name}</Text> : <></>}

      {uzoviCodeAddressee ? (
        <Text>
          {formattedAddressParts(uzoviCodeAddressee.address, ["street_name", "street_number", "street_number_suffix"])}
        </Text>
      ) : (
        <></>
      )}

      {uzoviCodeAddressee ? (
        <Text>{formattedAddressParts(uzoviCodeAddressee.address, ["postal_area", "city"])}</Text>
      ) : (
        <></>
      )}
      {uzoviCodeAddressee ? <Text>{formattedAddressParts(uzoviCodeAddressee.address, ["country"])}</Text> : <></>}
    </View>
  );
}

export default MMPdfInsurerAddress;
