import {
  EuiBasicTable,
  EuiComboBox,
  EuiFieldSearch,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiLink,
  EuiSpacer,
  EuiText,
} from "@elastic/eui";
import { Fragment, useEffect, useState } from "react";

import ConnectAPIHelper from "api/connect-api-helper";
import txt from "helpers/text-helper";
import { columnDateOnly, columnString } from "hoc/helper-hooks";
import { useNavigate } from "react-router-dom";
import { PersonalDetails } from "store/data/personal-details/personal-details";
import { AssignedDevice } from "store/data/scan/scanner";
import { useLocalStorage } from "store/local-storage";
import { useDebounce } from "use-debounce";

export const PAGE_SIZE_OPTIONS = [10, 25, 50, 100]; //,0]; //0 would mean without limit
export const DEFAULT_PAGE_SIZE = 10;
export const SEARCH_DEBOUNCE_DELAY = 700;
export const DEFAULT_SORT_BY = "name";
export const DEFAULT_SORT_ORDER = "asc";

function MMUsersList() {
  const navigate = useNavigate();
  const api = new ConnectAPIHelper();

  const [users, setUsers] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [limit, setLimit] = useLocalStorage("user_limit", DEFAULT_PAGE_SIZE);
  const [offset, setOffset] = useLocalStorage("user_offset", 0);
  const [total, setTotal] = useState(0);
  const [sortBy, setSortBy] = useLocalStorage("user_sort_by", DEFAULT_SORT_BY);
  const [sortOrder, setSortOrder] = useLocalStorage("user_sort_order", DEFAULT_SORT_ORDER);
  const [searchFieldValue, setSearchFieldValue] = useLocalStorage("user_search", "");
  const [search] = useDebounce(searchFieldValue, SEARCH_DEBOUNCE_DELAY);
  const [error, setError] = useState("");

  const [organisations, setOrganisations] = useState([]);
  const [selectedOrganisations, setSelectedOrganisations] = useLocalStorage("user_organisations", []);
  useEffect(() => {
    const loadOrganisations = async () => {
      const organisations = await api.getOrganisations();
      setOrganisations(organisations);
    };

    loadOrganisations();
  }, []);

  // useEffect(() => {
  //   setSelectedOrganisations([]);
  // }, [organisations]);

  const onOrganisationChange = (selectedOptions: any[]) => {
    setSelectedOrganisations(selectedOptions);
  };

  const onSearchChange = (event: any) => {
    setSearchFieldValue(event.target.value);
  };

  const toSelectOptions = (list: any[]) => {
    return list
      .map((item) => ({
        label: item.name,
        "data-id": item.id,
      }))
      .concat([
        {
          label: txt.uf("generic.without_x", txt.get("admin.organisations.name")),
          "data-id": "_",
        },
      ]);
  };

  useEffect(() => {
    const loadUsers = async () => {
      setIsLoading(true);

      let filters: any = {};
      if (selectedOrganisations && selectedOrganisations.length > 0) {
        filters.organisation_ids = selectedOrganisations.map((organisation: any) => organisation["data-id"]);
      }
      if (search) {
        filters.search = search;
      }

      const result = await api.getAdminUsers(filters, limit, offset, userFieldToSortKey(sortBy), sortOrder);
      if (result.status === "OK") {
        setUsers(result.result);
        setTotal(result.meta_data.result_set.total);
        setError("");
      } else {
        setUsers([]);
        setTotal(0);
        setError(`${result.status} (${result.code}): ${result.message}`);
      }
      setIsLoading(false);
    };

    loadUsers();
  }, [sortOrder, sortBy, limit, offset, selectedOrganisations, search]);

  const resultCountInfo = () =>
    total === 0
      ? txt.uf("generic.found_no_x", txt.get("admin.users.page_title"))
      : txt.uf(
          "generic.showing_x_of_y_found_z",
          limit === 0 ? txt.get("generic.all") : `${offset + 1}-${Math.min(total, offset + limit)}`,
          total,
          txt.get("admin.users.page_title")
        ) + ".";

  const limitOffsetToPage = (limit: number, offset: number) => {
    //pages in EUI are zero based
    const page = limit > 0 ? Math.max(0, offset / limit) : 0;
    return page;
  };

  const pagination: any = {
    pageIndex: limitOffsetToPage(limit, offset),
    pageSize: limit,
    totalItemCount: total,
    pageSizeOptions: PAGE_SIZE_OPTIONS,
    showPerPageOptions: true,
  };

  const sorting: any = {
    sort: {
      field: sortBy,
      direction: sortOrder,
    },
    enableAllColumns: false,
    // readOnly: false,
  };

  const userFieldToSortKey = (field: string) => {
    switch (field) {
      default:
        return field;
    }
  };

  const onUsersChange = ({ page = {} as any, sort = {} as any }) => {
    if (page.size) {
      const newLimit = page.size;
      const newOffset = Math.max(0, page.index * page.size);
      if (limit !== newLimit) setLimit(newLimit);
      if (offset !== newOffset) setOffset(newOffset);
    }
    if (sort.field) {
      setSortBy(sort.field);
      setSortOrder(sort.direction ?? "asc");
    }
  };

  const columns = [
    {
      name: txt.get("admin.users.id"),
      field: "id",
      sortable: api.userIsSortableBy(userFieldToSortKey("id")),
    },
    {
      name: txt.get("account.first_name"),
      field: "first_name",
      sortable: api.userIsSortableBy(userFieldToSortKey("first_name")),
      render: (name: string, user: PersonalDetails) =>
        name ? <EuiLink href={`/admin/users/${user.id}`}>{name}</EuiLink> : "-",
    },
    {
      name: txt.get("account.last_name"),
      field: "last_name",
      sortable: api.userIsSortableBy(userFieldToSortKey("last_name")),
      render: columnString,
    },
    {
      name: txt.get("account.email"),
      field: "email",
      sortable: api.userIsSortableBy(userFieldToSortKey("email")),
      render: columnString,
    },
    {
      name: txt.get("admin.organisations.name"),
      sortable: api.userIsSortableBy(userFieldToSortKey("organisation_id")),
      type: "string",
      field: "organisation_id",
      render: (organisation_id: number, user: PersonalDetails) => columnString(user.organisation?.name || ""),
    },
    {
      name: txt.get("admin.scanners.name_number_unsure"),
      sortable: api.userIsSortableBy(userFieldToSortKey("assigned_devices")),
      field: "assigned_devices",
      render: (assigned_devices: AssignedDevice[]) =>
        assigned_devices
          ? assigned_devices.map((device: AssignedDevice) => (
              <EuiLink href={`/admin/scanners/${device.device_id}`}>{device.device_serial}</EuiLink>
            ))
          : "",
    },
    {
      name: txt.get("generic.created_at"),
      sortable: api.userIsSortableBy(userFieldToSortKey("created_at")),
      type: "date",
      field: "created_at",
      render: columnDateOnly,
    },
    {
      name: txt.get("generic.updated_at"),
      sortable: api.userIsSortableBy(userFieldToSortKey("updated_at")),
      type: "date",
      field: "updated_at",
      render: columnDateOnly,
    },
  ];

  const getRowProps = (organisation: any) => {
    const { id } = organisation;
    return {
      "data-id": `row-${id}`,
      onClick: (e: any) => {
        if (e.target.tagName !== "BUTTON" && e.target.tagName !== "INPUT" && e.target.tagName !== "A") {
          navigate(`/admin/users/${id}`);
        }
      },
    };
  };

  return (
    <Fragment>
      <EuiFlexGroup alignItems="flexStart">
        <EuiFlexItem grow={false}>
          <EuiFieldSearch
            compressed={true}
            placeholder={txt.get("generic.search")}
            value={searchFieldValue}
            isLoading={isLoading}
            isClearable={!isLoading}
            contentEditable={!isLoading}
            onChange={(event: any) => onSearchChange(event)}
            aria-label={txt.get("generic.search")}
          />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiComboBox
            compressed={true}
            style={{ width: "225px" }}
            aria-label={txt.uf("generic.select_x", txt.get("admin.organisations.name"))}
            placeholder={txt.uf("generic.select_x", txt.get("admin.organisations.name"))}
            // singleSelection={{ asPlainText: true }}
            options={toSelectOptions(organisations)}
            selectedOptions={selectedOrganisations}
            onChange={onOrganisationChange}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiFlexGroup alignItems="flexEnd">
        <EuiFlexItem>
          <EuiText textAlign="right" size="xs">
            {resultCountInfo()}
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size="s" />
      <EuiHorizontalRule margin="none" style={{ height: 1 }} />
      <EuiBasicTable
        loading={isLoading}
        tableLayout="auto"
        itemId="id"
        items={users}
        columns={columns}
        pagination={pagination}
        sorting={sorting}
        rowProps={getRowProps}
        noItemsMessage={error ? error : txt.uf("generic.found_no_x", txt.get("admin.users.page_title"))}
        onChange={onUsersChange}
      />
    </Fragment>
  );
}

export default MMUsersList;
